import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Button } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { createOrder } from "../actions/orderActions";
import { useNavigate } from "react-router-dom";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

function CashOnDelivery({ userInfo, orderState, voucherApplied, couponCode }) {
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);

  const orderCreate = useSelector((state) => state?.orderCreate);
  const { order, success } = orderCreate;

  const [orderData, setOrderData] = useState();
  const [loading, setLoading] = useState(false);

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo?.token}`,
    },
  };

  const sentOrderConfirmation = (orderData) => {
    axios.post(`/api/orders/sendOrderConfirmation/`, { ...orderData }, config);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (order) {
      navigate(`../orderplaced/${order?.id}`, { replace: true });
      dispatch({ type: ORDER_CREATE_RESET });
      if (orderData) {
        sentOrderConfirmation(orderData);
        setOrderData({});
      }
    }
  }, [success]);

  const isPaySuccess = async (uniqueId) => {
    const orderData = {
      orderItems: orderState.cartItemsDataBase,
      shippingAddress: cart.shippingAddress,
      paymentMethod: "COD",
      itemsPrice: cart.itemsPrice,
      deliveryCharge: orderState.deliveryChargePrice,
      totalPrice:
        Number(
          voucherApplied === 0 ? orderState.checkoutTotal : voucherApplied
        ) + Number(49.0),
      discountPrice: orderState.discountPrice,
      unique_id: uniqueId[0],
      payment_status: "pending",
      order_item_count: orderState.cartItemsDataBase?.length,
      payment_id: null,
      couponApplied: voucherApplied !== 0,
      couponCode: voucherApplied === 0 ? "" : couponCode,
      cod_charge: 49.0,
    };

    dispatch(createOrder(orderData));
    setOrderData(orderData);
    dispatch({ type: ORDER_CREATE_RESET });
  };

  const MakeOrder = async () => {
    const uniqueId = await generateUniqueID();
    isPaySuccess(uniqueId);
    setLoading(false);
  };

  const generateUniqueID = async () => {
    const unique_id = [];
    while (true) {
      const id = uuidv4().toString().split("-").pop();
      const { data } = await axios.get(`/api/orders/check-order-id/${id}/`);
      if (data[0] === "notExist") {
        unique_id.push(id);
        break;
      }
    }
    return unique_id;
  };

  const askConfrimationAlert = () => {
    setLoading(true);
    Swal.fire({
      title: "Confirm order ",
      text: "Are you sure you want to confirm this order ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#000000",
      cancelButtonColor: "#a9a9a9",
      confirmButtonText: "confirm",
    })
      .then((result) => {
        if (result.isConfirmed) {
          MakeOrder();
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return (
    <div className="App">
      <header className="App-header">
        <Button
          type="submit"
          onClick={askConfrimationAlert}
          className="text-center  bg-primary  h-12 flex justify-center items-center text-white text-[15px] font-medium capitalize  rounded-lg  my-8  w-full"
        >
          {loading ? (
            <CircularProgress size={30} sx={{ color: "white" }} />
          ) : (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                />
              </svg>
              place order
            </>
          )}
        </Button>
      </header>
    </div>
  );
}

export default CashOnDelivery;
