export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const CART_ITEM_REQUEST = "CART_ITEM_REQUEST";
export const ITEM_ADD_SUCCESS = "ITEM_ADD_SUCCESS";
export const ITEM_ADD_FAIL = "ITEM_ADD_FAIL";

export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";

export const CART_SAVE_SHIPPING_ADDRESS = "CART_SAVE_SHIPPING_ADDRESS";

export const CART_SAVE_PAYMENT_METHOD = "CART_SAVE_PAYMENT_METHOD";

export const CART_CLEAR_ITEMS = "CART_CLEAR_ITEMS";

export const WISH_ADD_ITEM = "WISH_ADD_ITEM";
export const WISHITEM_ADD_FAIL = "WISHITEM_ADD_FAIL";
export const WISH_REMOVE_ITEM = "WISH_REMOVE_ITEM";
export const WISH_CLEAR_ITEMS = "WISH_CLEAR_ITEMS";

export const CARTDATABASE_LIST_REQUEST = "CARTDATABASE_LIST_REQUEST";
export const CARTDATABASE_LIST_SUCCESS = "CARTDATABASE_LIST_SUCCESS";
export const CARTDATABASE_LIST_FAIL = "CARTDATABASE_LIST_FAIL";

export const CARTDATABASE_PRODUCTLIST_REQUEST =
  "CARTDATABASE_PRODUCTLIST_REQUEST";
export const CARTDATABASE_PRODUCTLIST_SUCCESS =
  "CARTDATABASE_PRODUCTLIST_SUCCESS";
export const CARTDATABASE_PRODUCTLIST_FAIL = "CARTDATABASE_PRODUCTLIST_FAIL";
export const CARTDATABASE_CLEAR_ITEMS = "CARTDATABASE_CLEAR_ITEMS";

export const CARTDATABASE_ADD_REQUEST = "CARTDATABASE_ADD_REQUEST";
export const CARTDATABASE_ADD_SUCCESS = "CARTDATABASE_ADD_SUCCESS";
export const CARTDATABASE_ADD_FAIL = "CARTDATABASE_ADD_FAIL";

export const CARTDATABASE_DELETE_REQUEST = "CARTDATABASE_DELETE_REQUEST";
export const CARTDATABASE_DELETE_SUCCESS = "CARTDATABASE_DELETE_SUCCESS";
export const CARTDATABASE_DELETE_FAIL = "CARTDATABASE_DELETE_FAIL";

export const WISHDATABASE_ADD_REQUEST = "CARTDATABASE_ADD_REQUEST";
export const WISHDATABASE_ADD_SUCCESS = "CARTDATABASE_ADD_SUCCESS";
export const WISHDATABASE_ADD_FAIL = "CARTDATABASE_ADD_FAIL";

export const WISHDATABASE_DELETE_REQUEST = "WISHDATABASE_DELETE_REQUEST";
export const WISHDATABASE_DELETE_SUCCESS = "WISHDATABASE_DELETE_SUCCESS";
export const WISHDATABASE_DELETE_FAIL = "WISHDATABASE_DELETE_FAIL";

export const WISHDATABASE_LIST_REQUEST = "WISHDATABASE_LIST_REQUEST";
export const WISHDATABASE_LIST_SUCCESS = "WISHDATABASE_LIST_SUCCESS";
export const WISHDATABASE_LIST_FAIL = "WISHDATABASE_LIST_FAIL";
export const WISHDATABASE_CLEAR_ITEMS = "WISHDATABASE_CLEAR_ITEMS";

export const CARTDATABASE_UPDATE_REQUEST = "CARTDATABASE_UPDATE_REQUEST";
export const CARTDATABASE_UPDATE_SUCCESS = "CARTDATABASE_UPDATE_SUCCESS";
export const CARTDATABASE_UPDATE_FAIL = "CARTDATABASE_UPDATE_FAIL";
