import {
  BLOG_CATEGORY_FAIL,
  BLOG_CATEGORY_REQUEST,
  BLOG_CATEGORY_SUCCESS,
  BLOG_CONTENT_FAIL,
  BLOG_CONTENT_REQUEST,
  BLOG_CONTENT_SUCCESS,
  BLOGS_FAIL,
  BLOGS_REQUEST,
  BLOGS_SUCCESS,

} from "../constants/blogConstants";

export const getBlogReducer = (
  state = { categories: [], blogs: [] , blogContent : {} },
  action
) => {
  switch (action.type) {
    case BLOG_CATEGORY_REQUEST:
      return { loading: true, ...state, categories: [] };

    case BLOG_CATEGORY_SUCCESS:
      return { loading: false, ...state, categories: action.payload };

    case BLOG_CATEGORY_FAIL:
      return { loading: false, ...state, error: action.payload };

    case BLOGS_REQUEST:
      return { loading: true, ...state, blogs: [] };

    case BLOGS_SUCCESS:
      return { loading: false, ...state, blogs: action.payload };

    case BLOGS_FAIL:
      return { loading: false, ...state, error: action.payload };

    case BLOG_CONTENT_REQUEST:
      return { loading: true, ...state, blogContent: [] };

    case BLOG_CONTENT_SUCCESS:
      return { loading: false, ...state, blogContent: action.payload };

    case BLOG_CONTENT_FAIL:
      return { loading: false, ...state, error: action.payload };
    
    default:
      return state;
  }
};
