import React, { useState, useEffect, useCallback, Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import InnerImageZoom from "react-inner-image-zoom"
import { Button } from "@material-tailwind/react"
import {
  listProductDetails,
  listFabrics,
  listCategories,
  listStyles,
  listProductReviews,
} from "../actions/productActions"
import { useLocation, useParams } from "react-router-dom"
import {
  addToCart,
  CartDataBaseAdd,
  listCartDataBase,
  listWishDataBase,
  updateCartDataBase,
  WishDataBaseAdd,
} from "../actions/cartActions"
import { listItemImages } from "../actions/productActions"
import { listProductItem } from "../actions/productActions"
import { listColors } from "../actions/productActions"
import { listSizes } from "../actions/productActions"
import RelatedProducts from "../components/RelatedProducts"
import Zoom from "react-medium-image-zoom"
import { TbTruckDelivery } from "react-icons/tb"
import { BsFillHandbagFill, BsHeart, BsListStars } from "react-icons/bs"
import { BiDetail } from "react-icons/bi"
import "react-multi-carousel/lib/styles.css"
import "../css/style.css"
import "../css/sizeselect.css"
import "../css/reviewAlert.css"
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css"
import "react-medium-image-zoom/dist/styles.css"
import Loader from "../components/Loader"
import Error404 from "../components/ServerErrorPage"
import Swal from "sweetalert2"
import ReviewView from "../components/ReviewView"
import Writereview from "../components/Writereview"
import Select from "react-select"
import axios from "axios"
import ProgressiveImage from "react-progressive-graceful-image"
import load from "../images/mYUNDE LOGO.jpg"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import SupeRzoom from "../components/SupeRzoom"
import LoginSlide from "./LoginSlide"
import ProfileScreen from "./ProfileScreen"
import CartPage from "./CartPage"
import { CircularProgress, Tooltip } from "@mui/material"
import Carousel from "react-multi-carousel"
import { MdOutlineLocalOffer } from "react-icons/md"
import { Helmet } from "react-helmet"
import png from "../images/myundePng.png"
import PrintInitial from "../components/PrintInitial"
import { useForm } from "react-hook-form"
// import Sizechart from "../components/Size";

const starsGenerate = (value) => {
  return [
    [...Array(value)]?.map((x, i) => {
      return (
        <svg
          key={i}
          xmlns="http://www.w3.org/2000/svg"
          fill="white"
          viewBox="0 0 24 24"
          strokeWidth={0.5}
          stroke="rgb(0,0,0)"
          className="w-5 h-5 ml-1  "
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
          />
        </svg>
      )
    }),
  ]
}

const stars = [
  { value: 1, label: "1 -", icon: starsGenerate(1) },
  { value: 2, label: `2 -`, icon: starsGenerate(2) },
  { value: 3, label: `3 - `, icon: starsGenerate(3) },
  { value: 4, label: `4 - `, icon: starsGenerate(4) },
  { value: 5, label: `5 - `, icon: starsGenerate(5) },
]

const responsive = {
  laptop: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 768, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const FinalProductScreen = () => {
  const [open, setOpen] = useState(false)
  const [Cart, setCart] = useState(false)
  const [size, setSize] = useState(false)
  const [reviewOpen, setReviewOpen] = useState(false)
  const [state, setState] = useState(false)
  const search = useLocation().search

  const forZoom = () => {
    setOpen(!open)
  }

  const forReview = () => {
    setReviewOpen(!reviewOpen)
  }
  const forCartOpen = () => {
    setCart(!Cart)
  }

  const forSizeChartOpen = () => {
    setSize(!size)
  }

  const forLogin = () => {
    setState(!state)
  }

  const forProfileScreen = useCallback(() => {
    setState({ ...state, profilescreen: !state?.profilescreen })
  }, [state])

  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (params?.id) {
      dispatch(listItemImages(params.id))
      dispatch(listProductItem(params.id))
      dispatch(listProductDetails(params.id))
      dispatch(listProductReviews(params.id))
      dispatch(listSizes("", params.id))
      dispatch(listColors(params.id))
    }
    if (!fabrics?.length) {
      dispatch(listFabrics())
    }
    if (!category?.length) {
      dispatch(listCategories())
    }
    if (!styles?.length) {
      dispatch(listStyles())
    }

    setStateColor()
  }, [params?.id])

  const [qty, setQty] = useState(1)

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;

  const ItemImagesList = useSelector((state) => state.itemimages)
  const { itemimages } = ItemImagesList

  // const item = itemimages?.filter((p) => p.product === product?.id);

  const productItemList = useSelector((state) => state.productitem)
  const { productitem } = productItemList

  const productInitialColor =
    new URLSearchParams(search).get("clr") == 0
      ? productitem[0]?.color
      : new URLSearchParams(search).get("clr")

  const colorsList = useSelector((state) => state.colorsList)
  const { soloColor } = colorsList

  const sizesList = useSelector((state) => state.sizesList)
  const { soloSizes } = sizesList

  const brandList = useSelector((state) => state.brandList)
  const { brand } = brandList

  const fabricsList = useSelector((state) => state.fabricsList)
  const { fabrics } = fabricsList

  const categoryList = useSelector((state) => state.categoryList)
  const { category } = categoryList

  const reviewList = useSelector((state) => state.productReviews)
  const { productsReviews } = reviewList

  const stylesList = useSelector((state) => state.stylesList)
  const { styles } = stylesList

  const dataBaseWish = useSelector((state) => state.dataBaseWish)
  const { wishItemsDataBase } = dataBaseWish

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [filterRating, setFilterRating] = useState({ value: 5 })

  const filteredreviews = productsReviews[0]?.reviews?.filter(
    (review) => review.rating === Number(filterRating.value)
  )

  const [stateSize, setStateSize] = useState()
  const [statecolor, setStateColor] = useState()

  const selectedColor = soloColor?.filter((color) => color?.id === statecolor)

  // const ifstatecolorUndefined = itemimages
  //   ? itemimages[0]?.color
  //   : productColor[0];

  const productOnScreen = productitem?.filter(
    (p) =>
      p.color === Number(statecolor ?? productInitialColor) && p.active === true
  )

  const orderingProduct = productOnScreen.filter((s) => s.size === stateSize)

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const dataBaseCart = useSelector((state) => state.dataBaseCart)
  const { cartItemsDataBase, cartProductItemsDataBase } = dataBaseCart

  let cartItemsDBorLS = userInfo ? cartItemsDataBase : cartItems

  const [checkpincode, setcheckPincode] = useState()
  const [isAvailable, setIsAvailable] = useState()

  const pincodeCheck = async () => {
    if (checkpincode?.length === 6 && !isNaN(Number(checkpincode))) {
      await axios
        .get(`/api/shipping/shipping-availability/${checkpincode}`)
        .then((data) => {
          let availability =
            JSON.parse(data.data)?.message ?? "Delivery serivce is available"
          setIsAvailable(availability)
        })
    } else {
      setIsAvailable("enter a valid pincode to check")
    }
  }

  const sizeSwitch = (value) => {
    const cat = category?.filter(
      (cat, i) => Number(cat?.id) === Number(product?.category)
    )[0]

    const chest = [
      "t-shirt",
      "tank top",
      "vest undershirt",
      "gym vest",
    ].includes(cat?.name?.toLowerCase())

    switch (value) {
      case "s":
        if (chest) {
          return "chest 86-94cm"
        }
        return "waist 32 cm"

      case "m":
        if (chest) {
          return "chest 96-104cm"
        }
        return "waist 34 cm"

      case "l":
        if (chest) {
          return "chest 106-114cm"
        }
        return "waist 36 cm"
      case "xl":
        if (chest) {
          return "chest 117-124cm"
        }
        return "waist 38 cm"
      case "xxl":
        if (chest) {
          return "chest 127-135cm"
        }
        return "waist 40 cm"
      case "0":
        return "Out of stock !"

      case "free size":
        return "FREE SIZE"
      default:
        return "s"
    }
  }

  const addToWishAlert = (value) => {
    Swal.fire({
      toast: true,
      position: "center",

      icon: "success",
      title: `${value} is Added to wishlist`,
      showConfirmButton: false,
      timer: 1500,
    })
  }

  const alreadyInWishAlert = (value) => {
    Swal.fire({
      toast: true,
      position: "center",
      icon: "warning",
      title: `${value} is already in wishlist`,
      showConfirmButton: false,
      timer: 1500,
    })
  }

  const AddToCartAlert = (value) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: false,
      timer: 2000,

      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer)
        toast.addEventListener("mouseleave", Swal.resumeTimer)
      },
    })

    if (stateSize) {
      Toast.fire({
        icon: "success",
        title: `${value.slice(0, 10)}... is Added to Cart`,
      })
    }
    if (!stateSize) {
      Toast.fire({
        icon: "warning",
        title: "Select size befor adding",
      })
    }
  }

  const AlreadyInCartAlert = (value) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: false,
      timer: 2000,

      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer)
        toast.addEventListener("mouseleave", Swal.resumeTimer)
      },
    })
    Toast.fire({
      icon: "warning",
      title: `${value}`,
    })
  }

  const AddToCart = (productname, printValue) => {
    if (!stateSize) {
      AddToCartAlert(productname)
    }
    if (
      cartItemsDBorLS
        .map((Car) => Car.product_item)
        .includes(orderingProduct[0]?.id) === true
    ) {
      AlreadyInCartAlert("product is already in cart")
    } else {
      if (orderingProduct.length) {
        AddToCartAlert(productname?.slice(0, 20))
        if (userInfo) {
          return dispatch(
            CartDataBaseAdd(
              orderingProduct[0]?.id,
              qty,
              printValue?.print_value
            )
          )
            .then(() => {
              dispatch(listCartDataBase(userInfo?.id, ""))
            })
            .then(() => {
              forCartOpen()
            })
        } else {
          return dispatch(
            addToCart(orderingProduct[0]?.id, qty, printValue?.print_value)
          )
            .then(() => {
              dispatch(listCartDataBase(0, localStorage.getItem("cartItems")))
            })
            .then(() => {
              forCartOpen()
            })
        }
      }
    }
  }

  const addToWishList = (productItem) => {
    if (userInfo) {
      dispatch(WishDataBaseAdd(productItem?.id)).then((data) => {
        if (data === "success") {
          dispatch(listWishDataBase(userInfo?.id))
        }
      })
      if (
        wishItemsDataBase?.filter((i) => i?.product_item === productItem?.id)[0]
          ?.product_item ||
        wishItemsDataBase
          ?.map((i) => i?.product_item.id)
          .includes(productItem?.id)
      ) {
        alreadyInWishAlert(product?.name)
      } else {
        addToWishAlert(product?.name)
      }
    } else {
      forLogin()
    }
  }

  const ratingFilter = (numberOfRating) => {
    return productsReviews[0]?.reviews?.filter(
      (review) => review.rating === Number(numberOfRating)
    ).length
  }

  const progressBarFIlter = (numberOfRating) => {
    const totalNumberOfPeople = productsReviews[0]?.reviews?.length
    const numberOfPeople = Number(
      productsReviews[0]?.reviews?.filter(
        (review) => Number(review.rating) === Number(numberOfRating)
      ).length
    )

    return (Number(numberOfPeople) / Number(totalNumberOfPeople)) * 100
  }

  const reviewBarColor = (switchValue) => {
    let barColor = ""

    switch (switchValue) {
      case 5:
        return (barColor = "green")

      case 4:
        return (barColor = "green")

      case 3:
        return (barColor = "green")

      case 2:
        return (barColor = "yellow")

      case 1:
        return (barColor = "orange")

      default:
        return (barColor = "brand")
    }
  }

  const averageRating = productsReviews[0]?.reviews.reduce(
    (prevalue, curvalue) => {
      return { rating: prevalue.rating + curvalue.rating }
    },
    { rating: 0 }
  )

  const totalAverageRating = () => {
    let ratingAvg = (
      averageRating?.rating / productsReviews[0]?.reviews.length
    ).toFixed(1)
    if (isNaN(ratingAvg)) {
      return 0
    }
    return ratingAvg
  }

  const { productoffers } = useSelector((state) => state.productoffers)

  const [img, setImg] = useState()

  const hoverHandler = (image, i) => {
    setImg(image)
  }

  let itemimage = itemimages?.filter(
    (item) => item.color === (statecolor ?? Number(productInitialColor))
  )

  let startColorName = soloColor?.filter(
    (color) => color?.id === Number(productInitialColor)
  )
  let selectedStartColorName = startColorName?.length
    ? startColorName[0]?.name
    : ""

  const productSize = Array.from(
    new Set(
      productitem
        .filter(
          (productitem) =>
            Number(productitem.color) ===
              Number(statecolor ?? productInitialColor) &&
            Number(productitem?.quantity) !== 0
        )
        .map((size) => {
          return size.size
        })
    )
  )

  const setZoomImage = (colorId) => {
    setImg(
      itemimages?.filter((item) => item.color === Number(colorId))[0]?.image
    )
  }

  const productDescription = product?.description?.split("*")

  const offer = productoffers.find((val) => val.product === product.id)

  if (!productSize?.includes(Number(stateSize)) && stateSize) {
    setStateSize()
  }

  const categoryName = category?.filter(
    (cat) => cat?.id === product?.category
  )[0]?.name

  const brandName = brand?.filter((bra) => bra.id === product.brand)[0]?.name

  const [printOpen, setPrintOpen] = useState(false)

  const [printValue, setPrintValue] = useState({})
  const [loadingDelivery, setLoadingDelivery] = useState(false)
  const handlePrintOpen = () => {
    setPrintOpen(!printOpen)
  }

  useEffect(() => {
    if (
      cartItemsDBorLS
        .map((Car) => Car.product_item)
        .includes(orderingProduct[0]?.id)
    ) {
      const valueData = cartItemsDBorLS.filter(
        (data) => data.product_item === orderingProduct[0]?.id
      )
      // setPrintValue(
      //   valueData[0]?.print_value !== ""
      //     ? { initial: valueData[0]?.print_value }
      //     : {}
      // )
    } else {
      // setPrintValue({})
    }
  }, [stateSize, addToCart, statecolor])

  useEffect(() => {
    const customerPrint = cartItemsDBorLS.filter(
      (Car) => Car.product_item === orderingProduct[0]?.id
    )[0]

    if (customerPrint) {
      setPrintValue(customerPrint)
    }
  }, [cartItemsDBorLS, orderingProduct])

  const selectedProductItem = cartItemsDBorLS
    .map((Car) => Car.product_item)
    .includes(orderingProduct[0]?.id)

  const [render, setRender] = useState(null)

  const updatePrintValue = (dataBaseQty, quantity, PrintValue) => {
    if (userInfo) {
      dispatch(
        updateCartDataBase(
          { ...dataBaseQty, print_value: PrintValue ?? "" },
          quantity
        )
      ).then(() => {
        setRender(() => {
          const cartItem = cartItemsDBorLS.find(
            (value) => value.id === dataBaseQty.id
          )

          cartItem.quantity = quantity
          cartItem.print_value = PrintValue
          return {}
        })
      })
    } else {
      dispatch(addToCart(dataBaseQty?.id, Number(quantity), PrintValue))
    }
  }

  const onSubmit = (data) => {
    setPrintValue({ ...printValue, print_value: data?.initial })
    setPrintOpen(false)
    if (printValue?.id) {
      if (Object.keys(data ?? {})?.length) {
        updatePrintValue(printValue, printValue.quantity, data?.initial)
      } else {
        updatePrintValue(printValue, printValue.quantity, undefined)
      }
    }
  }

  const HandleRemovePrintValue = () => {
    if (selectedProductItem === true) {
      onSubmit()
    } else {
      setPrintValue({})
    }
  }

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm()

  const DeliveryAvailable = async (data) => {
    setLoadingDelivery(true)
    var pincode = Number(data.pincode)
    await axios
      .get(`/api/shipping/shipping-availability/${pincode}`)
      .then((data) => {
        let availability = JSON.parse(data.data)?.message

        if (availability === undefined) {
          setError("pincode", { message: "Delivery service available" })
          setLoadingDelivery(false)
        } else {
          setError("pincode", { message: "Delivery service is not available" })
          setLoadingDelivery(false)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <>
      <Helmet>
        <title>
          {`Buy Myunde ${product?.name ?? "this product"}  at Rs.${
            product?.price
          } online | ${categoryName ?? "Myunde"} online`}
        </title>
      </Helmet>

      <CartPage forCartOpen={forCartOpen} open={Cart} cartIcon={true} />
      {loading ? (
        <Loader />
      ) : error ? (
        // <Message variant="danger">{error}</Message>
        <Error404 />
      ) : (
        <div className="select-none lg:-mt-5 font-style ">
          <div className="md:flex justify-center">
            <div className="  md:w-[95%] xl:w-[90%]  lg:px-4">
              <div className=" ">
                <div className=" lg:grid grid-cols-5   ">
                  <div className="flex justify-center sm:hidden relative ">
                    <div className=" lg:w-80 md:w-96 w-80 overflow-hidden   m-4  ">
                      <Carousel
                        responsive={responsive}
                        infinite={true}
                        keyBoardControl={true}
                        removeArrowOnDeviceType={["tablet", "mobile", "laptop"]}
                        dotListClass="custom-dot-list-style"
                        className="absolute mb-3"
                        showDots={true}
                        autoPlay={false}
                      >
                        <div>
                          <img
                            src={productOnScreen[0]?.image}
                            alt={`Myunde ${
                              product.name ?? "Speciallty for men"
                            } `}
                            className=" "
                          />
                        </div>
                        {itemimage?.map((item, i) => {
                          return (
                            <div key={i}>
                              <img
                                src={item.image}
                                alt={`Myunde ${
                                  product.name ?? "Speciallty for men"
                                } `}
                                className=" "
                              />
                            </div>
                          )
                        })}
                      </Carousel>
                    </div>
                  </div>

                  <div className=" hidden sm:block col-span-3   lg:sticky top-40 lg:h-screen ">
                    <div
                      className=" flex flex-col-reverse lg:flex-row w-full  h-[50%]  lg:h-[75%]   relative p-3 md:rounded-lg shadow-lg "
                      style={{
                        background:
                          "linear-gradient(49deg, rgba(227,242,255,0.5) 0%, rgba(249,247,240,0.5) 87%)",
                      }}
                    >
                      <div className=" lg:w-[20%] 2xl:w-[10%] flex justify-start sm:justify-center overflow-x-scroll w-[98%]  style-4  my-2 lg:my-0 ">
                        <div className=" h-full    lg:overflow-y-scroll style-4 flex lg:flex-col lg:pr-2 ">
                          <ProgressiveImage
                            src={productOnScreen[0]?.image ?? load}
                            placeholder={load}
                            className="w-20 lg:w-full"
                          >
                            {(src) => (
                              <div
                                className="relative   mr-3 lg:mx-0 mb-2 rounded-lg w-20 lg:w-full"
                                onClick={() =>
                                  hoverHandler(productOnScreen[0]?.image)
                                }
                              >
                                <div
                                  className={
                                    productOnScreen[0]?.image === img || !img
                                      ? ""
                                      : "bg-black/30 absolute w-full h-full  rounded-lg"
                                  }
                                ></div>
                                <img
                                  src={src}
                                  alt={`Myunde ${
                                    product.name ?? "Speciallty for men"
                                  } `}
                                  className={
                                    productOnScreen[0]?.image === img
                                      ? "w-full   rounded-lg  "
                                      : "w-full   rounded-lg"
                                  }
                                />
                              </div>
                            )}
                          </ProgressiveImage>
                          {itemimage?.map((image, i) => {
                            return (
                              <ProgressiveImage
                                src={image.image}
                                placeholder={load}
                                key={i}
                                className="w-20 lg:w-full"
                              >
                                {(src) => (
                                  <div
                                    className="relative  mr-3 lg:mx-0 mb-2 rounded-lg w-20 lg:w-full"
                                    onClick={() => hoverHandler(image.image, i)}
                                  >
                                    <div
                                      className={
                                        image.image === img
                                          ? ""
                                          : "bg-black/30 absolute w-full h-full  rounded-lg"
                                      }
                                    ></div>
                                    <img
                                      src={src}
                                      key={i}
                                      alt={`Myunde ${
                                        product.name ?? "Speciallty for men"
                                      } `}
                                      className={
                                        image.image === img
                                          ? "w-full   rounded-lg  "
                                          : "w-full   rounded-lg"
                                      }
                                    />
                                  </div>
                                )}
                              </ProgressiveImage>
                            )
                          })}
                        </div>
                      </div>

                      <div className=" w-[80%] 2xl:w-[90%] flex justify-center mx-auto p-2 relative group ">
                        {/* <div className="z-10 box--gradient silver group-hover:z-0 font-style rounded-b-lg text-white capitalize  bg-gray-300 shadow-lg p-2  md:p-4  absolute left-0  md:-mt-5   md:left-2  text-center">
                        
                        <span className=""> OFFER </span> <br/> buy 1 get 1
                      
                      </div> */}
                        <div className="flex justify-end absolute  right-0   ">
                          <div
                            onClick={() => setOpen(true)}
                            className="flex  items-center z-10 m-3 shadow-sm   group-hover:bg-white/70  duration-500  rounded-lg"
                          >
                            <div className="transition font-style  ease-in-out  invisible py-1 group-hover:duration-500  group-hover:w-24 w-0  group-hover:visible ml-2 rounded-lg">
                              Superzoom
                            </div>
                            <div className=" bg-white/90   group-hover:bg-white/0 rounded-lg">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="black"
                                className="w-7 h-7 pr-1"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M7.5 3.75H6A2.25 2.25 0 003.75 6v1.5M16.5 3.75H18A2.25 2.25 0 0120.25 6v1.5m0 9V18A2.25 2.25 0 0118 20.25h-1.5m-9 0H6A2.25 2.25 0 013.75 18v-1.5M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                        <div className=" hover:w-full relative overflow-hidden flex justify-center items-center">
                          <InnerImageZoom
                            fullscreenOnMobile={false}
                            fadeDuration={500}
                            hideHint={false}
                            zoomType="hover"
                            zoomPreload={true}
                            src={img ?? productOnScreen[0]?.image ?? load}
                            hideCloseButton={true}
                            moveType="pan"
                            className=" w-auto xs:w-3/5 lg:w-[80%] md:w-1/2     2xl:w-[50%]  lg:hover:w-full lg:hover:h-full "
                          />
                        </div>

                        <SupeRzoom
                          zoomImages={itemimage}
                          firstImage={productOnScreen[0]}
                          forZoom={forZoom}
                          openZoom={open}
                        />
                      </div>
                    </div>
                  </div>

                  <div className=" col-span-2 ml-2 lg:ml-5 mt-6  lg:mt-9  bg-white/20 backdrop-blur-[1px]">
                    <div className="">
                      <div>
                        {brandName?.toLowerCase() === "myunde" ? (
                          <div className="w-28 h-8">
                            <img src={png} alt="bra.name" />
                          </div>
                        ) : (
                          <p
                            className="
                              
                                   text-[12px] md:text-lg  truncate uppercase
                                 text-gray-900 "
                          >
                            {brandName}
                          </p>
                        )}
                      </div>

                      <div className="py-2 text-lg font-style text-gray-500 capitalize w-full">
                        {product.name}
                        {statecolor ? (
                          <span className=""> - {selectedColor[0]?.name}</span>
                        ) : (
                          <> - {selectedStartColorName}</>
                        )}
                        {/* {selectedSize ? <p> ({selectedSize[0]?.name})</p> : ""} */}
                        {/* <div className="flex items-center ">
                        {stateSize  ? 
                          
                          
                            <Tooltip
                              title={`selected size - ${selectedSize[0]?.name}`}
                              arrow
                              placement="top"
                            >
                              <p className="mr-2">{selectedSize[0]?.name}</p> 
                            </Tooltip>    : "" }
                          
                            {statecolor  ? <Tooltip
                              title={`selected color - ${selectedColor[0]?.name}`}
                              arrow
                              placement="top"
                            >
                               <img
                              src={selectedColor[0]?.image}
                              alt={selectedColor[0]?.name}
                              className="w-5 h-5 rounded-full ml-2"
                            /> 
                            </Tooltip>:""}
                         </div> */}
                      </div>
                      <div className=" text-sm font-style text-gray-500 capitalize flex-wrap mr-2 xs:mx-0">
                        {productDescription ? productDescription[0] : ""}
                        {product.pack_of > 1 ? (
                          <span className="text-primary">
                            ( Pack of {product.pack_of} )
                          </span>
                        ) : (
                          ""
                        )}
                      </div>

                      <Button
                        onClick={forReview}
                        className="flex border p-2 my-3 hover:bg-white bg-white hover:border-gray-400 capitalize text-gray-600 items-center shadow-none"
                      >
                        <p className="flex border-r-2 px-2 items-center ">
                        <p className="mx-1 text-[13px] ">
                          {totalAverageRating()}
                          </p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="rgb(255,191,0,0.8)"
                            viewBox="0 0 24 24"
                            stroke-width="1"
                            stroke="rgb(255,191,0)"
                            class="w-5 h-5 "
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                            ></path>
                          </svg>
                        </p>
                        <p className="mx-2">
                          ({productsReviews[0]?.reviews?.length}) review
                        </p>
                      </Button>
                    </div>
                    {offer?.offer?.type === "discount" ? (
                      <>
                        <div className="flex  items-center">
                          <p className="sm:text-2xl text-lg text-primary ">
                            ₹
                            {(
                              product.mrp -
                              product?.mrp * (offer?.offer?.value / 100)
                            ).toFixed(2)}
                            <small className="text-gray-500"> MRP </small>
                            <del className="sm:text-2xl text-lg  text-gray-500  font-style ">
                              ₹{product?.mrp}
                            </del>
                          </p>
                          <p className="  text-[#ef3237] ml-1 text-sm lg:text-lg">
                            ({offer?.offer?.value} % OFFER)
                          </p>
                          {/* <del className="text-sm  ml-1    text-gray-600  font-style">
                            <small>₹</small>
                            {product?.price}
                          </del> */}
                          {/* <p className="text-sm  ml-1   text-ptimary  font-style">
                            Discount offer Applied
                          </p> */}
                        </div>
                        {/* <p className="text-xl  mt-1 font-style text-gray-500">
                         
                        </p> */}
                      </>
                    ) : (
                      <div className="flex items-center ">
                        <p className="sm:text-2xl text-lg text-primary ">
                          <small>₹</small>
                          {product?.price}
                        </p>
                        <p className=" mr-2 ml-2 font-style sm:text-2xl text-lg text-primary ">
                          <small className="text-gray-500 "> MRP </small>
                          <del className="sm:text-2xl text-lg  text-gray-500  font-style ">
                            ₹{product?.mrp}
                          </del>
                        </p>

                        <p className="  text-[#ef3237] ml-1 text-sm lg:text-lg">
                          ({product?.percentage} % OFFER)
                        </p>
                      </div>
                    )}

                    <p className="text-[#03a685]  text-sm ">
                      Inclusive of all taxes
                    </p>

                    {/* {offer && (
                      <div className="capitalize text-white  bg-primary w-fit font-style rounded-lg shadow-lg  my-3">
                        {offer?.offer?.type === "custom" && (
                          <p className="p-2 flex items-center px-4">
                            <MdOutlineLocalOffer className="w-5 h-5 mr-1" />
                            {offer?.offer?.value} Offer
                          </p>
                        )}
                        {offer?.offer?.type === "discount" && (
                          <p className="p-2 flex items-center px-4">
                            <MdOutlineLocalOffer className="w-5 h-5 mr-1" />
                            {offer?.offer?.value} % discount
                          </p>
                        )}
                      </div>
                    )} */}
                    <div className="flex pt-2  capitalize">
                      <p className="text-xl  text-gray-700">select color</p>
                    </div>
                    <div className="flex  justify-between mt-2">
                      <div className=" stock-images   flex  mr-2 sm:w-screen style-4  justify-start  overflow-x-auto">
                        {/* {colors?.map((color, i) => {
                        if (productColors?.has(color.id))
                          return (
                            <div className="p-1" key={i}>
                              <input
                                id={color.id}
                                name="same-group-name1"
                                type="radio"
                                className="hidden "
                              />
                              <label htmlFor={color.id}>
                                <div
                                  className="image "
                                  style={{
                                    backgroundColor: `${color.code}`,
                                  }}
                                  //  backgroundImage: `url(http://loremflickr.com/620/440/london)`
                                  value={color.id}
                                  onClick={() => {setStateColor(color.id)
                                                setZoomImage(color.id)}}
                                ></div>
                              </label>
                            </div>
                          );
                      })} */}

                        {soloColor?.map((color, i) => {
                          return (
                            <Tooltip
                              title={color?.name}
                              arrow
                              placement="top"
                              key={i}
                            >
                              <div className="p-1" key={i}>
                                <input
                                  id={color.id}
                                  type="radio"
                                  className="hidden"
                                />
                                <label htmlFor={color?.id}>
                                  <div
                                    className={`w-12 h-12 rounded-full flex justify-center border items-center overflow-hidden relative ${
                                      (statecolor ??
                                        Number(productInitialColor)) ===
                                      color?.id
                                        ? "border-primary border-[2px]"
                                        : ""
                                    } `}
                                    style={
                                      color?.image
                                        ? {
                                            backgroundImage: `url(${color?.image})`,
                                            backgroundSize: "contain",
                                          }
                                        : { backgroundColor: `${color?.code}` }
                                    }
                                    value={color.id}
                                    onClick={() => {
                                      setStateColor(color.id)
                                      setZoomImage(color.id)
                                      setImg(undefined)
                                    }}
                                  >
                                    {(statecolor ??
                                      Number(productInitialColor)) ===
                                    color?.id ? (
                                      <div className="  p-2  backdrop-blur-3xl   absolute ">
                                        <svg
                                          height="35px"
                                          viewBox="0 0 24 24"
                                          width="35px"
                                          className=""
                                          fill={` ${
                                            (statecolor ??
                                              Number(productInitialColor)) ===
                                              color?.id &&
                                            color.name === "White"
                                              ? "rgb(0,0,0)"
                                              : "rgb(255,255,255)"
                                          } `}
                                        >
                                          <path
                                            d="M0 0h24v24H0V0z"
                                            fill="none"
                                          />
                                          <path d="M9 16.2l-3.5-3.5c-.39-.39-1.01-.39-1.4 0-.39.39-.39 1.01 0 1.4l4.19 4.19c.39.39 1.02.39 1.41 0L20.3 7.7c.39-.39.39-1.01 0-1.4-.39-.39-1.01-.39-1.4 0L9 16.2z" />
                                        </svg>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </label>
                              </div>
                            </Tooltip>
                          )
                        })}
                      </div>
                    </div>
                    <div className="flex pt-5  capitalize items-center">
                      <p className="text-xl  text-gray-700 ">select size</p>
                      {/* <Sizechart forSize={forSizeChartOpen} openSize={size} />
                      <button
                        onClick={forSizeChartOpen}
                        className="text-md ml-8 text-gray-500  font-style bg-white  "
                      >
                        size chart
                      </button> */}
                    </div>
                    <div className=" ">
                      <div className="  flex flex-wrap  w-72  justify-start  ">
                        {soloSizes?.map((sizefill, i) => {
                          const disabled = !productSize?.includes(sizefill?.id)

                          return (
                            <Tooltip
                              title={sizeSwitch(
                                disabled ? "0" : sizefill?.name.toLowerCase()
                              )}
                              arrow
                              placement="top"
                              key={i}
                            >
                              <div className="p-1" key={i}>
                                <input
                                  disabled={disabled}
                                  id={sizefill?.name}
                                  type="radio"
                                  className="hidden"
                                  onClick={() => setStateSize(sizefill?.id)}
                                />
                                <label htmlFor={sizefill?.name}>
                                  {sizefill?.name === "FREE SIZE" ? (
                                    <div
                                      className={`w-12 h-12 rounded-full flex justify-center border  ${
                                        stateSize === sizefill?.id
                                          ? "bg-primary border-white text-white "
                                          : ""
                                      } items-center text-sm ${
                                        disabled
                                          ? "bg-black/20 text-gray-200   cursor-not-allowed"
                                          : ""
                                      }  font-style mt-2 `}
                                    >
                                      <p className="ml-2">{sizefill?.name}</p>
                                    </div>
                                  ) : (
                                    <div
                                      className={` w-12 h-12 rounded-full flex justify-center border ${
                                        stateSize === sizefill?.id
                                          ? "bg-primary text-white"
                                          : ""
                                      } items-center text-lg ${
                                        disabled
                                          ? "bg-black/10  cursor-not-allowed"
                                          : ""
                                      } font-style mt-2`}
                                    >
                                      {disabled ? (
                                        <del className="">{sizefill?.name}</del>
                                      ) : (
                                        <p>{sizefill?.name}</p>
                                      )}
                                    </div>
                                  )}
                                </label>
                              </div>
                            </Tooltip>
                          )
                        })}
                      </div>
                    </div>

                    {orderingProduct?.length ? (
                      <div>
                        <div className="flex pt-5  capitalize">
                          <p className="text-xl  text-gray-700 ">
                            Select Quantity
                          </p>
                        </div>

                        <select
                          className=" appearance-none rounded-lg w-32 mt-3 ml-2 p-1  focus:outline-none border-gray-300 border "
                          value={qty}
                          onChange={(e) => setQty(e.target.value)}
                        >
                          {[...Array(orderingProduct[0]?.quantity)]?.map(
                            (x, i) => {
                              if (i <= 7) {
                                return (
                                  <option key={i} value={i + 1} className="">
                                    {i + 1}
                                  </option>
                                )
                              }
                            }
                          )}
                        </select>
                        <p className="text-red-700 font-style m-2 ">
                          {Number(orderingProduct[0]?.quantity) < 10
                            ? `(Only ${orderingProduct[0]?.quantity} Left)`
                            : ""}
                        </p>
                      </div>
                    ) : (
                      <p className="text-lg text-primary font-style mt-2 ">
                        {productSize.length ? (
                          "select size to add quantity"
                        ) : (
                          <span className="text-red-700 font-style ml-2">
                            Out of stock at the moment !!
                          </span>
                        )}
                      </p>
                    )}

                    <div>
                    {[1, 2, 3].includes(Number(product?.category)) &&
                          brandName?.toLowerCase() === "myunde" && (
                            <>
                              <PrintInitial
                                forPrintOpen={handlePrintOpen}
                                PrintOpen={printOpen}
                                onSubmit={onSubmit}
                              />

                              {printValue?.print_value ? (
                                <div className="p-2 pb-3 border border-green-500 rounded-lg mt-2 w-fit">
                                  <div className=" text-lg p-2 w-fit rounded-lg">
                                    Your Print Initial is "
                                    {printValue?.print_value}"
                                    {selectedProductItem === true ? (
                                      ""
                                    ) : (
                                      <p className="text-xs">
                                        By adding the item to your cart,
                                        <br /> you will save your Initial.
                                      </p>
                                    )}
                                  </div>

                                  <div className="mt-3 mx-2 flex justify-between">
                                    <Button
                                      onClick={() => handlePrintOpen()}
                                      className="  capitalize text-primary px-2 ml-2 py-1 hover:bg-white/20  border  border-black  rounded-md"
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      onClick={HandleRemovePrintValue}
                                      className="  capitalize text-primary px-2 ml-2 py-1 hover:bg-white/20  border border-red-500   rounded-md"
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                <div className="p-2 border border-gray-300 rounded-lg mt-4 w-fit">
                                  <p className="mt-2 text-lg">
                                    Print Initial. Personalise your <br />
                                    Inner wear for free.
                                  </p>
                                  <div className=" ">
                                    <Tooltip
                                      title="printing your initial on product"
                                      placement="top"
                                      arrow
                                    >
                                      <Button
                                        onClick={() => handlePrintOpen()}
                                        className=" bg-primary capitalize text-white   border   rounded-md"
                                      >
                                        Add Initial
                                      </Button>
                                    </Tooltip>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                    </div>

                    <div className="flex flex-wrap  mt-8 mb-6 mx-2">
                      <Tooltip
                        title={`${
                          stateSize ? "add to cart" : "select size to add cart"
                        }`}
                        arrow
                        placement="top"
                      >
                        <Button
                          className="flex p-4 border items-center bg-black/90 text-white font-style capitalize text-lg w-full md:w-80 xl:w-72 justify-center md:mr-8 lg:mr-0 xl:mr-8 rounded-md hover:scale-105 duration-300 mb-3 lg:mb-3  md:mb-0 "
                          onClick={() => {
                            AddToCart(product?.name, printValue)
                          }}
                          // disabled={orderingProduct[0]?.quantity > 0 ? true : false}
                        >
                          <BsFillHandbagFill className="w-5 h-5 mx-2" /> add to
                          cart
                        </Button>
                      </Tooltip>
                      {!userInfo ? (
                        <LoginSlide forProfileOpen={forLogin} open={state} />
                      ) : (
                        <ProfileScreen
                          forProfileScreen={forProfileScreen}
                          open={state.profilescreen}
                        />
                      )}
                      <Button
                        className="flex p-4 border bg-white items-center mb-3  lg:mb-3  md:mb-0  text-black font-style capitalize text-lg  w-full md:w-80 xl:w-72 justify-center rounded-md hover:scale-105 duration-300 hover:bg-gray-100"
                        onClick={() => {
                          addToWishList(
                            (productitem?.filter(
                              (item) =>
                                item?.color ===
                                Number(statecolor ?? productInitialColor)
                            ))[0],
                            qty,
                            product?.name
                          )
                        }}
                      >
                        <BsHeart className="w-5 h-5 mx-2" /> wishlist
                      </Button>
                    </div>

                    <hr />

                    <div className="my-4">
                      <p className="text-xl capitalize  my-3 text-gray-700 flex items-center">
                        delivery options
                        <TbTruckDelivery className="w-7 h-7 ml-2 mt-1" />
                      </p>

                      <form
                        className=" my-3 px-2 "
                        onSubmit={handleSubmit(DeliveryAvailable)}
                      >
                        <div className="flex items-center  ">
                          <div className="relative">
                            <input
                              type="text"
                              placeholder="Enter pincode"
                              {...register("pincode", {
                                required: "Pin Code is required",
                                pattern: {
                                  value: /^[0-9]{6}$/,
                                  message: "Invalid Pin Code",
                                },
                              })}
                              aria-invalid={errors.pincode ? "true" : "false"}
                              id="pinCode"
                              className={`block font-style h-10 pl-2 w-40 text-[14px] text-gray-900 bg-gray-100 rounded-lg    ${
                                errors.pincode &&
                                errors?.pincode?.message !==
                                  "Delivery service available"
                                  ? "border-red-500 border"
                                  : errors.pincode &&
                                    errors?.pincode?.message ===
                                      "Delivery service available"
                                  ? "border-green-500 border"
                                  : "border-gray-300 border"
                              }  appearance-none  focus:outline-none focus:ring-0  peer`}
                            />
                          </div>

                          <button
                            type="submit"
                            className="text-medium ml-3 mt-1 h-9 w-16 mb-0.5 bg-primary px-2 text-white rounded-lg"
                          >
                            {loadingDelivery ? (
                              <CircularProgress
                                size={25}
                                sx={{ color: "white" }}
                              />
                            ) : (
                              <p> check </p>
                            )}
                          </button>
                        </div>
                        {errors.pincode ? (
                          <p
                            className={`text-sm m-1  ${
                              errors?.pincode?.message ===
                              "Delivery service available"
                                ? "text-green-600"
                                : "text-red-500"
                            } `}
                          >
                            {errors.pincode?.message}
                          </p>
                        ) : (
                          <p className=" mt-3 capitalize   font-style text-gray-500 ">
                            Enter PIN code to check service availability
                          </p>
                        )}
                      </form>

                      {/* <div className="my-6 ">
                        <div className="flex items-center  ">
                          <div className="relative my-2 ">
                            <input
                              type="text"
                              placeholder="Enter pincode"
                              pattern="[0-9]{6}"
                              maxLength="6"
                              onChange={(e) => setcheckPincode(e.target.value)}
                              id="floating_outlined2"
                              className={`block font-style h-10 pl-2 w-40 text-[14px] text-gray-900 bg-gray-100 rounded-lg  focus:outline-none focus:ring-0 ${
                                isAvailable ===
                                  ("enter a valid pincode to check" ||
                                    "Required field missing") &&
                                "border-red-300 border-2"
                              } ${
                                isAvailable ===
                                  "Delivery serivce is available" &&
                                "border-green-500 border-2"
                              }`}
                            />
                         
                          </div>

                          {isAvailable === "enter a valid pincode to check" && (
                            <div className="text-sm m-1 text-red-500 flex justify-end relative">
                              <ExclamationCircleIcon className="w-6 h-6  absolute -mt-3 mr-2" />
                            </div>
                          )}

                          {isAvailable === "Delivery serivce is available" && (
                            <div className="text-sm m-1 text-red-500 flex justify-end relative">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="green"
                                className="w-6 h-6  absolute -mt-3 mr-2"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </div>
                          )}

                          <button
                            className="text-medium ml-3 mt-1 h-9 mb-0.5 bg-primary px-2 text-white rounded-lg"
                            onClick={() => pincodeCheck()}
                          >
                            check
                          </button>
                        </div>

                        {isAvailable === "Delivery serivce is available" && (
                          <div className=" text-lg  text-green-600 font-style ml-2">
                            {isAvailable}
                            <p className="text-sm text-primary">
                              (The item will be delivered within a timeframe of
                              1 to 5 days)
                            </p>
                          </div>
                        )}
                        {isAvailable === "enter a valid pincode to check" && (
                          <p className="flex items-center text-lg  text-red-600 font-style ml-2">
                            {isAvailable}
                          </p>
                        )}
                        {isAvailable === "Required field missing" && (
                          <p className="flex items-center text-lg  text-red-600 font-style ml-2">
                            {isAvailable}
                          </p>
                        )}

                        {isAvailable ===
                          "Delivery postcode not serviceable." && (
                          <p className="flex items-center text-lg capitalize text-[rgb(0,56,93)] font-style ml-2">
                            {isAvailable}
                          </p>
                        )}

                        {!isAvailable && (
                          <p className=" mt-1 capitalize   font-style text-gray-500 ml-2">
                            Enter PIN code to check service availability
                          </p>
                        )}
                      </div> */}

                      <div className="mt-4 capitalize    font-style text-gray-600">
                        <li>100% Original Products</li>
                        <li> get free delivery for above rs.599 orders </li>
                        <li>Trustable Seller</li>
                      </div>
                      {/* <div className="my-4">
                    <p className="text-xl capitalize  flex my-3 text-gray-700 items-center ">
                      Best offers
                      <MdOutlineLocalOffer className="w-6 h-6 mt-1 ml-2" />
                    </p>
                    <p className="mt-1 capitalize   font-style text-gray-500 ">
                      best price: RS. 323
                    </p>
                    <div className="my-4 capitalize    font-style text-gray-600">
                      <li>
                        Applicable on: Orders above Rs. 799 (only on first
                        purchase)
                      </li>
                      <li>Coupon code: MYUNdE200</li>
                      <li>
                        Coupon Discount: Rs. 200 off (check cart for final
                        savings)
                      </li>
                    </div>
                  </div> */}
                    </div>
                    <hr />

                    <div>
                      <p className="text-xl capitalize  flex my-3 text-gray-700  items-center ">
                        product details
                        <BiDetail className="w-5 h-5 mt-1 ml-2" />
                      </p>
                      <span className="text-primary first-letter:capitalize  font-style text-gray-600">
                        * Pack of {product.pack_of}
                      </span>
                      {productDescription?.map((description, i) => {
                        return (
                          <p
                            className="mt-3 lowercase first-letter:uppercase  font-style text-gray-600 "
                            key={i}
                          >
                            <span className="text-xl">*</span>
                            {description}
                          </p>
                        )
                      })}

                      <div className="my-4">
                        <p className="text-xl capitalize  flex my-3 text-gray-700  items-center">
                          Material & Care
                        </p>

                        <div className="capitalize   font-style text-gray-500">
                          {fabrics?.map((fabric, i) => {
                            if (
                              Number(fabric?.id) === Number(product?.fabric)
                            ) {
                              return <div key={i}>fabric: {fabric?.name} </div>
                            }
                          })}

                          <div>Washing : Dry Clean</div>
                        </div>
                      </div>
                      <p className="text-xl capitalize  flex my-3 text-gray-700 items-center">
                        specifications
                      </p>
                      <div className="flex flex-wrap justify-between lg:h-96   overflow-hidden overflow-y-auto w-full style-4">
                        <div className=" w-full">
                          <div className="my-4">
                            <p className="text-sm text-gray-400 font-style ">
                              Product
                            </p>

                            <p className="text-lg font-style text-gray-600 capitalize">
                              {product.name}
                            </p>
                          </div>
                          <hr />
                          <div className="my-4">
                            <p className="text-sm text-gray-400 font-style">
                              Type
                            </p>

                            <div className="text-lg font-style text-gray-600 capitalize">
                              {categoryName}
                            </div>
                          </div>
                          <hr />

                          <div className="my-4">
                            <p className="text-sm text-gray-400 font-style">
                              Waistband
                            </p>
                            <p className="text-lg font-style text-gray-600 capitalize">
                              Elasticated
                            </p>
                          </div>

                          <hr />

                          <div className="my-4">
                            <p className="text-sm text-gray-400 font-style">
                              Product Style
                            </p>
                            {styles?.map((style, i) => {
                              if (
                                Number(style?.id) === Number(product?.style)
                              ) {
                                return (
                                  <p
                                    className="text-lg font-style text-gray-600 capitalize"
                                    key={i}
                                  >
                                    {style.name}
                                  </p>
                                )
                              }
                            })}
                          </div>
                          <hr />
                          <div className="my-3">
                            <p className="text-sm text-gray-400 font-style">
                              Weave Type
                            </p>
                            <p className="text-lg font-style text-gray-600 capitalize">
                              Machine Weave
                            </p>
                          </div>
                        </div>
                        <hr />

                        <div className=" w-full">
                          <div className="my-4">
                            <p className="text-sm text-gray-400 font-style">
                              Colors Available
                            </p>

                            {soloColor?.map((color, i) => {
                              return (
                                <li
                                  className="text-md font-style text-gray-600 capitalize"
                                  key={i}
                                >
                                  {color.name}
                                </li>
                              )
                            })}
                          </div>
                          <hr />
                          <div className="my-3" id="rating">
                            <p className="text-sm text-gray-400 font-style">
                              Size available
                            </p>
                            {soloSizes?.map((sizefill, i) => {
                              const disabled = !productSize?.includes(
                                sizefill?.id
                              )
                              return (
                                <div className="" key={i}>
                                  {disabled ? (
                                    ""
                                  ) : (
                                    <li
                                      className="text-md font-style text-gray-600 capitalize"
                                      key={i}
                                    >
                                      {sizefill?.name}
                                    </li>
                                  )}
                                </div>
                              )
                            })}
                          </div>
                          <hr />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div>
                      <div className="my-4 ">
                        <div className="text-xl uppercase  flex mt-5 text-whiteitems-center ">
                          RATINGS <BsListStars className="w-6 h-6 mt-1 ml-2 " />
                          {/* <span className="text-sm font-normal capitalize p-1 text-cyan-500" 
                       onClick={()=>{doesBoughtProduct()}}>Give a Rating</span> */}
                          <p className="ml-3"></p>
                          <Writereview
                            forReview={forReview}
                            openReview={reviewOpen}
                            productId={Number(params.id)}
                          />
                          <div
                            onClick={forReview}
                            className="flex justify-center items-end mt-1  text-sm font-style     "
                          >
                            Give a rating
                          </div>
                        </div>

                        {/* { haveBought && <div>
                      <HoverRating />
                    </div>} */}

                        <div className="flex flex-wrap my-2    justify-between items-center">
                          <div className="2xl:border-r-2 sm:text-center xl:text-start w-full sm:w-2/5 sm:flex-1 md:border-r-2 lg:border-0   ">
                            <div className="text-5xl  2xl:text-7xl flex font-normal  text-gray-700 items-center  sm:justify-center xl:justify-start">
                              {totalAverageRating()}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="rgb(0,0,0)"
                                viewBox="0 0 24 24"
                                strokeWidth={0.5}
                                stroke="rgb(0,0,0)"
                                className="w-16 h-16 ml-2 mt-1.5 svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                />
                              </svg>
                            </div>
                            <p className="mt-3 font-style text-gray-600">
                              ({productsReviews[0]?.reviews?.length}) Verified
                              Reviews
                            </p>
                          </div>
                          <div className="2xl:ml-12 md:ml-5 sm:w-3/5 w-full lg:ml-0 my-5">
                            {[...Array(5)].map((x, i) => {
                              return (
                                <div
                                  className="flex my-1 items-center "
                                  key={i}
                                >
                                  <p className="font-style w-6  h-6 flex justify-center items-center">
                                    {5 - i}
                                  </p>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="rgb(255,191,0,0.8)"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1}
                                    stroke="rgb(255,191,0)"
                                    className="w-5 h-5 "
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                    />
                                  </svg>
                                  <div className="w-52 bg-gray-200 flex h-2 rounded-sm dark:bg-gray-700 ml-2">
                                    <div
                                      className={`bg-${reviewBarColor(
                                        5 - i
                                      )}  h-2  rounded-sm`}
                                      style={{
                                        width: `${progressBarFIlter(5 - i)}%`,
                                      }}
                                    ></div>
                                  </div>
                                  <p className="ml-2 font-style w-6  h-6 flex justify-center items-center">
                                    {ratingFilter(5 - i)}
                                  </p>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {productsReviews[0]?.reviews?.length === 0 ? (
                  " "
                ) : (
                  <>
                    <hr />

                    <div className="grid xl:ml-12  xl:mt-12 lg:ml-9    xl:grid-cols-5 p-3">
                      <div className="  xl:col-span-3 ">
                        <div className="flex my-4  flex-wrap text-primary">
                          <p className="lg:text-xl 2xl:text-2xl text-lg capitalize   ">
                            customer photos({productsReviews[0]?.images?.length}
                            )
                          </p>
                          <div
                            onClick={forReview}
                            className="flex justify-center items-end text-sm lg:text-lg font-style ml-2 "
                          >
                            Upload a photo
                          </div>
                        </div>
                        <div className="flex">
                          <ReviewView productId={params?.id} />
                        </div>
                      </div>
                      <div className=" xl:ml-8  xl:col-span-2">
                        <div className="flex my-4 pr-3 justify-between  flex-wrap text-white items-center ">
                          <p className="lg:text-xl 2xl:text-2xl text-lg  capitalize  ">
                            customer review(
                            {productsReviews[0]?.reviews?.length})
                          </p>
                          <div
                            onClick={forReview}
                            className=" flex justify-center items-end font-style text-sm mx-2"
                          >
                            Give a review
                          </div>

                          <div className=" mt-4 sm:mt-0 lg:mt-3 2xl:mt-0 ">
                            <Select
                              options={stars}
                              defaultValue={stars[4]}
                              className="w-full md:w-52 mt-4 text-black font-style "
                              onChange={setFilterRating}
                              getOptionLabel={(e) => (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <span style={{ marginLeft: 5 }}>
                                    {e.label}
                                  </span>

                                  {e.icon?.map((o, i) => {
                                    return <Fragment key={i}>{o}</Fragment>
                                  })}
                                </div>
                              )}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: 5,
                                colors: {
                                  ...theme.colors,
                                  primary25: "#eeeeee",
                                  primary: "rgb(0,0,0)",
                                  primary50: "ffffff",
                                },
                              })}
                            />
                          </div>
                        </div>
                        {filteredreviews?.length ? (
                          <div className="   overflow-y-auto  style-4 pr-3 w-full min-h-56 max-h-96">
                            {filteredreviews?.map((review, i) => {
                              return (
                                <div
                                  key={i}
                                  className="w-full mb-5 p-2 capitalize font-style text-gray-700 bg-gray-100 rounded-xl"
                                >
                                  <div className="">
                                    <div className="flex items-center justify-between flex-wrap">
                                      <div className="flex w-full  items-start  ">
                                        <div className="p-4 w-6 h-6 border rounded-full mx-2 my-2 flex items-center justify-center bg-gray-400 text-white">
                                          {review?.name.slice(0, 1)}
                                        </div>

                                        <div className="flex items-start  sm:items-center justify-between w-full flex-wrap">
                                          <div className="">
                                            <div className="mt-2 text-md capitalize ">
                                              {review?.name}
                                              <p className="text-xs ml-0.5 text-primary">
                                                {new Date(
                                                  review?.createdAt
                                                ).toDateString()}
                                              </p>
                                            </div>
                                          </div>

                                          <div className=" flex items-center 2xs:my-2  w-32   justify-start xs:justify-end ">
                                            <br />
                                            <p className="text-xl hidden sm:block sm:text-2xl  text-gray-600 ">
                                              {review?.rating}
                                            </p>
                                            {[...Array(review?.rating)]?.map(
                                              (x, i) => {
                                                return (
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill=""
                                                    key={i}
                                                    viewBox="0 0 24 24"
                                                    strokeWidth={1.5}
                                                    stroke="currentColor"
                                                    className="w-3 h-3 xs:w-4 xs:h-4 sm:w-5 sm:h-5 sm:ml-1  fill-amber-300 stroke-amber-400"
                                                  >
                                                    <path
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                                    />
                                                  </svg>
                                                )
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="flex my-2 pl-5 flex-wrap justify-start ">
                                      {productsReviews[0]?.images?.map(
                                        (img, i) => {
                                          if (review.id === img.review) {
                                            return img.image
                                              ?.split(".")
                                              .pop() !== "mp4" ? (
                                              <Zoom key={i}>
                                                <div className="w-20 h-24 m-1 p-0.5 border overflow-hidden">
                                                  <img
                                                    src={img.image}
                                                    alt={`Myunde Review for ${
                                                      product.name ??
                                                      "Speciallty for men"
                                                    } `}
                                                    className="w-20 h-24  object-cover"
                                                  />
                                                </div>
                                              </Zoom>
                                            ) : (
                                              <div className="">
                                                <div className="w-20 h-24 m-1 p-0.5 border">
                                                  <video
                                                    src={img.image}
                                                    controls
                                                    className="w-20 h-24  object-cover"
                                                  />
                                                </div>
                                                <p className="font-style text-xs ml-2 lowercase ">
                                                  watch a video
                                                </p>
                                              </div>
                                            )
                                          }
                                        }
                                      )}
                                    </div>

                                    <p className=" indent-3 lowercase mx-4">
                                      {review.comment}
                                    </p>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        ) : (
                          <div className="flex flex-col justify-center items-center w-full bg-gradient rounded-lg  py-5 ">
                            <div className="flex">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="white"
                                viewBox="0 0 24 24"
                                strokeWidth={0.5}
                                stroke="rgb(0,0,0)"
                                className="w-6 h-6 ml-2 mt-1.5 "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="white"
                                viewBox="0 0 24 24"
                                strokeWidth={0.5}
                                stroke="rgb(0,0,0)"
                                className="w-6 h-6 ml-2 mt-1.5 "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="white"
                                viewBox="0 0 24 24"
                                strokeWidth={0.5}
                                stroke="rgb(0,0,0)"
                                className="w-6 h-6 ml-2 mt-1.5 "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="white"
                                viewBox="0 0 24 24"
                                strokeWidth={0.5}
                                stroke="rgb(0,0,0)"
                                className="w-6 h-6 ml-2 mt-1.5 "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                />
                              </svg>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="white"
                                viewBox="0 0 24 24"
                                strokeWidth={0.5}
                                stroke="rgb(0,0,0)"
                                className="w-6 h-6 ml-2 mt-1.5 "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                />
                              </svg>
                            </div>
                            <h1 className=" p-2 text-[rgb(0,0,0)] font-style">
                              There is no reviews in selected rating
                            </h1>
                            <Button className="bg-primary" onClick={forReview}>
                              Give rating
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>

              {product.id && product.category && product.brand ? (
                <div className="mt-12  p-4">
                  <RelatedProducts
                    productId={product.id}
                    categoryId={product.category}
                    brandId={product.brand}
                    SetImage={setImg}
                  />
                  <hr />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default FinalProductScreen
