import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import { PayPalButton } from 'react-paypal-button-v2'
import Message from "../components/Message";
import Loader from "../components/Loader";
import { getOrderDetails } from "../actions/orderActions";
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from "../constants/orderConstants";
// import "../css/orderscreen.css";

import logo from "../images/Myunde1.png";
import { Tooltip } from "@mui/material";

function OrderScreen() {
  const navigate = useNavigate();
  const params = useParams();
  const orderId = params.id;
  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.orderDetails);

  const { order, error, loading } = orderDetails;

  const goHome = () => {
    navigate("/");
  };

  // const cart = useSelector((state) => state.cart);
  // const { shippingAddress } = cart;

  const { state } = useLocation();

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const productoffer = useSelector((state) => state.productoffers);
  const { productoffers } = productoffer;

  if (!loading && !error) {
    order.itemsPrice = order.orderItems
      .reduce((acc, item) => acc + item.price * item.qty, 0)
      .toFixed(2);
  }

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }

    if (
      !order ||
      successPay ||
      order?.id !== Number(orderId) ||
      successDeliver
    ) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(getOrderDetails(orderId));
    }
  }, [successPay, successDeliver]);

  // const successPaymentHandler = (paymentResult) => {
  //   dispatch(payOrder(orderId, paymentResult));
  // };

  // const deliverHandler = () => {
  //   dispatch(deliverOrder(order));
  // };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <div
    // style={{
    //   backgroundImage: `url(${require("../images/bg.jpg")})`,
    //   backgroundSize: "1920px 1080px",
    // }}
    >
      <div className=" w-full  py-5 font-style">
        <div className="flex  flex-wrap justify-center m-2 ">
          <div
            className=" w-full xs:w-[500px] md:w-[600px] lg:w-[700px]  sm:px-4 p-3 h-full md:px-9  shadow-xl   lg:mx-4 my-2 "
            style={{
              background:
                "linear-gradient(49deg, rgba(227,242,255,0.7) 0%, rgba(249,247,240,0.7) 87%)",
            }}
          >
            <div className="my-2">
              <div className="w-full flex  justify-between mb-4 items-center">
                <div className="w-24 h-8 ">
                  <img src={logo} alt="" className=" " />
                </div>
                <div
                  onClick={goHome}
                  className="text-center cursor-pointer  w-52    flex justify-end items-center text-primary text-[15px] font-style capitalize  rounded-lg    "
                >
                  go home
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 sm:w-6 ml-2 h-4  sm:h-6 mt-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                    />
                  </svg>
                </div>
              </div>
              <div className="flex justify-start   items-start sm:my-3 ">
                <div className="font-style mr-2 text-[rgb(29,178,108)] text-2xl md:text-3xl capitalize text-center sm:text-start   w-full my-1">
                  your order confirmed!
                  {/* <div className="flex justify-ce w-full my-1">
<ImGift className="text-[rgb(13,147,68)] text-center w-8 h-8 "/>
</div> */}
                </div>
              </div>

              <div className="my-3 sm:my-5 ">
                <p className="my-2 capitalize text-sm  sm:text-lg  font-style    text-gray-500  ">
                  Hello,
                  <span className="text-[rgba(0,53,96,0.76)] font-bold">
                    {order?.user.username}
                  </span>
                </p>
                <p className="my-2    text-gray-500  text-xs md:text-[14px]   ">
                  Your order has been confrimed and we will send you tracking
                  details shortly. If you have any queries Please feel free to
                  contact us on
                  <span className=" lowercase text-primary text-sm italic font-style">
                    feedback@myunde.com
                  </span>
                </p>
              </div>
            </div>
            <hr className=" border-[1px] bg-gray-100  border-gray-100 " />
            <div className="flex  items-start  justify-between  my-3">
              <div className="my-1 capitalize text-xs md:text-[14px]  font-style  text-start text-gray-400 ">
                <div className="my-1"> order id</div>
                <p className=" text-gray-600"> {order?.id}</p>
              </div>
              <div className="my-1 capitalize text-xs md:text-[14px]  font-style text-center  text-gray-400 ">
                <p className="my-1">order date</p>
                <p className="  text-gray-600">
                  {new Date(order?.createdAt).toLocaleDateString()}
                </p>
              </div>

              <div className="my-1 capitalize text-xs md:text-[14px]  font-style text-end  text-gray-400  ">
                <p className="my-1">delivery address</p>
                <div className="text-xs md:text-[14px]  text-gray-600 w-32 sm:w-48 2xl:w-60">
                  {order?.shippingAddress?.name},<br />
                  {order?.shippingAddress?.number},<br />
                  {order?.shippingAddress?.address}
                  <p>
                    {order?.shippingAddress?.city},<br />
                    {order?.shippingAddress?.state} ,
                    {order?.shippingAddress?.pinCode}.
                  </p>
                </div>
              </div>
            </div>
            <hr className=" border-[1px] bg-gray-100  border-gray-100  " />
            {/*  */}

            {order?.orderItems.map((ordereditem) => {
              const isProductOffer = productoffers?.find(
                (val) => val.product === ordereditem.product
              );

              return (
                <div
                  key={ordereditem.id}
                  className=" my-2 p-2"
                  style={{
                    background:
                      "linear-gradient(29deg, rgba(227,242,255,0.8) 50%, rgba(249,247,240,0.8) 50%)",
                  }}
                >
                  {isProductOffer && (
                    <div className="capitalize mt-2  text-xs sm:text-sm  text-[rgb(0,53,96)]   font-style  rounded-sm flex justify-between">
                      <p className="p-2 flex items-center ">
                        <svg
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-5 h-5 mr-1"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                          />
                        </svg>
                        {isProductOffer?.offer?.value}{" "}
                        {isProductOffer?.offer?.type === "discount" && "%"}{" "}
                        Offer Applied
                      </p>
                    </div>
                  )}
                  <div className="flex   items-start  justify-between shadow-sm  rounded-xl">
                    <div className="flex  items-center   capitalize text-gray-800 sm:w-[85%]">
                      <div className=" rounded-xl  w-16 h-24 md:w-24 md:h-32  m-1">
                        <img
                          className=" rounded-xl w-16 h-24 md:w-24 md:h-32 object-contain"
                          src={`${ordereditem?.image}`}
                          alt="ordered items img"
                        />
                      </div>
                      <div className=" ml-2 text-xs md:text-[14px]  ">
                        <p className="text-xs md:text-sm font-style text-gray-600">
                          brand
                        </p>

                        <p className="font-style text-gray-600 truncate w-32 xs:w-full ">
                          {ordereditem?.name}
                        </p>

                        <div className=" text-xs md:text-[14px] ">
                          <p className="text-xs md:text-sm font-style text-start  text-gray-400 md:my-0.5">
                            size :
                            <span className=" text-[rgb(0,53,96)]">
                              {ordereditem?.size}
                            </span>
                          </p>
                          <p className="font-style text-start  text-gray-400 md:my-1">
                            color :
                            <span className=" text-[rgb(0,53,96)]">
                              {ordereditem?.color}
                            </span>
                          </p>
                        </div>
                        <div className="flex text-xs md:text-sm font-style text-start  text-gray-400 md:my-0.5">
                          <p className="">qty :</p>
                          <p className=" text-sm  text-primary mx-2">
                            {ordereditem?.qty}
                          </p>
                        </div>
                        {isProductOffer?.offer?.type === "discount" ? (
                          <div>
                            <p className="flex text-xs md:text-sm font-style text-start items-center text-gray-400 md:my-0.5">
                              price :
                              <span className=" text-sm  text-primary mx-2">
                                ₹
                                {Number(
                                  ordereditem.price -
                                    ordereditem?.price *
                                      (isProductOffer?.offer?.value / 100)
                                ).toFixed(2)}
                              </span>
                              <del className="hidden xs:block text-xs ml-1">
                                ₹{ordereditem?.price}
                              </del>
                            </p>
                          </div>
                        ) : (
                          <div className="flex text-xs md:text-sm font-style text-start  text-gray-400 md:my-0.5">
                            <p className="">price : </p>
                            <p className=" text-sm  text-primary mx-2">
                              ₹{ordereditem?.price}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="  my-0.5 capitalize text-xs md:text-[14px] font-style text-end  text-gray-400 pr-2">
                        <p className="">total</p>
                        <p className="text-end  text-xs md:text-[14px] [16px]  text-[rgb(0,53,96)]">
                          ₹ {(ordereditem?.price * ordereditem?.qty).toFixed(2)}
                        </p>
                      </div>
                      <div className="flex justify-center items-end h-12 sm:h-16 md:h-20">
                        {ordereditem?.print_value && (
                          <Tooltip title="Your Print initial" placement="top">
                            <div className="font-style text-xs sm:text-sm w-5 h-5 sm:w-7 sm:h-7 flex justify-center items-center  group duration-300 border border-primary text-primary rounded-full ">
                              <p> {ordereditem.print_value} </p>
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}

            <hr className=" border-[1px] bg-gray-100  border-gray-100 " />
            <div className="  w-full flex justify-end  ">
              <div className="divide-y-2 divide-dotted my-3 w-full sm:w-72 text-xs md:text-[14px]  text-gray-600 font-style">
                <div className="m-1 pt-2 flex justify-between items-center">
                  <div className="text-xs md:text-sm font-style text-start  text-gray-400 my-1">
                    Subtotal
                  </div>
                  <div> ₹ {order?.totalPrice}</div>
                </div>
                {order?.discountPrice > 1 && (
                  <div className="m-1  flex justify-between items-center">
                    <div className="text-xs md:text-sm font-style text-start  text-gray-400 my-1">
                      Promotion applied
                    </div>
                    <div>- ₹ {order?.discountPrice}</div>
                  </div>
                )}

                <div className="m-1  flex justify-between items-center">
                  <div className="text-xs md:text-sm font-style text-start  text-gray-400 my-1">
                    Delivery Charge
                  </div>
                  <div> ₹ {order?.shippingPrice}</div>
                </div>
                {order?.cod_charge > 0 ? 
                <div className="m-1  flex justify-between items-center">
                  <div className="text-xs md:text-sm font-style text-start  text-gray-400 my-1">
                    COD Charge
                  </div>
                  <div> ₹ {order?.cod_charge}</div>
                </div> : "" }
                <div className="m-1  font-bold py-1 flex justify-between items-center">
                  <div className="my-1">Order Total</div>
                  <div className="text-[rgb(0,53,96)]">
                    ₹
                    {(
                      parseFloat(order?.totalPrice) +
                      parseFloat(order?.shippingPrice)
                    ).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2 mb-6 text-gray-500  text-xs md:text-[14px] ">
              <p className="my-2">
                We'll be sending a shipping confirmation email when the item
                shipped successfully.
              </p>
              <p className="text-[rgb(0,53,96)] ">
                Thank You for shopping With us! a growing Platform Myunde.com
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center"></div>
    </div>
  );
}

export default OrderScreen;
