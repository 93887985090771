import {
  CART_ADD_ITEM,
  CART_ITEM_REQUEST,
  ITEM_ADD_SUCCESS,
  ITEM_ADD_FAIL,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  CART_CLEAR_ITEMS,
} from "../constants/cartConstants";



import {
 
  CARTDATABASE_LIST_REQUEST,
  CARTDATABASE_LIST_SUCCESS,
  CARTDATABASE_LIST_FAIL,
  CARTDATABASE_CLEAR_ITEMS
} from "../constants/cartConstants"

import {
  CARTDATABASE_PRODUCTLIST_REQUEST,
  CARTDATABASE_PRODUCTLIST_SUCCESS,
  CARTDATABASE_PRODUCTLIST_FAIL
} from "../constants/cartConstants"

import {
  CARTDATABASE_ADD_REQUEST,
  CARTDATABASE_ADD_SUCCESS,
  CARTDATABASE_ADD_FAIL,

  CARTDATABASE_UPDATE_REQUEST,
  CARTDATABASE_UPDATE_SUCCESS,
  CARTDATABASE_UPDATE_FAIL,

  CARTDATABASE_DELETE_REQUEST,
  CARTDATABASE_DELETE_SUCCESS,
  CARTDATABASE_DELETE_FAIL,


  WISHDATABASE_ADD_REQUEST,
  WISHDATABASE_ADD_SUCCESS,
  WISHDATABASE_ADD_FAIL,

  WISHDATABASE_DELETE_REQUEST,
  WISHDATABASE_DELETE_SUCCESS,
  WISHDATABASE_DELETE_FAIL,

  WISHDATABASE_LIST_REQUEST,
  WISHDATABASE_LIST_SUCCESS,
  WISHDATABASE_LIST_FAIL,
  WISHDATABASE_CLEAR_ITEMS

} from "../constants/cartConstants"



const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

 const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

const initialState = {
  cartItems: cartItemsFromStorage,
  shippingAddress: shippingAddressFromStorage,
};

export const cartReducer = (
  state = {
    cartItems: initialState.cartItems ? initialState.cartItems : 0,
    shippingAddress: initialState.shippingAddress
      ? initialState.shippingAddress
      : {},
  },
  action
) => {
  switch (action.type) {
    case CART_ADD_ITEM:
      const item = action.payload;
     
      const existItem = state.cartItems.find(
        (x) => Number(x.product_item) === Number(item.product_item)
      );

      if (existItem) {
        return {
          ...state,
          cartItems: state.cartItems.map((x) =>
            x.product_item === existItem.product_item ? item : x
          ),
        };
      } else {
        return { ...state,
          cartItems: [...state.cartItems, item],};
      }

      case CART_ITEM_REQUEST:
          return { loading: true, cartitems: [] }
          

      case ITEM_ADD_SUCCESS:
          return {
              loading: false,
              cartitems: action.payload,   
            }

    case ITEM_ADD_FAIL:
        return { loading: false, error: action.payload }




    case CART_REMOVE_ITEM:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (x) => x.product_item !== action.payload
        ),
      };

    case CART_SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        shippingAddress: action.payload,
      };

    case CART_SAVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };

    case CART_CLEAR_ITEMS:
      return {
        ...state,
        cartItems: [],
      };

    default:
      return state;
  }
};











// const wishItemsFromStorage = localStorage.getItem("wishItems")
//   ? JSON.parse(localStorage.getItem("wishItems"))
//   : [];

// const wishinitialState = {
//   wishItems: wishItemsFromStorage,
// };

// export const wishReducer = (
//   state = {
//     wishItems: wishinitialState.wishItems ? wishinitialState.wishItems : 0,
//   },
//   action
// ) => {

//   switch (action.type) {
//     case WISH_ADD_ITEM:
//       const item = action.payload;

//       const existItem = state.wishItems?.find(
//         (x) => Number(x.productItem) === Number(item.productItem)
//       );

//       if (existItem) {
//         return {
//           ...state,
//           wishItems: state.wishItems.map((x) =>
//             x.productItem === existItem.productItem ? item : x
//           ),
//         };
//       } else {
//         return {
//           ...state,
//           wishItems: [...state.wishItems, item],
//         };
//       }

      
//     case WISHITEM_ADD_FAIL:
//         return { loading: false, error: action.payload }


//     case WISH_REMOVE_ITEM:
     
//       return {
//         ...state,
//         wishItems: state.wishItems.filter(
//           (x) => Number(x.productItem) !== Number(action.payload)
//         ),
//       };

//     case WISH_CLEAR_ITEMS:
//       return {
//         ...state,
//         wishItems: [],
//       };

//     default:
//       return state;
//   }
// };

export const CartDatabaseListReducer = (state = { cartItemsDataBase: [], cartProductItemsDataBase:[] }, action) => {

  switch (action.type) {
      case CARTDATABASE_LIST_REQUEST:
          return { loading: true,  cartItemsDataBase: [], cartProductItemsDataBase:[] }

      case CARTDATABASE_LIST_SUCCESS:
          return {
              loading: false,
              cartItemsDataBase: action.payload,
              cartProductItemsDataBase:[],
             
            
          }
        

      case CARTDATABASE_LIST_FAIL:
          return { loading: false, error: action.payload }


      case CARTDATABASE_PRODUCTLIST_REQUEST:
          return { loading: true, cartItemsDataBase:[...state.cartItemsDataBase], cartProductItemsDataBase: [] }
  
      case CARTDATABASE_PRODUCTLIST_SUCCESS:
       
          return{
            loading:false,
            cartItemsDataBase:[...state.cartItemsDataBase],
            cartProductItemsDataBase: action.payload[0],
            
          }; 
  
      case CARTDATABASE_PRODUCTLIST_FAIL:
          return { loading: false, error: action.payload }

      case CARTDATABASE_CLEAR_ITEMS:
          return {
            loading:false,
            cartItemsDataBase:[],
            cartProductItemsDataBase:[],
            };

      default:
          return state
  }
}


export const cartAddReducer = (state = {}, action) => {
  switch (action.type) {
      case CARTDATABASE_ADD_REQUEST:
          return {
              loading: true
          }

      case CARTDATABASE_ADD_SUCCESS:
          return {
              loading: false,
              success: true,
              cartItemsDataBase: action.payload
          }

      case CARTDATABASE_ADD_FAIL:
          return {
              loading: false,
              error: action.payload
          }

        case CARTDATABASE_UPDATE_REQUEST:
            return { loading: true }
    
        case CARTDATABASE_UPDATE_SUCCESS:
            return { loading: false, success: true, cartItemsDataBase: action.payload }
    
        case CARTDATABASE_UPDATE_FAIL:
            return { loading: false, error: action.payload }
  
  
        case CARTDATABASE_DELETE_REQUEST:
            return { loading: true }
    
        case CARTDATABASE_DELETE_SUCCESS:
            return { loading: false, success: true }
    
        case CARTDATABASE_DELETE_FAIL:
            return { loading: false, error: action.payload }


      default:
          return state
  }
}


export const wishAddReducer = (state = {}, action) => {
  switch (action.type) {
      case WISHDATABASE_ADD_REQUEST:
          return {
              loading: true
          }

      case WISHDATABASE_ADD_SUCCESS:
          return { 
            loading: false,
              success: true,
              wishItemsDataBase: action.payload
            }

      case WISHDATABASE_ADD_FAIL:
          return {
              loading: false,
              error: action.payload
          }
  
        case WISHDATABASE_DELETE_REQUEST:
            return { loading: true }
    
        case WISHDATABASE_DELETE_SUCCESS:
            return { loading: false, success: true }
    
        case WISHDATABASE_DELETE_FAIL:
            return { loading: false, error: action.payload }

      default:
          return state
  }
}

export const WishDatabaseListReducer = (state = { wishItemsDataBase: [], productIds:[], colorIds:[] }, action) => {

  switch (action.type) {
      case WISHDATABASE_LIST_REQUEST:
          return { loading2: true,  wishItemsDataBase: [],  productIds:[],  colorIds:[] }

      case WISHDATABASE_LIST_SUCCESS:

          return {
            loading2:false,
            wishItemsDataBase:action.payload[0],
            productIds:action.payload[1],
            colorIds:action.payload[2],
        
          }

      case WISHDATABASE_LIST_FAIL:
          return { loading2: false, error: action.payload }

      
      case WISHDATABASE_CLEAR_ITEMS:
          return {
            loading2:false,
            cartItemsDataBase:[],
            cartProductItemsDataBase:[],
            productIds:[],  colorIds:[],
            };

      default:
          return state
  }
}