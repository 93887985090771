import React from 'react'
import loading from '../images/mYUNDE LOGO.jpg'


function LoaderCard() {
  return (
    <div className=' flex justify-center items-center '>
      <img src={loading} alt="brand logo" className=" "/>
      </div>
    
  )
}

export default LoaderCard 