import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBEASfvBDTYERKJmTVh_VWt7OyqOtADcGQ",
  authDomain: "myunde786.firebaseapp.com",
  projectId: "myunde786",
  storageBucket: "myunde786.appspot.com",
  messagingSenderId: "655033035589",
  appId: "1:655033035589:web:5bdfd319acde5250382c2c",
  measurementId: "G-SQGWXGRR4M",
};

firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
export { auth, firebase };
