import React from "react";

function Shipping() {
  return (
    <div className="flex justify-center items-center h-full -mt-5 py-12 flex-wrap font-style">
      <div
        className="w-full  lg:w-[90%] xl:w-[80%]  p-2 mx-2 shadow-xl  backdrop-blur-2xl rounded-xl  sm:p-5 lg:px-10  text-primary "
        style={{
          background:
            "linear-gradient(29deg, rgba(227,242,255,0.6) 50%, rgba(249,247,240,0.6) 50%)",
        }}
      >
        <p className=" text-3xl sm:text-4xl my-5 text-center capitalize  ">
          Delivery and Shipping{" "}
        </p>

        <div className="my-7 px-2 sm:px-4 text-lg">
          <p className="my-8 ">
            For International buyers, orders are shipped and delivered through
            registered international courier companies and/or International
            speed post only. For domestic buyers, orders are shipped through
            registered domestic courier companies and /or speed post only.
            Orders are shipped within 0-2 days or as per the delivery date
            agreed at the time of order confirmation and delivering of the
            shipment subject to Courier Company / post office norms.
          </p>
          <p className="my-8 ">
            WEBTEK FASHION PVT LTD is not liable for any delay in delivery by the courier
            company / postal authorities and only guarantees to hand over the
            consignment to the courier company or postal authorities within 0-2
            days from the date of the order and payment or as per the delivery
            date agreed at the time of order confirmation. Delivery of all
            orders will be to the address provided by the buyer.
          </p>
          <p className="my-8">
            Delivery of our services will be confirmed on your mail ID as
            specified during registration. For any issues in utilizing our
            services you may contact our helpdesk on 9514100444 or
            myunde.com@gmail.com
          </p>
          <p className="my-8">
            All purchases are shipped from our own or third-party warehouses
            from various locations across India. We do not ship to any other
            country at the moment. Our endeavor is to make the products reach
            you within a reasonable period varying from 1- 15 days from receipt
            of order and completion of successful payment. Delivery period may
            be affected by the address for delivery.
          </p>
          <p className="my-8">
            Goods received need to be acknowledged upon delivery at the given
            address. We take no responsibility for the acknowledgment given by
            any alternative person other than the person ordering the product at
            the address mentioned while placing the order.
          </p>
          <p className="my-8">
            Since the transactions are authorized by the cardholder, we do not
            take responsibility for incorrect addresses provided at the time of
            placing the order.
          </p>
          <p className="my-8">
            We are not responsible for damage of products once the products have
            been delivered.
          </p>
          <p className="my-8">
            All claims, if any, for shortages or damages must be reported to
            customer service within a day of delivery through the contact us
            page on the web store.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Shipping;
