import React from "react";

import BlogHomePage from "./BlogHomePage";

function BlogHome() {

    
  return (
    <div>
    
     <BlogHomePage/>
    </div>
  );
}

export default BlogHome;
