import React, { useState } from "react";
import { Link } from "react-router-dom";
import img5 from "../../images/images/Myunde.png";
import { useSelector } from "react-redux";
import { FaFacebookF, FaLinkedin, FaWhatsapp , FaTwitter ,FaYoutube } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import apple from "../../images/apple-logo-png-dallas-shootings-don-add-are-speech-zones-used-4.png";
import play from "../../images/google-play-arrow-png-logo-8.png";
import { Tooltip } from "@mui/material";
import Contact from "../../components/Contact";

function BlogFooter() {
  const categoryList = useSelector((state) => state.blog);
  const { categories } = categoryList;

  const [contactOpen, setContactOpen] = useState(false);

  const forContact = () => {
    setContactOpen(!contactOpen);
  };

  const year = new Date().getFullYear()


  return (
    <div
      className="bg-gray-100/10  select-none backdrop-blur-[0.8px] mt-12 border-t flex justify-center"
     
    >
      <div className="md:flex justify-center text-gray-800 font-style max-w-[1400px]">
        <div className="md:grid  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 p-2  md:w-[95%] mt-3 pt-7">
          <div className=" ">
            <div className=" flex items-center ">
              <img src={img5} alt="logo" className=" h-10 ml-5 "></img>
              <h1 className="font-bold text-3xl pb-2 ml-3"> Myunde</h1>
            </div>
            <div className="mb-3">
              <p className="ml-3 pt-3 justify-self-start   ">
              At Myunde, serenity awaits those who seek solace and tranquility in a fast-paced world .With serene landscapes, holistic wellness practices, and mindful retreats, Myunde offers a sanctuary for rejuvenation and self-reflection.
              </p>
            </div>
          </div>

          <div className=" md:ml-5 p-2 xl:flex justify-center">
            
            <div className="group ">
              <div className="pb-2 flex justify-between ">
                <b>SEARCH BY CATEGORIES </b>
                <svg
                  className="fill-current h-6 w-10  md:hidden"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>

              <div className=" hidden group-hover:block md:block">
                <ul>
                  {categories?.map((cat, i) => {
                    if (i < 10) {
                      return (
                        <Link
                          to={`/blog/category/${cat.name}`}
                          className="hover:text-[rgb(0,53,96)] "
                          key={cat.id}
                        >
                          <li className="underline-hover-effect py-2 sm:py-0.5">{cat.name}</li>
                        </Link>
                      );
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div className=" p-2 xl:flex justify-center">
            <div className="group">
              <div className="pb-2 flex justify-between ">
                <b>HELP & SUPPORT</b>
                <svg
                  className="fill-current h-6 w-10  md:hidden "
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>

              <ul className=" hidden group-hover:block md:block">
                <li className="underline-hover-effect py-2 sm:py-0.5">
                  <Link to={"/faq"}>FAQs</Link>
                </li>

                <Contact forContact={forContact} openContact={contactOpen} />
                <li
                  className="underline-hover-effect  pr-2 py-2 sm:py-0.5"
                  onClick={forContact}
                >
                  Contact Us
                </li>

                <Link to={"/privacy-policy"}>
                  <li className="underline-hover-effect w-auto pr-4 py-2 sm:py-0.5">
                    Privacy policy
                  </li>
                </Link>
                {/* <Link to={""} className="hover:text-green-500">
                  <li>Track/Return order</li>
                </Link> */}

                <Link to={"/terms-conditions"}>
                  <li className=" underline-hover-effect  pr-4 py-2 sm:py-0.5">
                    Terms & conditions
                  </li>
                </Link>
                <Link to={"/cancel-return"}>
                  <li className=" underline-hover-effect pr-4 py-2 sm:py-0.5">
                    Cancel & Refund policy
                  </li>
                </Link>
                <Link to={"/delivery-details"}>
                  <li className="underline-hover-effect w-auto pr-4 py-2 sm:py-0.5">
                    Delivery & Shipping
                  </li>
                </Link>
              </ul>
            </div>
          </div>

          <div className=" p-2 xl:flex justify-center">
            <div className="group">
              <div className="pb-2 flex justify-between ">
                <b>DISCOVER MYUNDE</b>
                <svg
                  className="fill-current h-6 w-10 md:hidden "
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>

              <ul className="hidden group-hover:block md:block">
                <Link to={"/ourstory"}>
                  <li className="underline-hover-effect w-auto pr-4">
                    Our Story
                  </li>
                </Link>
                {/* <Link to={"/Ourreview"}>
                    <li className="underline-hover-effect w-auto pr-4 ">Our Reviews</li>
                    
                  </Link> */}
                {/* <Link to={""} className="hover:text-green-500">
                  <li>Locate Myunde Studios</li>
                </Link>
                <Link to={""} className="hover:text-green-500">
                  <li>Our Investors</li>
                </Link>
                <Link to={""} className="hover:text-green-500">
                  <li>careers </li>
                </Link>
                <Link to={""} className="hover:text-green-500">
                  <li>Press careers</li>
                </Link>
                <Link to={""} className="hover:text-green-500">
                  
                  <li>Circle of Radiance</li>
                </Link>
                <Link to={""} className="hover:text-green-500">
                  
                  <li>Myunde Collections</li>
                </Link>
                <Link to={""} className="hover:text-green-500">
                  <li>Myunde Blog</li>
                </Link>
                <Link to={""} className="hover:text-green-500">
                  <li>Myunde Fit secrets</li>
                </Link>
                <Link to={""} className="hover:text-green-500">
                  <li>Find Your Perfect Fit</li>
                </Link> */}
              </ul>
            </div>
          </div>

          <div className=" flex w-full col-span-2 justify-evenly xl:justify-start items-center my-4">
            <button className="bg-white text-xs my-3 h-12 md:h-16 xl:mr-20  hover:scale-105 duration-300 font-style text-start rounded-xl shadow-md  w-36 sm:w-40 md:w-52 py-2">
              <a
                href="https://apps.apple.com/in/app/myunde/id1668960059"
                rel="noreferrer"
                target="_blank"
              >
                <div className="flex items-center justify-center">
                  <img
                    src={apple}
                    alt="app store"
                    className=" w-7 h-7 sm:w-8 sm:h-8 "
                  />
                  <div className="ml-3">
                    <p>Available on </p>
                    <p className="text-sm sm:text-[15px] md:text-lg">
                      App store
                    </p>
                  </div>
                </div>
              </a>
            </button>

            <button className="bg-white hover:scale-105 h-12 md:h-16  duration-300 text-xs my-3 font-style text-start rounded-xl shadow-md  w-36 sm:w-40 md:w-52  py-2">
              <a
                href="https://play.google.com/store/apps/details?id=com.myunde.myunde"
                rel="noreferrer"
                target="_blank"
              >
                <div className="flex items-center justify-center ">
                  <img
                    src={play}
                    alt="play store"
                    className="w-7 h-7 sm:w-8 sm:h-8 "
                  />
                  <div className="ml-3">
                    <p>Available on </p>
                    <p className="text-sm sm:text-[15px] md:text-lg">
                      Play store
                    </p>
                  </div>
                </div>
              </a>
            </button>
          </div>

          {/* <div className=" font-style   my-4   sm:flex w-full col-span-3  xl:col-span-2 ">
            <div className=" text-start  flex justify-evenly bg-gray-200 border-[1px]  border-black/30  h-20 w-full   p-4 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10  h-10 text-gray-500 mt-1 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                />
              </svg>
              Discreat <br />
              Packaging
            </div>

            <div className=" text-start  flex justify-evenly bg-gray-200 border-[1px]  border-black/30  h-20 w-full  p-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-10  h-10 text-gray-500 mt-1 "
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              Dispatch <br />
              within 24 hrs
            </div>
          </div> */}

          <div className="flex my-2 flex-wrap md:flex-nowrap  justify-evenly md:justify-between w-full col-span-4 md:col-span-3 xl:col-span-4  border-y-2 border-gray-400 ">
            <div className="uppercase text-sm my-5 md:my-3 w-full flex items-center justify-center md:justify-start">
              <div className="text-center md:text-start">
                <p className="mb-3 text-lg font-style">
                  registered office address
                </p>
                <h1 className="text-sm text-primary font-bold ">WEBTEK FASHION PVT LTD</h1>
                <div className="capitalize mt-1">
                  1st Floor,MK plaza, Thoppu thottam,
                  <br />
                  Solipalayam Road, 15 velampalayam,
                  <br />
                  Tirupur-641652.
                </div>
              </div>
            </div>

            <div className="my-2 md:my-5 w-full flex items-center justify-center text-start">
              <ul className="">
                <li className="py-2 sm:py-0.5">
                  <b>Phone No : </b>
                  <a
                    href="tel:95141 00444"
                    className="hover:text-[rgb(0,53,96)] "
                  >
                    +91 95141 00444
                  </a>
                </li>
                <li className="py-2 sm:py-0.5">
                  <b>Email : </b>
                  <button
                    onClick={() =>
                      (window.location = "mailto:feedback@myunde.com")
                    }
                    className="hover:text-[rgb(0,53,96)]"
                  >
                    
                    feedback@myunde.com
                  </button>
                </li>
                <li className="py-2 sm:py-0.5">
                  <b>Web :</b>
                  <Link to={"/"} className="hover:text-[rgb(0,53,96)]">
                    
                    www.myunde.com
                  </Link>
                </li>
              </ul>
            </div>

            <div className="my-5 w-full  flex items-center justify-center md:justify-end">
              <p>
                <b>CIN:</b> 000000000000000000
              </p>
            </div>
          </div>

          {/* <div className="flex flex-wrap md:flex-nowrap   justify-evenly md:justify-between w-full col-span-4 md:col-span-3 xl:col-span-4   ">
            <div className=" flex  w-[80%] ">
              <div className="text-center md:text-start  ">
                <h4 className="mb-2 text-lg font-style">
                  <b>Pay using</b>
                </h4>
                <div className="flex justify-center md:justify-start ">
                  <img
                    src={paymentOptions}
                    alt="pay"
                    className="lg:w-[60%]  xl:w-[50%] mb-2"
                  />
                </div>
              </div>
            </div>

            <div className="p-3 w-full lg:w-72 flex justify-center md:justify-end  ">
              <div className="w-[50%]  lg:w-full">
                <p className="text-center lg:text-end text-lg">
                  <b>100%</b> secure payment
                </p>
                <div className="flex justify-center lg:justify-end ">
                  <img src={razor} alt="pay" className="w-[70%]  mb-2 " />
                </div>
              </div>
            </div>
          </div> */}

          <div className="flex  flex-wrap md:flex-nowrap items-center  justify-evenly md:justify-between w-full col-span-4  md:col-span-3 xl:col-span-4   ">
            <hr className="border border-gray-400/10 w-full hidden md:block" />

            <div className=" my-2 md:w-[60%]">
              <div className="flex space-x-3 justify-center   mt-3">
                <a
                  href="https://www.facebook.com/profile.php?id=100085678073406"
                  target={"blank"}
                  aria-label="Facebook"
                >
                  
                  <Tooltip title="Facebook" arrow placement="top">
                    <div
                      className=" p-2 flex items-center justify-center  hover:text-[rgb(0,53,96)]"
                      aria-label="Facebook"
                    >
                      <FaFacebookF className="text-md  flex lg:text-md cursor-pointer" />
                    </div>
                  </Tooltip>
                </a>
                <a
                  href="https://www.linkedin.com/in/wb-pl-146168249"
                  target={"blank"}
                  aria-label="Linkedin"
                >
                  <Tooltip title="Linkedin" arrow placement="top">
                    <div
                      className="  hover:text-[rgb(0,53,96)]  p-2 flex items-center justify-center "
                      aria-label="Linkedin"
                    >
                      <FaLinkedin className="text-md lg:text-md cursor-pointer " />
                    </div>
                  </Tooltip>
                </a>
                <a
                  href="https://twitter.com/Myundee"
                  target={"blank"}
                  aria-label="Twitter"
                >
                  <Tooltip title="Twitter" arrow placement="top">
                    <div
                      className="  hover:text-[rgb(0,53,96)]  p-2 flex items-center justify-center "
                      aria-label="Twitter"
                    >
                      <FaTwitter className="text-md lg:text-md cursor-pointer " />
                    </div>
                  </Tooltip>
                </a>
                <a
                  href="https://www.youtube.com/@myunde"
                  target={"blank"}
                  aria-label="Youtube"
                >
                  <Tooltip title="Youtube" arrow placement="top">
                    <div
                      className="  hover:text-[rgb(0,53,96)]  p-2 flex items-center justify-center"
                      aria-label="Youtube"
                    >
                      <FaYoutube className="text-md lg:text-md cursor-pointer " />
                    </div>
                  </Tooltip>
                </a>
                
                <a
                  href="https://www.instagram.com/_myunde_"
                  target={"blank"}
                  aria-label="Instagram"
                >
                  <Tooltip title="Instagram" arrow placement="top">
                    <div
                      className="  hover:text-[rgb(0,53,96)]  p-2 flex items-center justify-center  "
                      aria-label="Instagram"
                    >
                      <GrInstagram className="text-md lg:text-md cursor-pointer " />
                    </div>
                  </Tooltip>
                </a>
                <a
                  href="https://api.whatsapp.com/message/IE3ILKLB3TM2F1?autoload=1&app_absent=0"
                  target={"blank"}
                  aria-label="Whatsapp"
                >
                  <Tooltip title="Whatsapp" arrow placement="top">
                    <div
                      className="  hover:text-[rgb(0,53,96)]  p-2 flex items-center justify-center  "
                      aria-label="Whatsapp"
                    >
                      <FaWhatsapp className="text-md lg:text-lg cursor-pointer " />
                    </div>
                  </Tooltip>
                </a>
              </div>
              <div className="text-center text-gray-500  font-style xl:text-lg ">
                <p> ©{year} Myunde all rights reserved</p>
              </div>
            </div>

            <hr className="border border-gray-400/10 w-full hidden md:block" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogFooter;

//    <div className=" flex flex-wrap justify-evenly mr-2   w-full    mt-5">
// <button className="bg-gray-100 text-xs my-3  hover:scale-105 duration-300 font-style text-start rounded-xl shadow-md  w-36 sm:w-40 lg:w-52 py-2">
//   <div className="flex items-center justify-center">
//     <img
//       src={apple}
//       alt="app store"
//       className=" w-7 h-7 sm:w-8 sm:h-8 "
//     />
//     <div className="ml-3">
//       <p>Available on </p>
//       <p className="text-sm sm:text-[15px] md:text-lg">
//         App store
//       </p>
//     </div>
//   </div>
// </button>

// <button className="bg-gray-100 hover:scale-105 duration-300 text-xs my-3 font-style text-start rounded-xl shadow-md  w-36 sm:w-40 lg:w-52  py-2">
//   <div className="flex items-center justify-center ">
//     <img
//       src={play}
//       alt="play store"
//       className="w-7 h-7 sm:w-8 sm:h-8 "
//     />
//     <div className="ml-3">
//       <p>Available on </p>
//       <p className="text-sm sm:text-[15px] md:text-lg">
//         Play store
//       </p>
//     </div>
//   </div>
// </button>
// </div>
