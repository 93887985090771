import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import { Link, useNavigate } from "react-router-dom";
import logo1 from "../../images/Myunde1.png";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Hidden, IconButton } from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { getBlogCategory } from "../../actions/blogActions";

function HideOnScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function BlogHeader(props) {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBlogCategory());
  }, []);

  const [open, setOpen] = React.useState(false);

  const categoryList = useSelector((state) => state.blog);
  const { categories, blogs } = categoryList;

  const [selectedCategory, setSelectedCategory] = React.useState({});

  const mapItems = categories?.map((item, i) => {
    return (
      <li key={i} className="flow-root my-5 px-2 w-54 font-poppins">
        <div
          // className="-m-2 block p-2 text-gray-500"
          className={`cursor-pointer uppercase ${
            item?.id === Number(selectedCategory.id)
              ? "-m-2 block p-2 text-primary font-style   rounded-lg bg-gray-100"
              : "-m-2 block p-2 text-gray-500 underline-hover-effect"
          }`}
          onClick={() => {
            setSelectedCategory(item);
            navigate(`/blog/category/${item.name}`);
            setOpen(false)
          }}
        >
          {item.name}
        </div>
      </li>
    );
  });

  return (
    <React.Fragment>
      <CssBaseline />
      <Drawer
        className=""
        anchor={"left"}
        transitionDuration={500}
        open={open}
        onClose={() => setOpen()}
      >
        <div className="relative flex w-screen xs:w-[22rem] flex-col   bg-white pb-12 ">
          <div
            className="flex px-4 pt-5 pb-4 justify-between rounded-b-xl sticky top-0 z-50 shadow-lg bg-gradient"
            // style={{
            //   background:
            //     "linear-gradient(49deg, rgba(227,242,255,1) 0%, rgba(249,247,240,1) 87%)",
            // }}
          >
            <Link to="/" aria-label="homepage">
              <img
                className=" w-[90px]   sm:w-28  outline-none"
                src={logo1}
                alt="MyundeLogo"
              />
            </Link>
            <button
              type="button"
              className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
              onClick={() => setOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          {/* Links */}
          <div className="p-4">{mapItems}</div>
        </div>
      </Drawer>
      <HideOnScroll {...props}>
        <AppBar color="">
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Hidden lgUp>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
                onClick={() => setOpen(true)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </IconButton>
            </Hidden>
            <div className="h-16 lg:h-24 w-full max-w-[1150px]  flex items-center justify-center lg:justify-between lg:px-4 font-poppins ">
              <div className="lg:w-[20%]">
                <Link to="/blog" onClick={() => setSelectedCategory({})}>
                  <img
                    className="   w-32 xl:w-36 outline-none"
                    src={logo1}
                    alt={`${logo1}`}
                  />
                </Link>
              </div>
              <Hidden lgDown>
                <div className=" lg:w-[80%] flex justify-end space-x-12 ">
                  {mapItems}
                </div>
              </Hidden>
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </React.Fragment>
  );
}

export default BlogHeader;
