import React, { useEffect } from "react";

import { useForm } from "react-hook-form";
import { useState } from "react";
// import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";
import Dialog from "@mui/material/Dialog";

import Rating from "@mui/material/Rating";
import { useDispatch, useSelector } from "react-redux";
import { PRODUCT_CREATE_REVIEW_RESET } from "../constants/productConstants";
import {
  createProductReview,
  listProductReviews,
} from "../actions/productActions";
import { Button } from "@material-tailwind/react";

import Compressor from "compressorjs";

const labels = {
  0: "give a rating",
  0.5: "Useless+",
  1: "Useless",
  1.5: "Poor+",
  2: "Poor",
  2.5: "Ok",
  3: "Ok+",
  3.5: "Good",
  4: "Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]} `;
}

function Writereview({ forReview, openReview, productId }) {
  const [value, setValue] = useState(5);
  const [hover, setHover] = useState(5);

  const { register, handleSubmit, reset } = useForm();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const reviewList = useSelector((state) => state.productReviews);
  const { productsReviews } = reviewList;

  const orderListMy = useSelector((state) => state.orderListMy);
  const { orders } = orderListMy;




  const checkEligible = () => {
    let eligible = false;

    if (orders !== "no_orders" && orders?.length && userInfo && productId) {
      eligible = orders?.filter((order) => order?.user?.id === userInfo?.id)
        ?.flatMap((map) => map.orderItems)
        ?.some((id) => id.product === productId);
    }
    return eligible;
  };

  let reviewAlreadyExist = [];

  if (userInfo) {
    reviewAlreadyExist =
      productsReviews[0]?.reviews
        ?.map((review) => review.product)
        ?.includes(productId) &&
      productsReviews[0]?.reviews?.map(
        (review) => review?.user === userInfo?.id
      );
  }

  useEffect(() => {
    dispatch(listProductReviews(productId));
  }, [productId]);

  // const reviewAlreadyExist = useSelector((state) => state.productReviewCreate);
  // const { status } = reviewAlreadyExist;

  const onSubmit = (data) => {
    const formData = new FormData();

    Object.assign(data, { rating: value });

    formData.append("rating", value);
    formData.append("comment", data.comment);

    const handleImageCompress = (index) => {
      new Compressor(data.images[index], {
        quality: 0.8,
        success: (compressedResult) => {
          formData.append("images", compressedResult, compressedResult?.name);
          const newIndex = index + 1;
          if (newIndex < data.images?.length) {
            handleImageCompress(newIndex);
          } else {
            dispatch(createProductReview(productId, formData));
          }
        },
        error: (error) => {
          console.log("error", error.message);
        },
      });
    };
    if (data?.images?.length) {
      handleImageCompress(0);
    } else {
      dispatch(createProductReview(productId, formData));
    }

    reset({ comment: "", rating: Number(5) });

    forReview();
  };

  const productReviewCreate = useSelector(
    (state) => state?.productReviewCreate
  );

  const {
    // loading: loadingProductReview,
    // error: errorProductReview,
    success: successProductReview,
  } = productReviewCreate;

  if (successProductReview) {
    dispatch({ type: PRODUCT_CREATE_REVIEW_RESET });
  }


  const handleVideoUpload = (file) => {
    if(file?.type?.includes("video")){
      alert("uploading vedio currently unavailable")
      return
    }
  }


  return (
    <div className="select-none">
      <Dialog
        open={openReview}
        transitionDuration={700}
        onClose={forReview}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {userInfo ? (
          reviewAlreadyExist === true ||
          (reviewAlreadyExist?.length &&
            reviewAlreadyExist?.some((some) => some === true)) ? (
            <div className="p-4 md:p-8  font-style text-primary flex font-style">
              <p className="">product already reviewed</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="rgb(247,185,0)"
                className="w-6 h-6 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                />
              </svg>
            </div>
          ) : checkEligible() ? (
            <div className="  w-full bg-white rounded-lg   font-style">
              <div className="w-full flex flex-wrap justify-between items-center text-gray-800 p-3  rounded-t-lg shadow-md  bg-gray-100 ">
                <p className="text-lg  sm:text-xl font-bold text-gray-800 ">
                  Give Rating & Review
                </p>

                <AiOutlineClose
                  className="w-6 h-6 hover:scale-75  "
                  onClick={() => forReview()}
                />
              </div>
 
              <div className="p-4 md:p-8  ">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="my-4">
                    <p className="flex justify-start mb-1  items-center font-bold text-gray-700">
                      Product rating
                    </p>
                    <div className=" flex justify-start items-center my-4 ">
                      <Rating
                        name="hover-feedback"
                        value={value}
                        precision={1}
                        getLabelText={getLabelText}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                        onChangeActive={(event, newHover) => {
                          setHover(newHover);
                        }}
                      />
                      {value !== null && (
                        <div className=" font-style rounded-xl flex text-primary px-2 py-1 justify-center bg-blue-50 mb-1.5 items-center capitalize w-32   ml-4">
                          {labels[hover !== -1 ? hover : value]}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="py-4">
                    <p className="flex justify-start mb-1 font-bold text-gray-700">
                      Comment
                    </p>

                    <textarea
                      {...register("comment")}
                      required
                      className="flex justify-start bg-blue-50  w-full md:h-32 p-4 sm:w-96 focus:outline-blue-100 rounded-tl-lg rounded-br-lg"
                    />
                  </div>
                  <div className="py-6">
                    <p className="flex justify-start mb-1 font-bold text-gray-700">
                      Add image
                    </p>

                    <input
                      {...register("images")}
                      type="file"
                      accept="image/*;capture=camera"
                      className="block my-2 w-full text-[15px] text-slate-500
                          file:mr-4  file:py-2 file:px-4
                          file:rounded-xl file:border-0
                          file:text-[15px] file:font-style
                          file:bg-blue-50 file:text-[rgb(0,53,96)]
                          hover:file:bg-blue-50 -ml-1"
                      multiple
                      onChange={(event) => handleVideoUpload(event.target.files[0])}
                    />
                  </div>

                  <div className="flex justify-end w-full px-3">
                    <Button
                      type="submit"
                      className="flex w-full mt-3   bg-primary  px-10 h-8  rounded-lg justify-center items-center  text-white "
                    >
                      save
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div className="p-4 md:p-8  font-style text-primary flex font-style">
              <p className="">
                You are not able to give a review before buying this product.
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="rgb(247,185,0)"
                className="w-6 h-6 ml-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
                />
              </svg>
            </div>
          )
        ) : (
          <div className="p-4 md:p-8  font-style text-primary flex font-style">
            <p className="">please login to give rating</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="rgb(247,185,0)"
              className="w-6 h-6 ml-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z"
              />
            </svg>
          </div>
        )}
      </Dialog>
    </div>
  );
}

export default Writereview;
