import React from "react";

import { useSelector } from "react-redux";

function BlogContentPage() {
  const { blogContent, categories } = useSelector((state) => state.blog);

  const categoryName = categories.find(
    (cat) => cat.id === blogContent.category
  );

  return (
    <div>
      <div className="lg:flex justify-center font-poppins p-2">
        <div className="lg:flex flex-wrap justify-evenly  lg:w-full max-w-[1100px]">
          <p className="text-center font-poppins my-3 uppercase  text-green-700 w-full">
            {categoryName?.name}
          </p>
          <p className="text-center font-poppins text-2xl w-full mb-3 ">
            {blogContent?.title}
          </p>
          <p className="text-center font-poppins text-lg mb-6 text-gray-400">
            {new Date(blogContent?.created_at).toDateString()}
          </p>
          <img src={blogContent.image} alt="" className="w-full mb-6" />
          <article style={{ wordWrap: "break-word" }}>
            <div
              dangerouslySetInnerHTML={{ __html: blogContent?.content }}
              className="space-y-6 "
            />
          </article>
        </div>
      </div>
    </div>
  );
}

export default BlogContentPage;
