import React, { useState, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import Slider from "@material-ui/core/Slider";
import {
  listFabrics,
  listColors,
  listStyles,
  listBrands,
  listCategories,
  listFilter,
  listSize,
} from "../actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import "../css/style.css";

import { Button } from "@material-tailwind/react";
import { RiFilter3Fill } from "react-icons/ri";

export const Filter = React.memo(() => {
  
  const search = useLocation().search;
  const categoryID = new URLSearchParams(search).get("category");
  const keyword = new URLSearchParams(search).get("keyword");
  const brandID = new URLSearchParams(search).get("brand");

  const [open, setOpen] = useState(false);

  const brandList = useSelector((state) => state.brandList);
  const { brand } = brandList;

  const fabricsList = useSelector((state) => state.fabricsList);
  const { fabrics } = fabricsList;

  // const sizesList = useSelector((state) => state.sizesList);
  // const { sizes } = sizesList;

  const sizeList = useSelector((state) => state.sizeList);
  const { size } = sizeList;

  const colorsList = useSelector((state) => state.colorsList);
  const { colors } = colorsList;

  const stylesList = useSelector((state) => state.stylesList);
  const { styles } = stylesList;

  const filterList = useSelector((state) => state.filterList);
  const { filter } = filterList;

  const productList = useSelector((state) => state.productList);
  const { products, categoryIds } = productList;

  const [priceValue, setPriceValue] = React.useState([0, 0]);

  // Changing State when volume increases/decreases
  const rangeSelector = (event, newValue) => {
    setPriceValue(newValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!fabrics?.length) {
      dispatch(listFabrics());
    }
    if (!colors?.length) {
      dispatch(listColors());
    }

    dispatch(listStyles(categoryID));
    // dispatch(listCategories());
    if (categoryID || brandID) {
      dispatch(listFilter(categoryID ? categoryID : brandID));
    } else if (keyword) {
      if (categoryIds) {
        dispatch(listFilter(categoryIds));
      }
    }
    if (!brand?.length) {
      dispatch(listBrands());
    }
    if (!size?.length) {
      dispatch(listSize());
    }

    localStorage.removeItem("filteredData");
    setState({
      color: [],
      size: [],
      category: [],
      brand: [],
      style: [],
      fabric: [],
      pack: [],
    });
  }, [categoryID]);

  const navigate = useNavigate();
  const [state, setState] = useState({
    color: [],
    size: [],
    category: [],
    brand: [],
    style: [],
    fabric: [],
    pack: [],
  });

  const clearAll = () => {
    localStorage.removeItem("filteredData");
    setState({
      color: [],
      size: [],
      category: [],
      brand: [],
      style: [],
      fabric: [],
      pack: [],
    });
    navigate({
      search: `?keyword=${keyword ?? ""}&page=1&category=${
        categoryID ?? ""
      }&brand=${
        state?.brand?.length
          ? state.brand
          : brandID?.length === 1 && !categoryID
          ? brandID
          : ""
      }&style=&fabric=&color=&size=&priceof=&pack=`,
    });
    if (categoryID || brandID) {
      dispatch(listFilter(categoryID ? categoryID : brandID));
    } else if (keyword) {
      const productCategoryIds = [];
      products.map((product) => {
        return productCategoryIds.push(product.category.id);
      });
      if (productCategoryIds?.length) {
        dispatch(listFilter(productCategoryIds));
      }
    }
  };

  const updatePack = ({ target: { checked, value } }) => {
    console.log(checked, "1111111111", value, "2222222222222222");
    if (checked) {
      setState(({ pack }) => ({ ...state, pack: [...pack, value] }));
    } else if (!checked) {
      setState(({ pack }) => ({
        ...state,
        pack: pack.filter((o) => Number(o) !== Number(value)),
      }));
    }
  };

  const update = function ({ target: { checked, value, name } }) {
    // console.log(e.target.value)
    // const name = e.target.name
    // const value = e.target.value
    if (name === "color") {
      if (checked) {
        const colorValue = [];
        colors
          .filter((color) => color.name === value)
          .map((color) => {
            return colorValue.push(color.id);
          });
        setState(({ color }) => ({ ...state, color: [...color, colorValue] }));
      } else if (!checked) {
        const colorValuefilter = [];
        colors
          .filter((color) => color.name === value)
          .map((color) => {
            return colorValuefilter.push(color.id);
          });
        setState(({ color }) => ({
          ...state,
          color:
            Array.isArray(color) && color?.length > 1
              ? color?.filter(
                  (i) => JSON.stringify(i) !== JSON.stringify(colorValuefilter)
                )
              : "",
        }));
      }

      // if (checked) {
      //   setState(({ color }) => ({ ...state, color: [...color, value] }));
      // } else if (!checked) {
      //   setState(({ color }) => ({
      //     ...state,
      //     color: color.filter((e) => e !== value),
      //   }));
      // }
    } else if (name === "size") {
      if (checked) {
        const sizeValue = [];
        size
          ?.filter((size) => size.name === value)
          .map((size) => {
            return sizeValue.push(size.id);
          });

        setState(({ size }) => ({ ...state, size: [...size, sizeValue] }));
      } else if (!checked) {
        const sizeValuefilter = [];

        size
          .filter((size) => size.name === value)
          .map((size) => {
            return sizeValuefilter.push(size.id);
          });

        setState(({ size }) => ({
          // ...state, size : Array.isArray(size) && size.length > 1 ? size.filter((s) => !sizeValuefilter.includes(s)):"",
          ...state,
          size:
            Array.isArray(size) && size?.length > 1
              ? size.filter(
                  (i) => JSON.stringify(i) !== JSON.stringify(sizeValuefilter)
                )
              : "",
        }));
      }
    } else if (name === "brand") {
      if (checked) {
        const brandValue = [];
        brand
          .filter((brand) => brand.name === value)
          .map((brand) => {
            return brandValue.push(brand.id);
          });
        setState(({ brand }) => ({ ...state, brand: [...brand, brandValue] }));
      } else if (!checked) {
        const brandValuefilter = [];
        brand
          .filter((brand) => brand.name === value)
          .map((brand) => {
            return brandValuefilter.push(brand.id);
          });
        setState(({ brand }) => ({
          ...state,
          brand:
            Array.isArray(brand) && brand?.length > 1
              ? brand?.filter(
                  (i) => JSON.stringify(i) !== JSON.stringify(brandValuefilter)
                )
              : "",
        }));
      }
    } else if (name === "style") {
      if (checked) {
        const styleValue = [];
        styles
          .filter((style) => style.name === value)
          .map((style) => {
            return styleValue.push(style.id);
          });
        setState(({ style }) => ({ ...state, style: [...style, styleValue] }));
      } else if (!checked) {
        const styleValuefilter = [];
        styles
          .filter((style) => style.name === value)
          .map((style) => {
            return styleValuefilter.push(style.id);
          });
        setState(({ style }) => ({
          ...state,
          style:
            Array.isArray(style) && style?.length > 1
              ? style?.filter(
                  (i) => JSON.stringify(i) !== JSON.stringify(styleValuefilter)
                )
              : "",
        }));
      }
    } else if (name === "fabric") {
      if (checked) {
        const fabricValue = [];
        fabrics
          .filter((fabric) => fabric.name === value)
          .map((fabric) => {
            return fabricValue.push(fabric.id);
          });
        setState(({ fabric }) => ({
          ...state,
          fabric: [...fabric, fabricValue],
        }));
      } else if (!checked) {
        const fabricValuefilter = [];
        fabrics
          .filter((fabric) => fabric.name === value)
          .map((style) => {
            return fabricValuefilter.push(style.id);
          });
        setState(({ fabric }) => ({
          ...state,
          fabric:
            Array.isArray(fabric) && fabric?.length > 1
              ? fabric?.filter(
                  (i) => JSON.stringify(i) !== JSON.stringify(fabricValuefilter)
                )
              : "",
        }));
      }
    }
  };

  const applyFilter = () => {
    navigate({
      search: `?keyword=${keyword ?? ""}&page=1&category=${
        categoryID ?? ""
      }&brand=${
        state?.brand?.length
          ? state.brand
          : brandID?.length === 1 && !categoryID
          ? brandID
          : ""
      }&style=${state.style}&fabric=${state.fabric}&color=${state.color}&size=${
        state.size
      }&priceof=${priceValue}&pack=${state.pack ?? ""}`,
    });
    localStorage.setItem("filteredData", JSON.stringify(state));
    setOpen(!open);
  };

  const Localstate = JSON.parse(localStorage.getItem("filteredData"));

  useEffect(() => {
    if (Localstate) {
      setState(Localstate);
    }
  }, []);

  const styleSet = new Set();
  styleSet.clear();

  const sizeSet = new Set();
  sizeSet.clear();

  const colorSet = new Set();
  colorSet.clear();

  const brandSet = new Set();
  brandSet.clear();

  const fabricSet = new Set();
  fabricSet.clear();

  const multiPack = new Set();
  multiPack.clear();




  
  // const categoryName = category?.find((category) => {
  //   return category.id === Number(categoryID);
  // });

  // let mymap = new Map();

  // const filterSizes = sizes?.filter((el) => {
  //   const val = mymap.get(el.name);
  //   if (val) {
  //     if (el.id < val) {
  //       mymap.delete(el.name);
  //       mymap.set(el.name, el.id);
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  //   mymap.set(el.name, el.id);
  //   return true;
  // });
  // let tick = JSON.parse(localStorage.getItem("filteredData"))
  // const UrlSearch = (tickValue, id) => {
  //    if (tick){
  //     console.log(typeof(tick[tick]))
  //    tick = tick[tickValue]
  //    return tick ? tick.includes(`${id}`) : false
  //    }

  //   // return tick ? tick.split(",").includes(`${id}`) : false;
  // };

  const UrlSearch = (value, id) => {
    const tick = new URLSearchParams(search).get(`${value}`);
    return tick ? tick.split(",").includes(`${id}`) : false;
  };

  return (
    <>
      {/* {categoryName?.name ? <p className=" flex sm:justify-center absolute   sm:left-[55%] pt-2 left-[50%] transform -translate-x-1/2 text-xl  sm:text-3xl  capitalize">
       category / <b> {categoryName?.name}</b>
      </p> : ""}  */}

      <div className="select-none xl:h-screen xl:sticky top-44  mt-8 font-style">
        <div className="float-left absolute  font-bold  -mt-6   pt-2  px-2 sm:px-3 py-3   lg:hidden w-full z-40  text-gray-600 flex ">
          <div
            onClick={() => setOpen(!open)}
            className=" flex sm:text-lg  items-center"
          >
            <RiFilter3Fill className="w-6 h-6  sm:w-7 sm:h-7    mr-2 text-gray-400" />
            FILTER
          </div>
        </div>
        <div
          className={
            open ? "relative z-50    " : "sticky top-40 z-10 hidden lg:block"
          }
        >
          <div className="inset-0 overflow-hidden relative ">
            <div className="relative inset-0 overflow-hidden">
              <div className="pointer-events-none fixed  lg:sticky inset-y-0 left-0 flex  ">
                <div className="pointer-events-auto w-screen xs:w-[27rem] lg:w-[16rem] xl:w-[18rem]  lg:ml-8 ">
                  <div className="flex h-screen flex-col bg-white opacity-95 lg:bg-white/10 lg:backdrop-blur-[1px] ">
                    <div className="flex justify-between  lg:justify-between py-4   border-b-2 shadow-lg lg:shadow-none shadow-gray-300  absolute   bg-gradient-to-r from-[rgb(228,242,255)] to-[rgb(249,247,240)]  lg:bg-gradient-to-r lg:from-white lg:to-white  w-full  z-10 ">
                      <div className="flex text-lg font-bold text-gray-700  space-x-10  ">
                        <RiFilter3Fill className="w-6 h-6 mt-0.5 mr-1" />
                        FILTER BY
                      </div>

                      <div className="ml-3 flex h-7 items-center text-gray-700">
                        <button
                          type="button"
                          className="-m-2 p-2  lg:hidden"
                          onClick={() => setOpen(!open)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-6 w-6 hover:scale-150 mr-2 "
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="pt-20 flex justify-between bg-white py-5 px-3">
                      
                      <Button
                        onClick={() => clearAll()}
                        className=" py-2 rounded-md capitalize  hover:text-white border border-primary bg-secondary text-primary font-style flex items-center"
                      >
                        clear
                      </Button>
                      <Button
                        className=" py-2 rounded-md  hover:text-white bg-white capitalize text-black border border-gray-400  font-style flex items-center"
                        onClick={() => applyFilter()}
                      >
                        Apply
                      </Button>
                    </div>
                    <div className="flex-1 divide-y  overflow-y-scroll  no-scrollbar pb-40 px-2 py-2">
                      <Accordion
                        className=" w-full  "
                        style={{
                          boxShadow: "none",
                          backgroundColor: "rgb(255,255,255,0.1)",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <svg
                              className="fill-current h-9 w-5 "
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          }
                          className="flex w-full justify-between "
                        >
                          <p className="font-style text-gray-600 ">SIZE </p>
                        </AccordionSummary>

                        <AccordionDetails className="    ">
                          <div className=" w-full ">
                            {filter?.size?.map((size, i) => {
                              if (!sizeSet?.has(size.name)) {
                                sizeSet?.add(size.name);
                                return (
                                  <div className=" flex items-center " key={i}>
                                    <input
                                      type="checkbox"
                                      name="size"
                                      defaultChecked={UrlSearch(
                                        "size",
                                        size.id
                                      )}
                                      value={size.name}
                                      onChange={(e) => update(e)}
                                      id={size.name}
                                      className=" accent-black 
                                       text-black    h-4 w-4 "
                                    />
                                    <label
                                      htmlFor={size.name}
                                      className="text-sm  m-1 ml-3"
                                    >
                                      {size.name}
                                    </label>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        className=" w-full mt-3 "
                        style={{
                          boxShadow: "none",
                          backgroundColor: "rgb(255,255,255,0.1)",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <svg
                              className="fill-current h-9 w-5 "
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          }
                          className="flex w-full justify-between "
                        >
                          <p className="font-style text-gray-600">COLOR</p>
                        </AccordionSummary>
                        <AccordionDetails className=" stock-images  ">
                          <div
                            className={`style-4 ${
                              filter?.color?.length >= 5
                                ? "h-40 overflow-y-auto   "
                                : ""
                            } `}
                          >
                            {filter?.color?.map((color, i) => {
                              if (!colorSet?.has(color.name)) {
                                colorSet?.add(color.name);
                                return (
                                  <div
                                    className="p-1 flex items-center "
                                    key={i}
                                  >
                                    <div
                                      className="w-6 h-6 border"
                                      style={
                                        color.image
                                          ? {
                                              backgroundImage: `url(${color?.image})`,
                                              backgroundSize: "contain",
                                            }
                                          : {
                                              backgroundColor: `${color?.code}`,
                                            }
                                      }
                                    ></div>
                                    <input
                                      id={color.name}
                                      name="color"
                                      defaultChecked={UrlSearch(
                                        "color",
                                        color.id
                                      )}
                                      type="checkbox"
                                      className="w-4 h-4 mx-2 p-1 border  "
                                      value={color.name}
                                      onChange={(e) => update(e)}
                                      style={{
                                        accentColor: `${color.code}`,
                                        border: `1px solid ${color.code}`,
                                      }}
                                    />
                                    <label
                                      htmlFor={color.name}
                                      className="text-xs  truncate m-1 ml-1  w-52 "
                                    >
                                      <div
                                        // className="image "
                                        // style={{
                                        //   backgroundColor: `${color.code}`,
                                        // }}
                                        //  backgroundImage: `url(http://loremflickr.com/620/440/london)`
                                        value={color.id}
                                        // style={{
                                        //   color: `${color.code}`,
                                        // }}
                                      >
                                        {color.name}
                                      </div>
                                    </label>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        className=" w-full mt-3 "
                        style={{
                          boxShadow: "none",
                          backgroundColor: "rgb(255,255,255,0.1)",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <svg
                              className="fill-current h-9 w-5 "
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          }
                          className="flex w-full justify-between "
                        >
                          <p className="font-style text-gray-600">BRAND</p>
                        </AccordionSummary>
                        <AccordionDetails className=" stock-images  ">
                          {filter?.brand?.map((brand, i) => {
                            if (!brandSet?.has(brand.name)) {
                              brandSet?.add(brand.name);
                              return (
                                <div key={i} className="flex items-center">
                                  <input
                                    type="checkbox"
                                    id={brand.name}
                                    onChange={(e) => update(e)}
                                    defaultChecked={UrlSearch(
                                      "brand",
                                      brand.id
                                    )}
                                    name="brand"
                                    value={brand.name}
                                    className="bg-smokewhite rounded-md accent-black
                                  text-black   h-4 w-4 "
                                  />
                                  <label
                                    htmlFor={brand.name}
                                    className="text-sm  truncate  m-1 ml-3   "
                                  >
                                    
                                    {brand.name}
                                  </label>
                                  <br />
                                </div>
                              );
                            }
                          })}
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        className="  w-full mt-3 "
                        style={{
                          boxShadow: "none",
                          backgroundColor: "rgb(255,255,255,0.1)",
                        }}
                      >
                        <AccordionSummary
                          className="flex w-full justify-between "
                          expandIcon={
                            <svg
                              className="fill-current h-5 w-5"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          }
                        >
                          <p className="font-style text-gray-600">FABRIC</p>
                        </AccordionSummary>
                        <AccordionDetails className="   ">
                          <div
                            className={` ${
                              filter?.fabric?.length >= 5
                                ? "h-40 overflow-y-auto  style-4"
                                : ""
                            } `}
                          >
                            {filter?.fabric?.map((fabric, i) => {
                              if (!fabricSet?.has(fabric.name)) {
                                fabricSet?.add(fabric.name);
                                return (
                                  <div key={i} className="flex items-center">
                                    <input
                                      type="checkbox"
                                      id={fabric.name}
                                      defaultChecked={UrlSearch(
                                        "fabric",
                                        fabric.id
                                      )}
                                      onChange={(e) => update(e)}
                                      name="fabric"
                                      value={fabric.name}
                                      className="bg-smokewhite rounded-md accent-black
                                  text-black   h-4 w-4 "
                                    />
                                    <label
                                      htmlFor={fabric.name}
                                      className="text-sm  truncate m-1 ml-3  w-52 "
                                    >
                                      
                                      {fabric.name}
                                    </label>
                                    <br />
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        className=" w-full mt-3  "
                        style={{
                          boxShadow: "none",
                          backgroundColor: "rgb(255,255,255,0.1)",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <svg
                              className="fill-current h-9 w-5 "
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          }
                          className="flex w-full justify-between "
                        >
                          <p className="font-style text-gray-600">
                            
                            PACK / MULTIPACK
                          </p>
                        </AccordionSummary>
                        <AccordionDetails className="">
                          <div
                            className={` ${
                              filter?.pack_of?.length >= 5
                                ? "h-40 overflow-y-auto  style-4"
                                : ""
                            } `}
                          >
                            {filter?.pack_of?.map((pack, i) => {
                              return (
                                <div key={i} className="flex items-center">
                                  <input
                                    type="checkbox"
                                    defaultChecked={UrlSearch("pack_of", pack)}
                                    id={i}
                                    onChange={(e) => updatePack(e)}
                                    // defaultChecked={UrlSearch("pack", pack.id)}
                                    name="pack"
                                    value={pack}
                                    className="bg-smokewhite rounded-md accent-black
                                text-black   h-4 w-4 "
                                  />

                                  <label
                                    htmlFor={i}
                                    className="text-sm truncate m-1 ml-3  capitalize"
                                  >
                                    
                                    pack of {pack}
                                  </label>
                                  <br />
                                </div>
                              );
                            })}

                            {/* {filter?.pack_of?.map((pack, i) => {
                              
                              if (!multiPack?.has(pack.name)) {
                                multiPack?.add(pack.name);
                                return (
                                  <div key={i} className="flex items-center">
                                    <input
                                      type="checkbox"
                                      id={i}
                                      onChange={(e) => update(e)}
                                      // defaultChecked={UrlSearch("pack", pack.id)}
                                      name="pack"
                                      value={pack}
                                      className="bg-smokewhite rounded-md accent-black
                                  text-black   h-4 w-4 "
                                    />
                                    <label
                                      htmlFor={i}
                                      className="text-sm truncate m-1 ml-3  "
                                    >
                                      
                                      pack of {pack}
                                    </label>
                                    <br />
                                  </div>
                                );
                              }
                            })} */}
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        className=" w-full mt-3 "
                        style={{
                          boxShadow: "none",
                          backgroundColor: "rgb(255,255,255,0.1)",
                        }}
                      >
                        <AccordionSummary
                          className="flex w-full justify-between "
                          expandIcon={
                            <svg
                              className="fill-current h-9 w-5 "
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          }
                        >
                          <p className="font-style text-gray-600"> STYLE</p>
                        </AccordionSummary>
                        <AccordionDetails className="   ">
                          <div
                            className={` ${
                              filter?.style?.length >= 5
                                ? "h-40 overflow-y-auto  style-4"
                                : ""
                            } `}
                          >
                            {filter?.style?.map((style, i) => {
                              if (!styleSet?.has(style.name)) {
                                styleSet?.add(style.name);
                                return (
                                  <div className="flex items-center" key={i}>
                                    <input
                                      type="checkbox"
                                      id={style.name}
                                      onChange={(e) => update(e)}
                                      defaultChecked={UrlSearch(
                                        "style",
                                        style.id
                                      )}
                                      name="style"
                                      value={style.name}
                                      className="bg-smokewhite rounded-md accent-black
                                  text-black   h-4 w-4 "
                                    />
                                    <label
                                      htmlFor={style.name}
                                      className="text-sm   m-1 ml-3"
                                    >
                                      {style.name}
                                    </label>
                                    <br />
                                  </div>
                                );
                              }
                            })}
                          </div>
                        </AccordionDetails>
                      </Accordion>

                      <Accordion
                        className=" w-full mt-3 "
                        style={{
                          boxShadow: "none",
                          backgroundColor: "rgb(255,255,255,0.1)",
                        }}
                      >
                        <AccordionSummary
                          className="flex w-full justify-between "
                          expandIcon={
                            <svg
                              className="fill-current h-9 w-5 "
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                            </svg>
                          }
                        >
                          <p className="font-style text-gray-600"> PRICE</p>
                        </AccordionSummary>
                        <AccordionDetails className="   ">
                          {/* <input
                           value={price}
                            className="range w-full"
                            type="range"
                            min={0}
                            max={100}
                            onChange={(e)=> setPrice(e.target.value)}
                          /> */}
                          <div
                            style={{
                              margin: "auto",
                              display: "block",
                              width: "fit-content",
                            }}
                          >
                            <Slider
                              value={priceValue}
                              onChange={rangeSelector}
                              style={{ width: 200 }}
                              min={99}
                              marks
                              max={599}
                              step={10}
                              valueLabelDisplay="auto"
                            />
                            <br />
                            <div className="flex justify-center font-bold text-gray-800">
                              ₹{priceValue[0]} - ₹{priceValue[1]}
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                    <div className=" flex justify-evenly  text-sm text-gray-500 ">
                      <button
                        type="button"
                        className="font-style text-md text-gray-500 hover:text-gray-700 mb-20 lg:hidden "
                        onClick={() => setOpen(!open)}
                      >
                        
                        Continue Shopping
                        <span aria-hidden="true"> &rarr;</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Filter;
