import React, { useEffect, useState, memo } from "react";

import Swal from "sweetalert2";

import { BsHeart } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import Drawer from "@mui/material/Drawer";

import "../css/wish.css";
import { useDispatch, useSelector } from "react-redux";
import {
  CartDataBaseAdd,
  deleteWishDataBase,
  listCartDataBase,
  listWishDataBase,
} from "../actions/cartActions";
import { Link } from "react-router-dom";
import { listSize, listWishProductItem } from "../actions/productActions";
import { Button } from "@material-tailwind/react";
import wishimg from "../images/slideimage/dribbl_62_4x.jpg";
import png from "../images/myundePng.png";

export const Wish = memo(({ forWishOpen, wishOpen }) => {
  const sizeList = useSelector((state) => state.sizeList);
  const { size } = sizeList;

  const dataBaseWish = useSelector((state) => state.dataBaseWish);
  const { loading2, wishItemsDataBase, whisProductIds, whisColorIds } =
    dataBaseWish;

  const dispatch = useDispatch();

  const wishProductItemList = useSelector((state) => state.wishproductitem);
  const { loading, wishproductitem } = wishProductItemList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const removeFromWishlist = (wishItemId) => {
    // dispatch(removeFromWish(productItemId))
    dispatch(deleteWishDataBase(wishItemId)).then((data) => {
      if (data === "success") {
        dispatch(listWishDataBase(userInfo?.id)).then(
          dispatch(listWishProductItem(whisProductIds, whisColorIds))
        );
      }
    });
  };

  useEffect(() => {
    if (wishItemsDataBase?.length && !wishproductitem?.length && !loading) {
      dispatch(listWishProductItem(whisProductIds, whisColorIds));
    }
    dispatch(listSize());
    if (
      userInfo?.id &&
      !wishItemsDataBase?.length &&
      !wishproductitem?.length &&
      !loading &&
      !loading2
    ) {
      dispatch(listWishDataBase(userInfo?.id));
    }
  }, [forWishOpen]);

  const [stateSize, setStateSize] = useState();

  const addTOcart = (presentWishItem, wishItemId) => {
    const addTOCartProduct = wishproductitem
      .filter((size) => size.size === Number(presentWishItem?.selectedSize))
      .filter((color) => color.color === Number(presentWishItem?.color.id));
    changeToCart(addTOCartProduct, wishItemId);
  };

  const changeToCart = (addTOCartProduct, wishItemId) => {
    if (userInfo) {
      dispatch(CartDataBaseAdd(addTOCartProduct[0]?.id, 1))
        .then(() => {
          dispatch(listCartDataBase(userInfo?.id, ""));
        })
        .then(() => removeFromWishlist(wishItemId));
      setStateSize();
    }
  };

  const RemoveFromWishlisttAlert = (value) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: false,
      timer: 2000,
      width: 450,

      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    if (value) {
      Toast.fire({
        icon: "success",
        title: value,
      });
    }
  };

  const brandList = useSelector((state) => state.brandList);
  const { brand } = brandList;

  const AddToCartAlert = (value, wishItemId) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: false,
      timer: 2000,

      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    const isSizeSelected = stateSize?.filter((product) => {
      return product.id === value;
    })[0];
    if (
      stateSize ? !Object.keys(isSizeSelected).includes("selectedSize") : true
    ) {
      Toast.fire({
        icon: "info",
        title: `Please select size before Adding to Cart`,
      });
    } else {
      addTOcart(
        stateSize.filter((product) => {
          return product.id === value;
        })[0],
        wishItemId
      );
      Toast.fire({
        icon: "success",
        title: `Added to Cart`,
      });
    }
  };

  // const AddToCart = (productname, productprice, qty, image) => {
  //   if (!stateSize) {
  //     AddToCartAlert();
  //   } else if (orderingProduct) {
  //     AddToCartAlert(productname);
  //     forCartOpen();
  //     return dispatch(
  //       addToCart(orderingProduct[0]?.id, productname, productprice, qty, image)
  //     );
  //   }
  // };

  const addSize = (sizeid, productitemId) => {
    return setStateSize(
      wishItemsDataBase.map((object) => {
        if (object.product_item.id === productitemId) {
          return { ...object.product_item, selectedSize: `${sizeid}` };
        } else {
          return { ...object.product_item };
        }
      })
    );
  };

  const availableSize = new Set();

  return (
    <div className="select-none ">
      <Drawer
        className=""
        anchor={"right"}
        transitionDuration={500}
        open={wishOpen}
        onClose={forWishOpen}
      >
        {" "}
        <div className="pointer-events-auto w-screen xs:w-[27rem] md:w-[28rem]  font-style">
          <div className="flex h-screen flex-col  bg-white shadow-xl ">
            <div
              className="flex  justify-between p-4  shadow-lg w-full z-10 rounded-b-xl bg-gradient"
              // style={{
              //   background:
              //     "linear-gradient(49deg, rgba(227,242,255,1) 0%, rgba(249,247,240,1) 87%)",
              // }}
            >
              <div className="flex text-lg font-style   ml-3">
                Wish List <BsHeart className="w-5 h-5 ml-2 mt-1" />{" "}
              </div>

              <div className="ml-3 flex h-7 items-center">
                <div
                  className="-m-2 p-2 text-gray-800 "
                  onClick={() => forWishOpen()}
                >
                  <AiOutlineClose className="h-6 w-6 hover:scale-125  " />
                </div>
              </div>
            </div>

            {wishItemsDataBase?.length === 0 && !loading && !loading2 ? (
              <div className="w-full flex justify-center items-center h-full ">
                <div className="px-5 w-full -mt-24">
                  <img src={wishimg} alt=""></img>
                  <div className="flex justify-center">
                    <Button
                      onClick={() => forWishOpen()}
                      className="text-center lg:w-1/2 bg-primary focus:outline-none  h-12 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg  my-8  w-full"
                    >
                      <Link to="/"> Go Back Home</Link>
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div className=" w-full  flex flex-wrap justify-start px-3 overflow-y-scroll  md:no-scrollbar  h-screen ">
                <div className="px-2 ">
                  {availableSize.clear()}
                  {wishItemsDataBase?.map((item, i) => {
                    const productItem = wishproductitem.filter(
                      (productitem) =>
                        Number(productitem.product) === Number(item.product)
                    );
                    const forSize = productItem
                      .filter((o) => Number(o.quantity) !== 0)
                      .map((sizes) => sizes?.size);
                    const printSize = size?.filter((s) =>
                      forSize?.some((o) => o === s?.id)
                    );

                    // console.log(size, "size")
                    // console.log(wishproductitem , "wishproductitem")
                    // console.log(productItem , "productItem")
                    // console.log(item.product.id , "item")
                    // console.log(forSize, "forSize")
                    // console.log(printSize , "printsize")

                    return (
                      <div
                        className="  shadow-md  p-3  my-6 rounded-lg  bg-gradient "
                        key={i}
                        // style={{
                        //   background:
                        //     "linear-gradient(49deg, rgba(227,242,255,1) 0%, rgba(249,247,240,1) 87%)",
                        // }}
                      >
                        <div className="flex   items-start ">
                          <div className=" w-1/2 md:w-2/5  flex  ">
                            <Link
                              to={`/product/${item.product_item.product.id}?clr=${item.product_item.color.id}`}
                            >
                              <img
                                src={item.product_item.image}
                                alt="wishlist"
                                className=" object-fit rounded-md"
                              />
                            </Link>
                          </div>

                          <div className=" mx-2 w-1/2">
                            {/* <Link to={}> */}
                            <div className="text-xs font-bold uppercase my-1 text-gray-700">
                              {brand
                                ?.filter(
                                  (cat) =>
                                    Number(cat?.id) ===
                                    Number(item.product_item.product.brand)
                                )
                                .map((cat, i) => {
                                  return (
                                    <div key={i}>
                                    {cat.name?.toLowerCase() === "myunde" ? (
                                      <div className="w-16 h-5" key={i}>
                                        <img src={png} alt="bra.name" />
                                      </div>
                                    ) : (
                                      <p
                                        className="text-[12px] md:text-sm truncate uppercase  text-gray-900 "
                                        key={i}
                                      >
                                        {cat.name}
                                      </p>
                                    )}
                                     </div>
                                  );
                                })}
                            </div>
                            {/* </Link> */}
                            <Link
                              to={`/product/${item.product_item.product}?clr=${item.product_item.color.id}`}
                            >
                              <p className="text-sm truncate capitalize my-1 text-gray-700">
                                {" "}
                                {item?.product_item.product.name}
                              </p>
                            </Link>
                            <p className="text-[rgb(0,53,96)] my-1  font-bold ">
                              ₹ {item?.product_item.product.price}
                            </p>
                            <div className="text-xs  font-bold capitalize my-1 text-gray-600">
                              color : {item?.product_item.color.name}
                            </div>
                            {printSize?.length ? (
                              <div className="">
                                <p className="text-xs mt-1 font-bold capitalize my-1 text-gray-600">
                                  select size :
                                </p>
                                <div className=" wish-sizes flex flex-wrap md:flex-nowrap justify-center ml-2 md:ml-10 ">
                                  {printSize?.map((itemSize, i) => {
                                    return (
                                      <div className="" key={i}>
                                        <input
                                          id={`${item?.product_item.id}${itemSize?.id}${i}`}
                                          name="same-group-name"
                                          type="radio"
                                          className="wishinput"
                                          onClick={() => {
                                            addSize(
                                              itemSize?.id,
                                              item.product_item.id
                                            );
                                          }}
                                        />
                                        <label
                                          htmlFor={`${item?.product_item.id}${itemSize?.id}${i}`}
                                          className="wishlabel"
                                        >
                                          <div className="wish flex justify-center items-center text-xs  ">
                                            {printSize[i]?.name ===
                                            "FREE SIZE" ? (
                                              "FS"
                                            ) : (
                                              <> {printSize[i]?.name}</>
                                            )}
                                          </div>
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            ) : (
                              <span className="text-red-700 font-style  text-xs">
                                Out of stock at the moment !!
                              </span>
                            )}

                            <div className=" my-1">
                              <p className="text-xs font-bold capitalize text-gray-600 my-1">
                                Description :
                              </p>
                              <div className=" mt-1">
                                <p className="text-xs font-normal  lowercase hidden md:block indent-6 text-gray-600">
                                  {item.product_item.product.description?.slice(
                                    0,
                                    100
                                  )}
                                  ...
                                </p>
                                <p className="text-xs font-normal lowercase  md:hidden indent-6 text-gray-600">
                                  {item.product_item.product.description?.slice(
                                    0,
                                    50
                                  )}
                                  ...
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="flex my-3 justify-evenly w-full">
                          <Button
                            className="bg-primary h-8 font-style rounded-md capitalize text-white  text-xs w-[45%]  flex justify-center items-center "
                            value={item.id}
                            onClick={(e) => {
                              removeFromWishlist(e.target.value);
                              RemoveFromWishlisttAlert(
                                `${item?.product_item.product?.name} removed from wishlist`
                              );
                            }}
                          >
                            <svg
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-3 h-3 mx-2 -ml-2"
                            >
                              <path
                                fillRule="evenodd"
                                d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                clipRule="evenodd"
                              />
                            </svg>
                            Remove
                          </Button>
                          {printSize?.length ? (
                            <Button
                              disabled={!printSize?.length ? true : false}
                              className="bg-primary h-8 font-style rounded-md capitalize text-white  w-[50%] text-xs   flex justify-center items-center "
                              onClick={() => {
                                AddToCartAlert(item?.product_item.id, item.id);
                              }}
                            >
                              <svg
                                viewBox="0 0 24 24"
                                fill="currentColor"
                                className="w-3 h-3 mx-2 -ml-2"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 004.25 22.5h15.5a1.875 1.875 0 001.865-2.071l-1.263-12a1.875 1.875 0 00-1.865-1.679H16.5V6a4.5 4.5 0 10-9 0zM12 3a3 3 0 00-3 3v.75h6V6a3 3 0 00-3-3zm-3 8.25a3 3 0 106 0v-.75a.75.75 0 011.5 0v.75a4.5 4.5 0 11-9 0v-.75a.75.75 0 011.5 0v.75z"
                                  clipRule="evenodd"
                                />
                              </svg>
                              Add to cart
                            </Button>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className=" flex justify-center w-full  text-sm text-gray-500  p-2 mt-auto">
                  <button
                    className="font-style text-md text-gray-500 hover:text-gray-700 pb-3"
                    onClick={() => forWishOpen()}
                  >
                    Continue Shopping
                    <span aria-hidden="true"> &rarr;</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
});

export default Wish;
