import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import cutting from "./cutting.jpg";
import checking from "./checking.jpg";
import packing from "./packing.jpg";
import stiching from "./stiching.jpg";
import myunde from "./myundeofc.JPG";
import logo from "../images/Myunde1.png";

const aboutImages = [
  {
    id: 4,
    image: cutting,
    name: "cutting the material by experts for your better fit",
  },
  {
    id: 1,
    image: checking,
    name: "checking before packing for quality",
  },
  {
    id: 2,
    image: packing,
    name: "packing ready to be a part of your waredrobe",
  },
  {
    id: 3,
    image: stiching,
    name: "we are using machines with our care",
  },
];

const aboutData = [
  {
    id: 1,
    tittle: "We’ll Always Remain Inclusive",
    content: "Creating Products For Every Body & Age",
    svg: (
      <svg
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"
        />
      </svg>
    ),
  },
  {
    id: 2,
    tittle: "We’ll Continue To Do Good And Innovate",
    content: "By Using Processes That Put Men First",
    svg: (
      <svg
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
        />
      </svg>
    ),
  },
  {
    id: 1,
    tittle: "We’ll Positively Impact Men",
    content: "Enabling The Entire Cycle From Creation To Delivery",
    svg: (
      <svg
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M7.848 8.25l1.536.887M7.848 8.25a3 3 0 11-5.196-3 3 3 0 015.196 3zm1.536.887a2.165 2.165 0 011.083 1.839c.005.351.054.695.14 1.024M9.384 9.137l2.077 1.199M7.848 15.75l1.536-.887m-1.536.887a3 3 0 11-5.196 3 3 3 0 015.196-3zm1.536-.887a2.165 2.165 0 001.083-1.838c.005-.352.054-.695.14-1.025m-1.223 2.863l2.077-1.199m0-3.328a4.323 4.323 0 012.068-1.379l5.325-1.628a4.5 4.5 0 012.48-.044l.803.215-7.794 4.5m-2.882-1.664A4.331 4.331 0 0010.607 12m3.736 0l7.794 4.5-.802.215a4.5 4.5 0 01-2.48-.043l-5.326-1.629a4.324 4.324 0 01-2.068-1.379M14.343 12l-2.882 1.664"
        />
      </svg>
    ),
  },
  {
    id: 1,
    tittle: "We’ll Stay True",
    content: "By Giving You The Finest Quality And Service",
    svg: (
      <svg
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M4.5 12.75l6 6 9-13.5"
        />
      </svg>
    ),
  },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function AboutMyunde() {
  const CustomRight = ({ onClick }) => (
    <div className="  absolute left-[7%] " onClick={onClick}>
      <svg
        className="w-10 h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 2xl:w-16 2xl:h-16 text-white svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="arcs"
      >
        <path d="M15 18l-6-6 6-6" />
      </svg>
    </div>
  );

  const CustomLeft = ({ onClick }) => (
    <div className=" absolute right-[7%] " onClick={onClick}>
      <svg
        className="w-10 h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 2xl:w-16 2xl:h-16 text-white svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="arcs"
      >
        <path d="M9 18l6-6-6-6" />
      </svg>
    </div>
  );

  return (
    <>
      <div className="mb-12 ">
        {/* <p className="text-5xl px-3 text-center   border-black ">About us</p> */}
      </div>
      <div className="flex justify-center items-center p-2 ">
        <div className="  group relative overflow-hidden  sm:h-96 ">
          <div className=" absolute  sm:backdrop-blur-sm group-hover:scale-110 duration-700 sm:text-4xl bg-gradient-to-b from-transparent to-black/90 w-full h-full p-2 ">
            <div className=" text-white   flex flex-col items-center justify-center h-full sm:text-6xl">
              <img
                className=" w-[90px] svg  sm:w-28  outline-none"
                src={logo}
                alt="MyundeLogo"
              />
              <p className=" tracking-widest font-bold text-gray-100">MYUNDE</p>
              <p className="text-lg italic  ">specially for men</p>
            </div>
          </div>

          <img className="" src={myunde} alt="img" />
        </div>
      </div>
      <div className="flex justify-center items-center bg-gray-100 font-poppins">
        <div className="w-full max-w-[1500px] ">
          <div className="border-l-4 p-3 my-8 m-1 border-black font-medium space-y-2 bg-gray-300">
            <p>
              Founded in 2022, Myunde 's mission is to make solutionwear for
              modern men. We specialize in making comfortable underwear,
              loungewear, and athleisure with an emphasis on technology and
              innovation. Our unique fabric blends provide proper fit and
              support both physically and emotionally throughout your day and
              throughout your life. More than just stylish and comfortable, our
              clothing is Made To Live In.
            </p>
            <p>
              Designed to change the way you think about briefs, Myunde adapts
              to your body and provides a comfortable fit you can rely on no
              matter where your day takes you. By far our most popular underwear
              series, Myunde is the perfect example of the Made To Live In
              comfort we strive to create.
            </p>
          </div>

          <div className="flex justify-start items-start m-1 flex-col my-8 border-l-4 border-black">
            <p className="text-3xl px-3 italic  ">Our Purpose</p>
            <p className="text-start  p-2">
              At Myunde , our vision is to revolutionize the world of men's
              undergarments by providing a premium platform that combines
              comfort, style, and convenience.
              <br /> We aim to become the go-to destination for men seeking
              superior quality undergarments that elevate their everyday
              experiences.
              <br /> Our commitment lies in empowering men to feel confident,
              supported, and effortlessly stylish, while prioritizing their
              comfort and well-being.
            </p>
          </div>
          <div className="p-2">
            <Carousel
              responsive={responsive}
              autoPlay={true}
              autoPlaySpeed={4000}
              infinite={true}
              customLeftArrow={<CustomRight />}
              customRightArrow={<CustomLeft />}
              removeArrowOnDeviceType={["mobile"]}
              className=" "
            >
              {aboutImages?.map((slide, i) => {
                return (
                  <div className="  group relative overflow-hidden ">
                    <div className=" absolute  group-hover:scale-110 duration-700  bg-gradient-to-b from-transparent to-black/90 w-full h-full p-2 ">
                      <div className=" text-white   flex flex-col items-center justify-center h-full  sm:text-6xl">
                        <p className="">MYUNDE</p>
                        <p className="sm:text-lg text-center">{slide.name}</p>
                      </div>
                    </div>

                    <img className=" " src={slide.image} alt="img" />
                  </div>
                );
              })}
            </Carousel>
          </div>
          <div className="flex justify-start items-start flex-col border-l-4 m-1 border-black my-8">
            <p className="text-3xl px-3   ">Modern Comfort</p>
            <p className="text-start  p-2">
              Myunde redefines men's undergarments with a sleek and minimalist
              design, incorporating premium fabrics and innovative features.
              <br /> Our thoughtfully crafted styles provide ultimate comfort
              and support, while customization options cater to individual
              preferences. <br />
              Experience a new level of confidence and style with Myunde .
            </p>
          </div>
          <div className="flex justify-start items-start flex-col border-l-4 m-1 border-black my-8">
            <p className="text-3xl px-3   ">Thoughtful fashion</p>
            <p className="text-start  p-2">
              Myunde brings you fashion that thinks. <br />
              We believe that style shouldn't compromise comfort or
              functionality.
              <br />
              With meticulous attention to detail, we design undergarments that
              combine fashion-forward aesthetics with intelligent features.
              <br />
              Experience the perfect fusion of style and practicality with
              Myunde , where fashion truly thinks.
            </p>
          </div>
          <div className="flex justify-start items-center flex-col  my-8">
            <p className="text-3xl px-3   ">Our promises</p>

            <div className="flex flex-wrap w-full space-x-2 justify-evenly items-center my-8">
              {aboutData.map((data) => {
                return (
                  <div className="flex flex-col justify-center items-center text-center p-1">
                    <div className="w-14 h-14"> {data.svg} </div>
                    <p>{data.tittle}</p>
                    <p className="text-gray-500">{data.content}</p>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex justify-start items-start m-1 flex-col my-8 border-l-4 border-black">
            <p className="text-3xl px-3   ">SUSTAINABILITY</p>
            <p className="text-start  p-2">
              At Myunde , sustainability is at the core of our brand. <br /> We
              are dedicated to reducing our environmental impact and promoting
              ethical practices throughout our entire supply chain. <br /> From
              the materials we use to our packaging, we prioritize
              sustainability every step of the way.
              <br /> Choose Myunde for undergarments that not only look good but
              also make a positive impact on the planet. <br /> Together, let's
              embrace sustainable style for a better future.
            </p>
          </div>
          <div className="flex justify-start items-start m-1 flex-col my-8 border-l-4 border-black">
            <p className="text-3xl px-3   "> Who are we ??</p>
            <p className="text-start  p-2">
              Welcome to MyUnde – the premier undergarment manufacturer in
              India, dedicated to providing unparalleled comfort and quality.<br/> As
              a trailblazer in the realm of undergarments, we pride ourselves on
              our commitment to crafting products that redefine comfort, style,
              and confidence.<br/> At MyUnde, we believe that what lies beneath
              matters just as much as what's on the surface.<br/> Our journey began
              with a simple yet powerful vision: to revolutionize the way people
              experience undergarments.<br/> With a seamless blend of innovative
              technology, meticulous craftsmanship, and an unwavering focus on
              customer satisfaction, we've emerged as the go-to destination for
              those seeking unmatched comfort that starts from the very first
              layer.<br/> Our undergarments are more than just pieces of clothing;
              they're a statement of self-assurance. Designed with precision and
              stitched with care, every MyUnde product is a testament to our
              commitment to excellence. We understand that true comfort doesn't
              compromise on style, which is why our collections cater to diverse
              tastes, ensuring that everyone finds their perfect fit.<br/> Quality is
              the cornerstone of our ethos. From the selection of premium
              fabrics to the final stitch, we leave no stone unturned to deliver
              undergarments that stand the test of time. Our dedication to
              quality extends beyond materials – it's reflected in our customer
              service, our attention to detail, and our constant pursuit of
              improvement.<br/> As an Indian undergarment manufacturer, we take
              immense pride in contributing to the 'Make in India' initiative.
              Our products are a result of the collective talent of skilled
              artisans and modern technology, showcasing the best of both
              worlds.<br/> Join us on a journey where comfort meets style, where
              quality meets affordability, and where undergarments become a
              source of empowerment.<br/> Discover MyUnde and experience a new level
              of confidence, starting from within.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutMyunde;
