export const PRODUCT_LIST_REQUEST = "PRODUCT_LIST_REQUEST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAIL = "PRODUCT_LIST_FAIL";

export const PRODUCT_DETAILS_REQUEST = "PRODUCT_DETAILS_REQUEST";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAIL = "PRODUCT_DETAILS_FAIL";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";

export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";
export const PRODUCT_CREATE_RESET = "PRODUCT_CREATE_RESET";

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";
export const PRODUCT_UPDATE_RESET = "PRODUCT_UPDATE_RESET";

export const PRODUCT_CREATE_REVIEW_REQUEST = "PRODUCT_CREATE_REVIEW_REQUEST";
export const PRODUCT_CREATE_REVIEW_SUCCESS = "PRODUCT_CREATE_REVIEW_SUCCESS";
export const PRODUCT_CREATE_REVIEW_FAIL = "PRODUCT_CREATE_REVIEW_FAIL";
export const PRODUCT_CREATE_REVIEW_RESET = "PRODUCT_CREATE_REVIEW_RESET";

export const PRODUCT_REVIEW_REQUEST = "PRODUCT_REVIEW_REQUEST";
export const PRODUCT_REVIEW_SUCCESS = "PRODUCT_REVIEW_SUCCESS";
export const PRODUCT_REVIEW_FAIL = "PRODUCT_REVIEW_FAIL";

export const PRODUCT_TOP_REQUEST = "PRODUCT_TOP_REQUEST";
export const PRODUCT_TOP_SUCCESS = "PRODUCT_TOP_SUCCESS";
export const PRODUCT_TOP_FAIL = "PRODUCT_TOP_FAIL";

export const FILTER_LIST_REQUEST = "FILTER_LIST_REQUEST";
export const FILTER_LIST_SUCCESS = "FILTER_LIST_SUCCESS";
export const FILTER_LIST_FAIL = "FILTER_LIST_FAIL";

export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAIL = "CATEGORY_LIST_FAIL";

export const BRAND_LIST_REQUEST = "BRAND_LIST_REQUEST";
export const BRAND_LIST_SUCCESS = "BRAND_LIST_SUCCESS";
export const BRAND_LIST_FAIL = "BRAND_LIST_FAIL";

export const STYLES_LIST_REQUEST = "STYLES_LIST_REQUEST";
export const STYLES_LIST_SUCCESS = "STYLES_LIST_SUCCESS";
export const STYLES_LIST_FAIL = "STYLES_LIST_FAIL";

export const FABRICS_LIST_REQUEST = "FABRICS_LIST_REQUEST";
export const FABRICS_LIST_SUCCESS = "FABRICS_LIST_SUCCESS";
export const FABRICS_LIST_FAIL = "FABRICS_LIST_FAIL";

export const COLORS_LIST_REQUEST = "COLORS_LIST_REQUEST";
export const COLORS_LIST_SUCCESS = "COLORS_LIST_SUCCESS";
export const COLORS_LIST_FAIL = "COLORS_LIST_FAIL";

export const SIZES_LIST_REQUEST = "SIZES_LIST_REQUEST";
export const SIZES_LIST_SUCCESS = "SIZES_LIST_SUCCESS";
export const SIZES_LIST_FAIL = "SIZES_LIST_FAIL";

export const SIZE_LIST_REQUEST = "SIZE_LIST_REQUEST";
export const SIZE_LIST_SUCCESS = "SIZE_LIST_SUCCESS";
export const SIZE_LIST_FAIL = "SIZE_LIST_FAIL";

export const PRODUCTITEMS_LIST_REQUEST = "PRODUCTITEMS_LIST_REQUEST";
export const PRODUCTITEMS_LIST_SUCCESS = "PRODUCTITEMS_LIST_SUCCESS";
export const PRODUCTITEMS_LIST_FAIL = "PRODUCTITEMS_LIST_FAIL";

export const PRODUCTITEM_LIST_REQUEST = "PRODUCTITEM_LIST_REQUEST";
export const PRODUCTITEM_LIST_SUCCESS = "PRODUCTITEM_LIST_SUCCESS";
export const PRODUCTITEM_LIST_FAIL = "PRODUCTITEM_LIST_FAIL";

export const WISHPRODUCTITEM_LIST_REQUEST = "WISHPRODUCTITEM_LIST_REQUEST";
export const WISHPRODUCTITEM_LIST_SUCCESS = "WISHPRODUCTITEM_LIST_SUCCESS";
export const WISHPRODUCTITEM_LIST_FAIL = "WISHPRODUCTITEM_LIST_FAIL";

export const ITEMIMAGES_LIST_REQUEST = "ITEMIMAGES_LIST_REQUEST";
export const ITEMIMAGES_LIST_SUCCESS = "ITEMIMAGES_LIST_SUCCESS";
export const ITEMIMAGES_LIST_FAIL = "ITEMIMAGES_LIST_FAIL";

export const CAMPAIGNIMAGES_LIST_REQUEST = "CAMPAIGNIMAGES_LIST_REQUEST";
export const CAMPAIGNIMAGES_LIST_SUCCESS = "CAMPAIGNIMAGES_LIST_SUCCESS";
export const CAMPAIGNIMAGES_LIST_FAIL = "CAMPAIGNIMAGES_LIST_FAIL";

export const SKU_LIST_REQUEST = "SKU_LIST_REQUEST";
export const SKU_LIST_SUCCESS = "SKU_LIST_SUCCESS";
export const SKU_LIST_FAIL = "SKU_LIST_FAIL";

export const PRODUCTOFFERS_LIST_REQUEST = "PRODUCTOFFERS_LIST_REQUEST";
export const PRODUCTOFFERS_LIST_SUCCESS = "PRODUCTOFFERS_LIST_SUCCESS";
export const PRODUCTOFFERS_LIST_FAIL = "PRODUCTOFFERS_LIST_FAIL";
