import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import { Button } from "@material-tailwind/react";
import Swal from "sweetalert2";
import axios from "axios";
import LoginSlide from "./LoginSlide";

function Sellonmyunde() {
  const categoryList = useSelector((state) => state.categoryList);
  const { category } = categoryList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [state, setState] = useState(false);

  const forLogin = () => {
    setState(!state);
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({ mode: "onChange", defaultValues: { categories: [] } });

  const onSubmit = async (data) => {
    const formData = new FormData();
    data.brand_logo = data.brand_logo[0];
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].map((category) => formData.append(key, category));
      } else {
        formData.set(key, data[key]);
      }
    });

    const config = {
      headers: {
        "Content-type": "multipart/form-data",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const payload = await axios
      .post(`/api/users/sell-on-myunde/`, formData, config)
      .then((response) => {
        FormsubmmittedAlert(true, response.data.message);
      })
      .catch((error) => {
        FormsubmmittedAlert(false, error.response.data.message);
      });

    reset();
  };

  const onClear = () => {
    reset();
  };

  const handleCheckboxChange = (event, field, checked) => {
    if (checked) {
      field.onChange([...field.value, event.target.value]);
    } else {
      field.onChange(
        field.value.filter((value) => value !== event.target.value)
      );
    }
  };

  const handleVideoUpload = (file) => {
    if (file?.type?.includes("video")) {
      alert("uploading vedio currently unavailable");
      return;
    }
  };

  const FormsubmmittedAlert = (bool, response) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: false,
      timer: 4000,
      width: 450,

      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: bool ? "success" : "warning",
      title: `${response}`,
    });
  };

  return (
    <div className="p-5 flex  justify-center font-poppins">
      <div className=" max-w-[1000px]">
        <div className=" ">
          <div className="bg-white/20 backdrop-blur-[1px] p-5 border border-[rgb(0,53,96)] rounded-xl">
            <h2 className="font-medium text-2xl text-[#202124] py-3">
              Myunde Seller
            </h2>

            <h2 className="pb-5 text-md font-light text-gray-700">
              Speak about our target audience, Only platform focused on intimate
              wear sell
            </h2>
            {/* <h2 className="pb-5 text-md font-light">Some metrics on Myunde </h2>
            <h2 className=" text-md font-light">- 200+ happy sellers </h2>
            <h2 className=" text-md font-light">- 500000+ monthly orders </h2>
            <h2 className=" text-md font-light">
              - 15 million+ happy shoppers
            </h2> */}
          </div>

          {/* <div className="sm:flex justify-between items-center bg-white border-[#c4ce5a] border ">
            <div className=" p-5 ">
              <h2 className="pb-5 text-[#5f636d] font-semibold text-md ">
                hp612669@gmail.com
                <span className="text-blue-600"> Switch account </span>
              </h2>
              <div className="flex items-center justify-between w-28 -my-3  ">
                <div className=" w-6 h-6  ">
                  <svg
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M44 24V9H24H4V24V39H24"
                        stroke="#8c9093"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M40 31L32 39"
                        stroke="#8c9093"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M32 31L40 39"
                        stroke="#8c9093"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M4 9L24 24L44 9"
                        stroke="#8c9093"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </g>
                  </svg>
                </div>
                <h2 className="text-sm font-bold text-black">Not shared</h2>
              </div>
            </div>
            <div className="flex items-center justify-between w-28  sm:mr-10  ml-5 ">
              <div className=" w-7 h-7  ">
                <svg
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#5f636d"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <rect x="0" fill="none" width="20" height="20"></rect>
                    <g>
                      <path d="M14.8 9c.1-.3.2-.6.2-1 0-2.2-1.8-4-4-4-1.5 0-2.9.9-3.5 2.2-.3-.1-.7-.2-1-.2C5.1 6 4 7.1 4 8.5c0 .2 0 .4.1.5-1.8.3-3.1 1.7-3.1 3.5C1 14.4 2.6 16 4.5 16h10c1.9 0 3.5-1.6 3.5-3.5 0-1.8-1.4-3.3-3.2-3.5zm-6.3 5.9l-3.2-3.2 1.4-1.4 1.8 1.8 3.8-3.8 1.4 1.4-5.2 5.2z"></path>
                    </g>
                  </g>
                </svg>
              </div>
              <h2 className="text-sm font-bold text-black">Not shared</h2>
            </div>
          </div> */}
        </div>
        {userInfo ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="bg-white/20 backdrop-blur-[1px] p-2 md:p-5 border border-gray-400 rounded-xl my-4">
              <p className="text-xl text-center m-1 text-gray-800 capitalize font-medium">
                please enter your details below
              </p>
              <h2 className="text-center text-sm  text-[#d93025] pb-3">
                ( * Indicates required question )
              </h2>
              <div className=" w-full mt-12  px-2  my-3">
                <div className="relative ">
                  <input
                    {...register("firstName", {
                      required: true,
                    })}
                    id="namefield"
                    className={`block px-2.5 pb-2.5  font-style pt-4 w-full max-w-sm text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                      errors.firstName
                        ? "border-red-300 border"
                        : "border-gray-300 border"
                    } appearance-none  focus:outline-none focus:ring-0  peer`}
                    placeholder=" "
                  />
                  <label
                    htmlFor="namefield"
                    className="  after:content-['*'] after:ml-1 after:text-red-500  absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                  >
                    Your Name*
                  </label>
                </div>
                {errors?.firstName && (
                  <p className="text-sm m-1 text-red-500 capitalize">
                    This field is required
                  </p>
                )}
              </div>
              <div className=" w-full  mt-8  px-2 my-3">
                <div className="relative ">
                  <input
                    {...register("PhoneNumber", {
                      required: true,
                      pattern: /^\d{10}$/,
                    })}
                    className={`block px-2.5 pb-2.5  font-style pt-4 w-full max-w-sm text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                      errors.PhoneNumber
                        ? "border-red-300 border"
                        : "border-gray-300 border"
                    } appearance-none  focus:outline-none focus:ring-0  peer`}
                    placeholder=" "
                    id="PhoneNumber"
                    maxLength={10}
                  />

                  <label
                    htmlFor="PhoneNumber"
                    className="  after:content-['*'] after:ml-1 after:text-red-500  absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                  >
                    Phone Number*
                  </label>
                </div>

                {errors?.PhoneNumber?.type === "pattern" && (
                  <p className="text-sm m-1 text-red-500 capitalize">
                    Please enter a valid 10-digit phone number
                  </p>
                )}
                {errors?.PhoneNumber?.type === "required" && (
                  <p className="text-sm m-1 text-red-500 capitalize">
                    This field is required
                  </p>
                )}
              </div>

              <div className=" w-full    px-2 mt-8 my-3">
                <div className="relative ">
                  <input
                    {...register("Email", {
                      required: true,
                      pattern:
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                    })}
                    className={`block px-2.5 pb-2.5  font-style pt-4 w-full max-w-sm text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                      errors.Email
                        ? "border-red-300 border"
                        : "border-gray-300 border"
                    } appearance-none  focus:outline-none focus:ring-0  peer`}
                    placeholder=" "
                    id="Email"
                  />

                  <label
                    htmlFor="Email"
                    className="  after:content-['*'] after:ml-1 after:text-red-500  absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                  >
                    Your E-mail*
                  </label>
                </div>

                {errors?.Email?.type === "pattern" && (
                  <p className="text-sm m-1 text-red-500 capitalize">
                    Please enter a valid email address
                  </p>
                )}
                {errors?.Email?.type === "required" && (
                  <p className="text-sm m-1 text-red-500 capitalize">
                    This field is required
                  </p>
                )}
              </div>

              <div className=" w-full    px-2 mt-8 my-3">
                <div className="relative ">
                  <input
                    {...register("BrandName", {
                      required: true,
                    })}
                    className={`block px-2.5 pb-2.5  font-style pt-4 w-full max-w-sm text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                      errors.BrandName
                        ? "border-red-300 border"
                        : "border-gray-300 border"
                    } appearance-none  focus:outline-none focus:ring-0  peer`}
                    placeholder=" "
                    id="BrandName"
                  />

                  <label
                    htmlFor="BrandName"
                    className="  after:content-['*'] after:ml-1 after:text-red-500  absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                  >
                    Brand Name*
                  </label>
                </div>

                {errors?.BrandName?.type === "pattern" && (
                  <p className="text-sm m-1 text-red-500 capitalize">
                    Alphabetical characters only
                  </p>
                )}
                {errors?.BrandName?.type === "required" && (
                  <p className="text-sm m-1 text-red-500 capitalize">
                    This field is required
                  </p>
                )}
              </div>
              <h2 className="font-bold text-sm after:content-['*'] mt-8 text-gray-500 ml-4 after:ml-1 after:text-red-500">
                Brand logo
              </h2>
              <input
                {...register("brand_logo", { required: true })}
                type="file"
                accept="image/*;capture=camera"
                className={` block my-2 ml-3 w-64 sm:w-full text-[15px] max-w-sm rounded-xl  ${
                  errors.brand_logo
                    ? "border-red-300 border file:border-red-300 file:border"
                    : "border-gray-300 border  file:border-[rgb(0,53,96)] file:border"
                } bg-gray-100 text-slate-500
                          file:mr-4  file:py-2 file:px-4
                          file:rounded-xl 
                          file:text-[15px] file:font-style
                          file:bg-blue-50 file:text-[rgb(0,53,96)]
                          
                          hover:file:bg-blue-50 `}
                multiple={false}
                onChange={(event) => handleVideoUpload(event.target.files[0])}
              />
              {errors?.brand_logo && (
                <p className="text-sm m-1  text-red-500 capitalize">
                  this field is required
                </p>
              )}
              <div className="mt-12 ">
                <FormControl>
                  <h2 className="font-bold after:content-['*'] ml-4 after:ml-1 after:text-red-500">
                    Categories
                  </h2>

                  <FormGroup>
                    {category?.length > 0 ? (
                      <Controller
                        name="categories"
                        control={control}
                        rules={{
                          required: "Please select at least one category",
                        }}
                        render={({ field }) => (
                          <>
                            {category?.map((category, index) => (
                              <FormControlLabel
                                className=" pl-8"
                                key={index}
                                control={
                                  <Checkbox
                                    value={category.id}
                                    checked={field.value.includes(
                                      String(category.id)
                                    )}
                                    className=" "
                                    onChange={(event, checked) =>
                                      handleCheckboxChange(
                                        event,
                                        field,
                                        checked
                                      )
                                    }
                                  />
                                }
                                label={category.name}
                              />
                            ))}
                          </>
                        )}
                      />
                    ) : null}
                  </FormGroup>
                  {errors.categories && (
                    <p className="text-sm m-1 text-red-500 capitalize">
                      {errors.categories.message}
                    </p>
                  )}
                </FormControl>
              </div>

              <div className=" w-full  px-2 mt-8 my-3">
                <p className="text-gray-800 font-bold pb-4 after:content-['*'] after:ml-1 after:text-red-500">
                  Share sample URL of your product
                </p>
                <div className=" ">
                  <input
                    {...register("URL", {
                      required: true,
                      pattern: /^(ftp|http|https):\/\/[^ "]+$/,
                    })}
                    className={`block px-2.5 pb-2.5  font-style pt-4 w-full max-w-sm text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                      errors.URL
                        ? "border-red-300 border"
                        : "border-gray-300 border"
                    } appearance-none  focus:outline-none focus:ring-0  peer`}
                    placeholder="Paste your url here"
                  />
                </div>

                {errors?.URL?.type === "pattern" && (
                  <p className="text-sm m-1 text-red-500 capitalize">
                    Please enter a valid URL
                  </p>
                )}
                {errors?.URL?.type === "required" && (
                  <p className="text-sm m-1 text-red-500 capitalize">
                    This field is required
                  </p>
                )}
              </div>
              <h2 className="pt-5 font-bold after:content-['*'] ml-4 after:ml-1 after:text-red-500">
                You Are
              </h2>
              <div className="w-64">
                <FormControl
                  sx={{ m: 1, minWidth: 220, marginLeft: 2 }}
                  size="small"
                >
                  <Controller
                    name="are_you"
                    control={control}
                    rules={{ required: "Please select what you are" }}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        {...field}
                        label=""
                      >
                        <MenuItem value="Manufacturer">Manufacturer</MenuItem>
                        <MenuItem value="Distributor">Distributor</MenuItem>
                        <MenuItem value="Brand Representative">
                          Brand Representative
                        </MenuItem>
                        <MenuItem value="Retailer">Retailer</MenuItem>
                      </Select>
                    )}
                  />
                  {errors.are_you && (
                    <p className="text-sm m-1 text-red-500 capitalize">
                      {errors.are_you.message}
                    </p>
                  )}
                </FormControl>
              </div>
            </div>

            <div className="flex justify-between items-center w-full">
              <div className=" flex justify-between  py-5 px-3 w-full ">
                <Button
                  onClick={() => onClear()}
                  className="py-2 rounded-md capitalize border border-primary bg-secondary text-primary font-style flex items-center"
                >
                  clear all
                </Button>
                <Button
                  className=" py-2 rounded-md   bg-primary capitalize text-white  font-style flex items-center"
                  type="submit"
                >
                  Submit
                </Button>
              </div>
            </div>
          </form>
        ) : (
          <div className="bg-white/20 backdrop-blur-[1px] my-3 p-5 border border-[rgb(0,53,96)] rounded-xl">
            <LoginSlide forProfileOpen={forLogin} open={state} />

            <h2 className="pb-5 text-md font-light text-gray-700">
              Before Entering a form, you must login to your account.
            </h2>
            <Button
              onClick={forLogin}
              className="text-center  bg-primary  h-12 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg    w-full "
            >
              Login here
            </Button>
          </div>
        )}

        <div className="bg-white/20 backdrop-blur-[1px] p-5 my-4 border border-gray-400 rounded-xl">
          <h2 className="pb-5 font-bold"> Who Can Sell?</h2>
          <h2 className="">
            A manufacturer, brand owner, distributor with NOC from the brand can
            sell on Myunde
          </h2>
        </div>

        <div className=" my-4 ">
          <div className=" p-5 bg-white/20 backdrop-blur-[1px] border border-gray-400 rounded-xl">
            <h2 className="pb-5 font-bold"> Why Sell on Myunde?</h2>
            <h2 className="">
              - Build you brand by selling on an men only platform
            </h2>
            <h2>- You can opt for 1 of 3 Business Models available</h2>
            <h2>- TPL managed by Myunde</h2>
            <h2>- Seamless Payment</h2>
            <h2>- Only platform focused on Intimate wear </h2>
          </div>
        </div>
{/* 100 days css challange #1  */}
        {/* <div className="flex justify-center items-center ">
          <div className="w-96 h-96 bg-gradient-to-br from-[#FFFC33]  via-orange-600 to-[#ff0000] relative">
            <div className=" ">
              <div className="w-6 h-12 bg-white absolute left-24 z-40 top-28 rounded-md  rotate-45"></div>
              <div className="w-6 h-24 bg-white absolute  shadow-xl rounded-md shadow-black/20 z-40 left-28 top-28"></div>
              <div className="w-24  h-24 border-[24px] border-white shadow-xl shadow-black/20 z-30 rounded-full absolute left-32 top-28"></div>
              <div className="w-24  h-24 border-[24px] border-white shadow-xl shadow-black/20 rounded-full absolute left-52 top-28"></div>
            </div>
            <div className="w-full h-full absolute mt-52 text-white"> 
            <div className="text-6xl uppercase text-center font-bold ">Days</div>
            <div className="text-2xl uppercase text-center ">css Challange</div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Sellonmyunde;
