import React, { useCallback, useEffect, useState } from "react"
// import Rating from "./Rating";
import { Link } from "react-router-dom"
// import { HashLink } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux"
// import { addToCart, removeFromCart } from "../actions/cartActions";
// import CartScreen from "../screens/CartScreen";
import { listBrands } from "../actions/productActions"
import { listProductItems } from "../actions/productActions"
import Loader from "../components/Loader"
// import Message from "../components/Message";
import { Button } from "@material-tailwind/react"
import Select from "react-select"

import "react-multi-carousel/lib/styles.css"
import { ShoppingBagIcon } from "@heroicons/react/24/outline"

import LoaderCard from "./LoaderCard"
import {
  addToCart,
  CartDataBaseAdd,
  listCartDataBase,
} from "../actions/cartActions"
import "../css/wishHeart.css"
import Swal from "sweetalert2"
import "../css/sizealert.css"

import ProgressiveImage from "react-progressive-graceful-image"
import load from "../images/logo loading page.gif"

import { listWishDataBase, WishDataBaseAdd } from "../actions/cartActions"
import Message from "./Message"
import { useLocation } from "react-router-dom"
import LoginSlide from "../screens/LoginSlide"
import ProfileScreen from "../screens/ProfileScreen"
import CartPage from "../screens/CartPage"
import { Tooltip } from "@mui/material"
import { Helmet } from "react-helmet"
import png from "../images/myundePng.png"

export const Product = React.memo(
  () => {
    // const Swal = require("sweetalert2");
    const [open, setOpen] = useState(false)

    // const [imgLoading, setImgLoading] = useState(true);
    // const reviewList = useSelector((state) => state.productReviews);
    // const { productsReviews } = reviewList;

    const forCartOpen = () => {
      setOpen(!open)
    }

    const qty = 1
    const dispatch = useDispatch()

    const options = [
      { value: "Ascending", label: "low to high " },
      { value: "Dscending", label: "high to low " },
    ]

    const [Productstate, setProductState] = useState()

    const sortProducts = ({ value }) => {
      const sorted =
        value === "Ascending"
          ? [...products].sort((a, b) => (a.price > b.price ? 1 : -1))
          : [...products].sort((a, b) => (a.price > b.price ? -1 : 1))
      return setProductState(sorted)
    }

    const search = useLocation().search
    const productColorFilter = new URLSearchParams(search).get("color")
    const productFilterColor = productColorFilter?.length
      ? productColorFilter?.split(",").map(function (item) {
          return Number(item)
        })
      : ""

    const productSizeFilter = new URLSearchParams(search).get("size")
    const productFilterSize = productSizeFilter?.length
      ? productSizeFilter?.split(",").map(function (item) {
          return Number(item)
        })
      : ""

    // const ItemImagesList = useSelector((state) => state.itemimages);
    // const { itemimages } = ItemImagesList;

    const brandList = useSelector((state) => state.brandList)
    const { brand } = brandList

    // const productColors = Array.from(
    //   new Set(
    //     productitems.map((color) => {
    //       return (color.color)
    //     })
    //   )
    // );

    const [state, setState] = useState(false)
    const forLogin = () => {
      setState(!state)
    }

    const forProfileScreen = useCallback(() => {
      setState({ ...state, profilescreen: !state.profilescreen })
    }, [state])

    const productList = useSelector((state) => state.productList)
    const { loading: loading2, products } = productList

    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    const categoryList = useSelector((prof) => prof.categoryList)
    const { category } = categoryList

    const productsId = []
    products?.forEach(function (obj) {
      productsId.push(obj.id)
    })

    useEffect(() => {
      if (productsId?.length) {
        dispatch(listProductItems(productsId))
      }

      if (!brand?.length) {
        dispatch(listBrands())
      }
    }, [products])

    const productItemsList = useSelector((state) => state.productitems)
    const { loading, error, productitems } = productItemsList

    // const colorsList = useSelector((state) => state.colorsList);
    // const { colors } = colorsList;
    const sizeList = useSelector((state) => state.sizeList)
    const { size } = sizeList

    // const wish = useSelector((state) => state.wish);
    // // const { wishItems } = wish;

    const dataBaseWish = useSelector((state) => state.dataBaseWish)
    const { wishItemsDataBase } = dataBaseWish

    const { productoffers } = useSelector((state) => state.productoffers)
    // const { brandoffers } = useSelector((state) => state.brandoffers)

    const cart = useSelector((state) => state.cart)
    const { cartItems } = cart

    const dataBaseCart = useSelector((state) => state.dataBaseCart)
    const { cartItemsDataBase } = dataBaseCart

    let cartItemsDBorLS = userInfo ? cartItemsDataBase : cartItems

    const AlreadyInCartAlert = (value) => {
      const Toast = Swal.mixin({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 2000,

        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer)
          toast.addEventListener("mouseleave", Swal.resumeTimer)
        },
      })
      Toast.fire({
        icon: "warning",
        title: `${value}`,
      })
    }

    const Call = async (printSizes, uniqueProductItems, productname, qty) => {
      const inputOptions = new Promise((resolve) => {
        setTimeout(() => {
          resolve(printSizes)
        }, 1000)
      })
      const { value: selectedsize } = await Swal.fire(
        {
          title: "Select Your Size",
          input: "radio",
          showCancelButton: true,
          confirmButtonColor: "rgb(0,0,0)",
          cancelButtonColor: "gray",
          inputOptions: inputOptions,
          inputValidator: (value) => {
            if (!value) {
              return "You need to choose something!"
            }
          },
        }
        // {
        //   title: "Log In to Continue",
        //   html: true,
        //   text: "Username: <input type='text'><br>Password: <input type='password'>",
        // }
      )
      if (
        cartItemsDBorLS
          .map((Car) => Car.product_item)
          .includes(
            uniqueProductItems.filter(
              (productitem) => Number(productitem.size) === Number(selectedsize)
            )[0]?.id
          ) === true
      ) {
        AlreadyInCartAlert("product is already in cart")
      } else {
        if (selectedsize) {
          const orderingItem = uniqueProductItems.filter(
            (productitem) => Number(productitem.size) === Number(selectedsize)
          )
          if (userInfo) {
            dispatch(CartDataBaseAdd(orderingItem[0]?.id, qty)).then(() => {
              dispatch(listCartDataBase(userInfo.id, ""))
            })
          } else if (!userInfo) {
            dispatch(addToCart(orderingItem[0]?.id, qty)).then(() => {
              dispatch(listCartDataBase(0, localStorage.getItem("cartItems")))
            })
          }

          const Toast = Swal.mixin({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            width: 350,
            customClass: {
              container: "sweet_containerImportant",
              popup: "my-popup-class",
              confirmButton: "my-confirm-button-class",
            },
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer)
              toast.addEventListener("mouseleave", Swal.resumeTimer)
            },
          })

          Toast.fire({
            icon: "success",
            title: `${productname} Added to Cart`,
          })

          // Swal.fire({ html:  })
          // setOpen(!open)
        }
      }
    }

    const addToCartHandler = (
      uniqueProductItems,
      productname,
      qty,
      uniquecolor
    ) => {
      const forSize = uniqueProductItems?.filter(
        (item) => Number(item.color) === Number(uniquecolor)
      )

      const productSize = new Set(
        forSize?.map((sizes) => {
          return sizes.size
        })
      )

      var printSizes = {}
      size?.map((size) => {
        if (productSize.has(size.id)) {
          const sizeId = `${size.id}`
          return (printSizes[sizeId] = `${
            size.name === "FREE SIZE" ? "FS" : size.name
          }`)
        }
      })
      Call(printSizes, forSize, productname, qty)
      // setOpen(!open)
    }

    const addToWishList = (wishProductItem) => {
      if (userInfo) {
        dispatch(WishDataBaseAdd(wishProductItem.id)).then((data) => {
          if (data === "success") {
            dispatch(listWishDataBase(userInfo?.id))
          }
        })
      } else {
        forLogin()
      }
    }

    // const addToWishAlert = (value) => {
    //   Swal.fire({

    //     position: "center",
    //     icon: "success",
    //     title: `${value} is Added to wishlist`,
    //     showConfirmButton: false,
    //     timer: 1500,
    //   });
    // };

    const wishItemIds = new Set(
      wishItemsDataBase?.map((item) => item.product_item.id)
    )

    let mymap = new Map()
    var unique = []

    const categoryName = category?.filter(
      (cat) => Number(cat?.id) === Number(products[0]?.category)
    )[0]?.name

    return (
      <>
        <Helmet>
          <title>
            {`${categoryName ?? "Myunde"} - Buy ${
              categoryName ?? "Products"
            } Online for Men in India | Myunde `}
          </title>
        </Helmet>

        <CartPage forCartOpen={forCartOpen} open={open} />
        {loading || loading2 ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : products === "No Products Available" ? (
          "no products available"
        ) : (
          <>
            <div className="flex justify-end  right-0 absolute lg:right-12 2xl:right-36 top-24 sm:top-28 lg:top-44 z-40 font-style">
              <Tooltip arrow title={"sort by price"} placement="top">
                <div className=" mr-2 ">
                  {/* <div className="overflow-x-scroll col-span-3 sm:col-span-4 flex no-scrollbar text-xs sm:text-sm capitalize "></div> */}
                  <Select
                    options={options}
                    className="w-40 font-style text-gray-800"
                    placeholder={"sort"}
                    onChange={(choice) => sortProducts(choice)}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 5,
                      colors: {
                        ...theme.colors,
                        primary25: "#eeeeee",
                        primary: "rgb(0,53,96)",
                        primary50: "ffffff",
                      },
                    })}
                  />
                </div>
              </Tooltip>
            </div>

            <div className="flex flex-wrap mt-4">
              {!userInfo ? (
                <LoginSlide forProfileOpen={forLogin} open={state} />
              ) : (
                <ProfileScreen
                  forProfileScreen={forProfileScreen}
                  open={state.profilescreen}
                />
              )}
              {(Productstate ? Productstate : products)?.map((product) => {
                const offer = productoffers?.find(
                  (val) => val.product === product.id
                )
                // const brandoffer = brandoffers?.find(
                //   (val) => val.brand === product.brand_id
                // )
                const brandName = brand?.find(
                  (val) => val.id === product.brand
                )?.name

                return (unique = productitems
                  ?.filter(
                    (productitem) =>
                      productitem.product === product.id &&
                      (productFilterColor?.length
                        ? productFilterColor?.includes(productitem.color)
                        : true) &&
                      (productFilterSize?.length
                        ? productFilterSize?.includes(productitem.size)
                        : true)
                  )
                  ?.filter((el) => {
                    const val = mymap.get(el.color)

                    if (val) {
                      if (el.id < val) {
                        mymap.delete(el.color)
                        mymap.set(el.color, el.id)
                        return true
                      } else {
                        return false
                      }
                    }
                    mymap.set(el.color, el.id)
                    return true
                  })
                  .map((unique, i) => {
                    mymap.clear()
                    return (
                      <div
                        className="flex flex-wrap justify-start my-4   w-1/2 sm:w-1/3 2xl:w-[23.5%] select-none font-poppins"
                        key={unique.id}
                      >
                        <div className="w-full">
                          <div
                            className=" group overflow-y-hidden  border border-gray-50
                      shadow-md   hover:shadow-lg hover:shadow-gray-400 duration-200 mx-1 lg:mx-2"
                          >
                            <div className=" relative overflow-hidden">
                              <Tooltip
                                arrow
                                title={"Add to wishlist"}
                                placement="top"
                              >
                                <div className="absolute m-1.5 sm:m-3  rounded-full group bg-gray-100 duration-500 hover:bg-[rgb(192,192,192)] ">
                                  <div className="xs:p-4 p-3.5  ">
                                    <input
                                      type="checkbox"
                                      id="toggle"
                                      className="opacity-0 absolute scale-[4] "
                                      checked={wishItemIds.has(unique.id)}
                                      value={unique.id}
                                      onChange={(e) => {
                                        addToWishList(
                                          productitems?.filter(
                                            (item) =>
                                              item.id === Number(e.target.value)
                                          )[0]
                                        )
                                      }}
                                    />

                                    <svg
                                      id="twitter-heart"
                                      htmlFor="toggle"
                                      viewBox="0 0 24 24"
                                      fill="white"
                                      strokeWidth={1}
                                      stroke="rgb(0,0,0)"
                                      className="sm:w-5  sm:h-5 w-4 h-4 "
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </Tooltip>

                              <Link
                                to={`/product/${product.id}?clr=${unique.color}`}
                                className=" "
                              >
                                {/* {offer && (
                                  <div
                                    className="w-24 md:w-36 h-8 md:h-12 capitalize  bg-[rgb(0,53,96)]    absolute top-1 -right-7 md:-right-10 rotate-45 flex justify-center text-center items-center  shadow-md   font-style   font-mono "

                                  >
                                    <p className="text-[10px]  md:text-sm  text-white ">
                                      <span className="uppercase ">offer</span>
                                      <br /> {offer?.offer?.value}
                                    </p>
                                  </div>
                                  <div className="w-20 sm:w-24 md:w-24 h-6 sm:h-8 md:h-10 capitalize  bg-[rgb(0,53,96)] m-0.5 md:m-2 rounded-b-lg   absolute  right-0 flex justify-center text-center items-center  shadow-md   font-style   font-mono ">
                                    <p className="text-[8px] sm:text-[10px]  md:text-xs  text-white ">
                                      <span className="uppercase ">offer</span>
                                      <br /> {offer?.offer?.value}
                                    </p>
                                  </div>
                                )} */}
                                {offer && (
                                  <div className="svg" >
                                    <div className="bookmarkRibbon    absolute  pt-1 pb-5 xs:pb-7 xs:pt-4  right-3 text-white text-center font-style text-[11px] " style={{ background: "#000000"}}>
                                      {offer?.offer?.type === "custom" && (
                                        <p className="px-1   w-10 xs:w-12">
                                          {offer?.offer?.value}
                                         
                                        </p>
                                      )}
                                      {offer?.offer?.type === "discount"  && (
                                        <div className="p-1   w-10 xs:w-12">
                                          <span className="text-lg ">
                                            {offer?.offer?.value}
                                           
                                          </span>
                                          %
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                )}

                                {/* <div className=" p-1 absolute box--gradient silver shadow-lg  right-2 rounded-b-md  text-center capitalize font-style text-xs "><span className="text-[rgb(0,53,96)]"> OFFER </span> <br/> buy 1 get 1 </div> */}
                                <div className="md:p-2 p-1 bg-white">
                                  {unique.image ? (
                                    <ProgressiveImage
                                      src={unique.image ?? unique.image}
                                      placeholder={load}
                                    >
                                      {(src, loading) => (
                                        <img
                                          src={src}
                                          alt={`Myunde ${
                                            product.name ?? "Speciallty for men"
                                          } `}
                                          className=""
                                        />
                                      )}
                                    </ProgressiveImage>
                                  ) : (
                                    <LoaderCard />
                                  )}
                                </div>
                              </Link>
                            </div>
                            <div
                              className="xl:-mt-12 xl:group-hover:-translate-y-0 xl:translate-y-12 duration-700 ease-in-out px-2 sm:px-3 
                                     py-1 sm:py-3 bg-gradient  "
                            >
                              <div
                                key={product?.brand}
                                className="flex justify-between items-center"
                              >
                                {brandName?.toLowerCase() === "myunde" ? (
                                  <div className="w-16 h-5">
                                    <img src={png} alt="bra.name" />
                                  </div>
                                ) : (
                                  <p
                                    className="text-[12px] md:text-sm truncate uppercase  text-gray-900 "
                                    key={i}
                                  >
                                    {brandName}
                                  </p>
                                )}
                              </div>
                              <Link
                                to={`/product/${product.id}?clr=${unique.color}`}
                                className=" "
                              >
                                <div className="flex justify-between     border-t-3  mr-3  ">
                                  <p className=" text-xs sm:text-sm capitalize text-gray-500 font-style truncate ">
                                    {product.name}
                                  </p>
                                </div>
                              </Link>
                              <div className="text-start text-gray-800 pb-1 xl:pb-3  flex justify-between  items-center ">
                                <div className="flex items-center ">
                                  {offer?.offer?.type === "discount" ? (
                                    <p className="  md:text-lg text-sm">
                                      <b>
                                        ₹
                                        {(
                                          product.mrp -
                                            product?.mrp *
                                              (offer?.offer?.value / 100)
                                        ).toFixed(2)}
                                      </b>
                                      <del className="text-xs   text-[#ef3237] ml-0.5 xl:ml-2 font-style ">
                                    ₹{product.mrp}
                                  </del>
                                    </p>
                                    
                                  ) : (
                                    <p className="  md:text-lg text-sm">
                                      <b>₹{product.price}</b>
                                      <del className="text-xs   text-[#ef3237]  ml-0.5 xl:ml-2 font-style ">
                                    ₹{product.mrp}
                                  </del>
                                    </p>
                                  )}

                                 
                                </div>
                                {product.pack_of > 1 ? (
                                  <div>
                                    <p className=" hidden xs:block  text-xs font-thin bg-gray-200 p-0.5 xl:p-1 ">
                                      <b>Pack of {product.pack_of}</b>
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="flex flex-wrap justify-center">
                                {productitems.filter(
                                  (item) =>
                                 item.color === Number(unique.color)  &&
                                    Number(item.quantity) !== 0
                                ).length ? (
                                
                                    <Button
                                    value={product.id}
                                    key={unique.id}
                                    onClick={(e) =>
                                      addToCartHandler(
                                        productitems.filter(
                                          (item) =>
                                            item.product ===
                                              Number(e.target.value) &&
                                            Number(item.quantity) !== 0
                                        ),
                                        product?.name,
                                        qty,
                                        unique.color
                                      )
                                    }
                                    type="button"
                                    className=" text-xs sm:text-sm w-full  py-1 sm:py-1.5 flex justify-center
                         text-white hover:text-black hover:bg-white hover:border-2 hover:border-black/50 bg-black  capitalize rounded-md   focus:outline-none border  "
                                  >
                                    add to
                                    <ShoppingBagIcon className="w-5 h-5 -mt-0.5 ml-1 " />
                                  </Button>

                        //           ):(
                        //             <p
                        //             className=" text-sm w-full  py-1 sm:py-2=1.5 flex justify-center
                        //  text-white bg-red-700  capitalize rounded-md  font-style  focus:outline-none border"
                        //           >
                        //             out of stock !!
                        //           </p>

                        //           )

                                  
                                ) : (
                                  <p
                                    className=" text-sm w-full  py-1 sm:py-2=1.5 flex justify-center
                         text-white bg-red-700  capitalize rounded-md  font-style  focus:outline-none border"
                                  >
                                    out of stock !!
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }))
              })}
            </div>
          </>
        )}
      </>
    )
  },

  (prevProps, nextProps) => {
    if (prevProps === nextProps) {
      return false
    } else {
      return true
    }
  }
)

export default Product

// {Category &&
//   <Helmet>
//     <title>
//       {`${Category.name ?? "Myunde"} - Buy ${
//         Category.name ?? "Products"
//       } Online for Men in India | Myunde `}
//     </title>

//     <meta name="theme-color" content="#fffff" />
//     <link
//       rel="dns-prefetch preconnect"
//       href="https//www.googletagmanager.com"
//     />
//     <link
//       rel="dns-prefetch preconnect"
//       href="https//www.google-analytics.com"
//     />
//     <link
//       rel="dns-prefetch preconnect"
//       href="https//connect.facebook.net"
//     />
//     <link rel="dns-prefetch preconnect" href="https://www.facebook.com" />

//     <link
//       rel="apple-touch-icon"
//       sizes="180x180"
//       href="https://www.myunde.com/static/media/Myunde.6a810a17183085440877.png "
//     />
//     <meta name="viewport" content="width=device-width,initial-scale=1" />
//     <meta
//       name="google-signin-clientid"
//       content="655033035589-2fpbmlr0n9von2jjg1s4t1v5h444atjn.apps.googleusercontent.com"
//     />
//     <meta
//       name="google-signin-cookiepolicy"
//       content="single_host_origin"
//     />
//     <meta name="google-signin-scope" content="profile email" />
//     <meta
//       name="google-site-verification"
//       content="oNLGZfQY_bvWnfd6PrZZ68u_uxtbnb4fKw7sv5VUpDA"
//     />
//     <meta
//       name="msvalidate.01"
//       content="AAB47CED678BD7521C0C37E1A6E3FDFE"
//     />
//     <meta
//       itemprop="image"
//       content="https://www.myunde.com/static/media/Myunde1.d6012e95359a380d27f1.png"
//     />
//     <meta
//       name="keywords"
//       content={`  ${category?.name} , boxer ${category?.name}, boxer shorts, underwear men, mens boxer shorts, mens boxer ${category?.name}, brief underwear, jockey ${category?.name}, jockey boxer ${category?.name}, jockey boxer shorts, mens underwear ${category?.name}`}
//     />
//     <meta
//       name="description"
//       content={` ${Category?.name} for Men - Buy ${Category?.name} for Men Online in India. Explore our latest collection of ${category?.name} for men that provides maximum comfort all day only at myunde. Buy now! `}
//     />
//     <meta name="google-play-app" content="app-id=com.myunde.myunde" />
//     <link
//       rel="canonical"
//       href={`https://www.myunde.com/#/products/?category=${Category.id}`}
//     />
//     <link
//       rel="alternate"
//       id="alternate"
//       hreflang="en-in"
//       href="https://www.myunde.in/"
//     />
//     <link
//       rel="alternate"
//       id="mydus"
//       hreflang="en-us"
//       href="https://www.myunde.com"
//     />
//     <meta property="fb:admins" content="100085678073406" />
//     <meta property="fb:app_id" content="582847913287350" />
//     <link
//       rel="android-touch-icon"
//       href="https://www.myunde.com/static/media/Myunde.6a810a17183085440877.png"
//     />
//     <meta
//       name="twitter:description"
//       content={` ${Category?.name} for Men - Buy ${Category?.name} for Men Online in India. Explore our latest collection of ${category?.name} for men that provides maximum comfort all day only at myunde. Buy now! `}
//     />
//     <meta
//       name="twitter:title"
//       content={` Buy ${Category?.name} For Men Online at Best Price in India | myunde`}
//     />
//     <meta name="twitter:url" content={`https://www.myunde.com/#/products/?category=${Category.id}`} />
//     <meta
//       property="og:title"
//       content={` Buy ${Category?.name} For Men Online at Best Price in India | myunde `}
//     />
//     <meta property="og:type" content="website" />
//     <meta
//       property="og:url"
//       content={`https://www.myunde.com/#/products/?category=${Category.id}`}
//     />
//     <meta
//       property="og:image"
//       content="https://www.myunde.com/static/media/Myunde1.d6012e95359a380d27f1.png"
//     />
//     <meta property="og:image:width" content="200" />
//     <meta property="og:image:height" content="200" />
//     <meta
//       property="og:description"
//       content={` ${Category?.name} for Men - Buy ${Category?.name} for Men Online in India. Explore our latest collection of ${category?.name} for men that provides maximum comfort all day only at myunde. Buy now! `}
//     />
//     <meta property="og:site_name" content="myunde" />
//     <meta name="apple-mobile-web-app-title" content="myunde" />
//     <meta
//       property="al:ios:url"
//       content={`https://www.myunde.com/#/products/?category=${Category.id}`}
//     />
//     <meta property="al:ios:app_store_id" content="1668960059" />
//     <meta property="al:ios:app_name" content="Myunde" />
//     <meta property="al:android:package" content="com.myunde.android" />
//     <meta property="al:android:app_name" content="Myunde" />
//     <meta
//       property="al:android:url"
//       content={`https://www.myunde.com/#/products/?category=${Category.id}`}
//     />
//     <meta property="al:web:should_fallback" content="true" />
//     <meta
//       name="apple-itunes-app"
//       content="app-id=1668960059, app-argument=https://www.myunde.com/"
//     />
//   </Helmet> }
