import React, { useState } from "react";
import { useParams } from "react-router-dom";
import BlogCategoryPage from "./BlogCategoryPage";
import BlogHeader from "../BlogHeader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getBlogCategory, getBlogs } from "../../../actions/blogActions";
import { BLOGS_REQUEST } from "../../../constants/blogConstants";

function BlogCategory() {
  const { name } = useParams();
  const { categories } = useSelector((state) => state.blog);

  const dispatch = useDispatch();

  useEffect(() => {
    const category = categories.find((value) => value.name === name);
    if (!!category) {
      dispatch(getBlogs(category.id));
    } else {
      dispatch({ type: BLOGS_REQUEST });
    }
  }, [name, categories]);

  return (
    <>
      <BlogCategoryPage categoryName={name} />
    </>
  );
}

export default BlogCategory;
