import { useDispatch, useSelector } from "react-redux";
import { auth, firebase } from "./firebase";

import { FcGoogle } from "react-icons/fc";
import { registerUser } from "../actions/userActions";
import { useState } from "react";
import { CircularProgress } from "@mui/material";
// import { FaFacebookSquare } from "react-icons/fa";

// import {FaFacebookSquare} from "react-icons/fa"

// import { useDispatch } from "react-redux";
// import { registerUser } from "../actions/userActions";
// import { useState } from "react";

const FireBaseGoogle = () => {
  // const dispatch = useDispatch();

  const GoogleAuth = new firebase.auth.GoogleAuthProvider();

  // const FacebookAuth = new firebase.auth.FacebookAuthProvider();

  GoogleAuth.setCustomParameters({
    prompt: "select_account",
    
    
  });

  // FacebookAuth.setCustomParameters({ display: "popup" });

  const signInWithGoogle = () => {
    auth.signInWithRedirect(GoogleAuth);
  };

  // const dispatch = useDispatch();

  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;

  // const [loading, setLoading] = useState(false);

  // const handleSignIn = async () => {
  //   // setLoading(true);
  //   // try {
  //   //   const result = await firebase.auth().signInWithPopup(GoogleAuth);
  //   //   const userCredential = result.user;

  //   //   if (userCredential) {
  //   //     await dispatch(registerUser(result.user)).then(() => {
  //   //       setLoading(false);
  //   //     });
  //   //   }
  //   // } catch (error) {
  //   //   console.log("Error:", error);
  //   //   setLoading(false);
  //   // }
  //   // setLoading(true);
  //   firebase
  //     .auth()
  //     .signInWithPopup(GoogleAuth)
  //     .then((result) => {
  //       /** @type {firebase.auth.OAuthCredential} */
  //       var credential = result.credential;
  //       GoogleAuth.addScope("profile");
  //       GoogleAuth.addScope("email");
  //       // This gives you a Google Access Token. You can use it to access the Google API.
  //       var token = credential.accessToken;
  //       // The signed-in user info.
  //       var user = result.user;
  //       if (user) {
  //         dispatch(registerUser(user))
  //       }
  //       // console.log("success-------->", user, token);
  //       // IdP data available in result.additionalUserInfo.profile.
  //       // ...
  //     })
  //     .catch((error) => {
  //       // Handle Errors here.
  //       var errorCode = error.code;
  //       var errorMessage = error.message;
  //       // The email of the user's account used.
  //       var email = error.email;
  //       // The firebase.auth.AuthCredential type that was used.
  //       var credential = error.credential;
  //       console.log(
  //         "error ----->",
  //         errorCode,
  //         errorMessage,
  //         error,
  //         credential,
  //         email
  //       );
  //       // setLoading(false);
  //       // ...
  //     });
  // };

  // const signInWithFacebook = async () => {
  //   try {
  //     const result = await firebase.auth().signInWithPopup(FacebookAuth);
  //     const userCredential = result.user;

  //     if (userCredential) {
  //       // dispatch(registerUser(userCredential));
  //       console.log(userCredential)
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.log("Error:", error);
  //     setLoading(false);
  //   }
  // };

  return (
    <div>
      <div
        className=" w-full  h-12 flex justify-center items-center rounded-md border border-gray-400 mt-8 mb-4 focus:outline-none hover:border-gray-200 group"
        onClick={signInWithGoogle}
      >
        {/* {loading ? (
          <CircularProgress size={30} sx={{ color: "black" }} />
        ) : ( */}
          <div className="flex justify-center items-center text-gray-500 text-sm group-hover:text-gray-400">
            <FcGoogle className="w-5 h-5 mx-1 text-gray-600 group-hover:opacity-70" />
            <p>Google Login</p>
          </div>
        {/* )} */}
      </div>

      {/* <button
        className=" w-full  h-12 rounded-md border border-gray-400 mt-8 mb-4 focus:outline-none hover:border-gray-200 group"
        onClick={signInWithFacebook}
      >
        <div className="flex justify-center items-center text-gray-500 text-sm group-hover:text-gray-400">
          <FaFacebookSquare className="w-5 h-5 mx-1 text-gray-600 group-hover:opacity-70" />
          <p>Facebook Login</p>
        </div>
      </button> */}
    </div>
  );
};

export default FireBaseGoogle;
