import { Dialog, Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { Button } from "@material-tailwind/react";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { createFeedback, listFeedbacks } from "../actions/userActions";

import Feedbackimage from "../images/feedback.jpg";

function Feedback({ forFeedback, openFeedback }) {
  
  const feedbackList = useSelector((state) => state.feedback);
  const { feedback } = feedbackList;

  const [feedbackComp, setFeedbackComp] = useState({
    giveFeedback: false,
    placedFeedback: true,
  });

  const [value, setValue] = useState(5);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    const formData = new FormData();

    Object.assign(data, { rating: value });

    formData.append("rating", value);
    formData.append("feedback", data.feedback);

    dispatch(createFeedback(formData));

    if (data.feedback) {
      reset({ feedback: "", rating: Number(5) });

      forFeedback();
    }
  };

  useEffect(() => {
    dispatch(listFeedbacks());
  }, []);

  return (
    <Dialog
      open={openFeedback}
      transitionDuration={700}
      onClose={forFeedback}
      maxWidth={"xl"}
    >
      <div className="  w-full bg-white rounded-lg   ">
        <div className="w-full flex flex-wrap justify-between sticky z-10 top-0 items-center text-gray-800 p-3  rounded-t-lg shadow-md  bg-gray-100 ">
          {feedbackComp.giveFeedback && (
            <>
              <IoIosArrowBack
                className="w-6 h-6 mr-1 mt-1  "
                onClick={() =>
                  setFeedbackComp({ placedFeedback: true, giveFeedback: false })
                }
              />

              <p className="text-lg  sm:text-xl font-bold text-gray-800 ">
                give feedback
              </p>
            </>
          )}

          {feedbackComp.placedFeedback && (
            <p className="text-lg  sm:text-xl font-bold text-gray-800 ">
              customer feedbacks{" "}
              {feedback?.length === 0 ? "" : `(${feedback?.length})`}
            </p>
          )}

          <AiOutlineClose
            className="w-6 h-6 hover:scale-75  "
            onClick={() => forFeedback()}
          />
        </div>

        <div className="md:flex md:w-full pt-3">
          <div className="w-1/2 hidden md:block">
            <img src={Feedbackimage} alt="123" className="w-full h-full" />
          </div>

          {feedbackComp.placedFeedback && (
            <div className="md:w-1/2 md:p-4 relative ">
              <div
                className={`${
                  feedback.length === 0
                    ? "flex-col justify-center items-center text-center p-4 "
                    : "flex bottom-4 justify-center items-center md:bottom-4 absolute"
                }   w-full md:w-[95%] bg-white`}
              >
                {feedback.length === 0 && (
                  <p className="m-3 font-medium text-primary">
                    By being the first feedback giver for Myunde.com, you have
                    the opportunity to help shape the future of the platform and
                    ensure that it meets the needs of its users.
                  </p>
                )}
                <Button
                  onClick={() =>
                    setFeedbackComp({
                      placedFeedback: false,
                      giveFeedback: true,
                    })
                  }
                  className="   bg-primary  px-10   rounded-lg  text-white "
                >
                  give your feedback !!
                </Button>
              </div>
              {feedback?.length !== 0 && (
                <div className="style-4  overflow-y-scroll overflow-hidden w-full  h-[25rem] lg:h-[40rem]   xs:px-3 xs:m-3 pb-6">
                  {feedback.map((fb, i) => {
                    return (
                      <div
                        key={i}
                        className="w-full mb-5 p-2 shadow-md capitalize font-medium text-gray-700 bg-gray-100 rounded-xl"
                      >
                        <div className="">
                          <div className="flex items-center justify-between flex-wrap">
                            <div className="flex w-full  items-start  ">
                              <div className="p-4 w-6 h-6 border rounded-full mx-2 my-2 flex items-center justify-center bg-gray-400 text-white">
                                {fb?.customer?.slice(0, 1)}
                              </div>

                              <div className="flex items-start  sm:items-center justify-between w-full flex-wrap">
                                <div className="">
                                  <div className="mt-2 text-md capitalize ">
                                    {fb?.customer}
                                    <p className="text-xs ml-0.5 text-[rgb(0,53,96)]">
                                      {new Date(fb?.createdAt).toDateString()}
                                    </p>
                                  </div>
                                </div>

                                <div className=" flex items-center 2xs:my-2  w-32   justify-start xs:justify-end ">
                                  <br />
                                  <p className="text-xl hidden sm:block sm:text-2xl font-bold text-gray-600 ">
                                    {fb?.rating}
                                  </p>
                                  {[...Array(fb?.rating)]?.map((x, i) => {
                                    return (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill=""
                                        key={i}
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-3 h-3 xs:w-4 xs:h-4 sm:w-5 sm:h-5 sm:ml-1  fill-amber-300 stroke-amber-400"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                                        />
                                      </svg>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex my-2 mx-5 justify-start "></div>

                          <p className=" indent-3 lowercase mx-4">
                            {fb.Feedback}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}

          {feedbackComp.giveFeedback && (
            <div className=" md:w-1/2  bg-white rounded-lg   ">
              <div className="xs:p-4 p-2  w-72 md:w-full ">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="my-4">
                    <p className="flex justify-start mb-1 after:content-['*'] after:ml-1 after:text-red-500  items-center font-bold text-gray-700">
                      User friendly
                    </p>
                    <div className=" flex justify-start items-center my-4 ">
                      <Rating
                        name="hover-feedback"
                        value={value}
                        precision={1}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                      />
                    </div>
                  </div>
                  <div className="py-4">
                    <p className="flex justify-start mb-1 font-bold text-gray-700 after:content-['*'] after:ml-1 after:text-red-500">
                      your feedback
                    </p>

                    <textarea
                      {...register("feedback", { required: true })}
                      aria-invalid={errors?.feedback ? "true" : "false"}
                      className={`flex justify-start bg-blue-50  w-full md:h-52 p-4  ${
                        errors?.feedback?.type === "required"
                          ? "border-red-300 border-2 focus:outline-red-300"
                          : " focus:outline-blue-100"
                      }  rounded-tl-lg rounded-br-lg `}
                    />
                  </div>
                  {errors?.feedback?.type === "required" && (
                    <p className="text-sm m-1 text-red-500 flex justify-center relative">
                      please enter your feedback
                    </p>
                  )}

                  <div className="flex justify-center text-center px-3 w-full">
                    <Button
                      type="submit"
                      className="flex  mt-3   bg-primary  px-10 h-8  rounded-lg justify-center items-center  text-white "
                    >
                      save feedback
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export default Feedback;
