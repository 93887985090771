import React from "react"; // react import
import { useForm } from "react-hook-form"; // useform imported from react hook
import { AiOutlineClose } from "react-icons/ai"; //
import axios from "axios";
import { Fab, Tooltip } from "@mui/material";
import { FaWhatsapp } from "react-icons/fa";

function ContactPage({ forContact, openContact }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    Object.assign(data);
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("message", data.message);
    forContact();
    await axios.post(`/api/users/contactus/`, formData);
    reset();
  };

  const fabStyle = {
    right: 20,
    position: "fixed",
    bottom: 10,
    backgroundImage: "linear-gradient(to right, #2cb13e, #35d74b)",
  };

  const handleWhatsAppClick = () => {
    window.location.href = `https://wa.me/9514100444`;
  };

  return (
    <>
      <div
        className="w-full    sm:mt-2 flex justify-center items-center flex-wrap       rounded-xl  sm:p-3   text-primary "
        // style={{
        //   background:
        //     "linear-gradient(49deg, rgba(227,242,255,0.7) 0%, rgba(249,247,240,0.7) 87%)",
        // }}
      >
        <div className="w-full flex justify-evenly max-w-[1500px] flex-wrap sm:my-8 ">
          <div
            className=" w-full mt-6 lg:mt-0 lg:w-[48%] m-2 md:shadow-md  flex md:justify-start items-start rounded-xl"
            style={{
              background:
                "linear-gradient(29deg, rgba(227,242,255,0.6) 50%, rgba(249,247,240,0.6) 50%)",
            }}
          >
            <div className="font-medium w-full p-2 sm:p-4 ">
              <p className="text-xl sm:text-4xl sm:my-8 md:mb-16 sm:ml-12">
                Contact info
              </p>
              <div className="flex justify-center   w-full ">
                <div className="text-sm sm:text-lg">
                  You may contact us using the information below:
                  <div className="flex items-center my-8">
                    <div className="lg:p-3 p-2 rounded-full bg-[rgb(0,0,0,0.3)] flex justify-center items-center mr-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="rgb(0,0,0)"
                        className="w-4 h-4 sm:w-6 sm:h-6  "
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                        />
                      </svg>
                    </div>
                    <p className=" sm:text-xl text-primary">
                      +91 95141 00444
                    </p>
                  </div>
                  <div className="flex items-center sm:my-8">
                    <div className="lg:p-3 p-2 rounded-full bg-[rgb(0,0,0,0.3)] flex justify-center items-center mr-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="rgb(0,0,0)"
                        className="w-4 h-4 sm:w-6 sm:h-6  "
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                        />
                      </svg>
                    </div>
                    <p className="sm:text-xl text-primary">
                      myunde.com@gmail.com
                    </p>
                  </div>
                  <div className="flex items-start my-8">
                    <div className="lg:p-3 p-2 rounded-full bg-[rgb(0,0,0,0.3)] flex justify-center items-center mr-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="rgb(0,0,0)"
                        className="w-4 h-4 sm:w-6 sm:h-6  "
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                        />
                      </svg>
                    </div>

                    <div className=" sm:text-lg xl:text-xl text-start mt-2">
                      <p className="uppercase text-primary font-bold ">
                        MYUNDE.COM
                      </p>

                      <p className="text-[15px] lg:text-lg">
                        1st Floor,MK plaza, Thoppu thottam,
                        <br />
                        Solipalayam Road, 15 velampalayam,
                        <br />
                        Tirpur 641652.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" w-full lg:w-[48%] sm:mt-6 lg:mt-0 p-4 font-medium text-xl capitalize flex md:justify-center">
            <div className=" ">
              <p className="text-xl sm:text-4xl">Contact us </p>
              <p className="text-sm sm:text-lg mt-4 ">
                Feel Free to contact us any time. We will get back to you as
                soon as we can!.
              </p>
              <div className="flex justify-center">
                <div className="w-full 2xl:w-[80%] ">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="relative mt-8 sm:mt-14 ">
                      <input
                        name="name"
                        type="text"
                        {...register("name", { required: true })}
                        aria-invalid={errors.name ? "true" : "false"}
                        placeholder="Name"
                        id="name"
                        className={` block px-2.5 pb-2.5 appearance-none border ${
                          errors.name ? "border-red-300 border-2" : ""
                        }  focus:outline-none focus:ring-0  pt-4 w-full text-[15px] text-gray-900  rounded-lg  `}
                      />
                    </div>

                    <div className="relative mt-10">
                      <input
                        name="email"
                        type="email"
                        {...register("email", {
                          required: "Email Address is required",
                        })}
                        aria-invalid={errors.email ? "true" : "false"}
                        placeholder="Email"
                        id="email"
                        className={`  block px-2.5 ${
                          errors.email
                            ? "border-red-300 border-2"
                            : "border-gray-300 border"
                        } pb-2.5 appearance-none  focus:outline-none focus:ring-0  pt-4 w-full text-[15px] text-gray-900  rounded-lg peer `}
                      />
                    </div>

                    <div className="relative mt-10">
                      <textarea
                        name="message"
                        placeholder="Message"
                        {...register("message", { required: true })}
                        aria-invalid={errors.message ? "true" : "false"}
                        id="message"
                        className={`  block px-2.5 pb-2.5 border ${
                          errors.message && "border-red-300 border-2"
                        }  appearance-none  focus:outline-none focus:ring-0 pt-4 w-full text-[15px] text-gray-900  rounded-lg peer `}
                      />
                    </div>

                    <button
                      type="submit"
                      className="text-center bg-primary  h-14 flex justify-center items-center text-white text-[15px] font-medium capitalize  rounded-lg  my-8  w-full"
                      // onClick={() => loginSubmit()}
                    >
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className="text-3xl font-medium capitalize text-center flex justify-center items-center">
          Our office located in
          <svg
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-7 h-7"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
            />
          </svg>
        </p>
      </div>
      <div className="flex justify-center items-center py-12 px-2 ">
        <div
          className="w-full max-w-[1300px] overflow-hidden border border-primary sm:p-2"
          style={{ height: "30rem" }}
        >
          <iframe
            title="contact"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3914.6490777038416!2d77.30492085873713!3d11.139491768152533!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba907ebdc983c31%3A0x1ba2e2b0abd3f0dd!2sMyunde.com!5e0!3m2!1sen!2sin!4v1688973441894!5m2!1sen!2sin"
            width="100%"
            height="100%"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <Tooltip title="chat with us" arrow>
        <Fab
          size="large"
          aria-label="add"
          style={fabStyle}
          className="animate-bounce"
          onClick={handleWhatsAppClick}
        >
          <FaWhatsapp className="text-2xl text-white cursor-pointer " />
        </Fab>
      </Tooltip>
    </>
  );
}

export default ContactPage;
