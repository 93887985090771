import { useEffect, useState } from "react";

import InnerImageZoom from "react-inner-image-zoom";
import Dialog from "@mui/material/Dialog";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import load from "../images/mYUNDE LOGO.jpg";

function SupeRzoom({ forZoom, openZoom, zoomImages, firstImage }) {
  const [pic, setPic] = useState();

  const hoverHandler = (image, i) => {
    setPic(image);
  };

  useEffect(() => {
    setPic(firstImage?.image);
  }, [firstImage?.image]);

  return (
    <div className="relative ">
      <Dialog
        open={openZoom}
        transitionDuration={700}
        onClose={forZoom}
        fullScreen
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          className="   rounded-lg   w-screen h-screen  overflow-hidden"
          style={{
            background:
              "linear-gradient(49deg, rgba(227,242,255,0.5) 0%, rgba(249,247,240,0.5) 87%)",
          }}
        >
          <div className=" flex  flex-row p-4 ">
            <div className="  m-2 h-screen w-[20%] lg:w-[8%]  sm:pr-2 overflow-y-scroll style-4  flex flex-col pb-12">
              <div
                className="relative  mr-2 lg:mx-0 mb-2 rounded-lg"
                onClick={() => hoverHandler(firstImage?.image)}
              >
                <div
                  className={
                    firstImage?.image === pic || !pic
                      ? ""
                      : "bg-black/30 absolute w-full h-full  rounded-lg"
                  }
                ></div>
                <img
                  src={firstImage?.image}
                  alt=""
                  className={
                    firstImage?.image === pic
                      ? "w-full   rounded-lg  "
                      : "w-full   rounded-lg"
                  }
                />
              </div>
              {zoomImages.map((product, i) => {
                return (
                  <div
                    className="relative  mr-3 lg:mx-0 mb-2 rounded-lg"
                    onClick={() => hoverHandler(product.image, i)}
                    key={i}
                  >
                    <div
                      className={
                        product.image === pic
                          ? ""
                          : "bg-black/30 absolute w-full h-full  rounded-lg"
                      }
                    ></div>
                    <img
                      src={product.image}
                      key={i}
                      alt=""
                      className={
                        product.image === pic
                          ? "w-full   rounded-lg  "
                          : "w-full   rounded-lg"
                      }
                    />
                  </div>
                );
              })}
            </div>

            <div className="bg-white relative  w-[80%] lg:w-[92%] sm:h-screen  overflow-hidden ">
              <div
                
                className="rounded-md w-full focus:outline-none flex justify-end absolute z-10"
                onClick={forZoom}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8 bg-gray-50/70 "
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>

              <InnerImageZoom
                fullscreenOnMobile={true}
                fadeDuration={300}
                hideHint={false}
                zoomType="hover"
                zoomPreload={true}
                src={pic === undefined || pic === null ? load : pic}
                hideCloseButton={true}
                moveType="pan"
                className=" h-[97%]"
              />
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default SupeRzoom;

// <Transition.Root show={open} as={Fragment}>
// <Dialog as="div" className="relative z-10" onClose={setOpen}>
//   <Transition.Child
//     as={Fragment}
//     enter="ease-out duration-300"
//     enterFrom="opacity-0"
//     enterTo="opacity-100"
//     leave="ease-in duration-200"
//     leaveFrom="opacity-100"
//     leaveTo="opacity-0"
//   >
//     <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
//   </Transition.Child>

//   <div className="fixed inset-0 z-10 pt-20  sm:pt-32">
//     <div className="flex min-h-full  justify-center text-center items-center px-2 lg:px-4 ">
//       <Transition.Child
//         as={Fragment}
//         enter="ease-out duration-300"
//         enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//         enterTo="opacity-100 translate-y-0 sm:scale-100"
//         leave="ease-in duration-200"
//         leaveFrom="opacity-100 translate-y-0 sm:scale-100"
//         leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
//       >
//         <Dialog.Panel className="  w-full bg-white rounded-lg   md:w-screen md:h-screen  sm:overflow-hidden">
//           <div className="flex p-4 ">
//             <div className="mx-3 h-screen w-[20%] lg:w-[8%] overflow-y-scroll style-4 pb-32 pr-2">
//               {zoomImages.map((product, i) => {
//                 return (
//                   <div
//                     className="relative  mr-3 lg:mx-0 mb-2 rounded-lg"
//                     onClick={() => hoverHandler(product.image, i)}
//                   >
//                     <div
//                       className={
//                         product.image === pic
//                           ? ""
//                           : "bg-black/30 absolute w-full h-full  rounded-lg"
//                       }
//                     ></div>
//                     <img
//                       src={product.image}
//                       key={i}
//                       alt=""
//                       className={
//                         product.image === pic
//                           ? "w-full   rounded-lg  "
//                           : "w-full   rounded-lg"
//                       }
//                     />
//                   </div>
//                 );
//               })}
//             </div>

//             <div className="bg-white relative  w-[80%] lg:w-[92%] h-screen  overflow-hidden ">
//               <button
//                 type="button"
//                 className="rounded-md w-full focus:outline-none flex justify-end absolute z-10"
//                 onClick={() => setOpen(!open)}
//               >
//                 <svg
//                   xmlns="http://www.w3.org/2000/svg"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                   strokeWidth={1.5}
//                   stroke="currentColor"
//                   className="w-8 h-8 bg-gray-50/70 "
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     d="M6 18L18 6M6 6l12 12"
//                   />
//                 </svg>
//               </button>

//               <InnerImageZoom
//                 fullscreenOnMobile={true}
//                 fadeDuration={300}
//                 hideHint={false}
//                 zoomType="hover"
//                 zoomPreload={true}
//                 src={pic}
//                 hideCloseButton={true}
//                 moveType="pan"
//                 className=" h-[90%]"
//               />
//             </div>
//           </div>
//         </Dialog.Panel>
//       </Transition.Child>
//     </div>
//   </div>
// </Dialog>
// </Transition.Root>{" "}
