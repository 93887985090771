import React from "react";
import Filter from "../components/Filter";
import Product from "../components/multiProduct";

function Products() {
  return (
    <>
      <div className="lg:flex select-none pb-12">
        <Filter />
        <div className="w-screen select-none lg:mt-10 mt-10 sm:mt-8">
          <div className="flex flex-wrap lg:mx-12 justify-start  ">
            <Product />
          </div>
        </div>
      </div>
    </>
  );
}

export default Products;
