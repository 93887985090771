import React from "react";
import BlogContent from "./BlogContent";


function BlogHomePage() {

  return (
    <BlogContent />
  );
}

export default BlogHomePage;
