import axios from "axios";
import {
  CART_ADD_ITEM,
  CART_ITEM_REQUEST,
  ITEM_ADD_SUCCESS,
  ITEM_ADD_FAIL,
  CART_REMOVE_ITEM,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
} from "../constants/cartConstants";

import {
  CARTDATABASE_LIST_REQUEST,
  CARTDATABASE_LIST_SUCCESS,
  CARTDATABASE_LIST_FAIL,
  WISHDATABASE_LIST_REQUEST,
  WISHDATABASE_LIST_SUCCESS,
  WISHDATABASE_LIST_FAIL,
} from "../constants/cartConstants";

import { CARTDATABASE_PRODUCTLIST_SUCCESS } from "../constants/cartConstants";

import {
  CARTDATABASE_ADD_REQUEST,
  CARTDATABASE_ADD_SUCCESS,
  CARTDATABASE_ADD_FAIL,
  CARTDATABASE_UPDATE_REQUEST,
  CARTDATABASE_UPDATE_SUCCESS,
  CARTDATABASE_UPDATE_FAIL,
  CARTDATABASE_DELETE_REQUEST,
  CARTDATABASE_DELETE_SUCCESS,
  CARTDATABASE_DELETE_FAIL,
  WISHDATABASE_ADD_REQUEST,
  WISHDATABASE_ADD_SUCCESS,
  WISHDATABASE_ADD_FAIL,
  WISHDATABASE_DELETE_REQUEST,
  WISHDATABASE_DELETE_SUCCESS,
  WISHDATABASE_DELETE_FAIL,
} from "../constants/cartConstants";

export const addToCart = (id, quantity , printvalue) => async (dispatch, getState) => {
  
  const { data } = await axios.get(`/base/api/product-item/${id}`);
 
  if (id) {
    try {
      dispatch({
        type: CART_ADD_ITEM,
        payload: {
          id: id,
          product_item: data.id,
          product: data.product,
          quantity: quantity,
          print_value : printvalue ?? ""
        },
      });
      // dispatch({
      //     type: CART_ADD_ITEM,
      //     payload:{
      //         productItem:data.id,
      //         quantity:qty
      //     }
      // })
      localStorage.setItem(
        "cartItems",
        JSON.stringify(getState().cart.cartItems)
      );
    } catch (error) {
      dispatch({
        type: ITEM_ADD_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  }

  // else if (!userInfo){
  //     console.log(userInfo)
  //     if (console.log(localStorage.getItem('cartItems'))){

  //     }
  //     try {
  //         const config = {
  //             headers: {
  //                 'Content-type': 'application/json'
  //             }
  //         }

  //         const { database } = await axios.post(
  //             '/api/orders/addcart/',{"productItemId":id, "quantity":qty, "user":userInfo.id  },
  //             config
  //         )
  //         dispatch({
  //             type:   ITEM_ADD_SUCCESS,
  //             payload: database
  //         })

  //     } catch (error) {
  //         dispatch({
  //             type: ITEM_ADD_FAIL,
  //             payload: error.response && error.response.data.detail
  //                 ? error.response.data.detail
  //                 : error.message,
  //         })
  //     }

  // }
};

export const listCartDataBase =
  (userId, cartProductItem) => async (dispatch) => {
  
    try {
      dispatch({ type: CARTDATABASE_LIST_REQUEST });
      const { data } =
        userId !== 0
          ? await axios.get(`/api/orders/cart/${userId}/`)
          : await axios.get(
              `/api/orders/cart/0/?cartProductItem=${cartProductItem}`
            );
            
      dispatch({
        type: CARTDATABASE_LIST_SUCCESS,
        payload: data.cartItems,
      });

      dispatch({
        type: CARTDATABASE_PRODUCTLIST_SUCCESS,
        payload: [data.cartProductItems, data.cartProduct],
      });
    } catch (error) {
      dispatch({
        type: CARTDATABASE_LIST_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }

    //  else if (productItemId) {
    //   try {
    //     console.log("product")
    //     dispatch({ type: CARTDATABASE_PRODUCTLIST_REQUEST });
    //     console.log(productItemId);
    //     const { data } = await axios.get(
    //       `/base/api/product-items/?productItemId=${productItemId}`
    //     );
    //     const { data: data2 } = await axios.get(
    //       `/base/api/products/?productItemId=${productItemId}`
    //     );

    //     dispatch({
    //       type: CARTDATABASE_PRODUCTLIST_SUCCESS,
    //       payload: [data, data2],
    //     });
    //   } catch (error) {
    //     dispatch({
    //       type: CARTDATABASE_PRODUCTLIST_FAIL,
    //       payload:
    //         error.response && error.response.data.detail
    //           ? error.response.data.detail
    //           : error.message,
    //     });
    //   }
    // }
  };

export const removeFromCart = (id) => (dispatch, getState) => {
  dispatch({
    type: CART_REMOVE_ITEM,
    payload: id,
  });
  localStorage.setItem("cartItems", JSON.stringify(getState().cart.cartItems));
};

export const saveShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });
  localStorage.setItem("shippingAddress", JSON.stringify(data));
};

export const savePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: CART_SAVE_PAYMENT_METHOD,
    payload: data,
  });

  localStorage.setItem("paymentMethod", JSON.stringify(data));
};

// export const addToWish =
//   (id, productName, productPrice, brandName, productDescription, wishSizes) =>
//   async (dispatch, getState) => {
//     const { data } = await axios.get(
//       `/base/api/product-item/${id}`
//     );

//     try {
//       dispatch({
//         type: WISH_ADD_ITEM,
//         payload: {
//           id: id,
//           productItem: data.id,
//           name: productName,
//           brand: brandName,
//           image: data.image,
//           price: productPrice,
//           productId: data.product,
//           countInStock: data.quantity,
//           description: productDescription,
//           color: data.color,
//           size: wishSizes,
//         },
//       });

//       localStorage.setItem(
//         "wishItems",
//         JSON.stringify(getState().wish.wishItems)
//       );
//     } catch (error) {
//       dispatch({
//         type: WISHITEM_ADD_FAIL,
//         payload:
//           error.response && error.response.data.detail
//             ? error.response.data.detail
//             : error.message,
//       });
//     }
//   };

// export const removeFromWish = (id) => (dispatch, getState) => {
//   console.log(id);
//   dispatch({
//     type: WISH_REMOVE_ITEM,
//     payload: id,
//   });
//   localStorage.setItem("wishItems", JSON.stringify(getState().wish.wishItems));
// };

export const CartDataBaseAdd =
  (productItemId, quantity , printvalue) => async (dispatch, getState) => {
   
    try {
      dispatch({
        type: CARTDATABASE_ADD_REQUEST,
      });

      const { data: productItem } = await axios.get(
        `/base/api/product-item/${productItemId}`
      );

      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `/api/orders/addcart/`,
        [
          {
            product_item: productItemId,
            quantity: quantity,
            product: productItem.product,
            print_value : printvalue ?? ""
          },
        ],
        config
      );

      dispatch({
        type: CARTDATABASE_ADD_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CARTDATABASE_ADD_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const updateCartDataBase =
  (cartProduct, Qty ) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CARTDATABASE_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `/api/orders/cartitem/update/`,
        { cartProduct: cartProduct, Qty: Qty },
        config
      );
      dispatch({
        type: CARTDATABASE_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: CARTDATABASE_UPDATE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const deleteCartDataBase =
  (cartItemId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: CARTDATABASE_DELETE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      await axios.delete(`/api/orders/cartitem/delete/${cartItemId}/`, config);
      dispatch({
        type: CARTDATABASE_DELETE_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: CARTDATABASE_DELETE_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const WishDataBaseAdd =
  (productItemId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: WISHDATABASE_ADD_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `/api/orders/wishadd/`,
        { product_item: productItemId },
        config
      );

      dispatch({
        type: WISHDATABASE_ADD_SUCCESS,
        payload: data,
      });

      return "success";
    } catch (error) {
      dispatch({
        type: WISHDATABASE_ADD_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const listWishDataBase = (userId) => async (dispatch) => {
  try {
    dispatch({ type: WISHDATABASE_LIST_REQUEST });
    const { data } = await axios.get(`/api/orders/wish/${userId}/`);

    dispatch({
      type: WISHDATABASE_LIST_SUCCESS,
      payload: [data.wishItems, data.wishProductIds, data.colorIds],
    });
  } catch (error) {
    dispatch({
      type: WISHDATABASE_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const deleteWishDataBase = (id) => async (dispatch, getState) => {
  // console.log(id)

  try {
    dispatch({
      type: WISHDATABASE_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    await axios.delete(`/api/orders/wishitem/delete/${id}/`, config);

    dispatch({
      type: WISHDATABASE_DELETE_SUCCESS,
    });
    return "success";
  } catch (error) {
    dispatch({
      type: WISHDATABASE_DELETE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
