import React from "react";
import { Fragment, useState, useEffect, useCallback } from "react";
import { Popover, Tab, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  ShoppingBagIcon,
  XMarkIcon,
  UserIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { listCategories, listSize } from "../actions/productActions";
// import { listBrands } from "../actions/productActions";
import { listProducts } from "../actions/productActions";
import LoginSlide from "../screens/LoginSlide";
import ProfileScreen from "../screens/ProfileScreen";
import Wish from "./wishList";
// import CartScreen from "../screens/CartScreen";
import CartPage from "../screens/CartPage";
import logo1 from "../images/Myunde1.png";
import Brand from "./Brands";
import Search from "./search";
import { Drawer } from "@mui/material";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import { listCartDataBase } from "../actions/cartActions";
import { Helmet } from "react-helmet";
import Contact from "./Contact";

export const Header = React.memo(({ serverError }) => {
  const search = useLocation().search;
  const categoryID = new URLSearchParams(search).get("category");
  const brandID = new URLSearchParams(search).get("brand");

  const [open, setOpen] = useState(false);

  function classNames(...classes) {
    return classes.filter(Boolean).join("");
  }

  const [prof, setProf] = useState({
    profile: false,
    cart: false,
    wishlist: false,
    profilescreen: false,
  });

  const forWishOpen = useCallback(() => {
    setProf({ ...prof, wishlist: !prof.wishlist });
  }, [prof]);

  const forCartOpen = useCallback(() => {
    setProf({ ...prof, cart: !prof.cart });
  }, [prof]);

  const forProfileOpen = useCallback(() => {
    setProf({ ...prof, profile: !prof.profile });
  }, [prof]);

  const forProfileScreen = useCallback(() => {
    setProf({ ...prof, profilescreen: !prof.profilescreen });
  }, [prof]);

  const userLogin = useSelector((prof) => prof.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();

  const categoryList = useSelector((prof) => prof.categoryList);
  const { error, category } = categoryList;

  const dataBaseCart = useSelector((prof) => prof.dataBaseCart);
  const { cartProductItemsDataBase, loading } = dataBaseCart;

  const dataBaseWish = useSelector((prof) => prof.dataBaseWish);
  const { wishItemsDataBase } = dataBaseWish;

  const brandList = useSelector((prof) => prof.brandList);
  const { brand } = brandList;

  // const location = useLocation();
  const keyword = useLocation().search;

  const navigation = {
    categories: [
      {
        id: "category",
        name: "Category",

        sections: category,
      },
      {
        id: "brand",
        name: "Brand",
        sections: brand,
      },
    ],
  };

  // if (cartProductItemsDataBase.length) {
  //   cartCall();
  // }

  useEffect(() => {
    if (!category?.length) {
      dispatch(listCategories());
    }
    if (error) {
      serverError(true);
    }
    if (keyword) {
      dispatch(listProducts(keyword));
    }
  }, [keyword, error]);

  const navigate = useNavigate();
  const navigateCategory = (category) => {
    navigate(`products/?category=${category}`);
  };

  const [contactOpen, setContactOpen] = useState(false);

  const forContact = () => {
    setContactOpen(!contactOpen);
  };

  // const submitHandler = (e) => {
  //   e.preventDefault();
  //   if (keyword) {
  //     navigate(`products/?keyword=${searchKeyword}&page=1`);
  //     // dispatch(listProducts(keyword));
  //   } else {
  //     navigate(location.pathname);
  //   }
  // };

  return (
    <>
      {/* <Helmet>
        <title>
          Shop Men's Clothing Online: Briefs, Boxer Briefs, Trunks, Tank Tops,
          Inner Boxers, Vests, T-shirts, Shorts, Joggers, Loungewear, Socks and
          More on MyUnde - India's Best Online Shopping Site for Men's Clothing!
        </title>
        <meta charset="utf-8" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="canonical" href="https://www.myunde.com" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css"
        />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <meta name="theme-color" content="#000000" />

        <meta
          name="description"
          content="Shop Men's Clothing Online: Briefs, Boxer Briefs, Trunks, Tank Tops, Inner Boxers, Vests, T-shirts, Shorts, Joggers, Loungewear, Socks and More on MyUnde - India's Best Online Shopping Site for Men's Clothing!"
        />
        <meta
          name="keywords"
          content="brief,boxerbrief,trunk,tanktop,innerboxer,vestundershit,gymvest,t-shirt,kids,shorts,jogger,loungewear,socks,myunde, online shopping, online shopping sites,online shopping india, india shopping, Online shopping site ,Men's clothing T-shirts,Men's Briefs ,Men's trackpants"
        />
       
      </Helmet> */}

      <div
        className="  z-[100]  select-none bg-gradient font-poppins"
        // style={{
        //   background:
        //     "linear-gradient(49deg, rgba(227,242,255,1) 0%, rgba(249,247,240,1) 87%)",
        // }}
      >
        {/* Mobile menu */}
        <Drawer
          className=""
          anchor={"left"}
          transitionDuration={500}
          open={open}
          onClose={() => setOpen()}
        >
          <div className="relative flex w-screen xs:w-[22rem] flex-col   bg-white pb-12 ">
            <div
              className="flex px-4 pt-5 pb-4 justify-between rounded-b-xl sticky top-0 z-50 shadow-lg bg-gradient"
              // style={{
              //   background:
              //     "linear-gradient(49deg, rgba(227,242,255,1) 0%, rgba(249,247,240,1) 87%)",
              // }}
            >
              <Link to="/" aria-label="homepage">
                <img
                  className=" w-[90px]   sm:w-28  outline-none"
                  src={logo1}
                  alt="MyundeLogo"
                />
              </Link>
              <div
               
                className="-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
                onClick={() => setOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </div>
            </div>

            {/* Links */}
            <Tab.Group as="div" className="mt-2 overflow-y-auto no-scrollbar">
              <Tab.List className="-mb-px flex space-x-8 px-4 justify-evenly border-b">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? "text-primary font-style   border-b-2 py-4 px-1 border-primary hover:bg-white"
                        : "text-gray-700 border-transparent font-style  hover:bg-white "
                    )
                  }
                >
                  Category
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      selected
                      ? "text-primary font-style   border-b-2 py-4 px-1 border-primary hover:bg-white"
                      : "text-gray-700 border-transparent font-style  hover:bg-white "
                    )
                  }
                >
                  Brand
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      selected
                        ? "text-primary font-style   border-b-2 py-4 px-1 border-primary hover:bg-white"
                        : "text-gray-700 border-transparent font-style  hover:bg-white "
                    )
                  }
                >
                  More
                </Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel className="space-y-10  pt-10 pb-8">
                  <div className=" flex flex-wrap h-full justify-between px-2 ">
                    {category?.map((item, i) => {
                      return (
                        <li key={i} className="flow-root w-full my-5 px-2">
                          <Link
                            to={`products/?category=${item?.id}`}
                            // className="-m-2 block p-2 text-gray-500"
                            className={`${
                              item?.id === Number(categoryID)
                                ? "-m-2 block p-2 text-primary font-style  rounded-lg bg-gray-100"
                                : "-m-2 block p-2 text-gray-500"
                            }`}
                            onClick={() => setOpen(false)}
                          >
                            {item.name}
                          </Link>
                        </li>
                      );
                    })}
                  </div>
                </Tab.Panel>
                <Tab.Panel className="space-y-10 py-8">
                  <div className=" px-3">
                    <div className="  l mb-8 font-style w-screen xs:w-full">
                      <p className="font-bold uppercase text-sm  text-gray-700">
                        our brands
                      </p>
                      <p className="text-xs text-gray-500">
                        styles designed in-house for your perfect fit
                      </p>
                    </div>
                    <div className="flex flex-wrap justify-between ">
                      {brand
                        ?.filter(({ our_brand }) => our_brand === true)
                        ?.map((brand) => {
                          return (
                            <div key={brand.id} className="mt-6">
                              <Link
                                className=" bg-white "
                                to={`/products/?category=&brand=${brand.id}&style=&fabric=&color=&size=`}
                                onClick={() => setOpen(false)}
                              >
                                <img
                                  src={brand.image}
                                  alt={brand.name}
                                  className="object-cover h-14 w-32  xs:h-20 xs:w-40 hover:shadow-md rounded-lg  "
                                />
                              </Link>
                            </div>
                          );
                        })}
                    </div>
                    <hr className="my-3" />
                    <div className=" font-style my-8 w-full">
                      <p className="font-bold uppercase text-sm text-gray-700">
                        PARTNER BRANDS
                      </p>
                      <p className="text-xs text-gray-500">
                        Curated styles handpicked by our designers
                      </p>
                    </div>
                    <div className="flex flex-wrap justify-between  ">
                      {brand
                        ?.filter(({ our_brand }) => our_brand === false)
                        ?.map((brand) => {
                          return (
                            <div key={brand.id} className="mt-6">
                              <Link
                                className=" bg-white shadow-lg  hover:shadow-gray-400"
                                to={`/products/?category=&brand=${brand.id}&style=&fabric=&color=&size=`}
                                onClick={() => setOpen(false)}
                              >
                                <img
                                  src={brand.image}
                                  alt={brand.name}
                                  className="object-cover h-14 w-32  xs:h-20 xs:w-40 hover:shadow-md rounded-lg "
                                />
                              </Link>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </Tab.Panel>
                <Tab.Panel className="space-y-10  pt-6 pb-8">
                  <div className="flex flex-col space-y-5  h-full justify-center px-2 ">
                   
                  <Link
                      onClick={() => setOpen(false)}
                      to={"/contact-us"}
                    
                      className=" text-gray-500  px-2 block p-2 text-primary font-style  rounded-lg "
                      
                    >
                      Contact us
                      </Link>
                    <Link
                      onClick={() => setOpen(false)}
                      to={"/blog"}
                      className="text-gray-500  px-2 block p-2 text-primary text-center font-style  rounded-lg"
                    >
                      Blog
                    </Link>
                    <Link
                      to={"/track-order"}
                      onClick={() => setOpen(false)}
                      className=" text-gray-500  px-2 block p-2 text-primary text-center font-style  rounded-lg "
                    >
                      Track order
                    </Link>
                    <Link
                      to={"/sell-on-myunde"}
                      onClick={() => setOpen(false)}
                      className=" text-gray-500  px-2 block p-2 text-primary font-style  text-center rounded-lg"
                    >
                      Sell on Myunde
                    </Link>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

            {/* 
          <div className="space-y-6 border-t border-gray-200 py-6 px-4">
            {navigation.pages.map((page) => (
              <div key={page.name} className="flow-root">
                <a href={page.href} className="-m-2 block p-2 font-style text-gray-900">
                  {page.name}
                </a>
              </div>
            ))}
          </div> */}

            {/* <div className="space-y-6 border-t border-gray-200 py-6 px-4">
            <div className="flow-root">
              <Link
                to="#"
                className="-m-2 block p-2 font-style text-gray-900"
              >
                Sign in
              </Link>
            </div>
            <div className="flow-root">
              <Link
                to="#"
                className="-m-2 block p-2 font-style text-gray-900"
              >
                Create account
              </Link>
            </div>
          </div> */}
          </div>
        </Drawer>

        <header
          className="w-full  fixed z-50 shadow-md  bg-white"
          // style={{
          //   background:
          //     "linear-gradient(49deg, rgba(227,242,255,1) 0%, rgba(249,247,240,1) 87%)",
          // }}
        >
          {/* <div className="bg-gray-200 flex h-6 items-center  justify-center  text-sm font-style text-white sm:px-6 lg:px-8">
            <p className=" animate-charcter">
              Myunde unbrf-1p101 buy 1 get 1 free
            </p>
          </div> */}

          <div className="bg-black flex h-8 items-center  justify-center  text-sm font-style text-white sm:px-6 lg:px-8">
            <p className="marquee">
              <span>
                Specially for men - Specially for men - Specially for men -
                Specially for men - Specially for men - Specially for men -
                Specially for men - Specially for men - Specially for men -
                Specially for men - Specially for men - Specially for men
                -&nbsp;
              </span>
            </p>
            <p className="marquee marquee2">
              <span>
                Specially for men - Specially for men - Specially for men -
                Specially for men - Specially for men - Specially for men -
                Specially for men - Specially for men - Specially for men -
                Specially for men - Specially for men - Specially for men
                -&nbsp;
              </span>
            </p>
          </div>

          <div className="  pt-2 items-center divide-x-2 hidden lg:flex  justify-end  text-sm font-style  sm:px-6 lg:px-8 ">
         
            <Link
              to={"/contact-us"}
              className=" text-gray-500 text-xs px-2 hover:text-[rgb(0,53,96)]"
            >
              Contact us
            </Link>
            <Link
              to={"/blog"}
              className=" text-gray-500 text-xs px-2 hover:text-[rgb(0,53,96)]"
            >
              Blog
            </Link>
            <Link
              to={"/track-order"}
              className=" text-gray-500 text-xs px-2 hover:text-[rgb(0,53,96)]"
            >
              Track order
            </Link>

            <Link
              to={"/sell-on-myunde"}
              className=" text-gray-500 text-xs px-2 hover:text-[rgb(0,53,96)]"
            >
              Sell on Myunde
            </Link>

            {/* <button
              type="button"
              className=" text-gray-500 text-xs px-2"
              onClick={() => setOpen(true)}
            >
              Sell on Myunde
            </button> */}
          </div>

          <nav
            aria-label="Top"
            className="mx-auto pt-2 sm:pt-0  sm:mt-0  pb-3 sm:pb-2 "
          >
            <div className=" border-gray-200 h-8 sm:h-12  lg:h-20 ">
              <div className="flex  md:h-[52px] items-start sm:my-2 lg:my-3 w-full px-2 sm:px-3 xl:px-12">
                <div
                 
                  className="rounded-md focus:outline-none py-1 px-2 text-gray-600 lg:hidden -ml-2"
                  onClick={() => setOpen(true)}
                >
                  <span className="sr-only">Open menu</span>
                  <Bars3Icon
                    className="h-6 w-6 sm:w-7 sm:h-7"
                    aria-hidden="true"
                  />
                </div>

                {/* Logo */}
                <div className=" flex lg:ml-0 w-full items-center">
                  <span className="sr-only">Your Company</span>

                  <Link to="/">
                    <img
                      className=" w-[90px] mt-1 md:mt-1 lg:mt-0 sm:w-28  lg:w-32 xl:w-36 outline-none"
                      src={logo1}
                      alt={`${logo1}`}
                    />
                  </Link>

                  <div className="sm:border-l-[1px]  sm:border-l-gray-500 ml-2 sm:text-xs hidden  lg:ml-4 pl-2    text-start sm:block md:w-auto py-1">
                    <Brand />
                  </div>

                  {/* <Link to={"/"}>
              <img
                src={logo1}
                className="w-[100px]  xl:ml-12 lg:ml-3 sm:w-28 md:w-32 lg:w-40 xl:w-44 mr-3"
                alt="myunde"
              />
            </Link> */}
                  <div className="w-9/12 sm:w-3/5 2xl:ml-32 xl:ml-14 lg:ml-4 md:ml-0  hidden xs:block ml-4 sm:ml-2  ">
                    <Search />
                  </div>
                </div>

                {/* Flyout menus */}

                <div className="ml-auto flex items-center lg:mr-6 xl:mr-10">
                  <div className="hidden xl:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6 ">
                    <div className="text-[16px] font-style text-center text-gray-700 hover:text-gray-800 w-24 -ml-6 pr-3 border-r-2 ">
                      {userInfo ? (
                        <Tooltip title={"Hi , " + userInfo?.username}>
                          <p
                            onClick={() => {
                              forProfileScreen();
                            }}
                            className="cursor-pointer truncate"
                          >
                            Hi, {userInfo?.username}
                          </p>
                        </Tooltip>
                      ) : (
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            forProfileOpen();
                          }}
                        >
                          Sign in
                        </p>
                      )}
                    </div>

                    {/* <Link to="#" className="text-sm font-style text-gray-700 hover:text-gray-800">
                    Create account
                  </Link> */}
                  </div>

                  {/* <div className="hidden lg:ml-8 lg:flex">
                  <Link to="#" className="flex items-center text-gray-700 hover:text-gray-800">
                    <img
                      src="https://tailwindui.com/img/flags/flag-canada.svg"
                      alt=""
                      className="block h-auto w-5 flex-shrink-0"
                    />
                    <span className="ml-3 block text-sm font-style">CAD</span>
                    <span className="sr-only">, change currency</span>
                  </Link>
                </div> */}

                  {/* Search */}
                  <div className="ml-4 flow-root lg:ml-6 xs:hidden">
                    <div className="group mt-0.5  flex items-center p-2  w-7 h-7 sm:w-8 sm:h-8   justify-center ">
                      <span className="sr-only">Search</span>
                      <MagnifyingGlassIcon
                        className="h-5 w-5 sm:h-7 sm:w-7  flex-shrink-0 text-gray-400 group-hover:text-gray-500 group-hover:hidden"
                        aria-hidden="true"
                      />

                      <div className="mt-32  w-full left-0 absolute  hidden group-hover:block bg-white px-32 py-6 ">
                        <div className="flex justify-center ">
                          <Search />
                        </div>
                      </div>

                      {/* <input className="hidden group-hover:block border -translate-x-[85px]  
                    w-[205px] mt-0.5 mx-1 pl-3 p-1  outline-none border-gray-300 text-gray-900 text-sm
                     rounded-full focus:ring-blue-500 focus:border-blue-500 " value={searchKeyword} 
                     onChange={(e)=>setSearchKeyword(e.target.value)} /> */}
                    </div>
                  </div>

                  {/* Cart */}
                  <div className="ml-4 flow-root lg:ml-6">
                    <div className="flex justify-center items-center ripple w-7 h-7 sm:w-8 sm:h-8">
                      <Tooltip title="Cart" arrow>
                        <Badge
                          badgeContent={cartProductItemsDataBase?.length}
                          onClick={() => forCartOpen()}
                          sx={{
                            "& .MuiBadge-badge": {
                              color: "white",
                              backgroundColor: "black",
                            },
                          }}
                        >
                          <ShoppingBagIcon
                            className="h-5 w-5 sm:h-7 sm:w-7 mt-0.5  flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </Badge>
                      </Tooltip>

                      {/* {cartProductItemsDataBase?.length >= 1 ? (
                      <span
                        onClick={() => forCartOpen()}
                        className="ml-5 mb-1 md:mb-3 text-primary bg-white border shadow-md font-bold rounded-full select-none   flex justify-center items-center w-4 h-4 md:w-5 md:h-5  text-xs absolute "
                      >
                        {cartProductItemsDataBase?.length}
                      </span>
                    ) : (
                      ""
                    )} */}
                      <CartPage
                        forCartOpen={forCartOpen}
                        open={prof.cart}
                        cartIcon={true}
                      />
                      <span className="sr-only">items in cart, view bag</span>
                    </div>
                  </div>

                  {/* profile */}
                  <div className="ml-4 flow-root lg:ml-6 mt-1">
                    {userInfo ? (
                      <>
                        <div className="group inline-block relative">
                          <div className="  flex justify-center items-center ripple w-7 h-7 sm:w-8 sm:h-8 ">
                            <Tooltip title="Your Profile" arrow>
                              <UserIcon
                                className="h-5 w-5 sm:h-7 sm:w-7  flex-shrink-0 text-green-600 group-hover:text-[rgba(0,53,96,0.7)]"
                                onClick={() => forProfileScreen()}
                              />
                            </Tooltip>
                            <ProfileScreen
                              forProfileScreen={forProfileScreen}
                              open={prof.profilescreen}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="group inline-block relative">
                        <div className="  flex justify-center items-center ripple w-7 h-7 sm:w-8 sm:h-8 ">
                          <Tooltip title="Login / Register" arrow>
                            <UserIcon
                              className="h-5 w-5 sm:h-7 sm:w-7  flex-shrink-0 text-gray-400 group-hover:text-[rgba(0,53,96,0.7)]"
                              onClick={() => forProfileOpen()}
                            />
                          </Tooltip>
                          <LoginSlide
                            forProfileOpen={forProfileOpen}
                            open={prof.profile}
                          />
                        </div>
                      </div>
                    )}
                    {/* <Link to="#" className="group -m-2 flex items-center p-2">
                    <UserIcon
                      className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    
                  </Link> */}
                  </div>

                  {/* wishList */}
                  <div className="ml-4 flow-root ">
                    <div className="flex justify-center items-center ripple w-7 h-7 sm:w-8 sm:h-8">
                      <Tooltip title="Wishlist" arrow>
                        <Badge
                          badgeContent={wishItemsDataBase?.length}
                          onClick={() => forWishOpen()}
                          sx={{
                            "& .MuiBadge-badge": {
                              color: "white",
                              backgroundColor: "black",
                            },
                          }}
                        >
                          <HeartIcon
                            className="h-5 w-5 sm:h-7 sm:w-7 mt-0.5  flex-shrink-0 text-gray-400 group-hover:text-gray-500 "
                            aria-hidden="true"
                          />
                        </Badge>
                      </Tooltip>
                      {/* {wishItemsDataBase.length >= 1 ? (
                      <span
                        onClick={() => forWishOpen()}
                        className="ml-4 mb-1 md:mb-3 text-primary bg-white border shadow-md font-bold hidden   rounded-full select-none  h-4 sm:flex justify-center items-center w-4 md:w-5 md:h-5  text-xs absolute "
                      >
                        {wishItemsDataBase?.length}
                      </span>
                    ) : (
                      ""
                    )} */}

                      <Wish
                        forWishOpen={forWishOpen}
                        wishOpen={prof.wishlist}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="hidden   lg:block lg:self-stretch  bg-white border-b-2 border-gray-700 xl:px-12 pt-1 ">
                <div className="flex h-full w-full space-x-4 justify-evenly  -mt-0.5 my-3 ">
                  {category?.map((category, i) => (
                    <div key={i} className="flex">
                      <div key={i}>
                        <div className="relative flex  py-1" key={i}>
                          <div
                            disabled={
                              category.id === Number(categoryID) ? true : false
                            }
                            onClick={() => {
                              navigateCategory(category.id);
                            }}
                            className={`${
                              category.id === Number(categoryID)
                                ? "text-primary rounded-md border-b-2  focus:outline-none font-style font-semibold bg-white border-black  text-xs md:text-sm xl:text-[15px]  px-1   "
                                : "  font-style underline-hover-effect text-black mt-1 xl:mt-0 text-xs xl:text-sm  px-1"
                            }`}
                          >
                            {category.name}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </>
  );
});

export default Header;
