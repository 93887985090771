import React from "react";

function Cancel() {
  return (
    <div className="flex justify-center items-center h-full py-12 flex-wrap font-style">
      <div
        className="w-full  lg:w-[90%] xl:w-[80%]  p-2 sm:mx-5 mx-2  shadow-xl  backdrop-blur-2xl rounded-xl  sm:p-3 lg:px-10  text-primary "
        style={{
          background:
            "linear-gradient(29deg, rgba(227,242,255,0.6) 50%, rgba(249,247,240,0.6) 50%)",
        }}
      >
        <p className="text-xl sm:text-4xl my-7 text-center capitalize  ">
          Cancellation & refund policy{" "}
        </p>
        <div className="my-7 px-2 sm:px-4 text-lg">
          <p className="my-8">No cancellations & Refunds are entertained</p>
          <p className="my-8">
            We gladly accept returns of items category Nightwear's that are
            unworn, unwashed, and in their original packaging within 15 days of
            delivery. In order to receive a full refund, we ask that your
            returned items be intact and free of any signs of wear. Considering
            the intimate nature of our merchandise, Health Code Regulations
            prohibit us from accepting the return of any packaged nightwear that
            have been opened and appear tried or washed.
          </p>
          <p className="my-8">
            For,Underwear, Innerwear and other apparel are labeled as
            non-returnable items & No Exchange due to hygiene concerns.{" "}
          </p>
          <p className="my-8">
            Go for “I have carefully looked into your situation” and “We can't
            issue a refund according to our policy”
          </p>
          <p className="my-8 font-bold">REFUND (IF APPLICABLE)</p>
          <p className="my-8 lowercase">
            ONCE YOUR RETURN IS RECEIVED AND INSPECTED, WE WILL SEND YOU AN
            EMAIL TO NOTIFY YOU THAT WE HAVE RECEIVED YOUR RETURNED ITEM. WE
            WILL ALSO NOTIFY YOU OF THE APPROVAL OR REJECTION OF YOUR REFUND.
          </p>
          <p className="my-8 lowercase">
            IF YOUR REFUND IS APPROVED, THEN YOUR REFUND WILL BE PROCESSED TO
            YOUR ORIGINAL METHOD OF PAYMENT, WITHIN A 7-15 WORKING DAYS.
          </p>
        </div>

        <div className="my-7 px-2 sm:px-4 text-lg">
          <p className="my-8 font-bold">EXCHANGES (IF APPLICABLE)</p>
          <p className="my-8 ">
            We only replace items if the are deffective or damaged. if you need
            to exchange it for same item, send us an email at
            FEEDBACK@MYUNDE.COM
          </p>
          <div className="text-lg xl:text-xl text-start mt-2">
            <p className="mt-8">And send your item to:</p>

            <p className="uppercase text-primary font-bold lg:text-lg mt-5">
              WEBTEK FASHION PRIVATE LIMITED
            </p>

            <p className="text-[15px] lg:text-lg">
              1st Floor,MK plaza, Thoppu thottam,
              <br />
              Solipalayam Road, 15 velampalayam,
              <br />
              Tirpur 641652.
            </p>
          </div>

          <p className="my-8 lowercase">
            YOU WILL BE RESPONSIBLE FOR PAYING FOR YOUR OWN SHIPPING COSTS FOR
            RETURNING YOUR ITEM. SHIPPING COSTS ARE NON-REFUNDABLE. IF YOU
            RECEIVE A REFUND, THE COST OF SHIPPING WILL BE DEDUCTED FROM YOUR
            REFUND. IN EXCHANGE, DEPENDING ON WHERE YOU LIVE, THE TIME IT MAY
            TAKE FOR YOUR EXCHANGED PRODUCT TO REACH YOU, MAY VARY.
          </p>
          <p className="my-8 lowercase">
            IF YOU ARE SHIPPING AN ITEM OVER RS.500, YOU SHOULD CONSIDER USING A
            TRACKABLE SHIPPING SERVICE OR PURCHASING SHIPPING INSURANCE. WE
            DON’T GUARANTEE THAT WE WILL RECEIVE YOUR RETURNED ITEM.
          </p>
          <p className="my-8"> Please contact us for further queries.</p>
        </div>
      </div>
    </div>
  );
}

export default Cancel;

// <p className='my-8 text-xl font-style' >How do I cancel my order?</p>
//     <p className='my-8'>You can cancel your order online before the product has been shipped. Your entire order amount will be refunded. An order cannot be cancelled once the item has been Shipped / delivered to you</p>
//     <p className='my-8'>In order to cancel an order / Product, write an email to feedback@myunde.com with your Order no. and reason to cancel the order. We will be sending a confirmation email for the cancelled order.</p>
