import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { Button } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { createOrder } from "../actions/orderActions";
import { useNavigate } from "react-router-dom";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import { useEffect } from "react";
import { CircularProgress } from "@mui/material";

function RazorPAY({ userInfo, orderState, voucherApplied, couponCode }) {
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);

  const orderCreate = useSelector((state) => state?.orderCreate);
  const { order, success } = orderCreate;

  const [orderData, setOrderData] = useState();
  const [loading, setLoading] = useState(false);

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo?.token}`,
    },
  };

  const sentOrderConfirmation = (orderData) => {
    axios.post(`/api/orders/sendOrderConfirmation/`, { ...orderData }, config);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (order) {
      navigate(`../orderplaced/${order?.id}`, { replace: true });
      dispatch({ type: ORDER_CREATE_RESET });
      if (orderData) {
        sentOrderConfirmation(orderData);
        setOrderData({});
      }
    }
  }, [success]);

  const isPaySuccess = async (response) => {
    await axios
      .post(`/api/payment/razorpay_callback/`, response, config)
      .then((data) => {
        const orderData = {
          orderItems: orderState.cartItemsDataBase,
          shippingAddress: cart.shippingAddress,
          paymentMethod: "Prepaid",
          itemsPrice: cart.itemsPrice,
          deliveryCharge: orderState.deliveryChargePrice,
          // taxPrice: cart.taxPrice,
          totalPrice:
            voucherApplied === 0 ? orderState.checkoutTotal : voucherApplied,
          discountPrice: orderState.discountPrice,
          unique_id: data?.data.payment_object.unique_id,
          payment_status: "paid",
          order_item_count: orderState.cartItemsDataBase?.length,
          payment_id: data?.data.payment_object.payment_id,
          couponApplied: voucherApplied !== 0,
          couponCode: voucherApplied === 0 ? "" : couponCode,
          cod_charge : 0
        };
        if (data?.status === 200) {
          dispatch(createOrder(orderData));
          setOrderData(orderData);

          dispatch({ type: ORDER_CREATE_RESET });
        }
      });
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  const displayRazorpay = async () => {
    setLoading(true);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    const uniqueId = await generateUniqueID();
    // creating a new order
    const result = await axios.post("/api/payment/razorpay_order/", {
      order_id: `${uniqueId[0]}`,
      name: `${userInfo.name}`,
      amount: `${
        voucherApplied === 0 ? orderState.checkoutTotal : voucherApplied
      }`,
      shippingPrice: `${orderState.deliveryChargePrice}`,
    });

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // Getting the order details back
    const {
      merchantId = null,
      amount = null,
      currency = null,
      orderId = null,
    } = result.data;

    const options = {
      key: merchantId,
      amount: amount,
      currency: currency,
      name: userInfo?.name,
      description: "payment for Myunde.com",
      image: "",
      order_id: orderId,
      // callback_url: "http://192.168.0.121:3000/",
      redirect: true,
      prefill: {
        name: result?.data.name,
        email: userInfo?.email,
        contact: userInfo?.number,
      },
      notes:
        // address: cart.shippingAddress,
        "paying for myunde",

      theme: {
        color: "rgb(0,53,96)",
      },
      handler: function (response) {
        isPaySuccess(response);
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
        // removeScript()
      },
    };

    if (result.data.orderId) {
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
    setLoading(false);
  };

  // async function displayRazorpay() {

  // }

  const generateUniqueID = async () => {
    const unique_id = [];
    while (true) {
      const id = uuidv4().toString().split("-").pop();
      const { data } = await axios.get(`/api/orders/check-order-id/${id}/`);
      if (data[0] === "notExist") {
        unique_id.push(id);
        break;
      }
    }
    return unique_id;
  };

  return (
    <div className="App">
      {/* <button onClick={()=>{generateUniqueID()
          }}>push</button> */}
      <header className="App-header">
        <Button
          type="submit"
          onClick={displayRazorpay}
          className="text-center  bg-primary  h-12 flex justify-center items-center text-white text-[15px] font-medium capitalize  rounded-lg  my-8  w-full"
        >
          {loading ? (
            <CircularProgress size={30} sx={{ color: "white" }} />
          ) : (
            <>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-5 h-5 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                />
              </svg>
              pay & place order
            </>
          )}
        </Button>
      </header>
    </div>
  );
}

export default RazorPAY;
