import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

function Faq() {
  return (
    <div className="flex justify-center items-start  h-full flex-wrap  py-12 -mt-5 font-style">
      <div
        className="w-full  lg:w-[80%]  p-2 mx-2 shadow-xl  backdrop-blur-2xl rounded-xl  sm:p-5 lg:px-10  text-primary "
        style={{
          background:
            "linear-gradient(29deg, rgba(227,242,255,0.6) 50%, rgba(249,247,240,0.6) 50%)",
        }}
      >
        <p className="text-xl sm:text-4xl mb-7 text-center capitalize  ">
          Frequently asked questions
        </p>

        <div className="my-5">
          <Accordion
            className=" w-full   border-b"
            style={{
              boxShadow: "none",
              backgroundColor: "rgba(52, 52, 52, 0.1)",
            }}
          >
            <AccordionSummary
              expandIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 md:w-6 md:h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              }
              className="flex w-full justify-between "
            >
              <p className="font-style text-lg sm:text-2xl  text-primary ">
                Do I need to create an account on Myunde to place an order?
              </p>
            </AccordionSummary>

            <AccordionDetails className=" text-lg text-primary ">
              Yes, you need to register on the site to place an order.
            </AccordionDetails>
          </Accordion>

          <Accordion
            className=" w-full   border-b"
            style={{
              boxShadow: "none",
              backgroundColor: "rgba(52, 52, 52, 0.1)",
            }}
          >
            <AccordionSummary
              expandIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 md:w-6 md:h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              }
              className="flex w-full justify-between "
            >
              <p className="font-style text-lg sm:text-2xl  text-primary ">
                What are the payment options that I can avail of while placing
                an order?
              </p>
            </AccordionSummary>

            <AccordionDetails className=" text-lg text-primary ">
              Currently, the following payment options are enabled.
              <br />
              Credit Cards ( Visa / Master) <br />
              Net Banking across Banks <br />
              Debit Cards (Visa / Master)
              <br />
              American Express
              <br />
            </AccordionDetails>
          </Accordion>
          <Accordion
            className=" w-full   border-b"
            style={{
              boxShadow: "none",
              backgroundColor: "rgba(52, 52, 52, 0.1)",
            }}
          >
            <AccordionSummary
              expandIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 md:w-6 md:h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              }
              className="flex w-full justify-between "
            >
              <p className="font-style text-lg sm:text-2xl  text-primary ">
                where can i find you ?
              </p>
            </AccordionSummary>

            <AccordionDetails className=" text-lg text-primary ">
              No:2 , first floor , Mk plaza, Thoppu thottam solipalayam road ,
              15 velampalayam Tirupur , 641652 - Tamilnadu
            </AccordionDetails>
          </Accordion>
          <Accordion
            className=" w-full   border-b"
            style={{
              boxShadow: "none",
              backgroundColor: "rgba(52, 52, 52, 0.1)",
            }}
          >
            <AccordionSummary
              expandIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 md:w-6 md:h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              }
              className="flex w-full justify-between "
            >
              <p className="font-style text-lg sm:text-2xl  text-primary ">
                How might I get in touch with you?
              </p>
            </AccordionSummary>

            <AccordionDetails className=" text-lg text-primary ">
              follow us on our social media platforms
            </AccordionDetails>
          </Accordion>
          <Accordion
            className=" w-full   border-b"
            style={{
              boxShadow: "none",
              backgroundColor: "rgba(52, 52, 52, 0.1)",
            }}
          >
            <AccordionSummary
              expandIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 md:w-6 md:h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              }
              className="flex w-full justify-between "
            >
              <p className="font-style text-lg sm:text-2xl  text-primary ">
                What materials and fabrics do you use ?
              </p>
            </AccordionSummary>

            <AccordionDetails className=" text-lg text-primary ">
              fabrics mentioned in filter
            </AccordionDetails>
          </Accordion>
          <Accordion
            className=" w-full   border-b"
            style={{
              boxShadow: "none",
              backgroundColor: "rgba(52, 52, 52, 0.1)",
            }}
          >
            <AccordionSummary
              expandIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 md:w-6 md:h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              }
              className="flex w-full justify-between "
            >
              <p className="font-style text-lg sm:text-2xl  text-primary ">
                How do i know what size to get ?
              </p>
            </AccordionSummary>

            <AccordionDetails className=" text-lg text-primary ">
              we have size chart you can see on prouct screen and also we have
              how to measuring yourself which is very helful to you
            </AccordionDetails>
          </Accordion>
          <Accordion
            className=" w-full   border-b"
            style={{
              boxShadow: "none",
              backgroundColor: "rgba(52, 52, 52, 0.1)",
            }}
          >
            <AccordionSummary
              expandIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 md:w-6 md:h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              }
              className="flex w-full justify-between "
            >
              <p className="font-style text-lg sm:text-2xl  text-primary ">
                Can i cancel my order ?
              </p>
            </AccordionSummary>

            <AccordionDetails className=" text-lg text-primary ">
              Nope, once you ordered you cant cancel any orders
            </AccordionDetails>
          </Accordion>
          <Accordion
            className=" w-full   border-b"
            style={{
              boxShadow: "none",
              backgroundColor: "rgba(52, 52, 52, 0.1)",
            }}
          >
            <AccordionSummary
              expandIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 md:w-6 md:h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
              }
              className="flex w-full justify-between "
            >
              <p className="font-style text-lg sm:text-2xl  text-primary ">
                What are you selling?
              </p>
            </AccordionSummary>

            <AccordionDetails className=" text-lg text-primary ">
              UnderGarments
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Faq;
