import axios from 'axios'
import {
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,

    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,

    PRODUCT_CREATE_REVIEW_REQUEST,
    PRODUCT_CREATE_REVIEW_SUCCESS,
    PRODUCT_CREATE_REVIEW_FAIL,

    PRODUCT_REVIEW_REQUEST,
    PRODUCT_REVIEW_SUCCESS,
    PRODUCT_REVIEW_FAIL,

    PRODUCT_TOP_REQUEST,
    PRODUCT_TOP_SUCCESS,
    PRODUCT_TOP_FAIL,

    FILTER_LIST_REQUEST,
    FILTER_LIST_SUCCESS,
    FILTER_LIST_FAIL,

    CATEGORY_LIST_REQUEST,
    CATEGORY_LIST_SUCCESS,
    CATEGORY_LIST_FAIL,

    BRAND_LIST_REQUEST,
    BRAND_LIST_SUCCESS,
    BRAND_LIST_FAIL,
    
    STYLES_LIST_REQUEST,
    STYLES_LIST_SUCCESS,
    STYLES_LIST_FAIL,

    FABRICS_LIST_REQUEST,
    FABRICS_LIST_SUCCESS,
    FABRICS_LIST_FAIL,

    COLORS_LIST_REQUEST,
    COLORS_LIST_SUCCESS,
    COLORS_LIST_FAIL,

    SIZES_LIST_REQUEST,
    SIZES_LIST_SUCCESS,
    SIZES_LIST_FAIL,

    SIZE_LIST_REQUEST,
    SIZE_LIST_SUCCESS,
    SIZE_LIST_FAIL,

    PRODUCTITEMS_LIST_REQUEST,
    PRODUCTITEMS_LIST_SUCCESS,
    PRODUCTITEMS_LIST_FAIL,

    PRODUCTITEM_LIST_REQUEST,
    PRODUCTITEM_LIST_SUCCESS,
    PRODUCTITEM_LIST_FAIL,

    WISHPRODUCTITEM_LIST_REQUEST,
    WISHPRODUCTITEM_LIST_SUCCESS,
    WISHPRODUCTITEM_LIST_FAIL,

    ITEMIMAGES_LIST_REQUEST,
    ITEMIMAGES_LIST_SUCCESS,
    ITEMIMAGES_LIST_FAIL,

    CAMPAIGNIMAGES_LIST_REQUEST,
    CAMPAIGNIMAGES_LIST_SUCCESS,
    CAMPAIGNIMAGES_LIST_FAIL,

    PRODUCTOFFERS_LIST_REQUEST,
    PRODUCTOFFERS_LIST_SUCCESS,
    PRODUCTOFFERS_LIST_FAIL,

} from '../constants/productConstants'


export const listProducts = (keyword = '' ) => async (dispatch) => {
    try {
        dispatch({ type: PRODUCT_LIST_REQUEST })
        const { data } = await axios.get(`/api/products${keyword}&depth=true`)
        dispatch({
            type: PRODUCT_LIST_SUCCESS,
            payload: data 
        })
    } catch (error) {
        dispatch({
            type: PRODUCT_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listTopProducts = (categoryId="", brandId="") => async (dispatch) => {
    try {
        dispatch({ type: PRODUCT_TOP_REQUEST })

        const { data } = categoryId || brandId 
        ? await axios.get(`/api/products/top/?categoryId=${categoryId}&brandId=${brandId}`) 
        :""
        dispatch({
            type: PRODUCT_TOP_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_TOP_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listProductDetails = (id="") => async (dispatch) => {
    try {
        dispatch({ type: PRODUCT_DETAILS_REQUEST })

        const { data } = await axios.get(`/base/api/product/${id}`)

        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}




export const createProductReview = (productId, review) => async (dispatch, getState) => {
    try {
        dispatch({
            type: PRODUCT_CREATE_REVIEW_REQUEST
        })

        const {userLogin: { userInfo }} = getState()
        const config = {
            headers: {
                'Content-type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(
            `/api/products/${productId}/reviews/`,
            review,
            config
        )
        
        dispatch({
            type: PRODUCT_CREATE_REVIEW_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_CREATE_REVIEW_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listProductReviews = (productId) => async (dispatch) => {
    try {
        dispatch({ type: PRODUCT_REVIEW_REQUEST })

        const { data } = await axios.get(`/api/products/review/${productId}`)

        dispatch({
            type: PRODUCT_REVIEW_SUCCESS,
            payload: data.reviews,
        })

    } catch (error) {
        dispatch({
            type: PRODUCT_REVIEW_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



export const listFilter = (categoryId="", brandId="") => async (dispatch) => {
    try {
        dispatch({ type: FILTER_LIST_REQUEST })

        const { data } = categoryId ? await axios.get(`/base/api/filter?categoryId=${categoryId}`) :
        await axios.get(`/base/api/filter?brandId=${brandId}`)
      
        dispatch({
            type: FILTER_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: FILTER_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



export const listCategories = ( ) => async (dispatch) => {
    try {
        dispatch({ type: CATEGORY_LIST_REQUEST })

        const { data } = await axios.get(`/base/api/categories`)

        dispatch({
            type: CATEGORY_LIST_SUCCESS,
            payload: data,
        })

    } catch (error) {
        dispatch({
            type: CATEGORY_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listBrands = () => async (dispatch) => {
    try {
        dispatch({ type: BRAND_LIST_REQUEST })

        const { data } = await axios.get(`/base/api/brands`)

        dispatch({
            type: BRAND_LIST_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: BRAND_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listStyles = ( id="") => async (dispatch) => {
    try {
        dispatch({ type: STYLES_LIST_REQUEST })

        const { data } = id ? await axios.get(`/base/api/styles/?categoryId=${id}`)
        : await axios.get(`/base/api/styles`)

        dispatch({
            type: STYLES_LIST_SUCCESS,
            payload: data 
            
        })
        
      
    } catch (error) {
        dispatch({
            type: STYLES_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listFabrics = ( ) => async (dispatch) => {
    try {
        dispatch({ type: FABRICS_LIST_REQUEST })

        const { data } = await axios.get(`/base/api/fabrics`)

        dispatch({
            type: FABRICS_LIST_SUCCESS,
            payload: data 
            
        })
        
      
    } catch (error) {
        dispatch({
            type: FABRICS_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listColors = (id) => async (dispatch) => {
    try {
        dispatch({ type: COLORS_LIST_REQUEST })

        const { data } = await axios.get(`/base/api/colors`)
        const { data: soloColor } = id ? await axios.get(`/base/api/colors/?productId=${id}`):""

        dispatch({
            type: COLORS_LIST_SUCCESS,
            payload: [data, soloColor]
        })
        
        
      
    } catch (error) {
        dispatch({
            type: COLORS_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



export const listSizes = (categoryId="", ProductId="" ) => async (dispatch) => {
    try {
        dispatch({ type: SIZES_LIST_REQUEST })

        const { data } = categoryId ? await axios.get(`/base/api/sizes/?categoryId=${categoryId}`) 
        : ""
        const {data: soloSize} = ProductId ? await axios.get(`/base/api/sizes/?productId=${ProductId}`) :""

        // const {data:data} = await axios.get(`/base/api/sizes`) 


        dispatch({
            type: SIZES_LIST_SUCCESS,
            payload: [data , soloSize] 
            
        })
        
      
    } catch (error) {
        dispatch({
            type: SIZES_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listSize = (id="" ) => async (dispatch) => {
    try {
        dispatch({ type: SIZE_LIST_REQUEST })

        const { data } = id ? await axios.get(`/base/api/sizes/?categoryId=${id}`) 
        : await axios.get(`/base/api/sizes`)

        dispatch({
            type: SIZE_LIST_SUCCESS,
            payload: data 
            
        })
        
      
    } catch (error) {
        dispatch({
            type: SIZE_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}



export const listProductItems = (id="" ) => async (dispatch) => {
  
    try {
        dispatch({ type: PRODUCTITEMS_LIST_REQUEST })

        const { data } = id ? await axios.get(`/base/api/product-items/?productId=${id}`) 
        : await axios.get(`/base/api/product-items/`)

        dispatch({
            type: PRODUCTITEMS_LIST_SUCCESS,
            payload: data 
            
        })
        
      
    } catch (error) {
        dispatch({
            type: PRODUCTITEMS_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listProductItem = (productId="", colorId="") => async (dispatch) => {
   
    try {
        dispatch({ type: PRODUCTITEM_LIST_REQUEST })

        const { data } = colorId ? await axios.get(`/base/api/product-items/?productId=${productId}&colorId=${colorId}`) 
        : await axios.get(`/base/api/product-items/?productId=${productId}`)

        dispatch({
            type: PRODUCTITEM_LIST_SUCCESS,
            payload: data 
            
        })
        
      
    } catch (error) {
        dispatch({
            type: PRODUCTITEM_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const listWishProductItem = (productId="", colorId="") => async (dispatch) => {

  
    try {
        dispatch({ type: WISHPRODUCTITEM_LIST_REQUEST})

        const { data } = colorId ? await axios.get(`/base/api/product-items/?productId=${productId}&colorId=${colorId}`) 
        : await axios.get(`/base/api/product-items/?productId=${productId}`)

        dispatch({
            type: WISHPRODUCTITEM_LIST_SUCCESS,
            payload: data 
            
        })
        
      
    } catch (error) {
        dispatch({
            type: WISHPRODUCTITEM_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}




export const listItemImages = ( id="" ) => async (dispatch) => {
    try {
        dispatch({ type: ITEMIMAGES_LIST_REQUEST })

        const { data } = id ? await axios.get(`/base/api/item-images/?productId=${id}`) 
        : await axios.get(`/base/api/item-images`)

        dispatch({
            type: ITEMIMAGES_LIST_SUCCESS,
            payload: data 
            
        })
        return data
      
    } catch (error) {
        dispatch({
            type: ITEMIMAGES_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listProductOffers = ( ) => async (dispatch) => {
    try {
        dispatch({ 
            type: PRODUCTOFFERS_LIST_REQUEST })

        const { data } = await axios.get(`/base/api/product-offers?depth=true`)

        dispatch({
            type: PRODUCTOFFERS_LIST_SUCCESS,
            payload: data 
            
        })
    } catch (error) {
        dispatch({
            type: PRODUCTOFFERS_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}


export const listCampaignImages = () => async (dispatch) => {
    try {
        dispatch({ type: CAMPAIGNIMAGES_LIST_REQUEST
         })

        const { data } = await axios.get(`/base/api/campaign-images/?depth=true`) 
        

        dispatch({
            type: CAMPAIGNIMAGES_LIST_SUCCESS,
            payload: data 
            
        })
        
      
    } catch (error) {
        dispatch({
            type: CAMPAIGNIMAGES_LIST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}