export const BLOG_CATEGORY_REQUEST = 'BLOG_CATEGORY_REQUEST'
export const BLOG_CATEGORY_SUCCESS = 'BLOG_CATEGORY_SUCCESS'
export const BLOG_CATEGORY_FAIL = 'BLOG_CATEGORY_FAIL'

export const BLOGS_REQUEST = 'BLOGS_REQUEST'
export const BLOGS_SUCCESS = 'BLOGS_SUCCESS'
export const BLOGS_FAIL = 'BLOGS_FAIL'

export const BLOG_CONTENT_REQUEST = 'BLOG_CONTENT_REQUEST'
export const BLOG_CONTENT_SUCCESS = 'BLOG_CONTENT_SUCCESS'
export const BLOG_CONTENT_FAIL = 'BLOG_CONTENT_FAIL'

