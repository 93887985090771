import React from "react";

function Message({ variant, children }) {
  return { children } ? (
    <div className="mt-10 font-medium text-center text-red-700 flex justify-center ">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-5 h-5 mt-0.5 mx-1"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
        />
      </svg>
      {children}
    </div>
  ) : (
    <div
      class="p-4 font-medium text-center text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800 select-none"
      role="alert"
      variant={variant}
    >
      <span class="font-medium">Danger alert!</span> Change a few things up and
      try submitting again. <br />
      <br />
    </div>
  );
}

export default Message;
