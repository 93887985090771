import React from "react"; // react import 
import { useForm } from "react-hook-form"; // useform imported from react hook 
import { AiOutlineClose } from "react-icons/ai"; // 
import axios from "axios";
import { Dialog } from "@mui/material";

function Contact({ forContact, openContact }) {

  const {register,formState: { errors },handleSubmit,reset,} = useForm();

const onSubmit = async (data) => {
    const formData = new FormData();
    Object.assign(data);
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("message", data.message);
    forContact();
    await axios.post(`/api/users/contactus/`, formData);
    reset();
};

  return (
    <Dialog
      open={openContact}
      transitionDuration={700}
      onClose={forContact}
      maxWidth={"xl"}
    >
      <div className="flex justify-center items-center flex-wrap  ">
        <div className="w-full sticky top-0 flex flex-wrap justify-between items-center text-gray-800 p-3  rounded-t-lg shadow-md  bg-gray-100 ">
          <p className="text-lg  sm:text-xl font-bold text-gray-800 ">
            Contact us
          </p>

          <AiOutlineClose
            className="w-6 h-6 hover:scale-75  "
            onClick={() => forContact()}
          />
        </div>
        <div
          className="w-full    sm:mt-2    rounded-xl  sm:p-3 lg:px-10  text-primary "
          style={{
            background:
              "linear-gradient(49deg, rgba(227,242,255,0.7) 0%, rgba(249,247,240,0.7) 87%)",
          }}
        >
          <div className="flex justify-evenly  flex-wrap sm:my-8 ">
            <div
              className=" w-full mt-6 lg:mt-0 lg:w-[48%] m-2 md:shadow-md  flex md:justify-start items-start rounded-xl"
              style={{
                background:
                  "linear-gradient(29deg, rgba(227,242,255,0.6) 50%, rgba(249,247,240,0.6) 50%)",
              }}
            >
              <div className="font-medium w-full p-2 sm:p-4 ">
                <p className="text-xl sm:text-4xl sm:my-8 md:mb-16 sm:ml-12">
                  Contact info
                </p>
                <div className="flex justify-center   w-full ">
                  <div className="text-sm sm:text-lg">
                    You may contact us using the information below:
                    <div className="flex items-center my-8">
                      <div className="lg:p-3 p-2 rounded-full bg-[rgb(0,53,96,0.3)] flex justify-center items-center mr-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="rgb(0,53,96)"
                          className="w-4 h-4 sm:w-6 sm:h-6  "
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                          />
                        </svg>
                      </div>
                      <p className=" sm:text-xl text-[rgb(0,53,96)]">
                        +91 95141 00444
                      </p>
                    </div>
                    <div className="flex items-center sm:my-8">
                      <div className="lg:p-3 p-2 rounded-full bg-[rgb(0,53,96,0.3)] flex justify-center items-center mr-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="rgb(0,53,96)"
                          className="w-4 h-4 sm:w-6 sm:h-6  "
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                          />
                        </svg>
                      </div>
                      <p className="sm:text-xl text-[rgb(0,53,96)]">
                        myunde.com@gmail.com
                      </p>
                    </div>
                    <div className="flex items-start my-8">
                      <div className="lg:p-3 p-2 rounded-full bg-[rgb(0,53,96,0.3)] flex justify-center items-center mr-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="rgb(0,53,96)"
                          className="w-4 h-4 sm:w-6 sm:h-6  "
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                          />
                        </svg>
                      </div>

                      <div className=" sm:text-lg xl:text-xl text-start mt-2">
                        <p className="uppercase text-primary font-bold ">
                          MYUNDE.COM
                        </p>

                        <p className="text-[15px] lg:text-lg">
                          1st Floor,MK plaza, Thoppu thottam,
                          <br />
                          Solipalayam Road, 15 velampalayam,
                          <br />
                          Tirpur 641652.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" w-full lg:w-[48%] sm:mt-6 lg:mt-0 p-4 font-medium text-xl capitalize flex md:justify-center">
              <div className=" ">
                <p className="text-xl sm:text-4xl">Contact us </p>
                <p className="text-sm sm:text-lg mt-4 ">
                  Feel Free to contact us any time. We will get back to you as
                  soon as we can!.
                </p>
                <div className="flex justify-center">
                  <div className="w-full 2xl:w-[80%] ">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="relative mt-8 sm:mt-14 ">
                        <input
                          name="name"
                          type="text"
                          {...register("name", { required: true })}
                          aria-invalid={errors.name ? "true" : "false"}
                          placeholder=" "
                          id="name"
                          className={` block px-2.5 pb-2.5 appearance-none ${
                            errors.name ? "border-red-300 border-2" : ""
                          }  focus:outline-none focus:ring-0  pt-4 w-full text-[15px] text-gray-900  rounded-lg peer `}
                        />
                        <label
                          htmlFor="name"
                          className="absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                        >
                          Name
                        </label>
                      </div>

                      <div className="relative mt-10">
                        <input
                          name="email"
                          type="email"
                          {...register("email", {
                            required: "Email Address is required",
                          })}
                          aria-invalid={errors.email ? "true" : "false"}
                          placeholder=" "
                          id="email"
                          className={`  block px-2.5 ${
                            errors.email
                              ? "border-red-300 border-2"
                              : "border-gray-300 "
                          } pb-2.5 appearance-none  focus:outline-none focus:ring-0  pt-4 w-full text-[15px] text-gray-900  rounded-lg peer `}
                        />
                        <label
                          htmlFor="email"
                          className="absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                        >
                          Email
                        </label>
                      </div>

                      <div className="relative mt-10">
                        <textarea
                          name="message"
                          placeholder=" "
                          {...register("message", { required: true })}
                          aria-invalid={errors.message ? "true" : "false"}
                          id="message"
                          className={`  block px-2.5 pb-2.5 ${
                            errors.message && "border-red-300 border-2"
                          }  appearance-none  focus:outline-none focus:ring-0 pt-4 w-full text-[15px] text-gray-900  rounded-lg peer `}
                        />
                        <label
                          htmlFor="message"
                          className="absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                        >
                          Message
                        </label>
                      </div>

                      <button
                        type="submit"
                        className="text-center bg-primary  h-14 flex justify-center items-center text-white text-[15px] font-medium capitalize  rounded-lg  my-8  w-full"
                        // onClick={() => loginSubmit()}
                      >
                        Send
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default Contact;

// <section className="contact_us">
// <div className="container">
//     <div className="row">
//         <div className="col-md-10 offset-md-1">
//             <div className="contact_inner">
//                 <div className="row">
//                     <div className="col-md-10">
//                         <div className="contact_form_inner">
//                             <div className="contact_field">
//                                 <h3>Contatc Us</h3>
//                                 <p>Feel Free to contact us any time. We will get back to you as soon as we can!.</p>
//                                 <input type="text" className="form-control form-group" placeholder="Name" />
//                                 <input type="text" className="form-control form-group" placeholder="Email" />
//                                 <textarea className="form-control form-group" placeholder="Message"></textarea>
//                                 <button className="contact_form_submit">Send</button>
//                             </div>
//                         </div>
//                     </div>
//                     <div className="col-md-2">
//                         <div className="right_conatct_social_icon d-flex align-items-end">
//                            <div className="socil_item_inner d-flex">
//                               <li><Link to={"#"}><i className="fab fa-facebook-square"></i></Link></li>
//                               <li><Link to={"#"}><i className="fab fa-instagram"></i></Link></li>
//                               <li><Link to={"#"}><i className="fab fa-twitter"></i></Link></li>
//                            </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="contact_info_sec">
//                     <h4>Contact Info</h4>
//                     <div className="d-flex info_single align-items-center">
//                         <i className="fas fa-headset"></i>
//                         <span>+91 8009 054294</span>
//                     </div>
//                     <div className="d-flex info_single align-items-center">
//                         <i className="fas fa-envelope-open-text"></i>
//                         <span>info@flightmantra.com</span>
//                     </div>
//                     <div className="d-flex info_single align-items-center">
//                         <i className="fas fa-map-marked-alt"></i>
//                         <span>1000+ Travel partners and 65+ Service city across India, USA, Canada & UAE</span>
//                     </div>

//                 </div>
//             </div>
//         </div>
//     </div>
// </div>
// </section>

// <section className="map_sec">
// <div className="container">
//     <div className="row">
//         <div className="col-md-10 offset-md-1">
//             <div className="map_inner">
//                 <h4>Find Us on Google Map</h4>
//                 <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quo beatae quasi assumenda, expedita aliquam minima tenetur maiores neque incidunt repellat aut voluptas hic dolorem sequi ab porro, quia error.</p>
//                 <div className="map_bind">
//                     <iframe style={{Width:"100%" , Height:"450"}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d471220.5631094339!2d88.04952462217592!3d22.6757520733225!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f882db4908f667%3A0x43e330e68f6c2cbc!2sKolkata%2C%20West%20Bengal!5e0!3m2!1sen!2sin!4v1596988408134!5m2!1sen!2sin"
//                      frameBorder="0"  allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
//                 </div>
//             </div>
//         </div>
//     </div>
// </div>
// </section>
