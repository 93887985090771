import axios from "axios";
import React, { useState } from "react";
import cancel from "../images/cancel-order.jpg";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Button } from "@material-tailwind/react";
import LoginSlide from "./LoginSlide";

function TrackOrder() {
  const [trackDetail, setTrackDetail] = useState({});
  const [id, setId] = useState("");

  const orderStatus = () => {
    let orderPlaced = "";
    let orderProcessing = "";
    let orderDispatched = "";
    let outForDelivery = "";
    let orderDelivered = "";

    const switchValue =
      Object.keys(trackDetail).length &&
      trackDetail?.shipment_track[0]?.current_status;

    switch (switchValue) {
      case "Delivered":
        orderPlaced = "green";
        orderProcessing = "green";
        orderDispatched = "green";
        outForDelivery = "green";
        orderDelivered = "green";
        break;
      case "Out for Delivery":
        orderPlaced = "green";
        orderProcessing = "green";
        orderDispatched = "green";
        outForDelivery = "orange";
        orderDelivered = "gray-500";
        break;
      case "Shipped":
        orderPlaced = "green";
        orderProcessing = "green";
        orderDispatched = "green";
        outForDelivery = "orange";
        orderDelivered = "gray-500";
        break;
      case "In Transit":
        orderPlaced = "green";
        orderProcessing = "green";
        orderDispatched = "orange";
        outForDelivery = "gray-500";
        orderDelivered = "gray-500";
        break;
      case "Pickup Generated":
        orderPlaced = "green";
        orderProcessing = "orange";
        orderDispatched = "gray-500";
        outForDelivery = "gray-500";
        orderDelivered = "gray-500";
        break;
      default:
        orderPlaced = "green";
        orderProcessing = "gray-500";
        orderDispatched = "gray-500";
        outForDelivery = "gray-500";
        orderDelivered = "gray-500";
    }

    return {
      orderPlaced,
      orderProcessing,
      orderDispatched,
      outForDelivery,
      orderDelivered,
    };
  };

  const trackOrderProgress = orderStatus();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const [state, setState] = useState(false);
  const forLogin = () => {
    setState(!state);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm();

  const onSubmit = async (orderData) => {
    const orderId = orderData.orderId;
    const data = await axios
      .get(`/api/shipping/order-tracking/?orderId=${orderId}`)
      .then((Response) => {
        console.log(Response)
        if(Response.data.length){
          setTrackDetail(Response.data[0].tracking_data);
          setId(orderId)
        }else{
          setError("orderId", {
            message: "There is no tracking detail in given id",
          });
        }
      })
      .catch((error) => {
        setError("orderId", {
          message: "There is no tracking detail in given id",
        });
        console.log(error, "error");
      });
  };

 

  return (
    <div
      className=" md:px-16 w-full  py-5 font-style "
      style={{
        backgroundImage: `url(${require("../images/map2.jpg")})`,
        backgroundSize: "1920px 1080px",
      }}
    >
      <div className="  flex  flex-wrap justify-center m-2 ">
        <div className=" w-full  md:w-[600px] lg:w-[700px]  sm:px-4 p-3 h-full md:px-9    lg:mx-4 my-2 ">
          <div className=" ">
            <div className="flex  items-center justify-center ">
              <p className="text-2xl   font-style capitalize">
                track your order
              </p>
            </div>
          </div>

          {!Object.keys(trackDetail).length ? (
            <>
              {!userInfo && (
                <LoginSlide forProfileOpen={forLogin} open={state} />
              )}
              <form
                onSubmit={userInfo ? handleSubmit(onSubmit) : () => forLogin()}
                className="p-8 max-w-96 h-96"
              >
                <p className="text-center text-primary pb-12">
                  (Here is an input field to track your order
                  <br /> details with your order ID)
                </p>
                <div className=" relative">
                  <input
                    type="text"
                    {...register("orderId", {
                      required: {
                        value: true,
                        message: "This field is required",
                      },

                      minLength: {
                        value: 9,
                        message: "order id must be atleast 10 digits or above.",
                      },
                      pattern: {
                        value: /^[0-9]+$/,
                        message: "order id must be contain only numbers",
                      },
                    })}
                    aria-invalid={errors.orderId ? "true" : "false"}
                    id="orderId"
                    className={`block px-2.5 pb-2.5  font-style pt-4 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                      errors.orderId
                        ? "border-red-300 border-2"
                        : "border-gray-300 border"
                    } appearance-none  focus:outline-none focus:ring-0  peer`}
                    placeholder=" "
                  />

                  <label
                    htmlFor="orderId"
                    className=" after:content-['*'] after:ml-1 after:text-red-500  absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                  >
                    order Id
                  </label>
                </div>
                {errors.orderId && (
                  <p className="text-sm m-1 text-red-500">
                    {errors.orderId.message}
                  </p>
                )}
                <div className=" flex justify-end items-end">
                  <Button
                    className="text-center w-44 bg-primary h-12 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg  my-4 "
                    type="submit"
                  >
                    track here
                  </Button>
                </div>
              </form>
            </>
          ) : (
            <>
              {Object.keys(trackDetail)?.length &&
              trackDetail?.shipment_track[0]?.current_status === "Canceled" ? (
                <div className="flex justify-center items-center h-[90%]">
                  <div className="">
                    <img src={cancel} alt="cancel" className=" " />
                    <div className="flex justify-center w-full">
                      <p className="text-center text-gray-700 capitalize text-xl font-style w-64">
                        its seems your order has been cancelled !!
                      </p>
                    </div>
                    <div className="flex justify-center mt-4">
                      <Button
                        //   onClick={() => forProfileScreenHandle()}
                        className="text-center w-1/2 bg-primary h-12 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg  my-4   "
                      >
                        shop now
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="p-4  font-style text-gray-700 text-sm">
                  {Object.keys(trackDetail).length &&
                  trackDetail?.shipment_track[0]?.current_status ===
                    "Delivered" ? (
                    <div>
                      <p className="text-center capitalize font-bold ">
                        order deliverd date
                      </p>
                      <p className="text-center capitalize text-xl text-[rgb(0,56,93)]">
                        {new Date(
                          trackDetail?.shipment_track[0]?.delivered_date
                        ).toDateString()}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <p className="text-center capitalize font-bold ">
                        Estimated Delivery Date
                      </p>
                      <p className="text-center capitalize text-xl text-[rgb(0,56,93)]">
                        {new Date(trackDetail?.etd).toDateString()}
                      </p>
                    </div>
                  )}

                  <div
                    className=" rounded-xl  shadow-lg border  my-5 bg-white/20 backdrop-blur-[5px] "
                    // style={{
                    //   backgroundImage: `url(${require("../images/map2.jpg")})`,
                    //   backgroundSize: "1920px 1080px",
                    // }}
                  >
                    <div className="flex justify-between items-end border-b p-4 bg-gray-100">
                      <p className="text-lg capitalize">Order status </p>
                      <p>
                        Order# {id}
                        {/* {trackDetail?.map((orderitem, i) => {
                          return (
                            <span className="text-[rgb(0,56,93)]" key={i}>
                              {orderitem?.id}
                            </span>
                          );
                        })} */}
                      </p>
                    </div>
                    <div className="p-3 font-style flex justify-center capitalize">
                      <div>
                        <div className="my-10 flex items-center">
                          <p
                            className={` bg-${trackOrderProgress?.orderPlaced}  h-10 w-10 flex items-center justify-center rounded-full mr-2`}
                          >
                            <svg
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="white"
                              className="w-6 h-6  "
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                              />
                            </svg>
                          </p>
                          <div
                            className={`text-${trackOrderProgress?.orderPlaced} text-lg ml-5`}
                          >
                            order placed
                            {/* {trackDetail?.map((orderDetail, i) => {
                              return (
                                <p className="text-xs text-gray-600 " key={i}>
                                  we have received your order on
                                  <span className="">
                                    {new Date(
                                      orderDetail?.createdAt
                                    ).toLocaleDateString()}
                                  </span>
                                </p>
                              );
                            })} */}
                          </div>
                        </div>

                        <div className="my-10 flex items-center">
                          <p
                            className={`bg-${trackOrderProgress?.orderProcessing}  h-10 w-10 flex items-center justify-center rounded-full mr-2`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="white"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </p>
                          <div
                            className={`text-${trackOrderProgress?.orderProcessing} text-lg ml-5`}
                          >
                            order processing
                            <p className="text-xs text-gray-600 ">
                              we are preparing for your order
                            </p>
                          </div>
                        </div>
                        <div className="my-10 flex items-center">
                          <p
                            className={`bg-${trackOrderProgress?.orderDispatched} h-10 w-10 flex items-center justify-center rounded-full mr-2`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="white"
                              className="w-6 h-6 "
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                              />
                            </svg>
                          </p>
                          <div
                            className={`text-${trackOrderProgress?.orderDispatched} text-lg ml-5`}
                          >
                            order dispatched
                            <p className="text-xs text-gray-600 ">
                              your order is ready for shipping
                            </p>
                          </div>
                        </div>
                        <div className="my-10 flex items-center">
                          <p
                            className={`bg-${trackOrderProgress?.outForDelivery} h-10 w-10 flex items-center justify-center rounded-full mr-2`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="white"
                              className="w-6 h-6 "
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                              />
                            </svg>
                          </p>

                          <div
                            className={`text-${trackOrderProgress?.outForDelivery} text-lg ml-5`}
                          >
                            out for delivery
                            <p className="text-xs text-gray-600">
                              your order is out for delivery
                            </p>
                          </div>
                        </div>
                        <div className="my-10 flex items-center">
                          <p
                            className={`bg-${trackOrderProgress?.orderDelivered} h-10 w-10 flex items-center justify-center rounded-full mr-2`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="white"
                              className="w-6 h-6 "
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                              />
                            </svg>
                          </p>
                          <div
                            className={`text-${trackOrderProgress?.orderDelivered} text-lg ml-5`}
                          >
                            order delivered
                            <p className="text-xs text-gray-600 ">
                              your order delivered
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-center text-primary">
                    <a
                      href={`${trackDetail?.track_url}`}
                      rel="noreferrer"
                      target="_blank"
                    >
                      see more tracking details
                    </a>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TrackOrder;
