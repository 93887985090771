import React, { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { saveShippingAddress } from "../actions/cartActions"
import { useLocation, useNavigate } from "react-router-dom"
import { ExclamationCircleIcon, UserIcon } from "@heroicons/react/24/outline"
import { Button } from "@material-tailwind/react"
import { updateUserProfile } from "../actions/userActions"
import { useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { Helmet } from "react-helmet"
import { Tooltip } from "@mui/material"
import axios from "axios"

function ShippingPage() {
  const stateOptions = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Jammu and Kashmir",
    "Ladakh",
    "Puducherry",
  ]

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { state } = useLocation()

  const brandList = useSelector((state) => state.brandList)
  const { brand } = brandList // get value of brands from redux

  const deliveryChargePrice = state?.checkoutTotal > 599 ? 0 : 49

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productoffer = useSelector((state) => state.productoffers)
  const { productoffers } = productoffer

  const addressSplit = userInfo?.Address?.split("*--*") ?? []

  const {
    register,
    formState: { errors },
    control,
    setValue,
    setError,
    handleSubmit,
  } = useForm({
    defaultValues: {
      name: "",
      number: "",
      email: "",
      houseNo: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
    },
  })

  const regexEmail =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  useEffect(() => {
    if (Object.keys(userInfo).length) {
      if (userInfo.name !== "UnKnown") {
        setValue("name", userInfo.name)
      }
      setValue("number", userInfo.number)
      if (regexEmail.test(userInfo.email)) {
        setValue("email", userInfo.email)
      }
      if (userInfo.Address) {
        const addressParts = userInfo.Address.split("*--*")
        setValue("houseNo", addressParts[0])
        setValue("address", addressParts[1])
        setValue("city", addressParts[2])
        setValue("state", { value: addressParts[4], label: addressParts[4] })
        setValue("pincode", addressParts[3])
      }
    }
  }, [setValue, userInfo])

  const [placeOrder, setPlaceOrder] = useState(false)

  const onSubmit = async (data) => {
    const pincode = Number(data.pincode)

    await axios
      .get(`/api/shipping/shipping-availability/${pincode}`)
      .then((data) => {
        console.log(data)
        let availability = JSON.parse(data.data)?.message
        let availabilityData = JSON.parse(data.data)
        console.log(availabilityData)
        if (availability === undefined) {
          setError("pincode", { message: "Delivery service available" })
          setPlaceOrder(true)
        } else {
          setError("pincode", {
            message: "Delivery service is not available",
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })

    if (placeOrder) {
      data.state = data.state.value

      let UserData = {}
      if (!userInfo.name || userInfo.name === "UnKnown") {
        UserData.name = data.name
      } else {
        UserData.name = userInfo.name
      }

      if (
        userInfo.email !== data.email &&
        (!userInfo.email || !regexEmail.test(userInfo.email))
      ) {
        UserData.email = data.email
      } else {
        UserData.email = userInfo.email
      }

      if (!userInfo.number) {
        UserData.number = data.number
      } else {
        UserData.number = userInfo.number
      }
      if (!userInfo.Address) {
        UserData = { ...UserData, ...data }
      }

      dispatch(saveShippingAddress(data))

      if (Object.keys(UserData).length) {
        dispatch(updateUserProfile(UserData)).then((response) => {
          if (response) {
            navigate("../placeorder", {
              state: { ...state, deliveryChargePrice },
              replace: true,
            })
          } else if (userInfo.email) {
            setError("number", { message: "number already exist" })
          } else {
            setError("email", { message: "email already exist" })
          }
        })
      } else {
        navigate("../placeorder", {
          state: { ...state, deliveryChargePrice },
          replace: true,
        })
      }
    }
  }

  return (
    <>
      <Helmet></Helmet>
      <div className="py-5 2xl:pb-16  rounded-xl  font-style">
        <div className="flex justify-center  ">
          <div className=" mx-2  sm:mx-16 xl:mx-0  w-full 2xl:w-[80%] lg:pr-12 ">
            <p className="text-3xl font-style capitalize  md:px-4 flex justify-start  text-gray-900">
              shipping address
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8 ml-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                />
              </svg>
            </p>

            <div className="xl:grid grid-cols-5 h-full ">
              <div
                className={`  w-full  xl:col-span-3   2xl:col-span-3   md:px-4 `}
              >
                <div
                  className={` ${
                    state?.cartProductItemsDataBase?.length > 2 &&
                    "lg:sticky md:top-40 "
                  }`}
                >
                  <div>
                    <div className="  py-2 rounded-2xl border  mt-4 xl:mt-3  border-[rgb(100,193,223)]">
                      <div className="flex   h-16   p-5 items-center justify-between rounded-xl  ">
                        <div className="flex justify-between  w-full items-center">
                          <div className=" flex ">
                            <p className="bg-zinc-800 mr-2 sm:mr-7  rounded-full w-7 h-7  flex justify-center items-center  text-white font-style">
                              A
                            </p>
                            <p className="font-style   text-gray-900 text-lg">
                              Shipping Address
                            </p>
                          </div>
                        </div>
                      </div>

                      <form
                        onSubmit={handleSubmit(onSubmit)}
                        autoComplete="off"
                      >
                        <div className=" p-2">
                          <div className=" w-full md:w-1/2 my-4 px-2 lg:w-[45%]  lg:ml-7 ">
                            <div className="relative  ">
                              <input
                                type="text"
                                id="name"
                                {...register("name", { required: true })}
                                aria-invalid={errors.name ? "true" : "false"}
                                className={`block px-2.5 pb-2.5  font-style pt-4 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                                  errors.name
                                    ? "border-red-300 border-2"
                                    : "border-gray-300 border"
                                } appearance-none  focus:outline-none focus:ring-0  peer`}
                                placeholder=" "
                              />

                              <label
                                htmlFor="name"
                                className=" after:content-['*'] after:ml-1 after:text-red-500 absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                              >
                                Your Name
                              </label>
                            </div>
                            <div>
                              {errors.name?.type === "required" && (
                                <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                  <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className=" flex  md:justify-evenly  flex-wrap w-full lg:my-3">
                            <div className=" w-full md:w-1/2 lg:w-[45%] my-3  px-2">
                              <div className="relative">
                                <input
                                  type="tel"
                                  id="number"
                                  {...register("number", {
                                    required: true,
                                    maxLength: {
                                      value: 10,
                                      message: "Max length (10) is exceeded",
                                    },
                                    minLength: {
                                      value: 10,
                                      message:
                                        " contact no. must be (10) digits",
                                    },
                                    pattern: {
                                      value: /^[0-9]+$/,
                                      message: "Please enter a valid number",
                                    },
                                  })}
                                  aria-invalid={
                                    errors.number ? "true" : "false"
                                  }
                                  className={`block px-2.5 pb-2.5  font-style pt-4 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                                    errors.number
                                      ? "border-red-300 border-2"
                                      : "border-gray-300 border"
                                  } appearance-none  focus:outline-none focus:ring-0  peer`}
                                  placeholder=" "
                                />
                                <label
                                  htmlFor="number"
                                  className=" after:content-['*'] after:ml-1 after:text-red-500   absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                                >
                                  Phone Number
                                </label>
                              </div>

                              {errors.number?.type === "required" && (
                                <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                  <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                                </div>
                              )}

                              {errors?.number && (
                                <span className="text-sm m-1 text-red-500">
                                  {errors?.number?.message}
                                </span>
                              )}
                            </div>

                            <div className=" w-full md:w-1/2 lg:w-[45%] my-3  px-2">
                              <div className="relative">
                                <input
                                  type="email"
                                  disabled={
                                    userInfo?.googleLogin ||
                                    (userInfo.email &&
                                      regexEmail.test(userInfo.email))
                                  }
                                  id="email"
                                  {...register("email", {
                                    required: true,
                                    pattern: {
                                      value:
                                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                                      message: "Please enter a valid email",
                                    },
                                  })}
                                  aria-invalid={errors.email ? "true" : "false"}
                                  className={`block px-2.5 pb-2.5  font-style ${
                                    userInfo?.googleLogin ||
                                    (userInfo.email &&
                                      regexEmail.test(userInfo.email))
                                      ? "bg-gray-200 text-gray-400"
                                      : "bg-gray-100 text-gray-700"
                                  } pt-4 w-full text-[15px]   rounded-lg  ${
                                    errors.email
                                      ? "border-red-300 border-2"
                                      : "border-gray-300 border"
                                  } appearance-none  focus:outline-none focus:ring-0  peer`}
                                  placeholder=" "
                                />
                                <label
                                  htmlFor="email"
                                  className={` ${
                                    userInfo?.googleLogin ||
                                    (userInfo.email &&
                                      regexEmail.test(userInfo.email))
                                      ? ""
                                      : "after:content-['*'] after:ml-1 after:text-red-500 "
                                  }    absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1 `}
                                >
                                  Email
                                </label>
                              </div>

                              {errors.email && (
                                <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                  <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                                </div>
                              )}

                              {errors?.email && (
                                <p className="text-sm m-1 text-red-500 capitalize">
                                  {errors.email.message}
                                </p>
                              )}
                            </div>
                          </div>

                          <div className=" flex  md:justify-evenly  flex-wrap w-full  lg:my-3">
                            <div className=" w-full md:w-[30%] lg:w-[25%] my-3  px-2">
                              <div className=" relative">
                                <input
                                  type="text"
                                  {...register("houseNo", { required: true })}
                                  aria-invalid={
                                    errors.houseNo ? "true" : "false"
                                  }
                                  id="houseNo"
                                  className={`block px-2.5 pb-2.5  font-style pt-4 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                                    errors.houseNo
                                      ? "border-red-300 border-2"
                                      : "border-gray-300 border"
                                  } appearance-none  focus:outline-none focus:ring-0  peer`}
                                  placeholder=" "
                                />

                                <label
                                  htmlFor="houseNo"
                                  className=" after:content-['*'] after:ml-1 after:text-red-500  absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                                >
                                  Door No
                                </label>
                              </div>
                              {errors.houseNo?.type === "required" && (
                                <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                  <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                                </div>
                              )}
                            </div>

                            <div className=" w-full md:w-[70%]  lg:w-[65%]  my-3 px-2">
                              <div className="relative">
                                <input
                                  type="text"
                                  {...register("address", { required: true })}
                                  aria-invalid={
                                    errors.address ? "true" : "false"
                                  }
                                  id="address"
                                  className={`block px-2.5 pb-2.5  font-style pt-4 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                                    errors.address
                                      ? "border-red-300 border-2"
                                      : "border-gray-300 border"
                                  } appearance-none  focus:outline-none focus:ring-0  peer`}
                                  placeholder=" "
                                />

                                <label
                                  htmlFor="address"
                                  className="after:content-['*'] after:ml-1 after:text-red-500 absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                                >
                                  Address
                                </label>
                              </div>
                              {errors.address?.type === "required" && (
                                <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                  <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                                </div>
                              )}
                            </div>
                          </div>

                          <div className=" flex  md:justify-evenly  flex-wrap w-full lg:my-3">
                            <div className=" w-full  md:w-1/2 lg:w-[45%]  px-2  my-3">
                              <div className="relative">
                                <input
                                  type="text"
                                  {...register("city", { required: true })}
                                  aria-invalid={errors.city ? "true" : "false"}
                                  id="city"
                                  className={`block px-2.5 pb-2.5  font-style pt-4 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                                    errors.city
                                      ? "border-red-300 border-2"
                                      : "border-gray-300 border"
                                  } appearance-none  focus:outline-none focus:ring-0  peer`}
                                  placeholder=" "
                                />

                                <label
                                  htmlFor="city"
                                  className="  after:content-['*'] after:ml-1 after:text-red-500  absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                                >
                                  Town / City
                                </label>
                              </div>
                              {errors.city?.type === "required" && (
                                <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                  <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                                </div>
                              )}
                            </div>

                            <div className=" w-full   md:w-1/2 lg:w-[45%]  px-2  my-3">
                              <Controller
                                name="state"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    styles={{
                                      control: (provided) => ({
                                        ...provided,
                                        height: "48px", // Adjust the height as needed
                                        backgroundColor: "#f3f4f6",
                                        border: "0px",
                                      }),
                                    }}
                                    options={stateOptions.map((state) => ({
                                      value: state,
                                      label: state,
                                    }))}
                                    className={`font-style text-gray-800    ${
                                      errors.state
                                        ? "border-2 border-red-300"
                                        : "border-gray-300 border"
                                    } rounded-md `}
                                    placeholder={"select state"}
                                    theme={(theme) => ({
                                      ...theme,
                                      borderRadius: 5,
                                      colors: {
                                        ...theme.colors,
                                        primary25: "#eeeeee",
                                        primary: "rgb(0,53,96)",
                                        primary50: "ffffff",
                                      },
                                    })}
                                    isClearable
                                  />
                                )}
                              />
                              {errors.state && (
                                <span className="text-sm m-1 text-red-500 capitalize">
                                  {errors.state.message}
                                </span>
                              )}
                            </div>
                          </div>

                          <div
                            className={`  flex  
                                 md:justify-start lg:ml-7
                              flex-wrap w-full lg:my-3 `}
                          >
                            <div className="  w-full md:w-[40%] lg:w-[35%]  my-3 px-2 ">
                              {/* <div className="relative">
                                <input
                                  type="text"
                                  {...register("pincode", {
                                    required: true,
                                    minLength: 6,
                                    maxLength: 6,
                                    pattern: {
                                      value: /^[0-9]+$/,
                                      message: "Please enter a valid pincode",
                                    },
                                  })}
                                  aria-invalid={
                                    errors.pincode ? "true" : "false"
                                  }
                                  id="pinCode"
                                  className={`block px-2.5 pb-2.5  font-style pt-4 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                                    errors.pincode
                                      ? "border-red-300 border-2"
                                      : "border-gray-300 border"
                                  } appearance-none  focus:outline-none focus:ring-0  peer`}
                                  placeholder=" "
                                />

                                <label
                                  htmlFor="pinCode"
                                  className=" after:content-['*'] after:ml-1 after:text-red-500 absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                                >
                                  Pincode
                                </label>
                              </div> */}

                              <div className="relative">
                                <input
                                  type="text"
                                  placeholder=" "
                                  {...register("pincode", {
                                    required: "Pin Code is required",
                                    pattern: {
                                      value: /^[0-9]{6}$/,
                                      message: "Invalid Pin Code",
                                    },
                                  })}
                                  aria-invalid={
                                    errors.pincode ? "true" : "false"
                                  }
                                  id="pinCode"
                                  className={`block px-2.5 pb-2.5  font-style pt-4 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg    ${
                                    errors.pincode &&
                                    errors?.pincode?.message !==
                                      "Delivery service available"
                                      ? "border-red-500 border"
                                      : errors.pincode &&
                                        errors?.pincode?.message ===
                                          "Delivery service available"
                                      ? "border-green-500 border"
                                      : "border-gray-300 border"
                                  }  appearance-none  focus:outline-none focus:ring-0  peer`}
                                />
                                <label
                                  htmlFor="pinCode"
                                  className=" after:content-['*'] after:ml-1 after:text-red-500 absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                                >
                                  Pincode
                                </label>
                              </div>
                              {errors.pincode ? (
                                <p
                                  className={`text-sm m-1  ${
                                    errors?.pincode?.message ===
                                    "Delivery service available"
                                      ? "text-green-600"
                                      : "text-red-500"
                                  } `}
                                >
                                  {errors.pincode?.message}
                                </p>
                              ) : (
                                <p className=" m-1 capitalize text-sm   font-style text-gray-500 ">
                                  check service availability
                                </p>
                              )}

                              {/* {errors?.pincode &&
                                errors?.pincode?.type === "minLength" && (
                                  <span className="text-sm m-1 text-red-500 capitalize">
                                    pincode must be (6) digits
                                  </span>
                                )}
                              {errors?.pincode &&
                                errors?.pincode?.type === "maxLength" && (
                                  <span className="text-sm m-1 text-red-500">
                                    Max length (6) is exceeded
                                  </span>
                                )} */}
                            </div>
                          </div>

                          {/* <div className=" flex  md:justify-start lg:mx-[4%] my-3  flex-wrap ml-3  lg:my-4">
                            <input
                              type="checkbox"
                              id="default"
                              className="w-5 h-5  mr-2 accent-[rgb(0,53,96)] "
                              placeholder=" "
                              value={isChecked}
                              onChange={() => setIsChecked(!isChecked)}
                            />

                            <label
                              htmlFor="default"
                              className=" text-sm text-gray-600 font-style capitalize "
                            >
                              use this my default address
                            </label>
                          </div> */}

                          <div className=" flex  md:justify-center  my-2 flex-wrap w-full lg:p-0  p-4 ">
                            <Button
                              //   disabled={!validateUserData()}
                              type="submit"
                              className="text-center lg:w-1/2 bg-primary  h-12 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg  my-4  w-full"
                            >
                              {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5 mr-2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                                  />
                                </svg> */}
                              {!placeOrder ? (
                                <p> Check Delivery status </p>
                              ) : (
                                <p>Continue to payment</p>
                              )}
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" w-full xl:col-span-2 md:px-4 xl:px-0  2xl:col-span-2    ">
                <div
                  className={` p-4   mt-4   xl:mt-2  ${
                    state?.cartProductItemsDataBase?.length < 2 &&
                    "lg:sticky md:top-40 "
                  }  border shadow-md border-[rgb(240,203,137)] rounded-xl bg-white/10 backdrop-blur-[1px]  my-2 divide-y-2 divide-dotted `}
                >
                  <div className="flex justify-start    items-center">
                    <p className="ml-2 mb-2  font-bold   text-gray-700 text-lg">
                      Order Summary ( {state?.cartProductItemsDataBase?.length}
                      items)
                    </p>
                  </div>

                  {state?.cartProductItemsDataBase?.map((cartitem, i) => {
                    const dataBaseQty = state?.cartItemsDataBase?.find(
                      (obj) => {
                        return obj.product_item === cartitem.id
                      }
                    )

                    const isProductOffer = productoffers.find(
                      (val) => val.product === cartitem.product.id
                    )

                    const brandName = brand?.filter(
                      (name) => name?.id === cartitem?.product?.brand
                    )[0]

                    return (
                      <div className={` my-3`} key={i}>
                        {isProductOffer && (
                          <div className="capitalize mt-2 -mb-3 text-xs sm:text-sm  text-[rgb(0,53,96)] bg-gradient  font-style  rounded-sm flex justify-between">
                            <p className="p-2 flex items-center ">
                              <svg
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 mr-1"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                                />
                              </svg>
                              {isProductOffer?.offer?.value}
                              {isProductOffer?.offer?.type === "discount" &&
                                "%"}
                              Offer Applied
                            </p>
                          </div>
                        )}
                        <div
                          className={`flex  items-start  justify-between overflow-hidden rounded-lg pr-2  ${
                            isProductOffer ? "bg-gradient " : ""
                          } `}
                        >
                          <div className="flex  items-start   capitalize text-gray-800  w-full my-3 ">
                            <div className=" rounded-xl  w-16 h-24 md:w-24 md:h-32  m-1">
                              <img
                                className=" rounded-xl w-16 h-24 md:w-24 md:h-32 object-contain"
                                src={cartitem?.image}
                                alt="ordered items img"
                              />
                            </div>
                            <div className=" ml-3 text-xs md:text-[14px]  pt-1">
                              <p className="text-xs md:text-sm font-style text-gray-600 ">
                                {brandName?.name}
                              </p>

                              <p className="font-style w-32 xs:w-full  text-gray-600 truncate md:my-1">
                                {cartitem?.product.name}
                              </p>

                              <div className=" text-xs md:text-[14px] ">
                                <p className="text-xs md:text-sm font-style text-start  text-gray-400 md:my-1">
                                  size :
                                  <span className=" text-[rgb(0,53,96)]">
                                    {cartitem?.size.name}
                                  </span>
                                </p>
                                <p className="font-style text-start  text-gray-400 md:my-1">
                                  color :
                                  <span className=" text-[rgb(0,53,96)]">
                                    {cartitem?.color.name}
                                  </span>
                                </p>
                              </div>
                              <div className="flex text-xs md:text-sm font-style text-start  text-gray-400 md:my-1">
                                <p className="">qty : </p>
                                <p className=" text-sm  text-primary mx-2">
                                  {dataBaseQty?.quantity}
                                </p>
                              </div>

                              {isProductOffer?.offer?.type === "discount" ? (
                                <div>
                                  <p className="flex items-center text-gray-400 font-style  text-sm">
                                    price :
                                    <span className="text-primary  ml-2">
                                      ₹
                                      {Number(
                                        cartitem.product.mrp -
                                          cartitem.product?.mrp *
                                            (isProductOffer?.offer?.value / 100)
                                      ).toFixed(2)}
                                    </span>
                                    <del className="hidden xs:block text-xs ml-1">
                                      ₹{cartitem.product?.mrp}
                                    </del>
                                  </p>
                                </div>
                              ) : (
                                <div className="flex text-xs md:text-sm font-style text-start  text-gray-400 md:my-1">
                                  <p className="">price: </p>
                                  <p className=" text-sm  text-primary mx-2">
                                    ₹{cartitem?.product.price}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="pt-1  my-3 capitalize text-xs md:text-[14px] font-style text-end  text-gray-600 ">
                            <p className="mb-1">Total </p>
                            <div className="jsutify-end w-14 text-xs md:text-[14px]  flex-1 text-primary">
                              {isProductOffer?.offer?.type === "discount" ? (
                                <p>
                                  ₹
                                  {Number(
                                    (cartitem.product.mrp -
                                      cartitem.product?.mrp *
                                        (isProductOffer?.offer?.value / 100)) *
                                      dataBaseQty?.quantity
                                  ).toFixed(2)}
                                </p>
                              ) : (
                                <p>
                                  ₹
                                  {(
                                    Number(dataBaseQty?.quantity) *
                                    Number(cartitem?.product.price)
                                  ).toFixed(2)}
                                </p>
                              )}
                            </div>
                            <div className="flex justify-center items-end h-16 md:h-24">
                              {dataBaseQty?.print_value && (
                                <Tooltip
                                  title="Your Print initial"
                                  placement="top"
                                >
                                  <div className="font-style   w-7 h-7 flex justify-center items-center  group duration-300 border border-primary text-primary rounded-full ">
                                    <p> {dataBaseQty.print_value} </p>
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  <div className=" divide-y-2 divide-dotted text-gray-600  rounded-b-xl m-2 font-style ">
                    {state?.discountPrice > 1 ? (
                      <>
                        <div className="m-1 pt-2 flex justify-between">
                          <div> Sub-total</div>
                          <div>
                            ₹
                            {(
                              Number(state?.checkoutTotal) 
                            )?.toFixed(2)}
                          </div>
                        </div>

                        

                        {/* <div className="m-1 pt-2 flex justify-between">
                          <div> Promotion applied </div>
                          <div>
                            - ₹ {Number(state?.discountPrice).toFixed(2)}
                          </div>
                        </div> */}
                      </>
                    ) : (
                      <div className="m-1 pt-2 flex justify-between">
                        <div> Subtotal</div>
                        <div>₹ {state?.checkoutTotal} </div>
                      </div>
                    )}

                    <div className="m-1  flex justify-between">
                      <div> Delivery Charge</div>
                      {deliveryChargePrice ? (
                        <div>₹ {deliveryChargePrice.toFixed(2)}</div>
                      ) : (
                        <p>Free</p>
                      )}
                    </div>
                    <div className="m-1 text-lg font-bold py-1 flex justify-between text-gray-700">
                      <div>Order Total</div>
                      <div>
                        ₹
                        {(
                          Number(state?.checkoutTotal) +
                          Number(deliveryChargePrice)
                        ).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShippingPage
