import React, { useEffect } from "react"
import Carousel from "react-multi-carousel"
import { Link } from "react-router-dom"
import LoaderCard from "./LoaderCard"
// import "../css/sizeselect.css";
import "../css/HomeScreen.css"
import { useDispatch, useSelector } from "react-redux"
import { listBrands, listTopProducts } from "../actions/productActions"
import ProgressiveImage from "react-progressive-graceful-image"
import load from "../images/Myunde Logo.gif"
import { useLocation } from "react-router-dom"
import png from "../images/myundePng.png"

const RelatedResponsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1450 },
    items: 6,
  },
  laptop: {
    breakpoint: { max: 1450, min: 1024 },
    items: 5,
  },

  tabletbig: {
    breakpoint: { max: 1024, min: 800 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 800, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
}

export const RelatedProducts = React.memo(
  ({ productId, categoryId, brandId, SetImage }) => {
    var uniqueProduct = []
    // var uniqueImages = [];
    // var uniquecolor=[]

    const search = useLocation().search
    const productInitialColor = new URLSearchParams(search).get("clr")

    const brandList = useSelector((state) => state.brandList)
    const { brand } = brandList

    const { productoffers } = useSelector((state) => state.productoffers)

    const dispatch = useDispatch()
    useEffect(() => {
      if (
        products?.length
          ? products[0]?.product?.category !== categoryId ||
            products[0]?.product?.brand !== brandId
          : true
      ) {
        dispatch(listTopProducts(categoryId, brandId))
      }

      if (!brand?.length) {
        dispatch(listBrands())
      }

      // dispatch(listProductItems());
    }, [categoryId, brandId, productId, brand?.length, productInitialColor])

    const productList = useSelector((state) => state.productTopRated)
    const { products, loading } = productList

    // const productItemsList = useSelector((state) => state.productitems);
    // const { loading, error, productitems } = productItemsList;

    return (
      <>
        {products?.length > 1 && !loading ? (
          <div className="select-none">
            <hr className="mt-12 lg:py-12" />
            <p className="lg:px-32 text-xl lg:text-4xl font-style">
              You might also like
            </p>

            <Carousel
              responsive={RelatedResponsive}
              infinite={true}
              autoPlaySpeed={2000}
              className="xl:mx-32  "
              autoPlay={false}
            >
              {products?.map((product, i) => {
                if (product.product.id !== productId) {
                  const offer = productoffers?.find(
                    (val) => val.product === product.product.id
                  )
                  return (
                    <div
                      className="  mb-12  rounded-md   border overflow-y-hidden  
                                shadow-md mt-5 hover:scale-100 hover:shadow-lg hover:shadow-gray-500 duration-200 mx-1 lg:mx-2"
                      key={i}
                    >
                      <Link
                        to={`/product/${product?.product.id}/?clr=${product?.color}`}
                        className=" "
                        onClick={() => SetImage(product.image)}
                      >
                        <div className="">
                          {offer && (
                            <div className="svg">
                              <div className="bookmarkRibbon    absolute  pt-1 pb-5 xs:pb-7 xs:pt-4  right-3 text-white text-center font-medium text-[11px]  ">
                                {offer?.offer?.type === "custom" && (
                                  <p className="px-1   w-10 xs:w-12">
                                    {offer?.offer?.value}
                                  </p>
                                )}
                                {offer?.offer?.type === "discount" && (
                                  <div className="p-1   w-10 xs:w-12">
                                    <span className="text-lg ">
                                      {offer?.offer?.value}
                                    </span>
                                    %
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          {uniqueProduct ? (
                            <ProgressiveImage
                              src={`${product.image}`}
                              placeholder={load}
                            >
                              {(src, loading) => (
                                <img src={src} alt="Product" />
                              )}
                            </ProgressiveImage>
                          ) : (
                            <LoaderCard />
                          )}
                        </div>
                      </Link>
                      <div className="  px-2  bg-slate-100 py-1   2xl:border-t-2 border-gray-200">
                        {/* <p className=" text-xs md:text-sm mb-1 uppercase text-gray-900  hidden md:block  "></p> */}

                        <div>
                          {brand?.map((bra, i) => {
                            if (bra?.id === product?.product?.brand) {
                              return (
                                <div key={i}>
                                  {bra.name.toLowerCase() === "myunde" ? (
                                    <div className="w-16 h-5">
                                      <img src={png} alt="bra.name" />
                                    </div>
                                  ) : (
                                    <p
                                      className="
                                      
                                           text-[12px] md:text-sm truncate uppercase
                                         text-gray-900 "
                                      key={i}
                                    >
                                      {bra?.name}
                                    </p>
                                  )}
                                </div>
                              )
                            }
                          })}
                        </div>

                        <div className="flex justify-between     border-t-3  mr-3  ">
                          <p className=" text-xs capitalize truncate text-gray-900 font-medium  sm:hidden ">
                            {product.product.name}
                          </p>

                          <p className=" text-sm capitalize truncate text-gray-900 font-medium   hidden sm:block">
                            {product.product.name}
                          </p>
                        </div>
                        {/* <-- try --> */}
                        <div className="text-start flex justify-between">
                          <p className="text-black text-sm">
                            <b>
                              
                              ₹
                              {offer?.offer?.type === "discount"
                                ? Math.round(
                                  product?.product?.mrp -
                                  product?.product?.mrp * (offer?.offer?.value / 100)
                                  ).toFixed(2)
                                : product.product.price}
                            </b>
                          </p>
                          {product.product.pack_of > 1 ? (
                            <div>
                              <p className="text-[10px] xl:text-xs font-thin bg-gray-200 p-1">
                                <b>Pack of {product.product.pack_of}</b>
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  )
                }
                // uniqueImages = itemimages
                //   .filter((itemimage) => Number(itemimage.color) === Number(uniquecolor))
                //   .filter((itemimage) => Number(itemimage.product) === Number(product.id))[0];
              })}
            </Carousel>

            <hr />
          </div>
        ) : (
          ""
        )}
      </>
    )
  }
)

export default RelatedProducts
