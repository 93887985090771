import React, { useEffect } from "react";
import gif from "../images/Comp 1_4.gif";

function ServerErrorPage() {
  

  return (
    <div className="flex justify-center lg:w-full select-none items-center h-screen  bg-[rgb(251,251,251)]">
      {/* <div className=" flex justify-center py-2  font-bold sm:text-2xl lg:text-5xl opacity-[0.2]">
          Working on Updates at the Moment
        </div>
        <div className="flex justify-center   font-bold sm:text-2xl lg:text-9xl  opacity-[0.2]">
          Server error{" "}
        </div> */}
      <img src={gif} alt="Error 404 Not Found " className=" 2xl:w-[50%]  " />

      {/* <div className=" flex justify-center    font-bold sm:text-2xl lg:text-4xl opacity-[0.2]">
          <p className="text-6xl  opacity-[0.8]"> 00PS Sorry for the
          Inconvenience</p>
        </div> */}
    </div>
  );
}

export default ServerErrorPage;
