import React from "react";

function Terms() {
  return (
    <div className="flex justify-center items-center h-full py-32 flex-wrap font-style">
      <div
        className="w-full  lg:w-[90%] xl:w-[80%]  p-2 sm:mx-5  mx-2  shadow-xl  backdrop-blur-2xl rounded-xl  sm:p-3 lg:px-10  text-primary "
        style={{
          background:
            "linear-gradient(29deg, rgba(227,242,255,0.6) 50%, rgba(249,247,240,0.6) 50%)",
        }}
      >
        <p className="text-xl sm:text-4xl mb-7 text-center capitalize  ">
          terms and conditions
        </p>
        <p>Last updated on Nov 30th 2022</p>
        <div className="my-7 px-2 sm:px-4 text-lg">
          <p className="my-8 ">
            The Website Owner, including subsidiaries and affiliates (“Website”
            or “Website Owner” or “we” or “us” or “our”) provides the
            information contained on the website or any of the pages comprising
            the website (“website”) to visitors (“visitors”) (cumulatively
            referred to as “you” or “your” hereinafter) subject to the terms and
            conditions set out in these website terms and conditions, the
            privacy policy and any other relevant terms and conditions, policies
            and notices which may be applicable to a specific section or module
            of the website.
          </p>

          <p className="my-8 ">
            Welcome to our website. If you continue to browse and use this
            website you are agreeing to comply with and be bound by the
            following terms and conditions of use, which together with our
            privacy policy govern WEBTEK FASHION PVT LTD relationship with you in relation
            to this website.
          </p>
          <p className="my-8 ">
            The term 'WEBTEK FASHION PVT LTD' or 'us' or 'we' refers to the owner of the
            website whose registered/operational office is NO.2, 1ST FLOOR, MK
            PLAZA, SOLIPALAYAM ROAD, 15-VELAMPALAYAM, Tiruppur Tiruppur TAMIL
            NADU 641652. The term 'you' refers to the user or viewer of our
            website.
          </p>

          <p className="my-8 ">
            The use of this website is subject to the following terms of use:
          </p>

          <p className="my-8 ">
            The content of the pages of this website is for your general
            information and use only. It is subject to change without notice.
          </p>

          <p className="my-8 ">
            Neither we nor any third parties provide any warranty or guarantee
            as to the accuracy, timeliness, performance, completeness or
            suitability of the information and materials found or offered on
            this website for any particular purpose. You acknowledge that such
            information and materials may contain inaccuracies or errors and we
            expressly exclude liability for any such inaccuracies or errors to
            the fullest extent permitted by law.
          </p>
          <p>
            Your use of any information or materials on this website is entirely
            at your own risk, for which we shall not be liable. It shall be your
            own responsibility to ensure that any products, services or
            information available through this website meet your specific
            requirements.
          </p>

          <p className="my-8 ">
            This website contains material which is owned by or licensed to us.
            This material includes, but is not limited to, the design, layout,
            look, appearance and graphics. Reproduction is prohibited other than
            in accordance with the copyright notice, which forms part of these
            terms and conditions.
          </p>

          <p className="my-8 ">
            All trademarks reproduced in this website which are not the property
            of, or licensed to, the operator are acknowledged on the website.
          </p>

          <p className="my-8 ">
            Unauthorized use of this website may give rise to a claim for
            damages and/or be a criminal offense.
          </p>
          <p className="my-8 ">
            From time to time this website may also include links to other
            websites. These links are provided for your convenience to provide
            further information.
          </p>
          <p className="my-8 ">
            You may not create a link to this website from another website or
            document without WEBTEK FASHION PVT LTD prior written consent.
          </p>
          <p className="my-8 ">
            Your use of this website and any dispute arising out of such use of
            the website is subject to the laws of India or other regulatory
            authority.
          </p>
          <p className="my-8 ">
            We as a merchant shall be under no liability whatsoever in respect
            of any loss or damage arising directly or indirectly out of the
            decline of authorization for any Transaction, on Account of the
            Cardholder having exceeded the preset limit mutually agreed by us
            with our acquiring bank from time to time.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Terms;


