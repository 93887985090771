import React from "react";
// import LoginScreen from "./LoginScreen";

import { AiOutlineClose } from "react-icons/ai";
// import { Fragment } from "react";
import Drawer from "@mui/material/Drawer";
import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import Message from "../components/Message";
import { login, registerUser } from "../actions/userActions";
// import { useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";
import { BsArrowLeft } from "react-icons/bs";
// import RegisterScreen from "./RegisterScreen";
import LoaderCard from "../components/LoaderCard";
import { useForm } from "react-hook-form";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
// import LoginGoogle from "../components/googleLogin";
import { listCartDataBase } from "../actions/cartActions";
import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
import Login from "../components/firebaseLogin";

import FireBaseGoogle from "../components/fireBaseGoogle";
// import GoogleLogin2 from "../components/GoogleLogin2";
// import GoLogin from "../components/GoLogin";
// import Gologin from "../components/Gologin";

const LoginSlide = ({ forProfileOpen, open }) => {
  // var navigate = useNavigate();
  // const [closeLogin, setCloseLogin]= useState(false)
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [customerNumber, setCustomerNumber] = useState("");
  const [registerTried, setRegisterTried] = useState(false);

  const [loginOpen, setLoginOpen] = useState(true);
  const [show, setShow] = useState({
    loginregister: true,
    loginmobile: false,
    loginmail: false,
    loginOTP: false,
    register: false,
  });

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const onSubmit = (data) => {
    if (show.loginmobile) {
      dispatch(login(data.number, data.password));
      // welcome();
    } else if (show.loginmail) {
      dispatch(login(data.email, data.password));
      // welcome();
    }

    reset();
    if (userInfo) {
      setLoginOpen(!loginOpen);

      // localStorage.removeItem("shipping");
      // history("/shipping");
    }
  };

  const onLogin = () => {
    const data = {};

    if (customerNumber) {
      Object.assign(data, { number: customerNumber, OTP: 123456 });

      dispatch(registerUser(data));
      // welcome()
    }

    if (userInfo) {
      reset();
    }
  };

  const dispatch = useDispatch();
  const verifiedNumber = (verifiedNumber) => {
    setCustomerNumber(verifiedNumber?.user?.phoneNumber.slice(3));
  };

  // console.log(verifiedNumber?.user?.phoneNumber.slice(3));
  // const location = useLocation().search;
  // const location = new URLSearchParams(search).get('qty');

  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;

  const userRegister = useSelector((state) => state.userRegister);

  // const shipping = localStorage.getItem("shipping");
  // const redirect = shipping ? "/shipping" : "/";

  // const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      // history("/shipping");
      dispatch(listCartDataBase(userInfo?.id, " "));
    }
  }, [userInfo]);

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <Drawer
        className=""
        anchor={"right"}
        transitionDuration={500}
        open={open}
        onClose={forProfileOpen}
      >
        <div className="pointer-events-auto w-screen sm:w-[28rem] font-style">
          <div
            type="button"
            className=" p-6 right-0 text-gray-400 hover:text-gray-500 absolute z-50"
            onClick={() => forProfileOpen()}
          >
            <AiOutlineClose className="w-6 h-6 " />
            <span className="sr-only">Close panel</span>
          </div>
          <div className="flex h-full justify-center items-center  flex-col  select-none   bg-white  ">
            <div className="flex-1 overflow-y-auto w-full  sm:w-[28rem] ">
              <div
                className="flex items-start justify-between py-6 rounded-b-xl shadow-lg bg-gradient"
                // style={{
                //   background:
                //     "linear-gradient(49deg, rgba(227,242,255,1) 0%, rgba(249,247,240,1) 87%)",
                // }}
              >
                <div className="ml-3 flex h-7 items-center w-full">
                  <div className="text-lg font-style   flex justify-start items-center w-full text-gray-900">
                    {show.loginregister === false ? (
                      <BsArrowLeft
                        className=" hover:scale-110"
                        onClick={() =>
                          setShow({
                            ...show,
                            loginmail: false,
                            loginmobile: false,
                            register: false,
                            loginregister: true,
                            loginOTP: false,
                          })
                        }
                      />
                    ) : (
                      ""
                    )}

                    <p className="pl-6 capitalize flex items-center">
                      login / register
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 ml-1 mt-0.5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </p>
                  </div>
                </div>
              </div>

              {/* <-------------------------------------------------Login register----------------------------------------------------------------------> */}

              {!show.register && error && (
                <Message variant="danger">{error}</Message>
              )}
              {loading && <LoaderCard />}
              <div>
                {!loading && show.loginregister && (
                  <>
                    <div className="my-6 px-3 md:px-6 ">
                      <div className="shadow-lg mt-10 p-6 py-8 rounded-lg text-center border bg-gray-50 ">
                        <p className="text-center pt-[10px] text-lg font-style text-gray-700">
                          Login With
                        </p>
                        {/* <button className=" w-full h-12  rounded-lg border outline-none    my-4  hover:border-black">
                        <div className="flex justify-center items-center "> */}
                        {/* <LoginGoogle /> */}
                        {/* <GoogleLogin2 /> */}
                        <FireBaseGoogle />
                        {/* <GoLogin/> */}
                        {/* <FcGoogle className="w-6 h-6 mb-2" /> oogle
                        </div>
                      </button> */}
                        {/* <button
                          className=" w-full  h-12 rounded-md border border-gray-400  my-6 focus:outline-none hover:border-gray-200 group"
                          onClick={() =>
                            setShow({
                              ...show,
                              loginmobile: !show.loginmobile,
                              loginregister: !show.loginregister,
                            })
                          }
                        >
                          <div className="flex justify-center items-center text-gray-500 text-sm group-hover:text-gray-400">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5 mx-1 text-gray-600 group-hover:text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                              />
                            </svg>

                            <p>Login using mobile number</p>
                          </div>
                        </button>
                        <button
                          className="w-full  h-12 rounded-md border border-gray-400 focus:outline-none my-4  hover:border-gray-200 group"
                          onClick={() =>
                            setShow({
                              ...show,
                              loginmail: !show.loginmail,
                              loginregister: !show.loginregister,
                            })
                          }
                        >
                          <div className="flex justify-center items-center text-gray-500 text-sm group-hover:text-gray-400">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5 mr-2 text-gray-600 group-hover:text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                              />
                            </svg>

                            <p>Login using mail</p>
                          </div>
                        </button> */}
                        <div
                          className="w-full flex justify-center items-center  h-12 rounded-md border border-gray-400 focus:outline-none my-4  hover:border-gray-200 group"
                          onClick={() =>
                            setShow({
                              ...show,
                              loginOTP: !show.loginOTP,
                              loginregister: !show.loginregister,
                            })
                          }
                        >
                          <div className="flex justify-center items-center text-gray-500 text-sm group-hover:text-gray-400">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentcolor"
                              className="w-5 h-5 mx-1 mt-1 text-gray-600 group-hover:text-gray-400"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm0 14H5.17L4 17.17V4h16v12zM7 9h2v2H7zm8 0h2v2h-2zm-4 0h2v2h-2z" />
                            </svg>
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              className="w-5 h-5 mx-1 text-gray-600 group-hover:text-gray-400"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M19 1H9c-1.1 0-2 .9-2 2v3h2V4h10v16H9v-2H7v3c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zM7.01 13.47l-2.55-2.55-1.27 1.27L7 16l7.19-7.19-1.27-1.27-5.91 5.93z" />
                            </svg> */}

                            <p>Login with OTP</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className="flex justify-center text-lg  font-style text-gray-700">
                      Don't have an account?
                      <div
                        className=" cursor-pointer ml-2 text-primary hover:text-[rgb(53,89,119)]"
                        onClick={() =>
                          setShow({
                            ...show,
                            register: true,
                            loginregister: false,
                            loginmail: false,
                            loginmobile: false,
                            loginOTP: false,
                          })
                        }
                      >
                        Register
                      </div>
                    </div> */}
                  </>
                )}

                {/* <-------------------------------------------------Login mobile number----------------------------------------------------------------------> */}

                {!loading && show.loginmobile && (
                  <div className="px-3 md:px-6 font-style text-gray-700">
                    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                      <div className="shadow-xl mt-10 py-8 p-6 rounded-lg text-center border bg-gray-50">
                        <p className="text-center  text-lg ">
                          Login Using Mobile Number
                        </p>

                        <div className="relative mt-14 ">
                          <input
                            name="number"
                            autoComplete="off"
                            type="tel"
                            placeholder=" "
                            {...register("number", {
                              required: true,
                              minLength: 10,
                              maxLength: 10,
                              pattern: {
                                value: /^[0-9]+$/,
                                message: "Please enter a valid number",
                              },
                            })}
                            aria-invalid={errors.number ? "true" : "false"}
                            id="floating_outlined"
                            className={`block px-2.5 pb-2.5  pt-4 w-full text-[15px] text-gray-900 bg-gray-100 rounded-lg  ${
                              errors.number
                                ? "border-red-300 border-2"
                                : "border-gray-300 border"
                            } appearance-none  focus:outline-none focus:ring-0  peer`}
                          />
                          <label
                            htmlFor="floating_outlined"
                            className="absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                          >
                            Enter Mobile Number
                          </label>
                        </div>

                        {errors.number && (
                          <div className="text-sm m-1 text-red-500 flex justify-end relative">
                            <ExclamationCircleIcon className="w-6 h-6  absolute -mt-9 mr-1.5" />
                          </div>
                        )}

                        <div className="relative mt-10">
                          <input
                            name="password"
                            type={passwordShown ? "text" : "password"}
                            placeholder=" "
                            {...register("password", {
                              required: true,
                            })}
                            aria-invalid={errors.password ? "true" : "false"}
                            id="floating_outlined21"
                            className={`block px-2.5 pb-2.5  pt-4 w-full text-[15px] text-gray-900 bg-gray-100 rounded-lg  ${
                              errors.password
                                ? "border-red-300 border-2"
                                : "border-gray-300 border"
                            } appearance-none  focus:outline-none focus:ring-0  peer`}
                          />
                          <label
                            htmlFor="floating_outlined21"
                            className="absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                          >
                            Enter Password
                          </label>
                        </div>
                        <div className="text-sm m-1  flex justify-end relative">
                          {!passwordShown ? (
                            <svg
                              onClick={togglePassword}
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5  absolute -mt-9 mr-2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                            </svg>
                          ) : (
                            <svg
                              onClick={togglePassword}
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5  absolute -mt-9 mr-2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                              />
                            </svg>
                          )}
                        </div>
                        <Button
                          type="submit"
                          className="text-center bg-primary  h-12 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg  my-8  w-full"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 mr-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                            />
                          </svg>
                          Login
                        </Button>

                        {/* <button
                          className="w-full  h-12 rounded-md border border-gray-400 focus:outline-none   hover:border-gray-200 group"
                          onClick={() =>
                            setShow({
                              ...show,
                              loginmobile: !show.loginmobile,
                              loginmail: !show.loginmail,
                            })
                          }
                        >
                          <div className="flex justify-center items-center text-gray-500 text-sm group-hover:text-gray-400 ">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5 mr-2 text-gray-600 group-hover:text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                              />
                            </svg>

                            <p>Login using mail</p>
                          </div>
                        </button> */}
                      </div>
                    </form>
                    <div className="flex justify-center mt-6 ">
                      Don't have an account?
                      <div
                        className=" cursor-pointer ml-2 text-primary "
                        onClick={() =>
                          setShow({
                            ...show,
                            register: true,
                            loginregister: false,
                            loginmail: false,
                            loginmobile: false,
                            loginOTP: false,
                          })
                        }
                      >
                        Register
                      </div>
                    </div>
                  </div>
                )}

                {/* <-------------------------------------------------Login mail----------------------------------------------------------------------> */}

                {!loading && show.loginmail && (
                  <div className="px-3 md:px-6 font-style text-gray-700">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="shadow-xl mt-10 py-8 p-6 rounded-lg text-center border bg-gray-50">
                        <p className="text-center mb-4 text-lg">
                          Login Using Mail
                        </p>

                        <div className="relative mt-14">
                          <input
                            name="email"
                            type="email"
                            placeholder=" "
                            {...register("email", {
                              required: true,
                            })}
                            aria-invalid={errors.email ? "true" : "false"}
                            id="floating_outlined"
                            className={`block px-2.5 pb-2.5  pt-4 w-full text-[15px] text-gray-900 bg-gray-100 rounded-lg  ${
                              errors.email
                                ? "border-red-300 border-2"
                                : "border-gray-300 border"
                            } appearance-none  focus:outline-none focus:ring-0  peer`}
                          />
                          <label
                            htmlFor="floating_outlined"
                            className="absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                          >
                            Enter Email
                          </label>
                        </div>

                        {errors.email?.type === "required" && (
                          <div className="text-sm m-1 text-red-500 flex justify-end relative">
                            <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                          </div>
                        )}

                        <div className="relative mt-10">
                          <input
                            name="password"
                            type={passwordShown ? "text" : "password"}
                            placeholder=" "
                            {...register("password", {
                              required: true,
                            })}
                            aria-invalid={errors.password ? "true" : "false"}
                            id="floating_outlined22"
                            className={`block px-2.5 pb-2.5  pt-4 w-full text-[15px] text-gray-900 bg-gray-100 rounded-lg  ${
                              errors.password
                                ? "border-red-300 border-2"
                                : "border-gray-300 border"
                            } appearance-none  focus:outline-none focus:ring-0  peer`}
                          />
                          <label
                            htmlFor="floating_outlined22"
                            className="absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                          >
                            Enter Password
                          </label>
                        </div>

                        <div className="text-sm m-1  flex justify-end relative">
                          {!passwordShown ? (
                            <svg
                              onClick={togglePassword}
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5  absolute -mt-9 mr-2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                            </svg>
                          ) : (
                            <svg
                              onClick={togglePassword}
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5  absolute -mt-9 mr-2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                              />
                            </svg>
                          )}
                        </div>
                        <Button
                          type="submit"
                          className="text-center bg-primary hover:bg-[rgb(47,108,158)] h-12 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg  my-8  w-full"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 mr-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                            />
                          </svg>
                          Login
                        </Button>

                        <button
                          className="w-full  h-12 rounded-md border border-gray-400 focus:outline-none   hover:border-gray-200 group"
                          onClick={() =>
                            setShow({
                              ...show,
                              loginmail: !show.loginmail,
                              loginmobile: !show.loginmobile,
                            })
                          }
                        >
                          <div className="flex justify-center items-center text-gray-500 text-sm group-hover:text-gray-400">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5 mr-2 text-gray-600 group-hover:text-gray-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                              />
                            </svg>

                            <p>Login using mobile number</p>
                          </div>
                        </button>
                      </div>
                    </form>
                    <div className="flex justify-center  mt-6">
                      Don't have an account?
                      <div
                        className=" cursor-pointer ml-2 text-primary "
                        onClick={() =>
                          setShow({
                            ...show,
                            register: true,
                            loginregister: false,
                            loginmail: false,
                            loginmobile: false,
                          })
                        }
                      >
                        Register
                      </div>
                    </div>
                  </div>
                )}

                {/* <-------------------------------------------------Login OTP----------------------------------------------------------------------> */}

                {!loading && show.loginOTP && (
                  <div className="px-6 font-style text-gray-700 my-6">
                    {userRegister.error && (
                      <Message variant="danger">
                        Given Number already exists try another number
                      </Message>
                    )}

                    <div className="shadow-xl mt-10 py-8 p-6 rounded-lg text-center border bg-gray-50">
                      <p className="text-center mb-4 text-lg">
                        Login Using OTP
                      </p>

                      <Login
                        verifiedNumber={verifiedNumber}
                        registerTried={registerTried}
                      />
                      {/* {console.log(verifiedNumber, registerTried)} */}
                      {/* {customerNumber ? (
                        <Button
                          type="submit"
                          className="text-center bg-primary hover:bg-[rgb(47,108,158)] h-12 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg  my-8  w-full"
                          onClick={() => onLogin(customerNumber)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 mr-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                            />
                          </svg>
                          Login
                          
                        </Button>
                      ) : (
                        ""
                      )} */}
                      {/* {!loginOpen && <CartScreen forCartOpen={()=>forCartOpen()} open={loginOpen} />} */}
                      {/* <button
                        className="w-full  h-12 rounded-md border border-gray-400 focus:outline-none   hover:border-gray-200 group"
                        onClick={() =>
                          setShow({
                            ...show,
                            loginmail: false,
                            loginmobile: !show.loginmobile,
                            loginOTP: false,
                          })
                        }
                      >
                        <div className="flex justify-center items-center text-gray-500 text-sm group-hover:text-gray-400">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 mr-2 text-gray-600 group-hover:text-gray-400"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                            />
                          </svg>

                          <p>Login using mobile number</p>
                        </div>
                      </button> */}
                    </div>

                    {/* <div className="flex justify-center  mt-6">
                      Don't have an account?
                      <div
                        className=" cursor-pointer ml-2 text-primary "
                        onClick={() =>
                          setShow({
                            ...show,
                            register: true,
                            loginregister: false,
                            loginmail: false,
                            loginmobile: false,
                            loginOTP: false,
                          })
                        }
                      >
                        Register
                      </div>
                    </div> */}
                  </div>
                )}

                {/* <-------------------------------------------------register form----------------------------------------------------------------------> */}

                {/* {!loading && show.register && (
                  <div className="font-style text-gray-700">
                    <RegisterScreen />

                    <div className="mt-8  text-lg text-center">
                      Already have an account?
                      <div
                        // to={redirect ? `#/login?redirect=${redirect}` : "/login"}
                        className="text-[rgb(0,56,93)] text-lg cursor-pointer "
                        onClick={() =>
                          setShow({
                            ...show,
                            register: !show.register,
                            loginregister: !show.loginregister,
                          })
                        }
                      >
                        Login
                      </div>
                    </div>
                  </div>
                )}  */}
              </div>
            </div>
          </div>
          {/* <LoginScreen /> */}
        </div>
      </Drawer>
    </>
  );
};

export default LoginSlide;

// import React from "react";
// import LoginScreen from "./LoginScreen";

// import { AiOutlineClose } from "react-icons/ai";

// import Drawer from "@mui/material/Drawer";

// const LoginSlide = ({ forProfileOpen, open }) => {
//   console.log(forProfileOpen, open);

//   return (
//     <>
//       <Drawer
//         className=""
//         anchor={"right"}
//         transitionDuration={500}
//         open={open}
//         onClose={forProfileOpen}
//       >
//
//         <div className="pointer-events-auto w-screen sm:w-[28rem] ">
//           <div
//             type="button"
//             className=" p-6 right-0 text-gray-400 hover:text-gray-500 absolute z-50"
//             onClick={() => forProfileOpen()}
//           >
//             <AiOutlineClose className="w-6 h-6 " />
//             <span className="sr-only">Close panel</span>
//           </div>
//           <LoginScreen />
//         </div>
//       </Drawer>
//     </>
//   );
// };

// export default LoginSlide;
