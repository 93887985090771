import React from "react";

function FooterContent() {
  return (
    <div className="p-2 text-xs">
      <h1 className=" font-semibold text-sm my-4 ">Leading underwear brand in India</h1>
      <h1 className="my-4 ">
        Founded in 2022, Myunde's mission is to make solutionwear for modern
        men. We specialize in making comfortable underwear, loungewear, and
        athleisure with an emphasis on technology and innovation. Our unique
        fabric blends provide proper fit and support both physically and
        emotionally throughout your day and throughout your life. More than just
        stylish and comfortable, our clothing is Made To Live In.
      </h1>
      <h1 className="font-semibold text-sm my-4"> Online store for men's apparel</h1>
      <h1 className="my-4">
        The men's underwear collection at Myunde is sure to impress you. We
        offer a wide range of underwear designed for all shapes and sizes. With
        the convenience of shopping from home, you can explore various styles
        including briefs, trunks, and boxer briefs, as well as attractive
        underwear sets and more. Our collection features different fits such as
        fitted, loose, with or without fly, and a variety of fabric options to
        suit your preferences. With our extensive online selection, you'll never
        run out of choices. Experience vibrant colors, patterns, and styles that
        cater to your individual taste. Whether you're in need of regular or
        plus-size underwear, Myunde has you covered. Visit us today to take
        advantage of the best deals and discounts available
      </h1>
      <h1 className="my-4 font-semibold text-sm"> Myunde  Men's Underwear Series</h1>
      <h1 className="my-4">
        At Myunde, you can explore a diverse selection of high-quality men's
        underwear sets online, designed to cater to every shape and size. We
        offer an extensive range of boxers, briefs, trunks, activewear,
        sleepwear, and shapewear. Choose from a variety of modern designs and
        styles in an array of colors and fits. Here are the different types of
        innerwear you can discover on our website.
      </h1>
      <div className="p-3"> 
      <h1 className="my-2">
        <span className="font-semibold text-sm"> Briefs : </span>
        Say goodbye to underwear woes and explore our range of stylish and
        comfortable men's briefs at Myunde. Our extensive collection of briefs
        is carefully crafted using high-quality fabrics that are soft,
        breathable, and stretchable. Discover our exquisite and trendy brief
        options to find your perfect fit and style. Choose from a variety of
        colors, designs, and sizes for every occasion. Our briefs are
        thoughtfully designed to provide maximum support, comfort, and
        protection. Made with soft, breathable, and stretchable fabric, our
        briefs ensure a comfortable fit throughout the day. Experience the
        convenience of shopping for high-quality men's briefs online at Myunde.
        Here are the different types of briefs available in our collection.
      </h1>
      <h1 className="my-2">
        <span className="font-semibold text-sm"> Boxer Briefs: </span>
        Our boxer briefs combine the length of boxers with the snug fit of
        briefs, providing support and comfort. They offer excellent coverage and
        are a popular choice for everyday wear.
      </h1>
      <h1 className="my-2">
        <span className="font-semibold text-sm"> Trunks: </span>
        Trunks are shorter and snugger than boxer briefs, providing a
        streamlined and modern look. They are a versatile option suitable for
        various activities, from daily wear to workouts.
      </h1>
      <h1 className="my-2">
        <span className="font-semibold text-sm"> Inner Boxers: </span>
        Inner boxers are designed to be worn underneath other garments. They
        provide an extra layer of comfort and support, making them ideal for
        those who prefer added coverage.
      </h1>
      <h1 className="my-2">
        <span className="font-semibold text-sm"> Vest Undershirts: </span>
        Our vest undershirts are designed to be worn under shirts for added
        comfort and sweat absorption. They provide a smooth layer underneath
        clothing and help keep you cool throughout the day.
      </h1>
      <h1 className="my-2">
        <span className="font-semibold text-sm"> Tank Tops: </span>
        Tank tops are sleeveless and provide a casual and relaxed look. They are
        perfect for layering or as standalone tops for workouts or warm weather.
      </h1>
      <h1 className="my-2">
        <span className="font-semibold text-sm"> Gym Vests: </span>
        Gym vests are specifically designed for workouts and physical
        activities. They offer breathability and freedom of movement, helping
        you stay comfortable during intense exercise sessions.
      </h1>
      <h1 className="my-2">
        <span className="font-semibold text-sm"> T-Shirts: </span>
        Our collection includes comfortable and stylish t-shirts suitable for
        everyday wear. Choose from various necklines, sleeve lengths, and
        designs to find your perfect style.
      </h1>
      <h1 className="my-2">
        <span className="font-semibold text-sm">
          
          Shorts, Joggers, Track Pants, and Loungewear:
        </span>
        We offer a range of bottoms for different purposes. Whether you need
        shorts for workouts or loungewear for relaxing at home, you'll find
        comfortable options to suit your needs.
      </h1>
      </div>
      <h1 className="my-4">
        Additionally, we also have a selection of socks to complete your
        ensemble and provide comfort for your feet.
      </h1>
      <h1 className="my-4">
        Explore our Myunde collection across these categories to find
        high-quality, comfortable, and stylish men's underwear and apparel for
        all occasions.
      </h1>
      <h1 className="my-4">
        <span className="font-semibold text-sm"> Myunde  Promotions & Discounts </span>
        <p className="my-4">
        Discover amazing offers and deals on men's underwear at Myunde. We have
        incredible discounts to make your shopping experience even better. For
        our brief collection, you can enjoy a special offer of buying 3 briefs
        and getting 3 additional briefs absolutely free, all for just Rs. 579.
        If you prefer to buy in smaller quantities, we have another exciting
        deal where you can buy 2 briefs and get 2 free, priced at just Rs. 479.
        That's unbeatable value for top-quality briefs!
        </p>
      </h1>
      <h1 className="my-4">
        When it comes to vests, we have a fantastic offer for you. Get a pack of
        5 comfortable and stylish vests for only Rs. 375. This deal allows you
        to stock up on your favorite vests without breaking the bank. Our vests
        are designed to provide optimum comfort and support, making them a
        must-have addition to your wardrobe.
      </h1>
     
      <h1 className="my-4">
        <span className="font-semibold text-sm">
          
        What Sets Myunde  Apart as Your Go-To Men's Underwear Brand ?
        </span>
        <p className="my-4">
        Myunde is your ultimate destination for high-quality men's underwear
        online. With a wide selection of comfortable and stylish underwear
        options, we cater to all shapes and sizes. Whether you're looking for
        briefs, trunks, boxer briefs, or other styles, we have you covered. Our
        collection includes various designs, patterns, and prints to suit your
        personal style. Choose from fitted, loose, with or without fly, and
        explore different fabric options for maximum comfort. From everyday wear
        to special occasions, our underwear is designed to provide the perfect
        fit, support, and durability.When it comes to men's underwear, Myunde
        ensures that you never run out of options. Shop with us for comfortable
        and long-lasting high-quality underwear that will keep you feeling
        confident and stylish throughout the day.
        </p>
      </h1>
    </div>
  );
}

export default FooterContent;
