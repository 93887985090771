import { Button } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function PageNotFound() {
  
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center flex-wrap w-screen h-96">
      <div className="text-center">
        <p className="text-9xl">404</p>
        <p className="w-full text-center text-lg">
        Oops !! Page Not Found .
        </p>
        {/* <p className="w-full text-center text-lg">
        We apologize for the inconvenience,<br/> But it seems that the page you were looking for cannot be found. <br/>It may have been moved, renamed, or deleted.
        </p> */}
        <Button
          onClick={() => navigate("/")}
          className=" bg-primary mt-2 text-white"
        >
          Go back to home
        </Button>
      </div>
    </div>
  );
}

export default PageNotFound;
