import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { saveShippingAddress } from "../actions/cartActions"
import { useLocation, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import { Button } from "@material-tailwind/react"
import { updateUserProfile } from "../actions/userActions"

import ProfileScreen from "./ProfileScreen"

function ShippingScreen() {
  const [address, setAddress] = useState({ buttonDiv: true, formDiv: false })
  const [name, setName] = useState("")
  const [num, setNum] = useState("")
  const [gmail, setGmail] = useState("")
  const [alreadyExists, setAlreadyExists] = useState(false)
  const [prof, setProf] = useState({ profilescreen: false })

  const forProfileScreen = useCallback(() => {
    setProf({ ...prof, profilescreen: !prof.profilescreen })
  }, [prof])

  const stateOptions = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Jammu and Kashmir",
    "Ladakh",
    "Puducherry",
  ]

  const [isChecked, setIsChecked] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { state } = useLocation()

  const brandList = useSelector((state) => state.brandList)
  const { brand } = brandList // get value of brands from redux

  const deliveryChargePrice = state?.checkoutTotal > 599 ? 0 : 49

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productoffer = useSelector((state) => state.productoffers)
  const { productoffers } = productoffer

  const addressSplit = userInfo?.Address?.split("*--*") ?? []

  // cart.deliveryCharge = (cart.itemsPrice > 599 ? 0 : 49).toFixed(2);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const profileData = {
    name: name?.length ? name : userInfo?.name,
    email: gmail?.length ? gmail : userInfo?.email,
    number: num?.length ? num : userInfo?.number,
  }

  const onSubmit = (data) => {
    console.log(data)

    if (isChecked === true) {
      dispatch(updateUserProfile(data))
    }

    dispatch(saveShippingAddress(data))

    if (gmail || num || name) {
      dispatch(updateUserProfile(profileData)).then((userData) => {
        if (userData) {
          setAlreadyExists(false)
          navigate("../placeorder", {
            state: { ...state, deliveryChargePrice },
            replace: true,
          })
        } else {
          setAlreadyExists(true)
        }
      })
    } else {
      navigate("../placeorder", {
        state: { ...state, deliveryChargePrice },
        replace: true,
      })
    }
  }

  const selectedAddress = () => {
    dispatch(
      saveShippingAddress({
        name: addressSplit[6]
          ? addressSplit[6] === "UnKnown"
            ? userInfo?.username
            : addressSplit[6]
          : userInfo?.username,
        number:
          num.length === 10
            ? num
            : addressSplit[5]?.length === 10
            ? addressSplit[5]
            : userInfo?.number,
        // email: gmail || userInfo?.email,
        houseNo: addressSplit[0],
        address: addressSplit[1],
        city: addressSplit[2],
        state: addressSplit[4],
        pincode: addressSplit[3],
      })
    )

    if (gmail || num || name) {
      dispatch(updateUserProfile(profileData)).then((userData) => {
        if (userData) {
          setAlreadyExists(false)
          navigate("/placeorder", { state: { ...state, deliveryChargePrice } })
        } else {
          setAlreadyExists(true)
        }
      })
    } else {
      navigate("/placeorder", { state: { ...state, deliveryChargePrice } })
    }
  }

  const regexEmail =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

  class RegexNumber {
    constructor(number) {
      this.number = number
    }

    isDigit() {
      return /^\d+$/.test(this.number)
    }

    isNumber() {
      return this.isDigit() && this.number.length === 10
    }
  }

  const validateUserData = () => {
    const isNumber =
      new RegexNumber(num).isNumber() ||
      new RegexNumber(addressSplit[5]).isNumber() ||
      new RegexNumber(userInfo?.number).isNumber()
    const isEmail = regexEmail.test(gmail) || regexEmail.test(userInfo?.email)
    const isName = name || userInfo.name !== "UnKnown"
    return isNumber && isEmail && isName
  }

  return (
    <>
      {/* <CheckoutSteps step1 /> */}

      <div className="py-5 2xl:pb-16  rounded-xl  font-style">
        <div className="flex justify-center  ">
          <div className=" mx-2  sm:mx-16 xl:mx-0  w-full 2xl:w-[80%] lg:pr-12 ">
            <p className="text-3xl font-style capitalize  md:px-4 flex justify-start  text-gray-900">
              shipping address
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-8 h-8 ml-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                />
              </svg>
            </p>

            <div className="xl:grid grid-cols-5 h-full ">
              <div
                className={`  w-full  xl:col-span-3   2xl:col-span-3   md:px-4 `}
              >
                <div
                  className={` ${
                    state?.cartProductItemsDataBase?.length > 2 &&
                    "lg:sticky md:top-40 "
                  }`}
                >
                  <div className=" my-2  order-last ">
                    <div className="flex w-full   border border-[rgb(119,200,141)] bg-white/10 backdrop-blur-[1px] p-4 items-center justify-between rounded-2xl   ">
                      <div className="w-full">
                        <div className="flex justify-between  items-center w-full ">
                          <div className="flex  ">
                            <p className="bg-[rgb(0,70,120)] mr-2 sm:mr-7  rounded-full w-7 h-7  flex justify-center items-center  text-white font-style">
                              A
                            </p>

                            <p className="font-style  mb-2 text-gray-900 text-lg ">
                              Login
                            </p>
                          </div>
                          {userInfo ? (
                            <ProfileScreen
                              forProfileScreen={forProfileScreen}
                              open={prof.profilescreen}
                            />
                          ) : (
                            ""
                          )}
                          <div
                            onClick={() => forProfileScreen()}
                            className=" bg-gradient-to-r from-[rgb(233,243,233)] to-[rgb(211,232,211)]  p-2 shadow-md text-black  hover:shadow-none text-xs rounded-xl"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                              />
                            </svg>
                          </div>
                        </div>

                        <div className=" md:ml-10 ">
                          <div className=" my-2 font-style  text-gray-600 flex items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5 mr-2 text-[rgb(0,53,96)]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                              />
                            </svg>
                            {userInfo?.name === "UnKnown" ? (
                              <div className=" w-full md:w-1/2 lg:w-[40%] my-3  pr-2 ">
                                <div className="relative">
                                  <input
                                    type="text"
                                    id="name"
                                    required
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    aria-invalid={!name ? "true" : "false"}
                                    className={`block px-2.5 pb-2.5  font-style pt-2 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                                      !name
                                        ? "border-red-300 border-2"
                                        : "border-gray-300 border"
                                    } appearance-none  focus:outline-none focus:ring-0  peer`}
                                    placeholder=" enter name "
                                  />
                                </div>

                                {!name && (
                                  <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                    <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <p>{userInfo?.name} </p>
                            )}
                          </div>
                          {!regexEmail.test(userInfo?.email) ? (
                            <div className="flex  items-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 mr-2  text-[rgb(0,53,96)]"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                />
                              </svg>

                              <div className=" w-full md:w-1/2 lg:w-[40%] my-3  pr-2  ">
                                <div className="relative">
                                  <input
                                    type="email"
                                    id="email"
                                    required={!gmail ? true : false}
                                    value={gmail}
                                    onChange={(e) => setGmail(e.target.value)}
                                    aria-invalid={!gmail ? "true" : "false"}
                                    className={`block px-2.5 pb-2.5  font-style pt-2 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                                      !gmail ||
                                      alreadyExists ||
                                      !regexEmail.test(gmail)
                                        ? "border-red-300 border-2"
                                        : "border-gray-300 border"
                                    } appearance-none  focus:outline-none focus:ring-0  peer`}
                                    placeholder=" enter email"
                                  />
                                </div>

                                {(!gmail ||
                                  alreadyExists ||
                                  !regexEmail.test(gmail)) && (
                                  <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                    <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                                  </div>
                                )}
                                {alreadyExists && (
                                  <p className="text-sm m-1 text-red-500 flex justify-end relative">
                                    email exists on another account
                                  </p>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="font-style  text-gray-600 flex items-center ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 mr-2  text-[rgb(0,53,96)]"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                />
                              </svg>
                              <p className="truncate">{userInfo?.email}</p>
                            </div>
                          )}

                          <div
                            className={`  items-center 
                            }  my-2 font-style  text-gray-600 flex `}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5 mr-2 text-[rgb(0,53,96)]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                              />
                            </svg>
                            {!new RegexNumber(userInfo?.number).isNumber() ? (
                              <div className=" w-full md:w-1/2 lg:w-[40%] my-3  pr-2 ">
                                <div className="relative">
                                  <input
                                    type="tel"
                                    id="number"
                                    required
                                    value={num}
                                    onChange={(e) => setNum(e.target.value)}
                                    aria-invalid={!num ? "true" : "false"}
                                    className={`block px-2.5 pb-2.5  font-style pt-2 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                                      !num ||
                                      num?.length !== 10 ||
                                      alreadyExists
                                        ? "border-red-300 border-2"
                                        : "border-gray-300 border"
                                    } appearance-none  focus:outline-none focus:ring-0  peer`}
                                    placeholder=" contact number"
                                  />
                                </div>

                                {(!num === "required" ||
                                  num?.length !== 10 ||
                                  alreadyExists) && (
                                  <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                    <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                                  </div>
                                )}
                                {num && num?.length !== 10 && (
                                  <span className="text-sm m-1 text-red-500 capitalize">
                                    contact no. must be (10) digits
                                  </span>
                                )}

                                {alreadyExists && (
                                  <p className="text-sm m-1 text-red-500 flex justify-end relative">
                                    number exists on another account
                                  </p>
                                )}
                              </div>
                            ) : (
                              <p className="truncate w-52 ">
                                {userInfo?.number}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {userInfo?.Address ? (
                    <div>
                      <div className="  py-2 rounded-2xl border shadow-md  mt-4 xl:mt-3 border-[rgb(100,193,223)] bg-white/10 backdrop-blur-[1px]">
                        <div className="flex   h-16   p-5 items-center justify-between rounded-xl  ">
                          <div className="flex justify-between  w-full items-center">
                            <div className=" flex ">
                              <p className="bg-zinc-800 mr-2 sm:mr-7  rounded-full w-7 h-7  flex justify-center items-center  text-white font-style">
                                B
                              </p>
                              <p className="font-style   text-gray-900 text-lg">
                                Shipping Address
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className=" m-3  ">
                          {/* <input type="radio" className="ml-5 mt-5"/> */}

                          <div className="ml-4 sm:ml-10  py-4">
                            <div className="my-2 font-style  text-gray-600 flex items-start  capitalize">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 mr-2 text-[rgb(0,53,96)]"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                                />
                              </svg>
                              <p className="truncate w-52 ">
                                {addressSplit[6] || userInfo?.name}
                              </p>
                            </div>
                            {userInfo?.number ? (
                              <div
                                className={`   my-2 font-style  text-gray-600 flex `}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5 mr-2 text-[rgb(0,53,96)]"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                                  />
                                </svg>

                                <p className="truncate w-52 ">
                                  {addressSplit[5] || userInfo?.number}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="mt-2 font-style  text-gray-600 flex items-start  capitalize">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 mr-2 mt-0.5 text-[rgb(0,53,96)]"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                />
                              </svg>

                              <div>
                                <span>{addressSplit[0]} ,</span>

                                <p className="flex flex-wrap">
                                  {addressSplit[1]},
                                </p>
                                <p className="flex flex-wrap">
                                  {addressSplit[2]}.
                                </p>

                                <p className="flex flex-wrap">
                                  {addressSplit[3]}-{addressSplit[4]}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className=" flex  md:justify-evenly   flex-wrap w-full lg:p-0  p-4  ">
                          <Button
                            onClick={() =>
                              validateUserData() ? selectedAddress() : ""
                            }
                            type="submit"
                            className="text-center lg:w-1/3 my-4 bg-primary hover:bg-[rgb(0,53,96,0.9)] h-12 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg   w-full"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5 mr-2"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                              />
                            </svg>
                            proceed to payment
                          </Button>
                          {address.buttonDiv && (
                            <>
                              {/* <p className="text-center w-full my-3 font-style lg:w-[10%] text-[rgb(0,53,96)]">
                              (or)
                            </p> */}
                              <Button
                                onClick={() => setAddress({ formDiv: true })}
                                className="text-center my-4 lg:w-1/3 border border-[rgb(0,53,96)] bg-[rgb(228,242,254)] text-primary h-12 flex justify-center items-center  text-[15px] font-style capitalize  rounded-lg   w-full"
                              >
                                send to another address
                              </Button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {(address.formDiv || !userInfo?.Address) && (
                    <div>
                      <div className="  py-2 rounded-2xl border  mt-4 xl:mt-3  border-[rgb(100,193,223)]">
                        <div className="flex   h-16   p-5 items-center justify-between rounded-xl  ">
                          <div className="flex justify-between  w-full items-center">
                            <div className=" flex ">
                              <p className="bg-zinc-800 mr-2 sm:mr-7  rounded-full w-7 h-7  flex justify-center items-center  text-white font-style">
                                {userInfo?.Address ? "C" : "B"}
                              </p>
                              <p className="font-style   text-gray-900 text-lg">
                                Shipping Address
                              </p>
                            </div>
                          </div>
                        </div>

                        <form
                          onSubmit={handleSubmit(onSubmit)}
                          autoComplete="off"
                        >
                          <div className=" p-2">
                            <div className=" flex  md:justify-evenly  flex-wrap w-full lg:my-3">
                              <div className=" w-full md:w-1/2 my-4 px-2 lg:w-[45%]    ">
                                <div className="relative  ">
                                  <input
                                    type="text"
                                    id="name"
                                    {...register("name", { required: true })}
                                    aria-invalid={
                                      errors.name ? "true" : "false"
                                    }
                                    className={`block px-2.5 pb-2.5  font-style pt-4 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                                      errors.name
                                        ? "border-red-300 border-2"
                                        : "border-gray-300 border"
                                    } appearance-none  focus:outline-none focus:ring-0  peer`}
                                    placeholder=" "
                                  />

                                  <label
                                    htmlFor="name"
                                    className=" after:content-['*'] after:ml-1 after:text-red-500 absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                                  >
                                    Full Name
                                  </label>
                                </div>
                                <div>
                                  {errors.name?.type === "required" && (
                                    <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                      <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className=" w-full md:w-1/2 lg:w-[45%] my-3  px-2">
                                <div className="relative">
                                  <input
                                    type="tel"
                                    id="number"
                                    {...register("number", {
                                      required: true,
                                      minLength: 10,
                                      maxLength: 10,
                                      pattern: {
                                        value: /^[0-9]+$/,
                                        message: "Please enter a valid number",
                                      },
                                    })}
                                    aria-invalid={
                                      errors.number ? "true" : "false"
                                    }
                                    className={`block px-2.5 pb-2.5  font-style pt-4 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                                      errors.number
                                        ? "border-red-300 border-2"
                                        : "border-gray-300 border"
                                    } appearance-none  focus:outline-none focus:ring-0  peer`}
                                    placeholder=" "
                                  />
                                  <label
                                    htmlFor="number"
                                    className=" after:content-['*'] after:ml-1 after:text-red-500   absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                                  >
                                    Contact Number
                                  </label>
                                </div>

                                {errors.number?.type === "required" && (
                                  <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                    <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                                  </div>
                                )}
                                {errors?.number &&
                                  errors?.number?.type === "minLength" && (
                                    <span className="text-sm m-1 text-red-500 capitalize">
                                      contact no. must be (10) digits
                                    </span>
                                  )}
                                {errors?.number &&
                                  errors?.number?.type === "maxLength" && (
                                    <span className="text-sm m-1 text-red-500">
                                      Max length (10) is exceeded
                                    </span>
                                  )}
                              </div>
                            </div>

                            <div className=" flex  md:justify-evenly  flex-wrap w-full  lg:my-3">
                              <div className=" w-full md:w-[30%] lg:w-[25%] my-3  px-2">
                                <div className=" relative">
                                  <input
                                    type="text"
                                    {...register("houseNo", { required: true })}
                                    aria-invalid={
                                      errors.houseNo ? "true" : "false"
                                    }
                                    id="houseNo"
                                    className={`block px-2.5 pb-2.5  font-style pt-4 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                                      errors.houseNo
                                        ? "border-red-300 border-2"
                                        : "border-gray-300 border"
                                    } appearance-none  focus:outline-none focus:ring-0  peer`}
                                    placeholder=" "
                                  />

                                  <label
                                    htmlFor="houseNo"
                                    className=" after:content-['*'] after:ml-1 after:text-red-500  absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                                  >
                                    Door No
                                  </label>
                                </div>
                                {errors.houseNo?.type === "required" && (
                                  <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                    <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                                  </div>
                                )}
                              </div>

                              <div className=" w-full md:w-[70%]  lg:w-[65%]  my-3 px-2">
                                <div className="relative">
                                  <input
                                    type="text"
                                    {...register("address", { required: true })}
                                    aria-invalid={
                                      errors.address ? "true" : "false"
                                    }
                                    id="address"
                                    className={`block px-2.5 pb-2.5  font-style pt-4 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                                      errors.address
                                        ? "border-red-300 border-2"
                                        : "border-gray-300 border"
                                    } appearance-none  focus:outline-none focus:ring-0  peer`}
                                    placeholder=" "
                                  />

                                  <label
                                    htmlFor="address"
                                    className="after:content-['*'] after:ml-1 after:text-red-500 absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                                  >
                                    Address
                                  </label>
                                </div>
                                {errors.address?.type === "required" && (
                                  <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                    <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className=" flex  md:justify-evenly  flex-wrap w-full lg:my-3">
                              <div className=" w-full  md:w-1/2 lg:w-[45%]  px-2  my-3">
                                <div className="relative">
                                  <input
                                    type="text"
                                    {...register("city", { required: true })}
                                    aria-invalid={
                                      errors.city ? "true" : "false"
                                    }
                                    id="city"
                                    className={`block px-2.5 pb-2.5  font-style pt-4 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                                      errors.city
                                        ? "border-red-300 border-2"
                                        : "border-gray-300 border"
                                    } appearance-none  focus:outline-none focus:ring-0  peer`}
                                    placeholder=" "
                                  />

                                  <label
                                    htmlFor="city"
                                    className="  after:content-['*'] after:ml-1 after:text-red-500  absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                                  >
                                    Town / City
                                  </label>
                                </div>
                                {errors.city?.type === "required" && (
                                  <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                    <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                                  </div>
                                )}
                              </div>

                              <div className=" w-full   md:w-1/2 lg:w-[45%]  px-2  my-3">
                                <select
                                  type="text"
                                  required
                                  placeholder="select state"
                                  {...register("state", { required: true })}
                                  aria-invalid={errors.state ? "true" : "false"}
                                  id="state"
                                  className={`block px-2.5 pb-2.5 font-style pt-4 w-full text-[15px] text-gray-500 bg-gray-100 rounded-lg  ${
                                    errors.state
                                      ? "border-red-300 border-2"
                                      : "border-gray-300 border"
                                  } appearance-none  focus:outline-none focus:ring-0  peer`}
                                >
                                  <option value="">select state</option>
                                  {console.log(stateOptions)}
                                  {stateOptions?.map((value, i) => {
                                    return (
                                      <option key={i} value={value}>
                                        {value}
                                      </option>
                                    )
                                  })}
                                </select>

                                {errors.state?.type === "required" && (
                                  <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                    <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                                  </div>
                                )}
                              </div>
                            </div>

                            <div
                              className={`  flex  
                                 md:justify-start lg:ml-7
                              flex-wrap w-full lg:my-3 `}
                            >
                              <div className="  w-full md:w-[40%] lg:w-[35%]  my-3 px-2 ">
                                <div className="relative">
                                  <input
                                    type="text"
                                    {...register("pincode", {
                                      required: true,
                                      minLength: 6,
                                      maxLength: 6,
                                      pattern: {
                                        value: /^[0-9]+$/,
                                        message: "Please enter a valid pincode",
                                      },
                                    })}
                                    aria-invalid={
                                      errors.pincode ? "true" : "false"
                                    }
                                    id="pinCode"
                                    className={`block px-2.5 pb-2.5  font-style pt-4 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
                                      errors.pincode
                                        ? "border-red-300 border-2"
                                        : "border-gray-300 border"
                                    } appearance-none  focus:outline-none focus:ring-0  peer`}
                                    placeholder=" "
                                  />

                                  <label
                                    htmlFor="pinCode"
                                    className=" after:content-['*'] after:ml-1 after:text-red-500 absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                                  >
                                    Pincode
                                  </label>
                                </div>
                                <p className="text-sm m-1 text-red-500">
                                  {errors.pincode?.message}
                                </p>
                                {errors.pincode?.type === "required" && (
                                  <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                    <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                                  </div>
                                )}
                                {errors?.pincode &&
                                  errors?.pincode?.type === "minLength" && (
                                    <span className="text-sm m-1 text-red-500 capitalize">
                                      pincode must be (6) digits
                                    </span>
                                  )}
                                {errors?.pincode &&
                                  errors?.pincode?.type === "maxLength" && (
                                    <span className="text-sm m-1 text-red-500">
                                      Max length (6) is exceeded
                                    </span>
                                  )}
                              </div>
                            </div>

                            <div className=" flex  md:justify-start lg:mx-[4%] my-3  flex-wrap ml-3  lg:my-4">
                              <input
                                type="checkbox"
                                id="default"
                                className="w-5 h-5  mr-2 accent-[rgb(0,53,96)] "
                                placeholder=" "
                                value={isChecked}
                                onChange={() => setIsChecked(!isChecked)}
                              />

                              <label
                                htmlFor="default"
                                className=" text-sm text-gray-600 font-style capitalize "
                              >
                                use this my default address
                              </label>
                            </div>

                            <div className=" flex  md:justify-center  my-2 flex-wrap w-full lg:p-0  p-4 ">
                              <Button
                                disabled={!validateUserData()}
                                type="submit"
                                className="text-center lg:w-1/2 bg-primary hover:bg-[rgb(47,108,158)] h-12 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg  my-4  w-full"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5 mr-2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z"
                                  />
                                </svg>
                                delivery here
                              </Button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}

                  {/* <div className=" my-2 rounded-2xl bg-white">
                <div>
                  <div className="flex w-full  h-20 border rounded-2xl  p-5 items-center justify-between   ">
                    <div className="flex justify-between   items-center">
                      <p className="bg-zinc-500   rounded-full w-7 h-7  flex justify-center items-center  text-white font-style">
                        C
                      </p>
                      <div className=" ml-7">
                        <p className="font-style  text-gray-600 text-lg">
                          Payment Method
                        </p>
                      </div>
                    </div>

                    <div className=" bg-gradient-to-r border-1 p-2 shadow-none text-black  hover:shadow-none text-xs rounded-xl">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div> */}
                </div>
              </div>

              <div className=" w-full xl:col-span-2 md:px-4 xl:px-0  2xl:col-span-2    ">
                <div
                  className={` p-4   mt-4   xl:mt-2  ${
                    state?.cartProductItemsDataBase?.length < 2 &&
                    "lg:sticky md:top-40 "
                  }  border shadow-md border-[rgb(240,203,137)] rounded-xl bg-white/10 backdrop-blur-[1px]  my-2 divide-y-2 divide-dotted `}
                >
                  <div className="flex justify-start    items-center">
                    <p className="ml-2 mb-2  font-bold   text-gray-700 text-lg">
                      Order Summary ( {state?.cartProductItemsDataBase?.length}
                      items)
                    </p>
                  </div>
                  {state?.cartProductItemsDataBase?.map((cartitem, i) => {
                    const dataBaseQty = state?.cartItemsDataBase?.find(
                      (obj) => {
                        return obj.product_item === cartitem.id
                      }
                    )

                    const isProductOffer = productoffers.find(
                      (val) => val.product === cartitem.product.id
                    )

                    const brandName = brand?.filter(
                      (name) => name?.id === cartitem?.product?.brand
                    )[0]

                    return (
                      <div className={` my-3`} key={i}>
                        {isProductOffer && (
                          <div className="capitalize mt-2 -mb-3 text-xs sm:text-sm  text-[rgb(0,53,96)] bg-gradient  font-style  rounded-sm flex justify-between">
                            <p className="p-2 flex items-center ">
                              <svg
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 mr-1"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                                />
                              </svg>
                              {isProductOffer?.offer?.value}
                              {isProductOffer?.offer?.type === "discount" &&
                                "%"}
                              Offer Applied
                            </p>
                          </div>
                        )}
                        <div
                          className={`flex  items-start  justify-between overflow-hidden rounded-lg pr-2  ${
                            isProductOffer ? "bg-gradient " : ""
                          } `}
                        >
                          <div className="flex  items-start   capitalize text-gray-800  w-full my-3 ">
                            <div className=" rounded-xl  w-16 h-24 md:w-24 md:h-32  m-1">
                              <img
                                className=" rounded-xl w-16 h-24 md:w-24 md:h-32 object-contain"
                                src={cartitem?.image}
                                alt="ordered items img"
                              />
                            </div>
                            <div className=" ml-3 text-xs md:text-[14px]  pt-1">
                              <p className="text-xs md:text-sm font-style text-gray-600 ">
                                {brandName?.name}
                              </p>

                              <p className="font-style w-32 xs:w-full  text-gray-600 truncate md:my-1">
                                {cartitem?.product.name}
                              </p>

                              <div className=" text-xs md:text-[14px] ">
                                <p className="text-xs md:text-sm font-style text-start  text-gray-400 md:my-1">
                                  size :
                                  <span className=" text-[rgb(0,53,96)]">
                                    {cartitem?.size.name}
                                  </span>
                                </p>
                                <p className="font-style text-start  text-gray-400 md:my-1">
                                  color :
                                  <span className=" text-[rgb(0,53,96)]">
                                    {cartitem?.color.name}
                                  </span>
                                </p>
                              </div>
                              <div className="flex text-xs md:text-sm font-style text-start  text-gray-400 md:my-1">
                                <p className="">qty : </p>
                                <p className=" text-sm  text-primary mx-2">
                                  {dataBaseQty?.quantity}
                                </p>
                              </div>
                              {isProductOffer?.offer?.type === "discount" ? (
                                <div>
                                  <p className="flex items-center text-gray-400 font-style  text-sm">
                                    price :
                                    <span className="text-primary  ml-2">
                                      ₹
                                      {Number(
                                        cartitem.product.price -
                                          cartitem.product?.price *
                                            (isProductOffer?.offer?.value / 100)
                                      ).toFixed(2)}
                                    </span>
                                    <del className="hidden xs:block text-xs ml-1">
                                      ₹{cartitem.product?.price}
                                    </del>
                                  </p>
                                </div>
                              ) : (
                                <div className="flex text-xs md:text-sm font-style text-start  text-gray-400 md:my-1">
                                  <p className="">price: </p>
                                  <p className=" text-sm  text-primary mx-2">
                                    ₹{cartitem?.product.price}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="pt-1  my-3 capitalize text-xs md:text-[14px] font-style text-end  text-gray-600 ">
                            <p className="mb-1">Total </p>
                            <div className="jsutify-end w-14 text-xs md:text-[14px]  flex-1 text-[rgb(0,53,96)]">
                              {isProductOffer?.offer?.type === "discount" ? (
                                <p>
                                  ₹
                                  {Number(
                                    (cartitem.product.price -
                                      cartitem.product?.price *
                                        (isProductOffer?.offer?.value / 100)) *
                                      dataBaseQty?.quantity
                                  ).toFixed(2)}
                                </p>
                              ) : (
                                <p>
                                  ₹
                                  {(
                                    Number(dataBaseQty?.quantity) *
                                    Number(cartitem?.product.price)
                                  ).toFixed(2)}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  <div className=" divide-y-2 divide-dotted text-gray-600  rounded-b-xl m-2 font-style ">
                    {state?.discountPrice > 1 ? (
                      <>
                        <div className="m-1 pt-2 flex justify-between">
                          <div> Subtotal</div>
                          <div>
                            ₹
                            {(
                              Number(state?.checkoutTotal) +
                              Number(state?.discountPrice)
                            )?.toFixed(2)}
                          </div>
                        </div>

                        <div className="m-1 pt-2 flex justify-between">
                          <div> Promotion applied </div>
                          <div>
                            - ₹ {Number(state?.discountPrice).toFixed(2)}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="m-1 pt-2 flex justify-between">
                        <div> Subtotal</div>
                        <div>₹ {state?.checkoutTotal} </div>
                      </div>
                    )}

                    <div className="m-1  flex justify-between">
                      <div> Delivery Charge</div>
                      {deliveryChargePrice ? (
                        <div>₹ {deliveryChargePrice.toFixed(2)}</div>
                      ) : (
                        <p>Free</p>
                      )}
                    </div>
                    <div className="m-1 text-lg font-bold py-1 flex justify-between text-gray-700">
                      <div>Order Total</div>
                      <div>
                        ₹
                        {(
                          Number(state?.checkoutTotal) +
                          Number(deliveryChargePrice)
                        ).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShippingScreen

// {userInfo?.email === userInfo?.number ? (
//   <div className=" w-full md:w-[60%] my-3 px-2 lg:w-[55%]   ">
//     <div className="relative  ">
//       <input
//         type="email"
//         id="email"
//         {...register("email", { required: true })}
//         aria-invalid={
//           errors.email ? "true" : "false"
//         }
//         className={`block px-2.5 pb-2.5  font-style pt-4 w-full text-[15px] text-gray-700 bg-gray-100 rounded-lg  ${
//           errors.name
//             ? "border-red-300 border-2"
//             : "border-gray-300 border"
//         } appearance-none  focus:outline-none focus:ring-0  peer`}
//         placeholder=" "
//       />

//       <label
//         htmlFor="name"
//         className=" after:content-['*'] after:ml-1 after:text-red-500 absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
//       >
//         Email
//       </label>
//     </div>
//     <div>
//       {errors.email?.type === "required" && (
//         <div className="text-sm m-1 text-red-500 flex justify-end relative">
//           <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
//         </div>
//       )}
//     </div>
//   </div>
// ) : (
//   ""
// )}
