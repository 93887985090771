import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails, updateUserProfile } from "../actions/userActions";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";
import { listMyOrders } from "../actions/orderActions";
import { AiOutlineClose } from "react-icons/ai";
import { IoIosArrowBack } from "react-icons/io";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { ExclamationCircleIcon, UserIcon } from "@heroicons/react/24/outline";
import Drawer from "@mui/material/Drawer";
import { logout } from "../actions/userActions";
import { useForm } from "react-hook-form";
import { Button } from "@material-tailwind/react";
import Writereview from "../components/Writereview";
import orderemptyimage from "../images/Screenshot 2022-11-18 133605.jpg";
import { Link, useNavigate } from "react-router-dom";
import { listCartDataBase } from "../actions/cartActions";
import Loader from "../components/Loader";
import { firebase } from "../components/firebase";
import Feedback from "../components/Feedback";
// import Contact from "../components/Contact";
import axios from "axios";
import cancel from "../images/cancel-order.jpg";

function ProfileScreen({ forProfileScreen, open }) {
  const [profile, setProfile] = useState({
    prof: true,
    mypro: false,
    address: false,
    myorder: false,
  });
  const stateOptions = [
    "select state",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
  ];
  const forProfileScreenHandle = () => {
    setProfile({
      prof: true,
      mypro: false,
      address: false,
      myorder: false,
      orderhistory: false,
      trackOrder: false,
    });
    forProfileScreen();
  };

  const {
    register,

    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const isEmail = (email) => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
  };

  const {
    register: register2,
    formState: { errors: errors2 },
    setValue: setValue2,
    handleSubmit: handleSubmit2,
  } = useForm();

  const onSubmit = (data) => {
    dispatch(updateUserProfile(data));
    saveProfile("your Profile");
  };

  const AddressSubmit = (data) => {
    dispatch(updateUserProfile(data));
    saveProfile("Your Address");
  };

  const navigate = useNavigate();

  const [reviewOpen, setReviewOpen] = useState(false);

  const [feedbackOpen, setFeedBackOpen] = useState(false);

  const [detailProduct, setDetailProduct] = useState("");

  const [trackDetail, setTrackDetail] = useState({});

  const logoutHandler = () => {
    // googleLogout();
    dispatch(logout());
    const LsCart = localStorage.getItem("cartItems");
    dispatch(listCartDataBase(0, LsCart ? LsCart : []));

    if (userInfo?.googleLogin === true) {
      firebase.auth().signOut();
    }
    navigate("/");
    LogoutAlert();
    window.location.reload();
  };

  const forReview = () => {
    setReviewOpen(!reviewOpen);
  };

  const forFeedback = () => {
    setFeedBackOpen(!feedbackOpen);
  };

  const [reviewProductId, setReviewProductId] = useState();

  const review = (id) => {
    forReview();
    setReviewProductId(id);
  };

  const dispatch = useDispatch();

  // const userDetails = useSelector((state) => state.userDetails);
  // const { user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const addressSplit = userInfo?.Address?.split("*--*");

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const orderListMy = useSelector((state) => state.orderListMy);
  const { orders } = orderListMy;

  const productoffer = useSelector((state) => state.productoffers);
  const { productoffers } = productoffer;

  // loading: loadingOrders, error: errorOrders,
  useEffect(() => {
    if (userInfo) {
      if (!userInfo || !userInfo?.username || success) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails("profile"));
      }
      if (!orders?.length) {
        dispatch(listMyOrders());
      }
      if (userInfo || (!userUpdateProfile?.error && success)) {
        setValue("name", userInfo?.username);
        setValue("email", userInfo?.email);
        setValue("number", userInfo?.number);
      }
      if (userInfo?.Address) {
        setValue2("houseNo", addressSplit[0]);
        setValue2("address", addressSplit[1]);
        setValue2("city", addressSplit[2]);
        setValue2("pincode", addressSplit[3]);
        setValue2("state", addressSplit[4]);
      }
    }
  }, [userInfo]);

  const Swal = require("sweetalert2");

  const LogoutAlert = (value) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: false,
      timer: 2000,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: `You'r Logged Out`,
    });
  };

  // const numberExist = (value) => {
  //   const Toast = Swal.mixin({
  //     toast: true,
  //     position: "center",
  //     showConfirmButton: false,
  //     timer: 2000,
  //     didOpen: (toast) => {
  //       toast.addEventListener("mouseenter", Swal.stopTimer);
  //       toast.addEventListener("mouseleave", Swal.resumeTimer);
  //     },
  //   });

  //   Toast.fire({
  //     icon: "warning",
  //     title: `${value}`,
  //   });
  // };

  const saveProfile = (value) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: false,
      timer: 2000,

      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: `${value} saved`,
    });
  };

  if (orders !== "no_orders") {
    var detailOfOrder = [];

    detailOfOrder = orders?.filter(
      (o) => Number(o.id) === Number(detailProduct)
    );
  }

  const orderDetails = () => {
    setProfile({
      mypro: false,
      prof: false,
      address: false,
      myorder: false,
      orderhistory: true,
    });
  };

  const orderStatus = () => {
    let orderPlaced = "";
    let orderProcessing = "";
    let orderDispatched = "";
    let outForDelivery = "";
    let orderDelivered = "";

    const switchValue =
      Object.keys(trackDetail).length &&
      trackDetail?.shipment_track[0]?.current_status;

    switch (switchValue) {
      case "Delivered":
        orderPlaced = "green";
        orderProcessing = "green";
        orderDispatched = "green";
        outForDelivery = "green";
        orderDelivered = "green";
        break;
      case "Out for Delivery":
        orderPlaced = "green";
        orderProcessing = "green";
        orderDispatched = "green";
        outForDelivery = "orange";
        orderDelivered = "gray-500";
        break;
      case "Shipped":
        orderPlaced = "green";
        orderProcessing = "green";
        orderDispatched = "green";
        outForDelivery = "orange";
        orderDelivered = "gray-500";
        break;
      case "In Transit":
        orderPlaced = "green";
        orderProcessing = "green";
        orderDispatched = "orange";
        outForDelivery = "gray-500";
        orderDelivered = "gray-500";
        break;
      case "Pickup Generated":
        orderPlaced = "green";
        orderProcessing = "orange";
        orderDispatched = "gray-500";
        outForDelivery = "gray-500";
        orderDelivered = "gray-500";
        break;
      default:
        orderPlaced = "green";
        orderProcessing = "gray-500";
        orderDispatched = "gray-500";
        outForDelivery = "gray-500";
        orderDelivered = "gray-500";
    }

    return {
      orderPlaced,
      orderProcessing,
      orderDispatched,
      outForDelivery,
      orderDelivered,
    };
  };

  // const trackOrderProgress = orderStatus("processing");

  const tracking = async (id) => {
    setProfile({
      mypro: false,
      prof: false,
      adress: false,
      myorder: false,
      orderhistory: false,
      trackOrder: true,
    });

    const orderId = id;
    const { data } = await axios.get(
      `/api/shipping/order-tracking/?orderId=${orderId}`
    );
    if (data?.length) {
      setTrackDetail(data[0].tracking_data);
    }
  };

  const trackOrderProgress = orderStatus();

  return (
    <div className="select-none">
      <Drawer
        className=""
        anchor={"right"}
        transitionDuration={500}
        open={open}
        onClose={() => forProfileScreen()}
      >
        <div className="pointer-events-auto  w-screen xs:w-[27rem] md:w-[28rem] font-poppins">
          <div className="flex flex-col  h-screen   bg-white ">
            {/* <----------------------------------profile area-----------------------------------------------------> */}
            {profile.prof && (
              <div>
                <div
                  className="h-16 bg-white  sticky z-10 top-0 items-center flex justify-between shadow-lg p-4 rounded-b-xl bg-gradient"
                  // style={{
                  //   background:
                  //     "linear-gradient(49deg, rgba(227,242,255,1) 0%, rgba(249,247,240,1) 87%)",
                  // }}
                >
                  <div className="flex ml-3 ">
                    <p className="text-lg  ml-1 font-style capitalize">
                      my profile
                    </p>
                    <UserIcon className="w-5 h-5 ml-1 mt-1 " />
                  </div>
                  <div className=" flex  items-center ">
                    <div
                      className="  "
                      onClick={() => forProfileScreenHandle()}
                    >
                      <AiOutlineClose className="w-6 h-6    hover:scale-125 text-gray-800 " />
                    </div>
                  </div>
                </div>
                <div className=" style-4">
                  <div className="shadow-lg border-[0.3px]    m-3 sm:m-6 py-4 rounded-lg text-center bg-gray-50 pb-8">
                    <div className="flex  w-[100%] justify-center items-center">
                      <div className="flex justify-center capitalize items-center">
                        <div className="w-16 h-16 lg:w-20 lg:h-20 text-gray-900 shadow-md  rounded-full bg-gray-300 flex justify-center capitalize items-center font-bold text-3xl lg:text-5xl">
                          {userInfo?.username?.slice(0, 1)}
                        </div>
                      </div>
                    </div>

                    <div className="  w-full  my-3 ">
                      <div className="  text-lg text-center font-style text-gray-700 capitalize">
                        {userInfo?.username}
                      </div>

                      {userInfo?.number ? (
                        <div className="text-sm  flex items-center justify-center  text-center text-gray-500">
                          {userInfo?.number?.slice(0, 1)}******
                          {userInfo?.number?.slice(7, 10)}
                        </div>
                      ) : (
                        <p className="text-sm capitalize font-style">
                          (edit profile to add phone number)
                        </p>
                      )}
                      <div className="   text-center truncate   text-xs lg:text-sm font-style text-gray-500">
                        {!isEmail(userInfo?.email)
                          ? " Email not added "
                          : userInfo?.email}
                      </div>
                    </div>
                    {userInfo?.Address ? (
                      <div className=" px-3   py-3 m-5 rounded-xl border  text-lg font-style  shadow-md bg-[rgb(221,235,248)] text-start  ">
                        <p className="text-xl  text-gray-800 mb-2 italic">
                          Address
                        </p>
                        <div className="text-sm text-gray-500 capitalize">
                          <div className="flex items-start">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5 mr-2 mt-0.5 text-[rgb(0,53,96)]"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                              />
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                              />
                            </svg>
                            <p className="">
                              {addressSplit[6] || userInfo?.name} ,
                              <br />
                              {addressSplit[5] || userInfo?.number} ,
                              <br />
                              {addressSplit[0]} ,
                              <br />
                              {addressSplit[1]}
                              <br />
                              {addressSplit[2]}
                              <br />
                              {addressSplit[3]}-{addressSplit[4]}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="mt-4 px-3  py-3 m-5"
                        onClick={() =>
                          setProfile({
                            myprof: false,
                            prof: false,
                            address: true,
                          })
                        }
                      >
                        <Button className="rounded-lg w-full outline-none h-32 hover:bg-[rgb(237,245,252)] bg-[rgb(223,223,223)] border border-gray-300 shadow-md  flex justify-center items-center">
                          <div>
                            <AiOutlinePlusCircle className="w-7 h-7 ml-12  text-primary " />
                            <p className="text-start text-primary text-sm  ">
                              ADD YOUR ADDRESS
                            </p>
                          </div>
                        </Button>
                      </div>
                    )}

                    <div className=" h-full    ">
                      <div className="   flex flex-wrap justify-center   items-center">
                        <div
                          className="w-[42%] h-28 m-1 sm:m-3 flex justify-center border  bg-gray-100 hover:bg-gray-200  rounded-xl hover:text-[rgb(0,56,93)] text-center capitalize text-lg py-3 font-style text-gray-700 items-center"
                          onClick={() => {
                            setProfile({
                              mypro: false,
                              prof: false,
                              address: false,
                              myorder: true,
                            });
                            // dispatch(listMyOrders());
                          }}
                        >
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5 ml-8"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25"
                              />
                            </svg>
                            <p>my orders</p>
                          </div>
                        </div>
                        <div
                          className="w-[42%] h-28 m-1 sm:m-3 flex justify-center  bg-gray-100 hover:bg-gray-200 rounded-xl hover:text-[rgb(0,56,93)] text-center border capitalize text-lg py-3 font-style text-gray-700 items-center"
                          onClick={() =>
                            setProfile({
                              mypro: true,
                              prof: false,
                              address: false,
                            })
                          }
                        >
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5 mt-1  hover:text-[rgb(0,56,93)] ml-8"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                              />
                            </svg>
                            <p>Edit profile</p>
                          </div>
                        </div>
                        {/* <div className="w-[42%] h-28  m-3 flex justify-center bg-gray-100 hover:bg-gray-200 rounded-xl hover:text-[rgb(0,56,93)] text-center border capitalize text-lg py-3 font-style text-gray-700 items-center">
                                <div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5  hover:text-[rgb(0,56,93)]  ml-5 "
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                                    />
                                  </svg>
                                  <p> wishlist </p>
                                </div>
                              </div> */}
                        <div
                          className="w-[42%] h-28 m-1 sm:m-3 flex justify-center bg-gray-100  hover:bg-gray-200 rounded-xl hover:text-[rgb(0,56,93)] text-center border capitalize text-lg py-3 font-style text-gray-700 items-center"
                          onClick={() =>
                            setProfile({
                              mypro: false,
                              prof: false,
                              address: true,
                            })
                          }
                        >
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5  hover:text-[rgb(0,56,93)]  ml-10 "
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                              />
                            </svg>
                            <p> Edit Address</p>
                          </div>
                        </div>
                        {/* <div
                            className="w-[42%] h-28 m-1  sm:m-3 flex justify-center bg-gray-100 hover:bg-gray-200 rounded-xl  hover:text-[rgb(0,56,93)] text-center border capitalize text-lg py-3 font-style text-gray-700 items-center"
                            onClick={() => logoutHandler()}
                          >
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 mt-1  hover:text-[rgb(0,56,93)]  ml-5 "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                                />
                              </svg>
                              Logout
                            </div>
                          </div> */}
                        {/* {!userInfo.googleLogin ? (
                          <div
                            className="w-[42%] h-28 m-1  sm:m-3 flex justify-center bg-gray-100 hover:bg-gray-200 rounded-xl  hover:text-[rgb(0,56,93)] text-center border capitalize text-lg py-3 font-style text-gray-700 items-center"
                            onClick={() => logoutHandler()}
                          >
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 mt-1  hover:text-[rgb(0,56,93)]  ml-5 "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                                />
                              </svg>
                              Logout
                            </div>
                          </div>
                        ) : (
                          <div className="w-[42%] h-28 m-1  sm:m-3 flex justify-center bg-gray-100 hover:bg-gray-200 rounded-xl  hover:text-[rgb(0,56,93)] text-center border capitalize text-lg py-3 font-style text-gray-700 items-center">
                            <div className="relative">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 mt-1  hover:text-[rgb(0,56,93)]  flex top-[37%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                                />
                              </svg>
                              <LoginGoogle logoutHandler={logoutHandler} />
                            
                            </div>
                          </div>
                        )} */}
                        <div
                          className="w-[42%] h-28 m-1  sm:m-3 flex justify-center bg-gray-100 hover:bg-gray-200 rounded-xl  hover:text-[rgb(0,56,93)] text-center border capitalize text-lg py-3 font-style text-gray-700 items-center"
                          onClick={() => logoutHandler()}
                        >
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-5 h-5 mt-1  hover:text-[rgb(0,56,93)]  ml-5 "
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                              />
                            </svg>
                            Logout
                          </div>
                        </div>

                        <div />

                        {/* <div className=' '>
                                                    <GiHumanTarget className="w-10 h-10 ml-6  mt-4  hover:text-red-400 font-thin" />
                                                    <p className='mr-3 mt-3 hover:text-red-400 text-center text-base'>MY PROFILE</p>
                                                </div>
                                                <div className=''>
                                                    <BsHeart className="w-10 h-10  mt-4 ml-3 hover:text-red-400" />
                                                    <p className=' mt-3 mr-8 hover:text-red-400 text-right text-base'>WISHLIST</p>
                                                </div> */}
                      </div>
                      {/* <Contact
                        forContact={forContact}
                        openContact={contactOpen}
                      /> */}
                      <div className=" flex justify-center w-full  items-end left-0 font-style  ">
                        <div
                          className="mt-4 w-1/2 text-center border-r-2"
                          // onClick={forContact}
                        >
                          <Link to={"/contact-us"}>
                            <div
                              className="hover:text-[rgb(0,56,93)] text-lg text-gray-700"
                              onClick={forProfileScreen}
                            >
                              Contact us
                            </div>
                          </Link>
                        </div>
                        <Feedback
                          forFeedback={forFeedback}
                          openFeedback={feedbackOpen}
                        />
                        <div
                          className="mt-4 w-1/2 text-center"
                          onClick={forFeedback}
                        >
                          <h5 className="hover:text-[rgb(0,56,93)] text-lg text-gray-700">
                            Feed back
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* <-------------------------------------------------------------------------MY ORDER area------------------------------------------------------------------> */}
            {profile.myorder && (
              <div className="h-screen">
                <div className="h-16 bg-white  sticky z-10 top-0 items-center  w-screen xs:w-[27rem] md:w-[28rem]  flex justify-between bg-gradient shadow-lg  p-4 rounded-b-xl">
                  <IoIosArrowBack
                    className="w-6 h-6  text-gray-800"
                    onClick={() =>
                      setProfile({
                        mypro: false,
                        prof: true,
                        adress: false,
                        myorder: false,
                      })
                    }
                  />
                  <div className="flex ml-3 items-center">
                    <p className="text-lg   font-style capitalize">My order</p>
                  </div>
                  <div className=" flex  items-center ">
                    <div
                      className="  "
                      onClick={() => forProfileScreenHandle()}
                    >
                      <AiOutlineClose className="w-7 h-7    hover:scale-110 text-gray-800" />
                      <span className="sr-only">Close panel</span>
                    </div>
                  </div>
                </div>

                {orders === "no_orders" ? (
                  <div className="flex justify-center items-center h-screen">
                    <div className="">
                      <img src={orderemptyimage} alt="" />
                      <div className="flex justify-center w-full">
                        <p className="text-center text-gray-700 capitalize text-xl font-style w-64">
                          its seems you dont make any orders yet !!
                        </p>
                      </div>
                      <div className="flex justify-center mt-4">
                        <Button
                          onClick={() => forProfileScreenHandle()}
                          className="text-center w-1/2 bg-primary  h-12 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg  my-4   "
                        >
                          shop now
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : orderListMy.loading ? (
                  <Loader />
                ) : (
                  <>
                    <div className="h-[80%]  mx-5 ">
                      <p className=" flex items-center text-xl text-gray-900 font-bold  italic  p-2  bg-white w-full ">
                        Orders
                        {/* <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-5 h-5 ml-2  mt-1 "
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                                    />
                                  </svg> */}
                      </p>

                      <div
                        className={` h-[90%] ${
                          orders?.length >= 5 ? "style-4 overflow-y-scroll" : ""
                        }   rounded-xl divide-y  border`}
                      >
                        {orders?.map((order, i) => {
                          return (
                            <div className="px-3 pt-2 " key={i}>
                              <div
                                className=" flex font-style text-gray-700 justify-between rounded-xl shadow-md bg-gray-100 p-2 items-center mb-2 "
                                key={i}
                              >
                                <div className="">
                                  <p className="text-[rgb(0,53,96)] ">
                                    Order :
                                    <span className="text-gray-500 ">
                                      #{order?.id}
                                    </span>
                                  </p>
                                  <p className="text-sm  text-[rgb(0,53,96)]">
                                    Order placed on :
                                    <span className="text-gray-500">
                                      {new Date(
                                        order?.createdAt
                                      ).toDateString()}
                                    </span>
                                  </p>
                                  <p className="text-[rgb(0,53,96)] text-sm  ">
                                    Order items :
                                    <span className="text-gray-500">
                                      ({order?.orderItems?.length})
                                    </span>
                                  </p>
                                  <p className="text-[rgb(0,53,96)] text-sm ">
                                    Paid amount :
                                    <span className="text-gray-500">
                                      ₹
                                      {(
                                        Number(order.totalPrice) +
                                        Number(order.shippingPrice)
                                      ).toFixed(2)}
                                    </span>
                                  </p>
                                  {/* <p className="text-green-700 text-xs mt-8">
                                  Delivered Date :
                                  <span className="text-[rgb(0,53,96)] ml-1">
                                    
                                    01/10/2022
                                  </span>
                                </p> */}
                                </div>
                                <div className="flex flex-col justify-center">
                                  {order?.orderItems.map((orderitem, i) => {
                                    if (i < 1) {
                                      return (
                                        <div className="w-12  ml-5" key={i}>
                                          <Link
                                            to={`/product/${Number(
                                              orderitem?.product
                                            )}?clr=0`}
                                          >
                                            <img
                                              src={`${orderitem?.image}`}
                                              alt={`Myunde ${
                                                orderitem.name ??
                                                "Speciallty for men"
                                              } `}
                                              className="w-12 my-2 h-16 object-contain text-xs"
                                            />
                                          </Link>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>

                              <div className="flex  justify-center w-full  bg-white rounded-md  items-center">
                                {/* <button className="border-r-2 h-8 hover:text-[rgb(43,99,146)] font-style  capitalize text-primary  text-xs w-[50%]  flex justify-center items-center ">
                                <svg
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-4 mr-1 h-4 -ml-2"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                  />
                                </svg>
                                cancel order
                              </button> */}
                                <div
                                  className=" h-10 border-b cursor-pointer  font-style  capitalize text-primary  w-[50%] text-xs shadow-none  flex justify-center items-center "
                                  value={order?.id}
                                  onClick={(e) => {
                                    orderDetails();
                                    setDetailProduct(
                                      e.target.getAttribute("value")
                                    );
                                  }}
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-3 h-3 mx-2 -ml-2"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 004.25 22.5h15.5a1.875 1.875 0 001.865-2.071l-1.263-12a1.875 1.875 0 00-1.865-1.679H16.5V6a4.5 4.5 0 10-9 0zM12 3a3 3 0 00-3 3v.75h6V6a3 3 0 00-3-3zm-3 8.25a3 3 0 106 0v-.75a.75.75 0 011.5 0v.75a4.5 4.5 0 11-9 0v-.75a.75.75 0 011.5 0v.75z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  view order
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {orders?.length >= 5 && (
                      <p className="text-sm text-center text-primary">
                        Scroll to see more
                      </p>
                    )}
                  </>
                )}
              </div>
            )}
            {/* <-------------------------------------------------------------------------ORDER HISTORY AREA ------------------------------------------------------------------> */}
            {profile.orderhistory && (
              <div>
                <div
                  className="h-16 bg-white items-center sticky
                  top-0 flex justify-between shadow-lg bg-gradient  p-4 rounded-b-xl"
                  // style={{
                  //   background:
                  //     "linear-gradient(49deg, rgba(227,242,255,1) 0%, rgba(249,247,240,1) 87%)",
                  // }}
                >
                  <IoIosArrowBack
                    className="w-6 h-6  text-gray-800"
                    onClick={() =>
                      setProfile({
                        mypro: false,
                        prof: false,
                        adress: false,
                        myorder: true,
                        orderhistory: false,
                      })
                    }
                  />
                  <div className="flex ml-3 items-center">
                    <p className="text-lg   font-style capitalize">
                      order details
                    </p>
                  </div>
                  <div className=" flex  items-center ">
                    <div
                      className="  "
                      onClick={() => forProfileScreenHandle()}
                    >
                      <AiOutlineClose className="w-7 h-7    hover:scale-110 text-gray-800" />
                      <span className="sr-only">Close panel</span>
                    </div>
                  </div>
                </div>

                {detailOfOrder?.map((orderDetail, i) => {
                  return (
                    <div
                      key={i}
                      className="p-4 font-style text-gray-700 text-sm"
                    >
                      <div className="flex justify-between py-2 ">
                        <p className="text-xl text-gray-900 flex items-center font-bold  italic">
                          Order info
                          {/* <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="rgb(0,53,96)"
                            className="w-5 h-5 ml-1 mt-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                            />
                          </svg> */}
                        </p>
                        <div
                          onClick={() =>
                            setProfile({
                              mypro: false,
                              prof: false,
                              adress: false,
                              myorder: true,
                              orderhistory: false,
                            })
                          }
                          className="text-sm  text-primary cursor-pointer"
                        >
                          see all orders
                        </div>
                      </div>
                      <div className="flex justify-between my-3 bg-gray-100 p-3 rounded-xl shadow-md">
                        <p className=" w-24 font-style text-xs text-start text-[rgb(0,53,96)]">
                          Order#
                          <span className=" text-sm font-bold text-gray-700">
                            {orderDetail?.id}
                          </span>
                        </p>
                        <div className="text-end text-xs text-[rgb(0,53,96)]">
                          Order placed on
                          <p className="w-32 text-sm font-bold text-gray-700">
                            {new Date(orderDetail?.createdAt).toDateString()}
                          </p>
                        </div>
                      </div>
                      <div
                        className={` ${
                          orderDetail?.orderItems?.length >= 4
                            ? "overflow-y-scroll h-72 p-2 style-4 "
                            : ""
                        } `}
                      >
                        {orderDetail?.orderItems?.map((orderitem, i) => {
                          const isProductOffer = productoffers.find(
                            (val) => val.product === orderitem.product
                          );

                          return (
                            <div
                              className="my-3 rounded-xl shadow-md overflow-hidden"
                              key={i}
                            >
                              {isProductOffer?.offer?.type === "custom" && (
                                <>
                                  <div className="capitalize text-xs sm:text-sm border-b-2 text-[rgb(0,53,96)] bg-gradient  font-style  rounded-sm flex justify-between">
                                    <p className="p-2 flex items-center ">
                                      <svg
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth="1.5"
                                        stroke="currentColor"
                                        className="w-5 h-5 mr-1"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                                        />
                                      </svg>
                                      {isProductOffer?.offer?.value} offer
                                    </p>
                                  </div>
                                </>
                              )}
                              {isProductOffer?.offer?.type === "discount" && (
                                <div className="capitalize text-xs sm:text-sm border-b-2  text-primary bg-gradient  font-style  rounded-sm flex justify-between">
                                  <p className="p-2 flex items-center ">
                                    <svg
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      className="w-5 h-5 mr-1"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                                      />
                                    </svg>
                                    {isProductOffer?.offer?.value} % discount
                                    offer
                                  </p>
                                </div>
                              )}
                              <div
                                className={` flex w-full  p-3 ${
                                  isProductOffer ? "bg-gradient" : "bg-gray-100"
                                }  `}
                              >
                                <div className="w-16 h-20 " key={i}>
                                  <Link
                                    to={`/product/${Number(
                                      orderitem?.product
                                    )}?clr=0`}
                                  >
                                    <img
                                      src={`${orderitem?.image}`}
                                      alt={`Myunde ${
                                        orderitem.name ?? "Speciallty for men"
                                      } `}
                                      className="w-full  h-full  text-xs"
                                    />
                                  </Link>
                                </div>
                                <div className="w-full mx-3 ">
                                  <div className="flex justify-between  ">
                                    <Link
                                      to={`/product/${Number(
                                        orderitem?.product
                                      )}?clr=0`}
                                    >
                                      <p className="capitalize truncate w-28  sm:w-32">
                                        {orderitem?.name}
                                      </p>
                                    </Link>

                                    <div className="flex">
                                      ({orderitem?.qty})
                                      {isProductOffer?.offer?.type ===
                                      "discount" ? (
                                        <p>
                                          ₹
                                          {Number(
                                            (orderitem.price -
                                              orderitem?.price *
                                                (isProductOffer?.offer?.value /
                                                  100)) *
                                              orderitem?.qty
                                          ).toFixed(2)}
                                        </p>
                                      ) : (
                                        <p>
                                          ₹
                                          {(
                                            orderitem?.price * orderitem?.qty
                                          )?.toFixed(2)}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                  <div className=" text-xs xs:text-sm ">
                                    <p>size : {orderitem.size} </p>
                                    <div className="flex justify-between items-center">
                                      <div>
                                        <div className="">
                                          color : {orderitem.color}
                                        </div>

                                        {isProductOffer?.offer?.type ===
                                        "discount" ? (
                                          <div>
                                            <p className="flex items-center font-style  text-sm">
                                              price :
                                              <span className="  ml-2">
                                                ₹
                                                {Number(
                                                  orderitem.price -
                                                    orderitem?.price *
                                                      (isProductOffer?.offer
                                                        ?.value /
                                                        100)
                                                ).toFixed(2)}
                                              </span>
                                              <del className="hidden xs:block text-xs ml-1">
                                                ₹{orderitem?.price}
                                              </del>
                                            </p>
                                          </div>
                                        ) : (
                                          <div>
                                            <p className=" items-center font-style  text-sm">
                                              price :
                                              <span className="  ml-2">
                                                ₹{orderitem?.price}
                                              </span>
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                      <button
                                        onClick={() =>
                                          review(orderitem?.product)
                                        }
                                        className=" bg-primary px-1 text-[9px] sm:text-xs rounded-md  text-white  h-7      "
                                      >
                                        Give a rating
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      {reviewProductId && (
                        <Writereview
                          forReview={forReview}
                          openReview={reviewOpen}
                          productId={Number(reviewProductId)}
                        />
                      )}
                      <div
                        className=" border-t  justify-center flex w-full   bg-primary p-2 rounded-xl shadow-md"
                        onClick={() => tracking(orderDetail?.id)}
                      >
                        {/* <button className="border-r-2 h-8 hover:text-[rgb(43,99,146)] font-style  capitalize text-primary  text-sm w-[50%]  flex justify-center items-center ">
                          <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 mr-1 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          cancel order
                        </button> */}

                        <div className=" h-8  font-style  capitalize text-white  text-sm shadow-none  flex justify-center items-center ">
                          <svg
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5 mr-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.75 15.75l-2.489-2.489m0 0a3.375 3.375 0 10-4.773-4.773 3.375 3.375 0 004.774 4.774zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          Track Order
                        </div>
                      </div>

                      <div className="   my-3 bg-gray-100 p-2 rounded-xl shadow-md">
                        <p className="text-lg text-gray-900  flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="red"
                            className="w-5 h-5 mr-1 "
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                            />
                          </svg>
                          Delivery Location
                        </p>

                        <div className=" my-3 ml-7 capitalize text-gray-500">
                          <p className="">
                            {orderDetail?.shippingAddress?.name}
                          </p>
                          <p>{orderDetail?.shippingAddress?.number}</p>
                          <p className="">
                            {orderDetail?.shippingAddress?.address},
                          </p>
                          <p>{orderDetail?.shippingAddress?.city}.</p>
                          <p>
                            {orderDetail?.shippingAddress?.state} -
                            {orderDetail?.shippingAddress?.pinCode}
                          </p>
                        </div>
                      </div>
                      <div className=" rounded-xl   p-3 bg-gray-100 shadow-md my-4">
                        <p className="text-lg text-gray-900  flex items-center text-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="green"
                            className="w-5 h-5 mr-1"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                          Payment
                        </p>

                        <div className=" my-3 mx-6 text-gray-500 divide-y-2 divide-dotted ">
                          <div className="flex justify-between items-center my-1 ">
                            <p>Total product price : </p>
                            <p>
                              ₹
                              {(
                                Number(orderDetail.totalPrice) -
                                Number(orderDetail.discountPrice) - (Number(orderDetail.shippingPrice) === 0 ? 0 : Number(orderDetail.shippingPrice) )
                              ).toFixed(2)}
                            </p>
                          </div>
                          <div className="flex justify-between items-center my-1">
                            <p>Shipping charge :</p>
                            {Number(orderDetail.shippingPrice) === 0 ? (
                              <p>Free</p>
                            ) : (
                              <p>
                                ₹ {Number(orderDetail.shippingPrice).toFixed(2)}
                              </p>
                            )}
                          </div>
                          {orderDetail.paymentMethod === "COD" ? (
                            <div className="flex justify-between items-center my-1">
                              <p>Cod charge :</p>

                              <p>
                                ₹ {Number(orderDetail.cod_charge).toFixed(2)}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          {Number(orderDetail.discountPrice) > 1 && (
                            <div className="flex justify-between items-center my-1">
                              <p>Promotion applied :</p>
                              <p>
                                - ₹
                                {Number(orderDetail.discountPrice).toFixed(2)}
                              </p>
                            </div>
                          )}
                          <div className="flex justify-between items-center my-1">
                            <p> Paid Amount :</p>
                            <p>
                              ₹
                              {(
                                Number(orderDetail.totalPrice) +
                                Number(orderDetail.shippingPrice)
                              ).toFixed(2)}
                            </p>
                          </div>
                          <div className="flex justify-between items-center my-1">
                            <p> Paid date :</p>
                            <p>
                              {new Date(orderDetail?.paidAt).toDateString()}
                            </p>
                          </div>

                          <div className="flex justify-between items-center my-1">
                            paymentMethod :
                            {orderDetail.cod_charge > 0 ? (
                              <p>Post paid</p>
                            ) : (
                              <p>Pre paid</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}

            {/* <-------------------------------------------------------------------------track order AREA------------------------------------------------------------------> */}
            {profile.trackOrder && (
              <div>
                <div
                  className="h-16 bg-white sticky top-0 items-center flex justify-between shadow-lg bg-gradient  p-4 rounded-b-xl"
                  // style={{
                  //   background:
                  //     "linear-gradient(49deg, rgba(227,242,255,1) 0%, rgba(249,247,240,1) 87%)",
                  // }}
                >
                  <IoIosArrowBack
                    className="w-6 h-6  text-gray-800"
                    onClick={() =>
                      setProfile({
                        mypro: false,
                        prof: false,
                        adress: false,
                        myorder: false,
                        orderhistory: true,
                        trackOrder: false,
                      })
                    }
                  />
                  <div className="flex ml-3 items-center ">
                    <p className="text-lg   font-style capitalize">
                      track order
                    </p>
                  </div>
                  <div className=" flex  items-center ">
                    <div
                      className="  "
                      onClick={() => forProfileScreenHandle()}
                    >
                      <AiOutlineClose className="w-7 h-7    hover:scale-110 text-gray-800" />
                      <span className="sr-only">Close panel</span>
                    </div>
                  </div>
                </div>

                {Object.keys(trackDetail)?.length &&
                trackDetail?.shipment_track[0]?.current_status ===
                  "Canceled" ? (
                  <div className="flex justify-center items-center h-[90%]">
                    <div className="">
                      <img src={cancel} alt="cancel" className=" " />
                      <div className="flex justify-center w-full">
                        <p className="text-center text-gray-700 capitalize text-xl font-style w-64">
                          its seems your order has been cancelled !!
                        </p>
                      </div>
                      <div className="flex justify-center mt-4">
                        <Button
                          onClick={() => forProfileScreenHandle()}
                          className="text-center w-1/2 bg-primary  h-12 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg  my-4   "
                        >
                          shop now
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="p-4 font-style text-gray-700 text-sm">
                    {Object.keys(trackDetail).length &&
                    trackDetail?.shipment_track[0]?.current_status ===
                      "Delivered" ? (
                      <div>
                        <p className="text-center capitalize font-bold ">
                          order deliverd date
                        </p>
                        <p className="text-center capitalize text-xl text-[rgb(0,56,93)]">
                          {new Date(
                            trackDetail?.shipment_track[0]?.delivered_date
                          ).toDateString()}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <p className="text-center capitalize font-bold ">
                          Estimated Delivery Date
                        </p>
                        <p className="text-center capitalize text-xl text-[rgb(0,56,93)]">
                          {new Date(trackDetail?.etd).toDateString()}
                        </p>
                      </div>
                    )}

                    <div
                      className=" rounded-xl  shadow-lg border  my-5 "
                      style={{
                        backgroundImage: `url(${require("../images/map2.jpg")})`,
                        backgroundSize: "1920px 1080px",
                      }}
                    >
                      <div className="flex justify-between items-end border-b p-4 bg-gray-100">
                        <p className="text-lg capitalize">Order status </p>
                        <p>
                          Order#
                          {detailOfOrder?.map((orderitem, i) => {
                            return (
                              <span className="text-[rgb(0,56,93)]" key={i}>
                                {orderitem?.id}
                              </span>
                            );
                          })}
                        </p>
                      </div>
                      <div className="p-3 font-style flex justify-center capitalize">
                        <div>
                          <div className="my-10 flex items-center">
                            <p
                              className={` bg-${trackOrderProgress?.orderPlaced}  h-10 w-10 flex items-center justify-center rounded-full mr-2`}
                            >
                              <svg
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="white"
                                className="w-6 h-6  "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                                />
                              </svg>
                            </p>
                            <div
                              className={`text-${trackOrderProgress?.orderPlaced} text-lg ml-5`}
                            >
                              order placed
                              {detailOfOrder?.map((orderDetail, i) => {
                                return (
                                  <p className="text-xs text-gray-600 " key={i}>
                                    we have received your order on
                                    <span className="">
                                      {new Date(
                                        orderDetail?.createdAt
                                      ).toLocaleDateString()}
                                    </span>
                                  </p>
                                );
                              })}
                            </div>
                          </div>

                          <div className="my-10 flex items-center">
                            <p
                              className={`bg-${trackOrderProgress?.orderProcessing}  h-10 w-10 flex items-center justify-center rounded-full mr-2`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="white"
                                className="w-6 h-6"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </p>
                            <div
                              className={`text-${trackOrderProgress?.orderProcessing} text-lg ml-5`}
                            >
                              order processing
                              <p className="text-xs text-gray-600 ">
                                we are preparing for your order
                              </p>
                            </div>
                          </div>
                          <div className="my-10 flex items-center">
                            <p
                              className={`bg-${trackOrderProgress?.orderDispatched} h-10 w-10 flex items-center justify-center rounded-full mr-2`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="white"
                                className="w-6 h-6 "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z"
                                />
                              </svg>
                            </p>
                            <div
                              className={`text-${trackOrderProgress?.orderDispatched} text-lg ml-5`}
                            >
                              order dispatched
                              <p className="text-xs text-gray-600 ">
                                your order is ready for shipping
                              </p>
                            </div>
                          </div>
                          <div className="my-10 flex items-center">
                            <p
                              className={`bg-${trackOrderProgress?.outForDelivery} h-10 w-10 flex items-center justify-center rounded-full mr-2`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="white"
                                className="w-6 h-6 "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
                                />
                              </svg>
                            </p>

                            <div
                              className={`text-${trackOrderProgress?.outForDelivery} text-lg ml-5`}
                            >
                              out for delivery
                              <p className="text-xs text-gray-600">
                                your order is out for delivery
                              </p>
                            </div>
                          </div>
                          <div className="my-10 flex items-center">
                            <p
                              className={`bg-${trackOrderProgress?.orderDelivered} h-10 w-10 flex items-center justify-center rounded-full mr-2`}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="white"
                                className="w-6 h-6 "
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                                />
                              </svg>
                            </p>
                            <div
                              className={`text-${trackOrderProgress?.orderDelivered} text-lg ml-5`}
                            >
                              order delivered
                              <p className="text-xs text-gray-600 ">
                                your order delivered
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-center text-primary">
                      <a
                        href={`${trackDetail?.track_url}`}
                        rel="noreferrer"
                        target="_blank"
                      >
                        see more tracking details
                      </a>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* <----------------------------------profile edit area-----------------------------------------------------> */}

            {profile.mypro && (
              <div className="h-screen w-[100%]">
                <div className="h-16  sticky z-20 top-0 bg-white items-center flex justify-between bg-gradient shadow-lg p-2 sm:p-4 rounded-b-xl">
                  <IoIosArrowBack
                    className="w-6 h-6  text-gray-800"
                    onClick={() =>
                      setProfile({
                        mypro: false,
                        prof: true,
                        adress: false,
                      })
                    }
                  />

                  <p className="text-lg  mr-1  font-style capitalize">
                    My profile
                  </p>

                  <div className=" flex  items-center ">
                    <div
                      className="  "
                      onClick={() => forProfileScreenHandle()}
                    >
                      <AiOutlineClose className="w-7 h-7    hover:scale-110 text-gray-800" />
                      <span className="sr-only">Close panel</span>
                    </div>
                  </div>
                </div>

                <form className=" " onSubmit={handleSubmit(onSubmit)}>
                  <div className="text-xl text-gray-900 flex items-center font-bold mx-4 py-2 mt-4  italic">
                    Edit Details :
                  </div>
                  <div className=" text-gray-700 font-style bg-gray-50 py-8 p-2 sm:p-4 m-2 sm:m-4 rounded-xl shadow-xl border">
                    <div>
                      <div className="px-2 sm:px-4">
                        <div className="relative mt-4">
                          <input
                            name="name"
                            type="text"
                            placeholder=" "
                            {...register("name", { required: true })}
                            aria-invalid={errors.name ? "true" : "false"}
                            id="name"
                            className={`block px-2.5 pb-2.5  pt-3 w-full text-[15px] text-gray-900 bg-gray-100 rounded-lg border ${
                              errors.name
                                ? "border-red-300 border-2"
                                : "border-gray-300 "
                            } appearance-none  focus:outline-none focus:ring-0  peer`}
                          />
                          <label
                            htmlFor="name"
                            className="absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                          >
                            Username
                          </label>
                        </div>
                        {errors.name?.type === "required" && (
                          <div className="text-sm m-1 text-red-500 flex justify-end relative">
                            <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                          </div>
                        )}
                        {userInfo.googleLogin === false ? (
                          <>
                            <div className="relative mt-10">
                              <input
                                name="email"
                                type="email"
                                placeholder=" "
                                {...register("email", {
                                  required: "Email Address is required",
                                })}
                                aria-invalid={errors.email ? "true" : "false"}
                                id="email"
                                className={`block px-2.5 pb-2.5  pt-3 w-full text-[15px] text-gray-900 bg-gray-100 rounded-lg border ${
                                  errors.email
                                    ? "border-red-300 border-2"
                                    : "border-gray-300 "
                                } appearance-none  focus:outline-none focus:ring-0  peer`}
                              />
                              <label
                                htmlFor="email"
                                className="absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                              >
                                Email
                              </label>
                            </div>
                            {errors.email && (
                              <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <div className="relative mt-10">
                              <input
                                name="number"
                                type="text"
                                placeholder=" "
                                {...register("number", {
                                  required: true,
                                  maxLength: 10,
                                  minLength: 10,
                                  pattern: {
                                    value: /^[0-9]+$/,
                                    message: "Please enter a valid number",
                                  },
                                })}
                                aria-invalid={errors.number ? "true" : "false"}
                                id="number"
                                className={`block px-2.5 pb-2.5  pt-3 w-full text-[15px] text-gray-800 bg-gray-100 rounded-lg border ${
                                  errors.number
                                    ? "border-red-300 border-2"
                                    : "border-gray-300 "
                                } appearance-none  focus:outline-none focus:ring-0  peer`}
                              />
                              <label
                                htmlFor="number"
                                className="absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                              >
                                Mobile number
                              </label>
                            </div>
                            <p className="text-sm m-1 text-red-500">
                              {errors.number?.message}
                            </p>
                            {(errors.number?.type === "required" ||
                              userUpdateProfile?.error) && (
                              <div className="text-sm m-1 text-red-500 flex justify-end relative">
                                <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                              </div>
                            )}
                            {errors.number?.type === "maxLength" && (
                              <p className="text-sm m-1 text-red-500 flex justify-end relative">
                                max length (10) exceeded
                              </p>
                            )}
                            {userUpdateProfile?.error && (
                              <p className="text-sm m-1 text-red-500 flex justify-end relative">
                                {userUpdateProfile?.error}
                              </p>
                            )}
                          </>
                        )}
                      </div>

                      <div className="flex justify-between  mt-8 text-md px-5">
                        <h5 className="font-style mt-1 ">ADDRESS BOOK</h5>
                        {userInfo?.Address ? (
                          <Button
                            onClick={() =>
                              setProfile({
                                myprof: false,
                                prof: false,
                                address: true,
                              })
                            }
                            className=" text-white bg-primary mt-1 font-style "
                          >
                            Edit Adress
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>

                      {userInfo?.Address ? (
                        <div className=" px-3   py-3 m-5 rounded-xl border  text-lg font-style  bg-[rgb(221,235,248)] text-start  ">
                          <p className="text-xl  text-gray-800 mb-2 italic">
                            Address
                          </p>
                          <div className="text-sm text-gray-500 capitalize">
                            <div className="flex items-start">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-5 h-5 mr-2 mt-0.5 text-[rgb(0,53,96)]"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                                />
                              </svg>
                              <p className="">
                                {addressSplit[0]} ,
                                <br />
                                {addressSplit[1]}
                                <br />
                                {addressSplit[2]}
                                <br />
                                {addressSplit[3]}-{addressSplit[4]}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="mt-4   px-3   py-3 m-5"
                          onClick={() =>
                            setProfile({
                              myprof: false,
                              prof: false,
                              address: true,
                            })
                          }
                        >
                          <Button className="rounded-lg w-full outline-none h-32 hover:bg-[rgb(237,245,252)] bg-[rgb(221,235,248)] border border-gray-300 shadow-none hover:shadow-none  flex justify-center items-center">
                            <div>
                              <AiOutlinePlusCircle className="w-7 h-7 ml-12  text-primary " />
                              <p className="text-start text-primary text-sm ">
                                ADD YOUR ADDRESS
                              </p>
                            </div>
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className="flex justify-between mt-6 sm:px-6">
                      <Button
                        type="submit"
                        // onClick={() => reset()}
                        className="text-center w-1/3 bg-primary  h-10 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg  my-4  "
                      >
                        Save
                      </Button>
                      <Button
                        className="text-center w-1/3 bg-primary  h-10 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg  my-4"
                        onClick={() =>
                          setProfile({
                            mypro: false,
                            prof: true,
                            adress: false,
                          })
                        }
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            )}

            {/* <-------------------------------------------------------------------------address fill area------------------------------------------------------------------> */}

            {profile.address && (
              <div className=" h-screen w-[100%]">
                <div
                  className="h-16 shadow-lg sticky z-20 top-0 bg-white bg-gradient items-center flex justify-between border-b-2  p-2 sm:p-4 rounded-b-xl"
                  // style={{
                  //   background:
                  //     "linear-gradient(49deg, rgba(227,242,255,1) 0%, rgba(249,247,240,1) 87%)",
                  // }}
                >
                  <IoIosArrowBack
                    className="w-6 h-6  text-gray-800"
                    onClick={() =>
                      setProfile({
                        mypro: false,
                        prof: true,
                        adress: false,
                      })
                    }
                  />

                  <p className="text-xl  mr-1  font-style">Edit Address</p>

                  <div className=" flex  items-center ">
                    <div
                      className="  "
                      onClick={() => forProfileScreenHandle()}
                    >
                      <AiOutlineClose className="w-7 h-7     hover:scale-110 text-gray-800" />
                      <span className="sr-only">Close panel</span>
                    </div>
                  </div>
                </div>
                <div className=" text-xl text-gray-900 flex items-center font-bold mx-4 py-2 mt-4  italic">
                  Add & Edit address :
                </div>
                <div className="bg-gray-50 py-8 p-2 sm:p-4 m-2 sm:m-4 rounded-xl shadow-xl border">
                  <div className="flex justify-evenly items-center font-style   mx-6 rounded-xl">
                    <div className="hover:text-[rgb(0,53,96)] text-sm text-gray-700   p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 ml-3 "
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                        />
                      </svg>

                      <p className=" mt-3 text-lg">Home</p>
                    </div>
                    (or)
                    <div className=" text-sm text-gray-700 p-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 ml-3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
                        />
                      </svg>

                      <p className=" mt-3 text-lg">Office</p>
                    </div>
                  </div>

                  <form
                    className="px-3 sm:px-5 mt-10"
                    onSubmit={handleSubmit2(AddressSubmit)}
                  >
                    <div>
                      <div className="relative mt-8">
                        <input
                          name="houseNo"
                          type="text"
                          placeholder=" "
                          {...register2("houseNo", {
                            required: true,
                          })}
                          aria-invalid={errors2.houseNo ? "true" : "false"}
                          id="houseNo"
                          className={`block px-2.5 pb-2.5  pt-3 w-full text-sm text-gray-900 bg-gray-100 rounded-lg border ${
                            errors2.houseNo
                              ? "border-red-300 border-2"
                              : "border-gray-300 "
                          } appearance-none  focus:outline-none focus:ring-0  peer`}
                        />
                        <label
                          htmlFor="houseNo"
                          className="absolute text-sm text-gray-500 after:content-['*'] after:ml-1 after:text-red-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4      z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                        >
                          House No
                        </label>
                      </div>
                      {errors2.houseNo?.type === "required" && (
                        <div className="text-sm m-1 text-red-500 flex justify-end relative">
                          <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                        </div>
                      )}
                      <div className="relative mt-8">
                        <input
                          name="address"
                          type="text"
                          placeholder=" "
                          {...register2("address", {
                            required: true,
                          })}
                          aria-invalid={errors2.address ? "true" : "false"}
                          id="address"
                          className={`block px-2.5 pb-2.5  pt-3 w-full text-sm text-gray-900 bg-gray-100 rounded-lg border ${
                            errors2.address
                              ? "border-red-300 border-2"
                              : "border-gray-300 "
                          } appearance-none  focus:outline-none focus:ring-0  peer`}
                        />
                        <label
                          htmlFor="address"
                          className="absolute text-sm text-gray-500 after:content-['*'] after:ml-1 after:text-red-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4      z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                        >
                          address
                        </label>
                      </div>
                      {errors2.address?.type === "required" && (
                        <div className="text-sm m-1 text-red-500 flex justify-end relative">
                          <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                        </div>
                      )}
                      <div className="relative mt-8">
                        <input
                          name="city"
                          type="text"
                          placeholder=" "
                          {...register2("city", {
                            required: true,
                          })}
                          aria-invalid={errors2.city ? "true" : "false"}
                          id="city"
                          className={`block px-2.5 pb-2.5  pt-3 w-full text-sm text-gray-900 bg-gray-100 rounded-lg border ${
                            errors2.city
                              ? "border-red-300 border-2"
                              : "border-gray-300 "
                          } appearance-none  focus:outline-none focus:ring-0  peer`}
                        />
                        <label
                          htmlFor="city"
                          className="absolute text-sm text-gray-500 after:content-['*'] after:ml-1 after:text-red-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4      z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                        >
                          City
                        </label>
                      </div>
                      {errors2.city?.type === "required" && (
                        <div className="text-sm m-1 text-red-500 flex justify-end relative">
                          <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                        </div>
                      )}
                      <div className="relative mt-8">
                        <input
                          name="pincode"
                          type="tel"
                          placeholder=" "
                          {...register2("pincode", {
                            required: true,
                            maxLength: 6,
                            minLength: 6,
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Please enter a valid pincode",
                            },
                          })}
                          aria-invalid={errors2.pincode ? "true" : "false"}
                          id="pincode"
                          className={`block px-2.5 pb-2.5  pt-3 w-full text-sm text-gray-900 bg-gray-100 rounded-lg border ${
                            errors2.pincode
                              ? "border-red-300 border-2"
                              : "border-gray-300 "
                          } appearance-none  focus:outline-none focus:ring-0  peer`}
                        />
                        <label
                          htmlFor="pincode"
                          className="absolute text-sm text-gray-500 after:content-['*'] after:ml-1 after:text-red-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4      z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                        >
                          Pincode
                        </label>
                      </div>
                      <p className="text-sm m-1 text-red-500">
                        {errors2.pincode?.message}
                      </p>
                      {errors2.pincode?.type === "required" && (
                        <div className="text-sm m-1 text-red-500 flex justify-end relative">
                          <ExclamationCircleIcon className="w-6 h-6  absolute -mt-10 mr-1.5" />
                        </div>
                      )}
                      {errors2.pincode &&
                        errors2?.pincode?.type === "minLength" && (
                          <span className="text-sm m-1 text-red-500 capitalize">
                            pin code must be (6) digits
                          </span>
                        )}
                      {errors2.pincode &&
                        errors2.pincode.type === "maxLength" && (
                          <span className="text-sm m-1 text-red-500">
                            Max length (6) is exceeded
                          </span>
                        )}
                      <div className="relative mt-8">
                        <label
                          htmlFor="state"
                          className="absolute after:content-['*'] after:ml-1 after:text-red-500 hidden sm:block font-style text-[15px]  text-gray-500 ml-1 -mt-[22px]"
                        >
                          State
                        </label>
                        <select
                          type="text"
                          placeholder="select state"
                          {...register2("state", { required: true })}
                          aria-invalid={errors2.state ? "true" : "false"}
                          id="state"
                          className={`block px-2.5 pb-2.5 font-style pt-4 w-full text-[15px] text-gray-500 bg-gray-100 rounded-lg  ${
                            errors2.state
                              ? "border-red-300 border-2"
                              : "border-gray-300 border"
                          } appearance-none  focus:outline-none focus:ring-0  peer`}
                        >
                          {stateOptions?.map((value, i) => {
                            return (
                              <option key={i} value={value}>
                                {value}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div className="flex flex-wrap justify-center mt-10  mb-6">
                      <Button
                        className="text-center w-full bg-primary  h-12 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg   "
                        type="submit"
                      >
                        Add Address
                      </Button>
                    </div>
                    {/* <div className="flex justify-center mt-8"> 
                              <Button className="bg-primary  h-10 w-full rounded-lg hover:scale-105 text-white">
                                  
                                  Cancel
                                </Button></div> */}
                  </form>
                </div>
              </div>
            )}

            {/* * <-------------------------------------------------------------------------address fill area------------------------------------------------------------------> */}
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default ProfileScreen;
