import { Button } from "@material-tailwind/react";
import { Dialog } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { AiOutlineClose } from "react-icons/ai";

function PrintInitial({ PrintOpen, forPrintOpen, onSubmit }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>
      <Dialog
        open={PrintOpen}
        transitionDuration={400}
        onClose={forPrintOpen}
        maxWidth={"lg"}
      >
        <div className="w-full sticky top-0 flex flex-wrap justify-between items-center text-gray-800 p-3  rounded-t-lg shadow-md  bg-gray-100 ">
          <p className="text-lg  sm:text-xl font-bold text-gray-800 ">
            Print Initial
          </p>

          <AiOutlineClose
            className="w-6 h-6 hover:scale-75  "
            onClick={() => forPrintOpen()}
          />
        </div>

        <div className=" p-2 sm:p-8 ">
          <p className="font-medium">
            By entering any single letter of your choice,
            <br /> we can print the letter on the product,
            <br /> making it unique and easily identifiable.
          </p>
          <form onSubmit={handleSubmit(onSubmit)} className="">
            <div className=" relative mt-8">
              <input
                name="initial"
                type="text"
                {...register("initial", {
                  required: {
                    value: true,
                    message: "This field is required.",
                  },
                  maxLength: {
                    value: 1,
                    message: "Your initial must be a single letter.",
                  },
                  pattern: {
                    value: /^[A-Za-z]+$/i,
                    message: "Your initial should be a alphabetic character",
                  },
                })}
                aria-invalid={errors?.initial ? "true" : "false"}
                placeholder=" enter your initial "
                id="initial"
                className={`  appearance-none ${
                  errors?.initial ? "border-red-300 border-2" : " border"
                }  focus:outline-none focus:ring-0  w-full text-[15px] text-gray-900 p-2 rounded-lg  `}
              />
            </div>
            {errors?.initial && (
              <div>
                <p className="text-sm text-red-700">
                  {errors?.initial?.message}
                </p>
              </div>
            )}

            <div className=" flex justify-between items-center pt-4">
              <Button
                onClick={() => forPrintOpen()}
                className=" py-2 rounded-md capitalize border border-primary bg-secondary text-primary font-style flex items-center"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                className=" py-2 rounded-md   bg-primary capitalize text-white  font-style flex items-center"
              >
                Submit
              </Button>
            </div>
          </form>
          {/* <p
            className="font-medium text-center pt-6 text-primary cursor-pointer"
            onClick={() => alert("not yet done this function")}
          >
            Click here to see how it works !
          </p> */}
        </div>
      </Dialog>
    </>
  );
}
export default PrintInitial;
