import { HashRouter, Route, Routes } from "react-router-dom";
// import HomeScreen from "./screens/HomeScreen";
import HomePage from "./screens/HomePage";
// import LoginScreen from "./screens/LoginScreen";
// import RegisterScreen from "./screens/RegisterScreen";
// import ProfileScreen from "./screens/ProfileScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import Products from "./screens/ProductsGetScreen";
import ScrollToTop from "./components/ScrollTop";
// import Header from "./components/Header1";
import { useEffect, useState } from "react";
// import OurReview from "./screens/footerScreens/OurReview";
// import Contact from "./screens/footerScreens/Contact";
import Faq from "./screens/footerScreens/Faq";
import Ourstory from "./screens/Ourstory";
import Shipping from "./screens/footerScreens/Shipping";
import Terms from "./screens/footerScreens/Terms";
import Privacy from "./screens/footerScreens/Privacy";
import Cancel from "./screens/footerScreens/Cancel";
// import FooterZ from "./components/FooterZ";
import FinalProductScreen from "./screens/FinalProductScreen";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "./actions/userActions";
import { auth } from "./components/firebase";
import TrackOrder from "./screens/TrackOrder";
import Sellonmyunde from "./screens/sellonmyunde";
import Layout from "./components/Layout";
import BlogCategory from "./screens/MyundeBlog/BlogCategory";
import BlogContent from "./screens/MyundeBlog/BlogContent";
import BlogHome from "./screens/MyundeBlog";
import ServerErrorPage from "./components/ServerErrorPage";
import PageNotFound from "./components/PageNotFound";
import ShippingPage from "./screens/ShippingPage";
import ContactPage from "./screens/ContactPage";
import AboutMyunde from "./screens/AboutMyunde";

// import Feedback from "./screens/Feedback";
// import Loader from "./components/Loader";
// import { useDispatch, useSelector } from "react-redux";
// import { registerUser } from "./actions/userActions";
// import jwt_decode from "jwt-decode";
// const HomeScreen = lazy(() => import("./screens/HomeScreen"));

function App() {
  const [ServerError, setServerError] = useState(false);
  // const [loading, setLoading] = useState(false);

  const serverError = (error) => {
    if (error) {
      setServerError(true);
    }
  };

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (!userInfo) {
      auth
        .getRedirectResult()
        .then((result) => {
          if (result?.user) {
            dispatch(registerUser(result.user)).then(() => {});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  });

  return (
    <>
      {ServerError ? (
        <ServerErrorPage />
      ) : (
        <HashRouter>
          <Layout serverError={serverError}>
            <div className="h-20 sm:h-24 md:h-24 lg:h-[150px]  xl:h-[155px] 2xl:h-[150px] no-scrollbar"></div>
            <ScrollToTop />
            <Routes>
              <Route exact path="" element={<HomePage />} />
              {/* <Route path="/login" element={<LoginScreen />} />
            <Route path="/register" element={<RegisterScreen />} />
            <Route path="/profile" element={<ProfileScreen />} /> */}
              <Route path="/shipping" element={<ShippingPage />} />
              <Route path="/placeorder" element={<PlaceOrderScreen />} />
              <Route path="/orderplaced/:id" element={<OrderScreen />} />
              <Route path="/product/:id" element={<FinalProductScreen />} />
              <Route path="/products" element={<Products />} />
              <Route path="/about-us" element={<AboutMyunde />} />
              <Route path="/blog" element={<BlogHome />} />
              <Route path="/blog/category/:name" element={<BlogCategory />} />
              <Route path="/blog/:slug" element={<BlogContent />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/cancel-return" element={<Cancel />} />
              <Route path="/track-order" element={<TrackOrder />} />
              <Route path="/sell-on-myunde" element={<Sellonmyunde />} />
              <Route path="/contact-us" element={<ContactPage />} />
              {/* <Route path="/Feedback" element={<Feedback />} />
            <Route path="/Contact" element={<Contact />} /> */}
              <Route path="/delivery-details" element={<Shipping />} />
              <Route path="/terms-conditions" element={<Terms />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Layout>
        </HashRouter>
      )}
    </>
  );
}

export default App;
