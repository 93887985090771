import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

function Search() {
  const [keyword, setKeyword] = useState("");
  const location = useLocation();
  const history = useNavigate();

  const submitHandler = (e) => {
    if (e){
      e.preventDefault();
      if (keyword) {
        history(`products/?keyword=${keyword}&page=1`);
      } else {
        history(location.pathname);
      }
    }
   
  };

  // useEffect(() => {
  //   // e.preventDefault()
  //   if (keyword) {
  //       history(`products/?keyword=${keyword}&page=1`)
  //   } else {
  //       history(location.pathname)
  //   }
  // },[keyword]);

  return (
    <form className="flex items-center mx-2 " onSubmit={submitHandler}>
      <label htmlFor="simple-search1" className="sr-only">
        Search
      </label>
      <div className="relative w-full">
        <div className=" absolute hidden sm:flex inset-y-0 left-0 items-center pl-3 pointer-events-none ">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            ></path>
          </svg>
        </div>
        <input
          type="text"
          // id="simple-search"
          className="h-8  sm:h-9 bg-gray-50 border outline-none border-gray-300 
          text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-64 xs:w-full pl-2 sm:pl-10  p-2"
          placeholder="Search"
          onChange={(e) => setKeyword(e.target.value)}
          required
        />
      </div>
      <button
        type="submit"
        onClick={() => submitHandler()}
        className="p-1.5 ml-1 text-sm  font-medium text-white bg-primary rounded-full border border-primary  focus:ring-4 focus:outline-none "
      >
        <svg
          className="w-4 h-4  sm:w-5 sm:h-5 svg"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          ></path>
        </svg>
        <span className="sr-only">Search</span>
      </button>
    </form>
    // <form onSubmit={submitHandler}>
    //   <div style={{ zIndex: "354654" }} className="select-none">
    //     <div className="wrapper ml-4">
    //       {/* <div className='logo' ><img  src={logo} alt="myunde.com"/> <div className="brands">Our Brands <br/> Partner Brands</div> </div> */}

    //       <div className="search">
    //         <input
    //           id=""
    //           type="text"
    //           name="q"
    //           className="search__text "
    //           onChange={(e) => setKeyword(e.target.value)}
    //           placeholder="Search"
    //         ></input>
    //         {/* <a href="#" className=""><i className="fas fa-search"></i></a> */}
    //         <button onClick={() => submitHandler()}>
    //           <svg
    //             className=""
    //             style={{ width: "24px", height: "24px" }}
    //             viewBox="0 0 24 24"
    //           >
    //             <path
    //               fill="#666666"
    //               d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11
    //         15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,
    //         1 3,9.5A6.5,6.5 0 0, 1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
    //             />
    //           </svg>
    //         </button>
    //       </div>

    //     </div>
    //   </div>
    // </form>
  );
}

export default Search;
