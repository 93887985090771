import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { getBlogContent } from "../../../actions/blogActions";
import BlogContentPage from "./BlogContentPage";

function BlogContent() {
  
  const { slug } = useParams();
  const dispatch = useDispatch();


  useEffect(() => {
    if (slug) {
      dispatch(getBlogContent(slug));
    }else {
      dispatch(getBlogContent("how-to-buy-mens-underwear"));
    }
    
  }, []);

  return (
    <>
      <BlogContentPage />
    </>
  );
}

export default BlogContent;
