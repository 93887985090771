import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useForm, Controller } from "react-hook-form";
import { firebase, auth } from "./firebase";
import { Button } from "@material-tailwind/react";
import { useDispatch } from "react-redux";
import { registerUser } from "../actions/userActions";
import { CircularProgress } from "@mui/material";

function Login() {
  const [screen, setScreen] = useState({ otp: false, number: true });
  const [loading, setLoading] = useState(false);
  const [final, setfinal] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm();

  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setLoading(true);
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        }
      );
    }
    window.recaptchaVerifier.render();
    auth
      .signInWithPhoneNumber(data.number, window.recaptchaVerifier)
      .then((result) => {
        setfinal(result);
        setLoading(false);
        setScreen({ otp: true, number: false });
      })
      .catch((err) => {
        setError("number", { message: "Invalid Phone number" });
        setLoading(false);
      });
  };

  const onValidateOtp = (data) => {
    if (final === null) {
      return;
    } else {
      final
        .confirm(data.otp)
        .then((result) => {
          const data = {};
          if (result) {
            Object.assign(data, {
              number: result.user?.phoneNumber.slice(3),
              OTP: 123456,
            });
            dispatch(registerUser(data));
          }
        })
        .catch((err) => {
          console.log(err.message);
          setError("otp");
        });
    }
  };

  const validationRules = {
    number: {
      required: {
        value: true,
        message: "Phone number is required",
      },
      minLength: {
        value: 13,
        message: "Number must be atleast 10 digits",
      },
      maxLength: {
        value: 13,
        message: "Number must not exceed 10 digits",
      },
      pattern: {
        value: /^\+[1-9]\d{1,14}$/,
        message: "Invalid phone number format",
      },
    },
  };

  return (
    <>
      {screen.number && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="relative mt-12 text-end">
            <Controller
              name="number"
              control={control}
              defaultValue=""
              rules={validationRules.number}
              render={({ field, fieldState }) => (
                <>
                  <PhoneInput
                    id="number"
                    defaultCountry="IN"
                    countries={["IN"]}
                    addInternationalOption={false}
                    className={`block  w-full bg-gray-100 focus:outline-none text-sm text-gray-900 ${
                      errors.number ? "border-red-300 border-2" : ""
                    } rounded-lg border appearance-none px-2.5 pb-2.5  pt-4  focus:outline-none   peer`}
                    {...field}
                    error={fieldState.error?.message}
                  />
                  <label
                    htmlFor="number"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-9  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                  >
                    Enter Phone Number
                  </label>
                </>
              )}
            />
            {errors.number && (
              <span className="text-sm text-red-700">
                {errors.number.message}
              </span>
            )}
          </div>
          <div id="recaptcha-container" className="mt-2 "></div>
          <Button
            type="submit"
            className="text-center bg-primary  h-12 flex justify-center items-center text-white text-[15px] font-medium capitalize  rounded-lg  my-6  w-full"
          >
            {loading ? (
              <CircularProgress size={30} sx={{ color: "white" }} />
            ) : (
              <p> send OTP </p>
            )}
          </Button>
        </form>
      )}

      {screen.otp && (
        <form onSubmit={handleSubmit(onValidateOtp)}>
          <div className="relative mt-8">
            <input
              name="otp"
              type="text"
              {...register("otp", { required: true })}
              aria-invalid={errors.otp ? "true" : "false"}
              maxLength={6}
              id="otp"
              className={`block  w-full bg-gray-100 focus:outline-none text-sm text-gray-900 ${
                errors.otp ? "border-red-300 border-2" : ""
              } rounded-lg border appearance-none px-2.5 pb-2.5  pt-4  focus:outline-none   peer`}
            />

            <label
              htmlFor="otp"
              className="absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
            >
              Enter OTP
            </label>
          </div>
          {errors.otp && (
            <span className="text-sm text-red-700">Invalid Otp</span>
          )}

          <Button
            type="submit"
            className="text-center bg-primary  h-12 flex justify-center items-center text-white text-[15px] font-medium capitalize  rounded-lg  my-6  w-full"
          >
            verify OTP
          </Button>
        </form>
      )}
    </>
  );
}

export default Login;
