import React from "react";
import Header from "./Header1";
import FooterZ from "./FooterZ";
import { useLocation } from "react-router-dom";
import BlogHeader from "../screens/MyundeBlog/BlogHeader";
import BlogFooter from "../screens/MyundeBlog/BlogFooter";



function Layout({ children ,serverError }) {


  const location = useLocation();
  
  const hideHeader = /^\/blog\w*/.test(location.pathname) 

  const hideFooter = /^\/blog\w*/.test(location.pathname) 
 
  return (
    <React.Fragment>
      {!hideHeader ? <Header serverError={serverError} /> :   <BlogHeader /> }
      {children}
     {!hideFooter ? <FooterZ />  : <BlogFooter/>}
    </React.Fragment>
  );
}

export default Layout;
