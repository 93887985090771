import { combineReducers, applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  productListReducer,
  productDetailsReducer,
  productReviewCreateReducer,
  productTopRatedReducer,
  BrandListReducer,
  CategoryListReducer,
  StylesListReducer,
  FabricsListReducer,
  ColorsListReducer,
  SizesListReducer,
  productItemsListReducer,
  ItemImagesListReducer,
  CampaignImagesListReducer,
  SkuListReducer,
  ProductOffersListReducer,
  SizeListReducer,
  productItemListReducer,
  wishProductItemListReducer,
  productReviewReducer,
  FIlterListReducer,
} from "./reducers/productReducers";

import {
  cartAddReducer,
  CartDatabaseListReducer,
  cartReducer,
  WishDatabaseListReducer,
} from "./reducers/cartReducers";

import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userUpdateReducer,
  listFeedbackReducer,
} from "./reducers/userReducers";

import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderListMyReducer,
  orderListReducer,
  orderDeliverReducer,
} from "./reducers/orderReducers";
import { WishDataBaseAdd } from "./actions/cartActions";
import { getBlogReducer } from "./reducers/blogReducers";

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productReviewCreate: productReviewCreateReducer,
  productReviews: productReviewReducer,
  productTopRated: productTopRatedReducer,
  cart: cartReducer,
  dataBaseCart: CartDatabaseListReducer,
  dataBaseCartAdd: cartAddReducer,
  // wish :wishReducer,
  dataBaseWishAdd: WishDataBaseAdd,
  dataBaseWish: WishDatabaseListReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  orderDeliver: orderDeliverReducer,
  filterList: FIlterListReducer,
  categoryList: CategoryListReducer,
  brandList: BrandListReducer,
  stylesList: StylesListReducer,
  fabricsList: FabricsListReducer,
  colorsList: ColorsListReducer,
  sizesList: SizesListReducer,
  sizeList: SizeListReducer,
  productitems: productItemsListReducer,
  productitem: productItemListReducer,
  wishproductitem: wishProductItemListReducer,
  itemimages: ItemImagesListReducer,
  campaignimagesList: CampaignImagesListReducer,
  sku: SkuListReducer,
  productoffers: ProductOffersListReducer,
  feedback: listFeedbackReducer,
  blog : getBlogReducer 
});

// const cartItemsFromStorage = localStorage.getItem('cartItems') ?
//     JSON.parse(localStorage.getItem('cartItems')) : []

// const userInfoFromStorage = localStorage.getItem('userInfo') ?
//     JSON.parse(localStorage.getItem('userInfo')) : null
//     console.log(userInfoFromStorage)

// const shippingAddressFromStorage = localStorage.getItem('shippingAddress') ?
//     JSON.parse(localStorage.getItem('shippingAddress')) : {}

// const initialState = {
//     cart: {
//         cartItems: cartItemsFromStorage,
//         shippingAddress: shippingAddressFromStorage,},
//     userLogin: { userInfo : userInfoFromStorage },
// }

const middleware = [thunk];

const store = configureStore(
  {
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }),
  },

  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
