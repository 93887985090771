import React, { useCallback, useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"

// import { createOrder, payOrder } from "../actions/orderActions";
// import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import { useLocation, useNavigate } from "react-router-dom"
import { listSizes } from "../actions/productActions"
import RazorPay from "../components/RazorPaymen"
import axios from "axios"
import ProfileScreen from "./ProfileScreen"
// import { Button } from "@material-tailwind/react";
// import PrintInitial from "../components/PrintInitial";
import {
  FormControl,
  FormControlLabel,
  // FormLabel,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material"
// import { Helmet } from "react-helmet";
import CashOnDelivery from "../components/CashOnDelivery"

function PlaceOrderScreen() {
  const navigate = useNavigate()

  const orderCreate = useSelector((state) => state.orderCreate)
  const { error } = orderCreate

  const dispatch = useDispatch()

  const cart = useSelector((state) => state.cart)
  // const { cartItems } = cart;

  const { shippingAddress } = cart
  const userLogin = useSelector((state) => state.userLogin)

  const { userInfo } = userLogin

  // const [printOpen, setPrintOpen] = useState(false);
  // const [productItemImage, setProductItemImage] = useState();

  // const handlePrintOpen = (img) => {
  //   setPrintOpen(!printOpen);
  //   setProductItemImage(img);
  // };

  const { state } = useLocation()

  const [prof, setProf] = useState({
    profilescreen: false,
  })

  const brandList = useSelector((state) => state.brandList)
  const { brand } = brandList // get value of brands from redux

  const forProfileScreen = useCallback(() => {
    setProf({ ...prof, profilescreen: !prof.profilescreen })
  }, [prof])

  // const sizesList = useSelector((state) => state.sizesList);
  // const { sizes } = sizesList;

  // cart.deliveryCharge = cart.itemsPrice > 599 ? 0 : 49;
  // cart.taxPrice = Number(0.082 * cart.itemsPrice).toFixed(2);
  // cart.totalPrice = Number(cart.itemsPrice) + Number(cart.deliveryCharge);
  // Number(cart.taxPrice)

  // if (!cart.paymentMethod) {
  //     history('/payment')
  // }

  useEffect(() => {
    if (error) {
      alert("sorry something went wrong try again")
    }

    if (!userInfo) {
      navigate("/")
    }

    dispatch(listSizes())
  }, [userInfo])

  const productoffer = useSelector((state) => state.productoffers)
  const { productoffers } = productoffer

  const [code, setCode] = useState("")

  const [CodeApplied, setCodeApplied] = useState()

  const VocherFunc = async (e) => {
    e.preventDefault()
    await axios
      .post("/api/payment/redeem-voucher/", {
        couponCode: code,
        cartItems: state?.cartProductItemsDataBase,
        discountPrice: state?.discountPrice,
        totalPrice: Number(state?.checkoutTotal),
        userId: userInfo?.id,
      })
      .then((data) => {
        if (data?.status === 200) {
          setCodeApplied(data)
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const [value, setValue] = useState("online")

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <div className="select-none  2xl:pb-16 font-style">
      <div className="  lg:p-4 ">
        <div className="w-full flex justify-center">
          <div className="lg:w-[95%] xl:w-[85%]">
            <p className="text-3xl font-style capitalize md:p-4 py-3 flex justify-start  text-gray-900">
              Order confirmation
            </p>
          </div>
        </div>
        <div className="flex  flex-wrap justify-center mx-4  ">
          <div
            className={` w-full  lg:mx-4 lg:w-[53%]  xl:w-[50%]  ${
              state?.cartProductItemsDataBase?.length >= 1 &&
              "lg:sticky lg:top-40 "
            } p-2 shadow-md backdrop-blur-[1px] bg-white/10  border border-[rgb(240,203,137,0.7)] divide-y-2 divide-dotted divide-gray-300  h-full md:px-4   rounded-xl   my-2 `}
          >
            <div className="flex justify-start    items-center my-3">
              <p className="font-bold mr-2 text-primary text-xl ">
                Order Summary ( {state?.cartProductItemsDataBase?.length} items)
              </p>
            </div>

            {state?.cartProductItemsDataBase?.map((cartitem, i) => {
              const dataBaseQty = state?.cartItemsDataBase?.find((obj) => {
                return obj.product_item === cartitem.id
              })
              const isProductOffer = productoffers.find(
                (val) => val.product === cartitem.product.id
              )
              const brandName = brand?.filter(
                (name) => name?.id === cartitem?.product?.brand
              )[0]
              return (
                <div className="  mt-3 " key={i}>
                  {isProductOffer && (
                    <div className="capitalize mt-2 -mb-3 relative text-xs sm:text-sm  text-primary bg-gradient  font-style  rounded-sm flex justify-between">
                      <p className="p-2 flex items-center ">
                        <svg
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-5 h-5 mr-1"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                          />
                        </svg>
                        {isProductOffer?.offer?.value}
                        {isProductOffer?.offer?.type === "discount" && "%"}
                        Offer Applied
                      </p>
                    </div>
                  )}
                  <div
                    className={`flex  items-start  justify-between overflow-hidden rounded-lg pr-2 ${
                      isProductOffer ? "bg-gradient " : ""
                    }`}
                  >
                    <div className="flex  items-center   capitalize overflow-hidden text-gray-800  my-3 ">
                      <div className=" rounded-xl   w-16 h-24 sm:w-24 sm:h-32 lg:w-36 lg:h-44  m-1">
                        <img
                          className=" rounded-xl w-16 h-24 sm:w-24 sm:h-32 lg:w-36 lg:h-44 object-contain"
                          src={cartitem?.image}
                          alt="ordered items img"
                        />
                      </div>

                      <div className=" ml-3 text-xs sm:text-[15px] lg:text-[16px] ">
                        <p className="  text-xs sm:text-[14px] lg:text-[16px] my-1 font-style text-gray-600">
                          {brandName?.name}
                        </p>
                        <p className="font-style text-gray-600 truncate lg:w-52 xs:w-full xl:w-full w-32 ">
                          {cartitem?.product.name}
                        </p>

                        <div className=" text-xs sm:text-[14px] lg:text-[16px]">
                          <p className="  font-style text-start  text-gray-500 sm:my-2">
                            size :
                            <span className=" text-primary ml-1">
                              {cartitem?.size.name}
                            </span>
                          </p>
                          <p className="font-style text-start  text-gray-500 sm:my-2">
                            color :
                            <span className=" text-primary ml-1">
                              {cartitem.color.name}
                            </span>
                          </p>
                        </div>
                        <div className="flex text-xs sm:text-[14px] lg:text-[16px] font-style text-start  text-gray-500 sm:my-2">
                          <p className=""> qty : </p>
                          <p className="  text-primary mx-2">
                            {dataBaseQty?.quantity}
                          </p>
                        </div>
                        {isProductOffer?.offer?.type === "discount" ? (
                          <div>
                            <p className="flex items-center text-gray-500 font-style  text-xs sm:text-[14px] lg:text-[16px]">
                              price :
                              <span className="text-primary  ml-2">
                                ₹
                                {Number(
                                  cartitem.product.mrp -
                                    cartitem.product?.mrp *
                                      (isProductOffer?.offer?.value / 100)
                                ).toFixed(2)}
                              </span>
                              <del className="hidden xs:block text-xs ml-1">
                                ₹{cartitem.product?.mrp}
                              </del>
                            </p>
                          </div>
                        ) : (
                          <div className="flex text-xs sm:text-[14px] lg:text-[16px] font-style text-start  text-gray-500 sm:my-2">
                            <p className=""> price : </p>
                            <p className="   text-primary mx-2">
                              ₹{cartitem?.product.price}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className=" ">
                      <div className="pt-3  my-3 capitalize text-xs sm:text-[14px] lg:text-[16px] font-style text-end  text-gray-600 ">
                        <p className="mb-1"> Total </p>
                        <p className="text-end    text-primary">
                          ₹
                          {isProductOffer?.offer?.type === "discount"
                            ? Number(
                                cartitem.product.mrp -
                                  cartitem.product?.mrp *
                                    (isProductOffer?.offer?.value / 100)
                              ).toFixed(2) * Number(dataBaseQty?.quantity)
                            : (
                                Number(dataBaseQty?.quantity) *
                                Number(cartitem?.product.price)
                              ).toFixed(2)}
                        </p>
                      </div>
                      <div className="flex justify-center items-end h-12 sm:h-16 md:h-24">
                        {dataBaseQty?.print_value && (
                          <Tooltip title="Your Print initial" placement="top">
                            <div className="font-style   w-7 h-7 flex justify-center items-center  group duration-300 border border-primary text-primary rounded-full ">
                              <p> {dataBaseQty.print_value} </p>
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>

          <div
            className={`  w-full  lg:w-[42%]  xl:w-[35%]  h-full   ${
              state?.cartProductItemsDataBase?.length > 2 &&
              "lg:sticky lg:top-40 "
            }  rounded-2xl`}
          >
            <div className=" my-2">
              <div className="flex w-full  bg-gray-200/40    p-4 items-center justify-between rounded-2xl   ">
                <div className="w-full ">
                  <div className="flex justify-between mb-3 items-center">
                    <p className="bg-primary   rounded-full w-7 h-7  flex justify-center items-center  text-white font-style">
                      A
                    </p>
                    <p className="font-style  text-lg text-center w-full text-gray-900">
                      Login
                    </p>
                    <ProfileScreen
                      forProfileScreen={forProfileScreen}
                      open={prof.profilescreen}
                    />

                    <div
                      className="  p-2 shadow-md text-black   hover:shadow-none text-xs rounded-xl"
                      onClick={() => forProfileScreen()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className=" ml-1">
                    <div className="my-2 font-style  text-gray-600 flex items-start">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 mr-2 text-primary"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                        />
                      </svg>

                      <p className="  capitalize  truncate w-52 xs:w-full">
                        {userInfo?.username}
                      </p>
                    </div>

                    <div className="mt-2 font-style  text-gray-600 flex items-start">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 mr-2  text-primary"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                        />
                      </svg>

                      <p className=" truncate w-52 xs:w-full">
                        {/* {userInfo?.email === userInfo.number ? shippingAddress.email : userInfo.email} */}
                        {userInfo?.email}
                      </p>
                    </div>

                    <div
                      className={` ${
                        !userInfo?.number ? "items-center" : "items-start "
                      }  my-2 font-style  text-gray-600 flex `}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 mr-2 text-primary"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                        />
                      </svg>

                      <p className="truncate w-52 ">{userInfo?.number}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex w-full my-3  bg-gray-200/40 p-4 items-center justify-between rounded-2xl   ">
              <div className="w-full">
                <div className="flex justify-start mb-3 w-full">
                  <p className="bg-primary   rounded-full w-7 h-7  flex justify-center items-center  text-white font-style">
                    B
                  </p>

                  <p className="font-style text-lg  text-center w-full text-gray-900">
                    Delivery Address
                  </p>
                  {/* <Link to={"/shipping"}>
                  <div className=" bg-gradient-to-l from-gray-200  p-2 shadow-md text-black  hover:shadow-none text-xs rounded-xl">
                   
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                      />
                    </svg>

                  </div>
                  </Link> */}
                </div>

                <div className="ml-1">
                  <div className="my-2 font-style  text-gray-600 flex items-start  capitalize">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mr-2 text-primary"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                      />
                    </svg>
                    <p className="truncate w-52 ">{shippingAddress?.name}</p>
                  </div>

                  <div className="my-2 font-style  text-gray-600 flex items-start">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mr-2 text-primary"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                      />
                    </svg>
                    <p className="truncate w-52">
                      {shippingAddress?.number ?? userInfo?.number}
                    </p>
                  </div>

                  <div className="mt-2 font-style  text-gray-600 flex items-start  capitalize">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-5 h-5 mr-2 mt-0.5 text-primary"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                      />
                    </svg>

                    <div>
                      <span>{shippingAddress?.houseNo},</span>
                      <p className="flex flex-wrap">
                        {shippingAddress?.address},
                      </p>
                      <p className="flex flex-wrap">{shippingAddress?.city}.</p>

                      <p className="flex flex-wrap">
                        {shippingAddress?.state} - {shippingAddress?.pincode}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`flex-col w-full my-3  bg-gray-200/40  items-center justify-between
             rounded-2xl font-style  text-[15px] text-gray-900  ${
               CodeApplied?.data?.error && "border border-red-700"
             }  ${
                !CodeApplied?.data?.error &&
                CodeApplied &&
                "border-green-700 border"
              } `}
            >
              {!CodeApplied?.data?.error && CodeApplied ? (
                <div
                  className={` text-sm text-green-700 p-3 flex items-center rounded-2xl  `}
                >
                  <div className="w-7 h-7">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="green"
                      className="w-6 h-6  "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <p className=" m-4">{CodeApplied?.data?.successMessage}</p>
                </div>
              ) : (
                <>
                  <div className="  p-3 rounded-xl ">
                    <p>
                      Redeem your gift voucher before proceeding to payment.
                    </p>
                    {CodeApplied?.data?.error && (
                      <p className="text-xs text-red-600">
                        {CodeApplied?.data?.error}
                      </p>
                    )}
                  </div>

                  <form
                    className="flex items-center mt-2  p-4"
                    onSubmit={(e) => VocherFunc(e)}
                  >
                    <div className="relative  ">
                      <input
                        type="text"
                        value={code}
                        required={!code.includes("-")}
                        maxLength={14}
                        minLength={14}
                        onChange={(e) => setCode(e.target.value)}
                        placeholder=" "
                        id="Redeem"
                        className={`block border font-style px-2.5 pb-2.5 w-48 2xs:w-auto  pt-2  text-[15px] text-gray-900 bg-gray-100 rounded-lg peer focus:outline-none focus:ring-0 `}
                      />
                      <label
                        htmlFor="Redeem"
                        className="absolute text-sm text-gray-500  duration-300 transform -translate-y-8  -translate-x-4 peer-placeholder-shown:px-8   scale-100 top-3    px-4     z-10 origin-[0]   peer-focus:px-2   peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-100 peer-focus:-translate-y-8 left-1"
                      >
                        Enter reedeem code
                      </label>
                    </div>
                    <button
                      type="submit"
                      className="text-medium ml-3 mt-1 h-9 mb-0.5 bg-primary px-2 text-white rounded-lg"
                    >
                      Apply
                    </button>
                  </form>
                </>
              )}
            </div>

            <div className=" divide-y-2 divide-dotted text-gray-600  rounded-b-xl m-2 font-style ">
              {state?.discountPrice > 1 ? (
                <>
                  <div className="m-1 pt-2 flex justify-between">
                    <div> Subtotal</div>
                    <div>
                      ₹
                      {(
                        Number(state?.checkoutTotal) 
                      )?.toFixed(2)}
                    </div>
                  </div>
                  {/* {CodeApplied?.data?.error && (
                    <div className="m-1 pt-2 flex justify-between">
                      <div> Promotion products </div>
                      <div>
                        - ₹ {Number(state?.discountPrice * 2).toFixed(2)}
                      </div>
                    </div>
                  )} */}

                  {/* <div className="m-1 pt-2 flex justify-between">
                    <div> Promotion applied </div>
                    <div>- ₹ {Number(state?.discountPrice).toFixed(2)} </div>
                  </div> */}
                </>
              ) : (
                <div className="m-1 pt-2 flex justify-between">
                  <div> Subtotal</div>
                  <div>₹ {state?.checkoutTotal} </div>
                </div>
              )}

              {!CodeApplied?.data?.error && CodeApplied && (
                <div className="m-1 pt-2 flex justify-between">
                  <div> Vocher applied </div>
                  <div className="text-green-700">
                    - ₹ {Number(CodeApplied?.data.CouponPrice).toFixed(2)}
                  </div>
                </div>
              )}

              <div className="m-1 pt-2 flex justify-between">
                <div> Delivery Charge</div>
                {state?.deliveryChargePrice ? (
                  <div>₹ {(state?.deliveryChargePrice).toFixed(2)}</div>
                ) : (
                  <p>Free</p>
                )}
              </div>
              {value === "cod" ? (
                <div className="m-1 pt-2 flex justify-between">
                  <div> COD Charge</div>
                  <div>₹ 49.00</div>
                </div>
              ) : (
                ""
              )}

              <div className="m-1 text-lg font-bold py-1 flex justify-between text-gray-700 text-end">
                <div>Order Total</div>
                <div>
                  {!CodeApplied?.data?.error && CodeApplied ? (
                    <>
                      ₹
                      {CodeApplied?.data?.totalPriceAfterDiscount < 599
                        ? (
                            CodeApplied?.data?.totalPriceAfterDiscount +
                            state?.deliveryChargePrice +
                            (value === "cod" ? Number(49) : 0)
                          ).toFixed(2)
                        : (
                            CodeApplied?.data?.totalPriceAfterDiscount +
                            (value === "cod" ? Number(49) : 0)
                          )?.toFixed(2)}
                    </>
                  ) : (
                    <>
                      ₹
                      {value === "cod"
                        ? (
                            Number(state?.checkoutTotal) +
                            Number(state?.deliveryChargePrice) +
                            Number(49)
                          ).toFixed(2)
                        : (
                            Number(state?.checkoutTotal) +
                            Number(state?.deliveryChargePrice)
                          ).toFixed(2)}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="flex w-full my-3  bg-gray-200/40 p-4 items-center justify-between rounded-2xl   ">
              <div className="w-full ">
                <FormControl sx={{ width: "100%" }}>
                  <div className="flex justify-center items-center">
                    <svg
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <p className="text-center font-medium ml-1">
                      Payment Method
                    </p>
                  </div>

                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="online"
                      control={
                        <Radio
                          sx={{
                            color: "black",

                            "&.Mui-checked": {
                              color: "black",
                            },
                          }}
                        />
                      }
                      label={
                        <p className="font-bold text-gray-800">
                          Online Payment
                        </p>
                      }
                      sx={{ paddingTop: "1rem" }}
                    />
                    <FormControlLabel
                      value="cod"
                      control={
                        <Radio
                          sx={{
                            color: "black",
                            "&.Mui-checked": {
                              color: "black",
                            },
                          }}
                        />
                      }
                      label={
                        <p className="font-bold text-gray-800 ">
                          Cash on Delivery (COD)
                        </p>
                      }
                    />
                    <p className="text-xs">
                      (Additional charge of Rs.49 will be add for cod option)
                    </p>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            {value === "online" ? (
              <RazorPay
                userInfo={userInfo}
                orderState={state}
                voucherApplied={
                  !CodeApplied?.data?.error && CodeApplied
                    ? CodeApplied?.data?.totalPriceAfterDiscount?.toFixed(2)
                    : 0
                }
                couponCode={
                  !CodeApplied?.data?.error && CodeApplied
                    ? CodeApplied?.data?.offerCode
                    : ""
                }
              />
            ) : (
              <CashOnDelivery
                userInfo={userInfo}
                orderState={state}
                voucherApplied={
                  !CodeApplied?.data?.error && CodeApplied
                    ? CodeApplied?.data?.totalPriceAfterDiscount?.toFixed(2)
                    : 0
                }
                couponCode={
                  !CodeApplied?.data?.error && CodeApplied
                    ? CodeApplied?.data?.offerCode
                    : ""
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlaceOrderScreen
