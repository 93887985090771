import React, { useEffect, useState } from "react"; // react import
import { XMarkIcon } from "@heroicons/react/24/outline"; // icon import
import { useDispatch, useSelector } from "react-redux"; // react redux import
import { listBrands } from "../actions/productActions"; // brands imported from  productactions
import { Link } from "react-router-dom"; //Link imported from react dom
import Drawer from "@mui/material/Drawer"; // side drawer imported from  material ui

function Brand() {
  const brandList = useSelector((state) => state.brandList);
  const { brand } = brandList; // get value of brands from redux

  const [brandOpen, setBrandOpen] = useState(false); // useState declared for open and close drawer

  const dispatch = useDispatch();

  useEffect(() => {
    // useeffect function for load one time
    if (!brand?.length) dispatch(listBrands());
  }, []);

  return (
    <div className="select-none font-style">
      <div
        onClick={() => setBrandOpen(!brandOpen)}
        className="float-left font-style text-gray-800  capitalize text-start 
         w-full text-[12px] w-80px mx-2 lg:text-sm xl:text-sm hover:text-[rgb(0,53,96)]  "
      >
        our brands <br />
        partner brands
      </div>
      <Drawer
        className="w-96"
        transitionDuration={500}
        open={brandOpen}
        onClose={() => setBrandOpen(!brandOpen)}
      >
        <div className="pointer-events-auto w-screen xs:w-[26rem]  ">
          <div className="flex h-screen flex-col  bg-gray-100  ">
            <div className="flex  justify-between  p-4  border-b-2   absolute  w-full z-10 rounded-b-xl shadow-lg bg-gradient">
              <div className="flex text-lg font-bold   ml-3 text-gray-800">
                <p>Brands</p>
              </div>
              <div className="ml-3 flex h-7 items-center">
                <button
                  type="button"
                  className="-m-2 p-2 text-gray-300 "
                  onClick={() => setBrandOpen(!brandOpen)}
                >
                  <XMarkIcon
                    className="h-6 w-6 hover:scale-150 mr-2 text-gray-700"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
            <div className="overflow-y-auto h-full no-scrollbar bg-white">
              <div className=" px-3">
                <div className="mt-20  l mb-3 font-style">
                  <p className="font-bold uppercase text-lg  text-gray-700">
                    our brands
                  </p>
                  <p className="text-sm text-gray-500">
                    styles designed in-house for your perfect fit
                  </p>
                </div>
                <div className="flex flex-wrap justify-between ">
                  {brand?.filter(({ our_brand }) => our_brand === true)
                    .map((brand) => {
                      return (
                        <div key={brand.id} className="mt-6">
                          <Link
                            className=" bg-white "
                            to={`/products/?category=&brand=${brand.id}&style=&fabric=&color=&size=`}
                            onClick={() => {
                              setBrandOpen(!brandOpen);
                            }}
                          >
                            <img
                              src={brand.image}
                              alt={brand.name}
                              className="object-cover h-14 w-32  xs:h-20 xs:w-40 hover:shadow-md rounded-lg  "
                            />
                          </Link>
                        </div>
                      );
                    })}
                </div>
                <hr className="my-3" />
                <div className="mb-3 font-style">
                  <p className="font-bold uppercase text-lg text-gray-700">
                    PARTNER BRANDS
                  </p>
                  <p className="text-sm text-gray-500">
                    Curated styles handpicked by our designers
                  </p>
                </div>
                <div className="flex flex-wrap justify-between  ">
                  {brand
                    ?.filter(({ our_brand }) => our_brand === false)
                    .map((brand) => {
                      return (
                        <div key={brand.id} className="mt-6">
                          <Link
                            className=" bg-white shadow-lg  hover:shadow-gray-400"
                            to={`/products/?category=&brand=${brand.id}&style=&fabric=&color=&size=`}
                            onClick={() => {
                              setBrandOpen(!brandOpen);
                            }}
                          >
                            <img
                              src={brand.image}
                              alt={brand.name}
                              className="object-cover h-14 w-32  xs:h-20 xs:w-40 hover:shadow-md rounded-lg "
                            />
                          </Link>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>

            <div className=" flex justify-evenly  text-sm text-gray-500  p-2 py-2  ">
              <button
                className="font-style text-sm text-gray-500 hover:text-gray-700 "
                onClick={() => setBrandOpen(!brandOpen)}
              >
                Continue Shopping
              </button>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default Brand;
