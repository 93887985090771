
import {
    PRODUCT_LIST_REQUEST,
    PRODUCT_LIST_SUCCESS,
    PRODUCT_LIST_FAIL,

    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,

   


    PRODUCT_CREATE_REVIEW_REQUEST,
    PRODUCT_CREATE_REVIEW_SUCCESS,
    PRODUCT_CREATE_REVIEW_FAIL,
    PRODUCT_CREATE_REVIEW_RESET,

    PRODUCT_REVIEW_REQUEST,
    PRODUCT_REVIEW_SUCCESS,
    PRODUCT_REVIEW_FAIL,

    PRODUCT_TOP_REQUEST,
    PRODUCT_TOP_SUCCESS,
    PRODUCT_TOP_FAIL,

    FILTER_LIST_REQUEST,
    FILTER_LIST_SUCCESS,
    FILTER_LIST_FAIL,

    CATEGORY_LIST_REQUEST,
    CATEGORY_LIST_SUCCESS,
    CATEGORY_LIST_FAIL,

    BRAND_LIST_REQUEST,
    BRAND_LIST_SUCCESS,
    BRAND_LIST_FAIL,

    STYLES_LIST_REQUEST,
    STYLES_LIST_SUCCESS,
    STYLES_LIST_FAIL,

    FABRICS_LIST_REQUEST,
    FABRICS_LIST_SUCCESS,
    FABRICS_LIST_FAIL,

    COLORS_LIST_REQUEST,
    COLORS_LIST_SUCCESS,
    COLORS_LIST_FAIL,

   SIZES_LIST_REQUEST,
   SIZES_LIST_SUCCESS,
   SIZES_LIST_FAIL,

   SIZE_LIST_REQUEST,
   SIZE_LIST_SUCCESS,
   SIZE_LIST_FAIL,


   PRODUCTITEMS_LIST_REQUEST,
   PRODUCTITEMS_LIST_SUCCESS,
   PRODUCTITEMS_LIST_FAIL,

   PRODUCTITEM_LIST_REQUEST,
   PRODUCTITEM_LIST_SUCCESS,
   PRODUCTITEM_LIST_FAIL,

   WISHPRODUCTITEM_LIST_REQUEST,
   WISHPRODUCTITEM_LIST_SUCCESS,
   WISHPRODUCTITEM_LIST_FAIL,

   ITEMIMAGES_LIST_REQUEST,
   ITEMIMAGES_LIST_SUCCESS,
   ITEMIMAGES_LIST_FAIL,


   CAMPAIGNIMAGES_LIST_REQUEST,
   CAMPAIGNIMAGES_LIST_SUCCESS,
   CAMPAIGNIMAGES_LIST_FAIL,


   SKU_LIST_REQUEST,
   SKU_LIST_SUCCESS,
   SKU_LIST_FAIL,

   PRODUCTOFFERS_LIST_REQUEST,
   PRODUCTOFFERS_LIST_SUCCESS,
   PRODUCTOFFERS_LIST_FAIL,


   
} from '../constants/productConstants'


export const productListReducer = (state = { products: [], categoryIds:[] }, action) => {
    switch (action.type) {
        case PRODUCT_LIST_REQUEST:
            return { loading: true, products: [], categoryIds:[] }

        case PRODUCT_LIST_SUCCESS:
            return {
                loading: false,
                products: action.payload.products,
                categoryIds:action.payload.categoryIds

                // page: action.payload.page,
                // pages: action.payload.pages
            }

        case PRODUCT_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}



export const productDetailsReducer = (state = { product: { reviews: [] } }, action) => {
    switch (action.type) {
        case PRODUCT_DETAILS_REQUEST:
            return { loading: true, ...state }

        case PRODUCT_DETAILS_SUCCESS:
            return { loading: false, product: action.payload }

        case PRODUCT_DETAILS_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}












export const productReviewCreateReducer = (state = {status:[]}, action) => {
    switch (action.type) {
        case PRODUCT_CREATE_REVIEW_REQUEST:
            return { loading: true, status:[] }

        case PRODUCT_CREATE_REVIEW_SUCCESS:
            return { loading: false, success: true, status : action.payload  }

        case PRODUCT_CREATE_REVIEW_FAIL:
            return { loading: false, error:action.payload }

        case PRODUCT_CREATE_REVIEW_RESET:
            return {}

        default:
            return state
    }
}


export const productTopRatedReducer = (state = { products: [] }, action) => {
    switch (action.type) {
        case PRODUCT_TOP_REQUEST:
            return { loading: true, products: [] }

        case PRODUCT_TOP_SUCCESS:
            return { loading: false, products: action.payload, }

        case PRODUCT_TOP_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const productReviewReducer = (state = { productsReviews: [] }, action) => {
    switch (action.type) {
        case PRODUCT_REVIEW_REQUEST:
            return { loading: true, productsReviews: [] }

        case PRODUCT_REVIEW_SUCCESS:
            return { loading: false, productsReviews: action.payload, }

        case PRODUCT_REVIEW_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const FIlterListReducer = (state = { filters: [] }, action) => {
    switch (action.type) {
        case FILTER_LIST_REQUEST:
            return { loading: true, filters: [] }

        case FILTER_LIST_SUCCESS:
            return {
                loading: false,
                filter: action.payload,
               
            }

        case FILTER_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const CategoryListReducer = (state = { categories: [] }, action) => {
    switch (action.type) {
        case CATEGORY_LIST_REQUEST:
            return { loading: true, categories: [] }

        case CATEGORY_LIST_SUCCESS:
            return {
                loading: false,
                category: action.payload,
               
            }

        case CATEGORY_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const BrandListReducer = (state = { brands: [] }, action) => {
    switch (action.type) {
        case BRAND_LIST_REQUEST:
            return { loading: true, brands: [] }

        case BRAND_LIST_SUCCESS:
            return {
                loading: false,
                brand: action.payload,
               
            }

        case BRAND_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const StylesListReducer = (state = { styles: [] }, action) => {
    switch (action.type) {
        case STYLES_LIST_REQUEST:
            return { loading: true, styles: [] }

        case STYLES_LIST_SUCCESS:
            return {
                loading: false,
                styles: action.payload,   
            }
        case STYLES_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}



export const FabricsListReducer = (state = { fabrics: [] }, action) => {
    switch (action.type) {
        case FABRICS_LIST_REQUEST:
            return { loading: true, fabrics: [] }

        case FABRICS_LIST_SUCCESS:
            return {
                loading: false,
                fabrics: action.payload,
               
            }

        case FABRICS_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const ColorsListReducer = (state = { colors: [], soloColor:[] }, action) => {
    switch (action.type) {
        case COLORS_LIST_REQUEST:
            return { loading: true, colors: [], soloColor:[] }

        case COLORS_LIST_SUCCESS:
            return {
                loading: false,
                colors:action.payload[0],
                soloColor:action.payload[1] ,
            }
            
        case COLORS_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const SizesListReducer = (state = { sizes: [], soloSizes:[] }, action) => {
    switch (action.type) {
        case SIZES_LIST_REQUEST:
            return { loading: true, sizes: [] ,soloSizes:[]}

        case SIZES_LIST_SUCCESS:
            return {
                loading: false,
                sizes: action.payload[0], 
                soloSizes: action.payload[1]  
            }

        case SIZES_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const SizeListReducer = (state = { size: [] }, action) => {
    switch (action.type) {
        case SIZE_LIST_REQUEST:
            return { loading: true, size: [] }

        case SIZE_LIST_SUCCESS:
            return {
                loading: false,
                size: action.payload,   
            }
            
        case SIZE_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const productItemsListReducer = (state = { productitems: [] }, action) => {
    switch (action.type) {
        case PRODUCTITEMS_LIST_REQUEST:
            return { loading: true,  productitems : [] }

        case PRODUCTITEMS_LIST_SUCCESS:
            return {
                loading: false,
                productitems: action.payload,   
            }

        case PRODUCTITEMS_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const productItemListReducer = (state = { productitem: [] }, action) => {
    switch (action.type) {
        case PRODUCTITEM_LIST_REQUEST:
            return { loading: true,  productitem : [] }

        case PRODUCTITEM_LIST_SUCCESS:
            return {
                loading: false,
                productitem: action.payload,   
            }

        case PRODUCTITEM_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const wishProductItemListReducer = (state = { wishproductitem: [] }, action) => {
    switch (action.type) {
        case WISHPRODUCTITEM_LIST_REQUEST:
            return { loading: true,  wishproductitem : [] }

        case WISHPRODUCTITEM_LIST_SUCCESS:
            return {
                loading: false,
                wishproductitem: action.payload,   
            }

        case WISHPRODUCTITEM_LIST_FAIL:
            return { loading: false,  error: action.payload }

        default:
            return state
    }
}





export const ItemImagesListReducer = (state = { itemimages: [] }, action) => {
    switch (action.type) {
        case ITEMIMAGES_LIST_REQUEST:
            return { loading: true,  itemimages : [] }

        case ITEMIMAGES_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                itemimages:[...state.itemimages, ...action.payload],   
            }

        case ITEMIMAGES_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const SkuListReducer = (state = { sku: [] }, action) => {
    switch (action.type) {
        case SKU_LIST_REQUEST:
            return { loading: true,  sku : [] }

        case SKU_LIST_SUCCESS:
            return {
                loading: false,
                sku: action.payload,   
            }

        case SKU_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const ProductOffersListReducer = (state = { productoffers: [] }, action) => {
    switch (action.type) {
        case PRODUCTOFFERS_LIST_REQUEST:
           
            return { loading: true,  productoffers : [] }

        case PRODUCTOFFERS_LIST_SUCCESS:
            return {
                loading: false,
                productoffers: action.payload
            }

        case PRODUCTOFFERS_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}


export const CampaignImagesListReducer = (state = { campaignimages: [] }, action) => {
    switch (action.type) {
        case CAMPAIGNIMAGES_LIST_REQUEST:
            return { loading: true,  campaignimages : [] }

        case CAMPAIGNIMAGES_LIST_SUCCESS:
            return {
                loading: false,
                campaignimages : action.payload,   
            }

        case CAMPAIGNIMAGES_LIST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}
