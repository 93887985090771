import axios from "axios";
import {
    BLOG_CATEGORY_REQUEST,
    BLOG_CATEGORY_SUCCESS,
    BLOG_CATEGORY_FAIL,

    BLOGS_REQUEST,
    BLOGS_SUCCESS,
    BLOGS_FAIL ,

    BLOG_CONTENT_REQUEST ,
    BLOG_CONTENT_SUCCESS,
    BLOG_CONTENT_FAIL ,
    
} from "../constants/blogConstants";


export const getBlogCategory = () => async (dispatch) => {
  try {
    dispatch({ type: BLOG_CATEGORY_REQUEST });

    const { data } = await axios.get(`/base/api/blog-categories/`);

    dispatch({
      type: BLOG_CATEGORY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BLOG_CATEGORY_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getBlogs = (categoryId) => async (dispatch) => {
  try {
    dispatch({ type: BLOGS_REQUEST });

    const { data } = await axios.get(`/base/api/blogs/?categoryId=${categoryId}`);

    dispatch({
      type: BLOGS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BLOGS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getBlogContent = (slug) => async (dispatch) => {
  try {
    dispatch({ type: BLOG_CONTENT_REQUEST });

    const { data } = await axios.get(`/base/api/blog/${slug}/`);

    dispatch({
      type: BLOG_CONTENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: BLOG_CONTENT_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


