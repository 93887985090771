import * as React from "react";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import img from "../../../images/images/box.png";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@material-tailwind/react";

const blogContent = [
  {
    id: 1,
    image: img,
    tittle: "something something something something something",
    category: "fashion",
  },
  {
    id: 2,
    image: img,
    tittle: " something something ",
    category: "fashion",
  },
  {
    id: 3,
    image: img,
    tittle: "somethingsome thingsome thingso methings omethingsome",
    category: "fashion",
  },
];

function BlogCategoryPage({ categoryName, ...rest }) {
  const { categories, blogs } = useSelector((state) => state.blog);

  const navigate = useNavigate();

  return (
    <React.Fragment>
      {blogs?.length ? (
        <>
          <div>
            <p className="text-center font-poppins text-2xl mb-12 uppercase">
              {categoryName}
            </p>
          </div>

          <div className="flex justify-center font-poppins">
            <div className="flex flex-wrap justify-evenly lg:space-x-6 w-full max-w-[1100px]">
              {blogs?.map((map, i) => {
                const catName = categories.filter(
                  (cat) => cat.id === map.category
                )[0];

                return (
                  <div className="xs:w-1/2 lg:w-[40%] p-2" key={i}>
                    <Link to={`/blog/${map.slug}`}>
                      <img src={map.image} alt="" className="w-full" />
                    </Link>
                    <div className="mt-6 uppercase">
                      <p className="text-center  text-green-700">
                        {catName?.name}
                      </p>
                      <p className="text-center text-sm mt-2">{map.title}</p>
                      <p className="text-center text-[10px] text-gray-500 ">
                        {new Date(map.created_at).toDateString()}
                      </p>
                    </div>
                  </div>
                );
              })}

              <div>
                <p className="text-center font-poppins text-2xl my-12 uppercase w-screen">
                  Recent posts
                </p>
              </div>

              {blogs?.map((map, i) => {
                const catName = categories.filter(
                  (cat) => cat.id === map.category
                )[0];
                return (
                  <div className="xs:w-1/2 lg:w-[40%] p-2" key={i}>
                    <Link to={`/blog/${map.slug}`}>
                      <img src={map.image} alt="" className="w-full" />
                    </Link>
                    <div className="mt-6 uppercase">
                      <p className="text-center  text-green-700">
                        {catName.name}
                      </p>
                      <p className="text-center text-sm mt-2">{map.title}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex justify-center flex-wrap ">
            <p className="w-full text-center text-lg">
              sorry , there is no content here !!
            </p>
            <Button
              onClick={() => navigate("/blog")}
              className="text-black bg-green-200 mt-2"
            >
              Go home
            </Button>
          </div>
        </>
      )}
    </React.Fragment>
  );
}
export default BlogCategoryPage;
