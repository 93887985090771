import React, { useEffect } from "react";
import { Fragment, useState } from "react";
// import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";
// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";
// import star from "../images/Myunde.png";
import { IoIosArrowBack } from "react-icons/io";

import { useDispatch, useSelector } from "react-redux";
import { listProductReviews } from "../actions/productActions";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Dialog from "@mui/material/Dialog";

// const images = [
//   {
//     cutomerimage:
//       "https://tailwindui.com/img/ecommerce-images/product-quick-preview-02-detail.jpg",
//     cutomerreview: "Two each of gray white and black shirts arranged on table.",
//   },
//   {
//     cutomerimage:
//       "https://tailwindui.com/img/ecommerce-images/product-quick-preview-02-detail.jpg",
//     cutomerreview: "Two each of gray white and black shirts arranged on table.",
//   },
//   {
//     cutomerimage:
//       "https://tailwindui.com/img/ecommerce-images/product-quick-preview-02-detail.jpg",
//     cutomerreview: "Two each of gray white and black shirts arranged on table.",
//   },
//   {
//     cutomerimage:
//       "https://tailwindui.com/img/ecommerce-images/product-quick-preview-02-detail.jpg",
//     cutomerreview: "Two each of gray white and black shirts arranged on table.",
//   },
//   {
//     cutomerimage:
//       "https://tailwindui.com/img/ecommerce-images/product-quick-preview-02-detail.jpg",
//     cutomerreview: "Two each of gray white and black shirts arranged on table.",
//   },
//   {
//     cutomerimage:
//       "https://tailwindui.com/img/ecommerce-images/product-quick-preview-02-detail.jpg",
//     cutomerreview: "Two each of gray white and black shirts arranged on table.",
//   },
// ];

// const responsive = {
//   superLargeDesktop: {
//     // the naming can be any, depends on you.
//     breakpoint: { max: 4000, min: 3000 },
//     items: 1,
//   },
//   desktop: {
//     breakpoint: { max: 3000, min: 1024 },
//     items: 1,
//   },
//   tablet: {
//     breakpoint: { max: 1024, min: 464 },
//     items: 1,
//   },
//   mobile: {
//     breakpoint: { max: 464, min: 0 },
//     items: 1,
//   },
// };

// const product = [];

function ReviewView({ productId }) {
  const reviewList = useSelector((state) => state.productReviews);
  const { productsReviews } = reviewList;

  const [stateReview, setStateReview] = useState(null);
  const [stateImage, setStateImage] = useState(null);

  const [pic, setPic] = useState({
    photo: true,
    review: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (productId && !productsReviews?.length) {
      dispatch(listProductReviews(productId));
    }
  }, [productId]);

  const [open, setOpen] = useState(false);

  const reArrange = (id) => {
    setStateImage(
      productsReviews[0]?.images?.filter((o) => o.review === Number(id))
    );
    setStateReview(
      productsReviews[0]?.reviews?.filter((o) => o.id === Number(id))[0]
    );

    setPic({
      photo: false,
      review: true,
    });
  };

  return (
    <div className=" select-none">
      <div className="flex flex-wrap  md:justify-start   w-full  h-full justify-start font-style">
        {productsReviews[0]?.images?.slice(0, 4).map((reviewimage, i) => {
          return (
            <div key={i}>
              <div
                className=" w-24 md:w-32 md:h-40 relative rounded-xl border h-28 m-1 overflow-hidden"
                key={i}
              >
                <img
                  src={reviewimage.image}
                  alt="reviewimage"
                  className="object-cover relative w-full h-full    hover:scale-110 duration-500"
                  value={reviewimage.review}
                  onClick={(e) => {
                    setOpen(true);
                    reArrange(e.target.getAttribute("value"));
                  }}
                />
                {i === 3 && (
                  <div
                    className="  group hover:bg-gray-700/30  absolute 
                h-full inset-x-0 bottom-0 flex justify-center items-center text-xl  bg-gray-800/50 font-style"
                    onClick={() => {
                      setOpen(true);
                      setPic({ photo: true, review: false });
                    }}
                  >
                    {productsReviews[0]?.images?.length - Number(4) !== 0 && (
                      <span className="  text-white">
                        (+{productsReviews[0]?.images?.length - Number(4)})
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <Dialog
        open={open}
        transitionDuration={700}
        onClose={() => setOpen(!open)}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className=" w-full bg-white rounded-lg   ">
          <div className="w-full flex flex-wrap 2xs:justify-between shadow-lg rounded-lg items-center p-3  text-gray-800  bg-gray-100 ">
            {pic.review && (
              <>
                <IoIosArrowBack
                  className="w-6 h-6 mr-1 mt-1 hover:scale-75 "
                  onClick={() =>
                    setPic({
                      photo: true,
                      review: false,
                    })
                  }
                />
                <p className="text-xl font-medium ">
                  Customer Review({productsReviews[0]?.reviews?.length})
                </p>
              </>
            )}
            {pic.photo && (
              <p className="text-xl font-medium ">
                Image gallery ({productsReviews[0]?.images?.length})
              </p>
            )}
            <button className=" " onClick={() => setOpen(!open)}>
              <AiOutlineClose className="w-6 h-6 hover:scale-75" />
            </button>
          </div>

          {pic.photo && (
            <div className=" p-4 grid grid-cols-3 xs:grid-cols-3 md:grid-cols-4 grid-rows-3   gap-2 style-4  overflow-y-scroll overflow-hidden md:justify-start h-[30rem]  justify-evenly">
              {productsReviews[0]?.images?.map((reviewimages, i) => {
                return (
                  <div
                    className=" w-28 h-28  sm:w-32  sm:h-32  overflow-hidden border  rounded-lg"
                    key={i}
                  >
                    {reviewimages.image.split(".").pop() !== "mp4" ? (
                      <img
                        src={reviewimages.image}
                        alt={"review images"}
                        key={i}
                        className=" hover:scale-110 duration-500 w-32  h-32 object-cover"
                        value={reviewimages.review}
                        onClick={(e) =>
                          reArrange(e.target.getAttribute("value"))
                        }
                      />
                    ) : (
                      <video
                        src={reviewimages?.image}
                        muted
                        controls
                        lazyload={true}
                        key={i}
                        className=" hover:scale-110 duration-500 w-32  h-32 object-cover"
                        value={reviewimages.review}
                        onClick={(e) =>
                          reArrange(e.target.getAttribute("value"))
                        }
                      />
                    )}
                  </div>
                );
              })}
            </div>
          )}

          {pic.review && (
            <>
              <div className="w-full h-full sm:h-[30rem]  sm:flex items-start justify-center  p-2 mt-3">
                <div className="sm:w-1/2 h-[20rem] sm:h-[28.5rem]   grid grid-cols-1   items-center justify-items-center gap-2   overflow-y-scroll overflow-hidden md:justify-start style-4  justify-evenly">
                  {stateImage
                    ?.filter(
                      (img) => Number(img?.review) ===  Number(stateReview.id)
                    )
                    .map((image, i) => {
                      return (
                        <Zoom key={i}>
                          {image.image.split(".").pop() !== "mp4" ? (
                            <div className="   w-64  h-64  overflow-hidden border  rounded-lg">
                              <img
                                src={image.image}
                                alt={"imagesalt"}
                                className="  hover:scale-110 duration-500 w-64  h-64  object-cover"
                              />
                            </div>
                          ) : (
                            <video src={image?.image} muted autoPlay loop />
                          )}
                        </Zoom>
                      );
                    })}
                </div>
                <hr />
                <div className="sm:w-1/2  bg-gray-100 mt-2 sm:mt-0 sm:h-[28.5rem] rounded-xl py-2  sm:mx-4  px-5 font-medium text-gray-700">
                  <div className="flex flex-wrap ">
                    <div className="p-4 w-6 h-6 border rounded-full capitalize    flex items-center justify-center bg-gray-400 text-white">
                      {stateReview?.name.slice(0, 1)}
                    </div>

                    <div className="ml-3 ">
                      <p className=" flex justify-start text-sm capitalize font-bold truncate  ">
                        {stateReview?.name}
                      </p>
                      <p className="flex justify-start text-xs text-[rgb(0,53,96)]">
                        {new Date(stateReview?.createdAt).toDateString()}
                      </p>

                      <div className="flex items-center  ">
                        <br />
                        <p className="text-xs sm:text-sm  font-bold text-gray-600 ">
                          ({stateReview?.rating})
                        </p>
                        {[...Array(stateReview?.rating)]?.map((x, i) => {
                          return (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill=""
                              key={i}
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-3 h-3 sm:w-4 sm:h-4  ml-1  fill-amber-300 stroke-amber-400"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              />
                            </svg>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <h2 className="text-sm  text-gray-900 text-left mt-2  h-20 sm:h-[23rem]   overflow-y-scroll  style-4 ">
                    {stateReview?.comment}
                  </h2>
                </div>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
}

export default ReviewView;
