import React, { useEffect, useState, memo, useCallback, useRef } from "react"
import { Link, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { MdOutlineLocalOffer } from "react-icons/md"
import {
  addToCart,
  deleteCartDataBase,
  listCartDataBase,
  removeFromCart,
  updateCartDataBase,
} from "../actions/cartActions"
import { useNavigate } from "react-router-dom"
import Drawer from "@mui/material/Drawer"
import { BsCart3 } from "react-icons/bs"
import { AiOutlineClose } from "react-icons/ai"
import { AiOutlineGift } from "react-icons/ai"
import Swal from "sweetalert2"
import { Button } from "@material-tailwind/react"
import cartimg from "../images/Myunde Cartlist_Mesa de trabajo 1_Mesa de trabajo 1.jpg"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import LoginSlide from "./LoginSlide"
import { listProductOffers } from "../actions/productActions"
import PrintInitial from "../components/PrintInitial"
import axios from "axios"
import {
  Avatar,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material"

export const CartPage = memo(({ forCartOpen, open }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productoffer = useSelector((state) => state.productoffers)
  const { productoffers } = productoffer

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart

  const [render, setRender] = useState(null)
  const [print, setPrint] = useState(false)
  const [printData, setPrintData] = useState({})

  const [prof, setProf] = useState({
    profile: false,
    cart: false,
  })

  const forProfileOpen = () => {
    setProf({ ...prof, profile: !prof.profile, cart: prof.cart })
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const dataBaseCart = useSelector((state) => state.dataBaseCart)

  const { loading, cartItemsDataBase, cartProductItemsDataBase } = dataBaseCart

  let cartItemsDBorLS = userInfo ? cartItemsDataBase : cartItems

  const updateCartQuantity = (dataBaseQty, quantity, printValue) => {
    if (userInfo) {
      dispatch(
        updateCartDataBase(
          { ...dataBaseQty, print_value: printValue ?? "" },
          quantity
        )
      ).then(() => {
        setRender(() => {
          const cartItem = cartItemsDBorLS.find(
            (value) => value.id === dataBaseQty.id
          )

          cartItem.quantity = quantity
          cartItem.print_value = printValue
          return {}
        })
      })
    } else {
      dispatch(addToCart(dataBaseQty?.id, Number(quantity), printValue))
    }
  }

  const cartTotalMRP = cartItemsDBorLS?.reduce(
    (acc, item) =>
      acc +
      item.quantity *
        cartProductItemsDataBase?.find(
          (obj) => obj?.product.id === item?.product
        )?.product.mrp,
    0
  )

  const cartTotalMrp = cartTotalMRP?.toFixed(2)

  const totalPrice = cartItemsDBorLS
    ?.reduce(
      (acc, item) =>
        acc +
        item.quantity *
          cartProductItemsDataBase?.find(
            (obj) => obj?.product.id === item?.product
          )?.product.price,
      0
    )
    .toFixed(2)

  const saveMrp = cartTotalMrp - totalPrice

  // if (forCartOpen) {
  //   dispatch(listProductOffers())
  // }

  const cartCall = useCallback(() => {
    if (userInfo) {
      dispatch(listCartDataBase(userInfo?.id, " "))
    } else {
      const LScartItemss = localStorage.getItem("cartItems")
      if (
        (LScartItemss ? JSON.parse(LScartItemss?.length) : false) &&
        !loading &&
        !cartProductItemsDataBase?.length
      ) {
        dispatch(listCartDataBase(0, LScartItemss))
      }
    }
  }, [userInfo])

  useEffect(() => {
    cartCall()
  }, [userInfo])

  useEffect(() => {
    // if (!productoffers?.length && !productoffer.loading)
    dispatch(listProductOffers())
  }, [])

  const RemoveFromCartAlert = (productName) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showConfirmButton: false,
      timer: 2000,
      width: 450,

      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer)
        toast.addEventListener("mouseleave", Swal.resumeTimer)
      },
    })

    Toast.fire({
      icon: "success",
      title: `${productName} removed from cart`,
    })
  }

  const offerAvaliableAlert = (value) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "center",
      showCancelButton: false,
      confirmButtonColor: "rgb(0, 53, 96)",
      confirmButtonText: "ok ",

      width: 350,

      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer)
        toast.addEventListener("mouseleave", Swal.resumeTimer)
      },
    })

    Toast.fire({
      icon: false,
      title: `${value}`,
    })
  }

  const removeFromCartHandler = (ProductItemId, productName, cartItemId) => {
    delete checkListRef.current[ProductItemId]
    if (userInfo) {
      dispatch(deleteCartDataBase(cartItemId)).then(
        () => dispatch(listCartDataBase(userInfo.id, ""))
        // cartItemsDBorLS = cartItemsDBorLS.filter(value => value.id !== ProductItemId)
      )
      RemoveFromCartAlert(productName)
    } else {
      dispatch(removeFromCart(ProductItemId))
      const LScartItems = localStorage.getItem("cartItems")
      if (LScartItems) {
        dispatch(listCartDataBase(0, LScartItems))
      }
      RemoveFromCartAlert(productName)
    }
  }

  const checkoutofStock = () => {
    for (let cart_item of cartProductItemsDataBase) {
      const dataBaseQty = cartItemsDBorLS?.find((obj) => {
        return obj.product_item === cart_item.id
      })

      if (dataBaseQty.quantity > cart_item.quantity) {
        return true
      }
    }
    return false
  }

  const checkoutHandler = () => {
    if (checkoutofStock()) {
      offerAvaliableAlert(
        "one of your product seems out of stock please edit your cart to checkout"
      )
    } else {
      if (userInfo) {
        if (
          Object.values(checkListRef.current).every((list) => list === true)
        ) {
          forCartOpen()
          navigate("../shipping", {
            state: {
              cartItemsDataBase,
              cartProductItemsDataBase,
              checkoutTotal,
              discountPrice,
            },
            replace: true,
          })
        } else {
          offerAvaliableAlert(
            "To apply 'Buy one, Get one' offer, kindly add another item of the same product by clicking the 'add item' button or increase the quantity by one."
          )
        }
      } else {
        forProfileOpen()
      }
    }
  }

  const addProduct = (id) => {
    navigate(`/product/${id}?clr=0`)
    forCartOpen()
  }

  const checkListRef = useRef({})

  const chceckOffer = (productId, productItemId) => {
    const products = cartProductItemsDataBase.filter(
      (value) => value.product.id === productId
    )

    const getQty = (id) => cartItemsDBorLS.find((v) => v.product_item === id)

    let totalProductQty = 0
    for (let i = 0; i < products?.length; i++) {
      totalProductQty += getQty(products[i].id)?.quantity
    }

    if (totalProductQty % 2 === 0) {
      checkListRef.current[productItemId] = true
      return -1
    } else {
      checkListRef.current[productItemId] = false
      const sortedProducts = products.sort(function (a, b) {
        return getQty(a.id)?.quantity - getQty(b.id)?.quantity
      })

      return sortedProducts[0].id
    }
  }

  const getOfferPrice = () => {
    const priceChart = {}
    const products = cartProductItemsDataBase?.filter((value) =>
      productoffers?.some((element) => element?.product === value?.product?.id)
    )

    const getQty = (id) => cartItemsDBorLS.find((v) => v.product_item === id)

    for (let i = 0; i < products?.length; i++) {
      if (priceChart[products[i]?.product.id] === undefined) {
        priceChart[products[i]?.product.id] = {
          qty: getQty(products[i].id)?.quantity,
          price: Number(products[i]?.product?.price),
        }
      } else {
        priceChart[products[i].product.id].qty += getQty(
          products[i].id
        ).quantity
      }
    }

    let offerPrice = 0
    for (const [key, value] of Object.entries(priceChart)) {
      const productOffer = productoffers.find(
        ({ product }) => Number(product) === Number(key)
      )

      if (productOffer.offer.type === "discount") {
        offerPrice +=
          (value.qty *
            (value.price -
              (value.price * Number(productOffer.offer.value)) / 100)) /
          2
      } else {
        if (value.qty % 2 === 0) {
          offerPrice += (value.qty * value.price) / 2
        } else {
          offerPrice += ((value.qty - 1) * value.price) / 2
        }
      }
    }
    return offerPrice
  }

  const checkoutTotal = (totalPrice - getOfferPrice()).toFixed(2)

  const discountPrice = getOfferPrice()

  const [anchorEl, setAnchorEl] = useState(null)
  const [currentNumber, setCurrentNumber] = useState(null)

  const handlePrintOpen = () => {
    setPrint(!print)
  }

  const HandlePrintValueEdit = () => {
    handlePrintOpen()
    handleClose()
  }

  const onSubmit = (data) => {
    setPrint(false)
    if (Object.keys(printData).length && Object.keys(data ?? {}).length) {
      updateCartQuantity(printData, printData.quantity, data.initial)
    } else {
      updateCartQuantity(printData, printData.quantity, undefined)
    }
  }

  const handleClick = (event, index, data) => {
    setPrintData(data)
    setAnchorEl(event.currentTarget)
    setCurrentNumber(index)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setCurrentNumber(null)
  }

  const HandleRemovePrintValue = () => {
    if (Object.keys(printData)?.length) {
      handleClose()
      onSubmit()
    }
  }

  // const { state } = useLocation()

  // const [code, setCode] = useState("")
  // const [CodeApplied, setCodeApplied] = useState()
  

  // const VoucherFunc = async (e) => {
  //   e.preventDefault();
  
  //   try {
  //     const response = await fetch("http://192.168.0.117:8000/api/payment/redeem-voucher/", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: {
  //         couponCode: code,
  //         cartItems: state?.cartProductItemsDataBase,
  //         discountPrice:getOfferPrice(),
  //         totalPrice: Number(state?.checkoutTotal),
  //         userId: userInfo?.id,
  //       },
  //     });
  
  //     if (response.status === 200) {
  //       const data = await response.json();
  //       setCodeApplied(data);
  //     } else {
  //       throw new Error("Network response was not ok");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };
  

  // const VocherFunc = async (e) => {

  //   e.preventDefault()
  //   await axios
  //     .post("/api/payment/redeem-voucher/", {
  //       couponCode: code,
  //       cartItems: state?.cartProductItemsDataBase,
  //       discountPrice: getOfferPrice(),
  //       totalPrice: Number(state?.checkoutTotal),
  //       userId: userInfo?.id,
  //     })
  //     .then((data) => {
  //       if (data?.status === 200) {
  //         setCodeApplied(data)
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //     })
  // }

  return (
    <Drawer
      className=""
      anchor={"right"}
      transitionDuration={500}
      open={open}
      onClose={() => forCartOpen()}
    >
      <div className="pointer-events-auto w-screen xs:w-[27rem] md:w-[28rem] font-style ">
        <div className="flex h-screen flex-col  bg-white shadow-xl">
          <div className="flex  justify-between p-4  shadow-lg  relative w-full z-10  rounded-b-xl bg-gradient">
            <div className="text-lg  ml-1 font-style capitalize flex">
              shopping cart
              <BsCart3 className="w-5 h-5 ml-2 mt-1" />
            </div>

            <div className="ml-3 flex h-7 items-center">
              <div
                type="Button"
                className="-m-2 p-2 text-gray-800 "
                onClick={() => forCartOpen()}
              >
                <AiOutlineClose
                  className="h-6 w-6 hover:scale-125 "
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          {!cartProductItemsDataBase?.length && !loading ? (
            <div className="w-full flex justify-center items-center h-full ">
              <div className="px-5 w-full">
                <img src={cartimg} alt=""></img>

                <div className="flex justify-center">
                  <Button
                    onClick={() => forCartOpen()}
                    className="text-center lg:w-1/2 bg-primary focus:outline-none  h-12 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg  my-8  w-full"
                  >
                    <Link to="/"> Go Back Home</Link>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className=" overflow-y-auto  my-4 m-3 sm:mr-4  style-4  ">
                <div className="">
                  <div className="flow-root">
                    <PrintInitial
                      forPrintOpen={handlePrintOpen}
                      PrintOpen={print}
                      onSubmit={onSubmit}
                    />
                    <div role="list" className="  sm:px-3">
                      {cartProductItemsDataBase?.map((item, i) => {
                        const dataBaseQty = cartItemsDBorLS?.find((obj) => {
                          return obj.product_item === item.id
                        })

                        const isProductOffer = productoffers.find(
                          (val) => val.product === item.product.id
                        )
                        const offer =
                          isProductOffer?.offer?.type === "custom"
                            ? chceckOffer(item.product.id, item.id)
                            : ""

                        return (
                          <div className={` my-5  `} key={i}>
                            <div
                              className={` rounded-xl overflow-hidden shadow-md ${
                                item.quantity < 7 ? "border border-red-800" : ""
                              } `}
                            >
                              {item.quantity === 0 ? (
                                <div className="capitalize text-xs sm:text-sm border-b-2 rounded-sm mt-2">
                                  <span className="text-red-700 font-style ml-2 border-b-2">
                                    Out of stock at the moment !!
                                  </span>
                                </div>
                              ) : item.quantity < 7 ? (
                                <div className="capitalize text-xs sm:text-sm border-b-2      rounded-sm  mt-2">
                                  <span className="text-red-700 border-b-2  font-style ml-2">
                                    limited stock (only {item.quantity} left)
                                  </span>
                                </div>
                              ) : (
                                ""
                              )}

                              {isProductOffer?.offer?.type === "custom" && (
                                <>
                                  {offer === item.id ? (
                                    <div className="capitalize text-xs sm:text-sm border-b-2  text-[rgb(0,53,96)] bg-gradient  font-style  rounded-sm flex justify-between">
                                      <p className="p-2 flex items-center">
                                        <MdOutlineLocalOffer className="w-5 h-5 mr-1" />
                                        {isProductOffer?.offer?.value} Offer
                                        Available
                                      </p>
                                      <div
                                        onClick={() =>
                                          addProduct(item.product.id)
                                        }
                                      >
                                        <p className="p-2 font-bold cursor-pointer">
                                          Add Items
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="capitalize text-xs sm:text-sm border-b-2 text-[rgb(0,53,96)] bg-gradient  font-style  rounded-sm flex justify-between">
                                      <p className="p-2 flex items-center ">
                                        <svg
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          strokeWidth="1.5"
                                          stroke="currentColor"
                                          className="w-5 h-5 mr-1"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                                          />
                                        </svg>
                                        {isProductOffer?.offer?.value} Offer
                                        Applied
                                      </p>
                                    </div>
                                  )}
                                </>
                              )}

                              {isProductOffer?.offer?.type === "discount" && (
                                <div className="capitalize text-xs sm:text-sm border-b-2  text-black font-bold bg-gradient  font-style  rounded-sm flex justify-between">
                                  <p className="p-2 flex items-center ">
                                    <svg
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      strokeWidth="1.5"
                                      stroke="currentColor"
                                      className="w-5 h-5 mr-1"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                                      />
                                    </svg>
                                    {isProductOffer?.offer?.value} % discount
                                    offer
                                  </p>
                                </div>
                              )}

                              <div
                                key={i}
                                className={`flex p-2 ${
                                  isProductOffer ? "bg-gradient " : ""
                                } `}
                              >
                                <Link to={`/product/${item.product.id}?clr=0`}>
                                  <div className="h-28 w-20 flex-shrink-0 overflow-hidden rounded-md   ">
                                    <img
                                      src={item.image}
                                      alt="hello"
                                      className="h-full w-full object-contain object-center"
                                    />
                                  </div>
                                </Link>

                                <div className="ml-4 flex flex-1 flex-col ">
                                  <div>
                                    <div className="text-sm flex justify-between  font-style text-gray-700  ">
                                      <Link
                                        to={`/product/${item.product.id}?clr=0`}
                                        className=" outline-none truncate   w-20 2xs:w-36 xs:w-40 sm:w-44 lg:w-48"
                                        value={item?.id}
                                      >
                                        {item.product?.name}
                                      </Link>

                                      <div className="flex ">
                                        ({dataBaseQty?.quantity})
                                        {isProductOffer?.offer?.type ===
                                        "discount" ? (
                                          <p>
                                            ₹
                                            {(
                                              (item.product.mrp -
                                                item.product?.mrp *
                                                  (isProductOffer?.offer
                                                    ?.value /
                                                    100)) *
                                              dataBaseQty?.quantity
                                            ).toFixed(2)}
                                          </p>
                                        ) : (
                                          <p>
                                            ₹
                                            {(
                                              item.product?.price *
                                              dataBaseQty?.quantity
                                            )?.toFixed(2)}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex flex-1 items-center justify-start ">
                                    <div className="text-gray-700  lowercase font-style text-sm rounded-lg items-center justify-start text-start">
                                      <p>
                                        size :
                                        <span className="uppercase ml-1">
                                          {item?.size.name}
                                        </span>
                                      </p>
                                      <p>color : {item?.color.name} </p>
                                      <div className="flex items-center justify-start ">
                                        <h1 className="font-style">qty :</h1>
                                        <Button
                                          disabled={
                                            dataBaseQty?.quantity === 1
                                              ? true
                                              : false
                                          }
                                          onClick={() => {
                                            updateCartQuantity(
                                              dataBaseQty,
                                              Number(
                                                Math.max(
                                                  dataBaseQty?.quantity - 1,
                                                  1
                                                )
                                              ),
                                              dataBaseQty.print_value
                                            )
                                          }}
                                          className={`  ${
                                            dataBaseQty?.quantity === 1
                                              ? "cursor-not-allowed"
                                              : ""
                                          }  flex text-white bg-primary justify-center items-center p-0 w-4  h-4 m-1 rounded-md `}
                                        >
                                          <span className="text-sm font-style -mt-0.5 ">
                                            -
                                          </span>
                                        </Button>
                                        <span className="font-bold">
                                          {dataBaseQty?.quantity}
                                        </span>

                                        <Button
                                          disabled={
                                            dataBaseQty?.quantity >=
                                            item.quantity
                                              ? true
                                              : false
                                          }
                                          onClick={() => {
                                            updateCartQuantity(
                                              dataBaseQty,
                                              Number(
                                                Math.max(
                                                  Number(
                                                    dataBaseQty?.quantity
                                                  ) + 1,
                                                  1
                                                )
                                              ),
                                              dataBaseQty.print_value
                                            )
                                          }}
                                          className={`  ${
                                            dataBaseQty?.quantity >=
                                            item.quantity
                                              ? "cursor-not-allowed"
                                              : ""
                                          }  flex text-white bg-primary justify-center items-center p-0 w-4  h-4 m-1 rounded-md `}
                                        >
                                          <p className="text-sm font-style">
                                            +
                                          </p>
                                        </Button>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex justify-between font-style text-sm text-gray-700">
                                    {isProductOffer?.offer?.type ===
                                    "discount" ? (
                                      <div>
                                        <p className="flex items-center gap-2 text-xs text-black">
                                          {" "}
                                          MRP :
                                          <del className="hidden xs:block text-xs text-[#b91c1c]">
                                            ₹{item.product?.mrp}
                                          </del>
                                        </p>
                                        <p className="flex items-center  text-black  text-sm">
                                          price :
                                          <span className="  ml-2">
                                            ₹
                                            {(
                                              item.product.mrp -
                                              item.product?.mrp *
                                                (isProductOffer?.offer?.value /
                                                  100)
                                            ).toFixed(2)}
                                          </span>
                                          {/* <del className="hidden xs:block text-xs ml-1">
                                            ₹{item.product?.price}
                                          </del> */}
                                        </p>
                                      </div>
                                    ) : (
                                      <div>
                                        <p className="flex items-center gap-2 text-xs text-black">
                                          {" "}
                                          MRP :
                                          <del className="hidden xs:block text-xs text-[#b91c1c]">
                                            ₹{item.product?.mrp}
                                          </del>
                                        </p>

                                        <p className=" items-center text-black  text-sm">
                                          price :
                                          <span className="  ml-2">
                                            ₹{item.product?.price}
                                          </span>
                                        </p>
                                      </div>
                                    )}

                                    <div className="flex space-x-2 items-center">
                                      {dataBaseQty?.print_value && (
                                        <>
                                          <Tooltip
                                            title="Your Print initial"
                                            placement="top"
                                          >
                                            <button
                                              type="Button"
                                              onClick={(e) =>
                                                handleClick(e, i, dataBaseQty)
                                              }
                                              className="font-style  w-7 h-7  group duration-300 border border-primary hover:text-white text-primary rounded-full "
                                            >
                                              {dataBaseQty.print_value}
                                            </button>
                                          </Tooltip>
                                          <Menu
                                            anchorEl={anchorEl}
                                            id="account-menu"
                                            open={currentNumber === i}
                                            onClose={handleClose}
                                            PaperProps={{
                                              elevation: 0,
                                              sx: {
                                                overflow: "visible",
                                                filter:
                                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                                mt: 1.5,
                                                "& .MuiAvatar-root": {
                                                  width: 32,
                                                  height: 32,
                                                  ml: -0.5,
                                                  mr: 1,
                                                },
                                                "&:before": {
                                                  content: '""',
                                                  display: "block",
                                                  position: "absolute",
                                                  top: 0,
                                                  right: 14,
                                                  width: 10,
                                                  height: 10,
                                                  bgcolor: "background.paper",
                                                  transform:
                                                    "translateY(-50%) rotate(45deg)",
                                                  zIndex: 0,
                                                },
                                              },
                                            }}
                                            transformOrigin={{
                                              horizontal: "right",
                                              vertical: "top",
                                            }}
                                            anchorOrigin={{
                                              horizontal: "right",
                                              vertical: "bottom",
                                            }}
                                          >
                                            <MenuItem
                                              onClick={HandlePrintValueEdit}
                                            >
                                              Edit
                                            </MenuItem>
                                            <MenuItem
                                              onClick={HandleRemovePrintValue}
                                              sx={{ color: "red" }}
                                            >
                                              Remove
                                            </MenuItem>
                                          </Menu>
                                        </>
                                      )}
                                      <button
                                        type="Button"
                                        className="font-style text-red-700 p-2 group duration-300 hover:bg-red-200 hover:rounded-full"
                                        onClick={() =>
                                          removeFromCartHandler(
                                            item.id,
                                            item.product?.name,
                                            dataBaseQty.id
                                          )
                                        }
                                      >
                                        <svg
                                          viewBox="0 0 24 24"
                                          fill="currentColor"
                                          className="w-5 h-5  duration-300 group-hover:scale-125  "
                                        >
                                          <path
                                            fillRule="evenodd"
                                            d="M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z"
                                            clipRule="evenodd"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <hr />

                {/* <Accordion
                  className="relative  w-full py-2 "
                  style={{ boxShadow: "none" }}
                >
                  <AccordionSummary
                    className="flex w-full justify-between  items-center "
                    expandIcon={
                      <svg
                        className="fill-current h-9 w-5   text-gray-700"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    }
                  >
                    <div className=" text-gray-500 text-sm  font-style flex justify-center items-center">
                      <AiOutlineGift className=" w-4 h-4 mr-2" />
                      <p>ADD A GIFT WRAP</p>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="   ">
                    <div className=" lg:px-3">
                      <div className="flex justify-between  items-center my-2">
                        <label
                          htmlFor="price"
                          className="block text-sm font-style text-gray-700  capitalize"
                        >
                          Your name
                        </label>
                        <input
                          type="text"
                          name="price"
                          id="price"
                          className=" rounded-md border w-56 focus:outline-none sm:text-sm  p-2"
                          placeholder="your name"
                        />
                      </div>

                      <div className="flex justify-between  items-center my-2">
                        <label
                          htmlFor="price"
                          className="block text-sm font-style text-gray-700 capitalize "
                        >
                          recipient's name
                        </label>
                        <input
                          type="text"
                          name="price"
                          id="price"
                          className="block rounded-md border w-56 focus:outline-none p-2 sm:text-sm "
                          placeholder="Beloved one's name"
                        />
                      </div>

                      <div className="flex justify-between items-center my-2">
                        <label
                          htmlFor="price"
                          className="block text-sm font-style text-gray-700 capitalize "
                        >
                          your message
                        </label>
                        <textarea
                          type="text"
                          name="price"
                          id="price"
                          maxLength="250"
                          className=" rounded-md focus:outline-none border w-56 sm:text-sm  p-2 flex items-start"
                          placeholder="(250 CHARACTERS MAX)"
                        />
                      </div>

                      <div className="flex justify-between mt-5 ">
                        <Button className=" text-sm   p-1 px-7  bg-primary font-style">
                          <p className="drop-shadow-lg">CANCEL</p>
                        </Button>
                        <Button className=" text-sm   p-1 px-7 bg-primary font-style">
                          <p className="drop-shadow-lg text-center py-1">
                            ADD GIFT WRAP
                          </p>
                        </Button>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion> */}
                <hr />

                {/* <Accordion
                  className="relative  w-full py-2 "
                  style={{ boxShadow: "none" }}
                >
                  <AccordionSummary
                    className="flex w-full justify-between items-center "
                    expandIcon={
                      <svg
                        className="fill-current h-9 w-5   text-gray-700"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    }
                  >
                    <div className=" text-gray-500 text-sm   font-style flex justify-center items-center ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-4 h-4 mr-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                        />
                      </svg>
                      <p>REDEEM COUPON</p>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className="">

                  </AccordionDetails>
                </Accordion> */}

                {/* <div className="  p-3 rounded-xl ">
                  <p>REDEEM COUPON</p>
                  {CodeApplied?.data?.error && (
                    <p className="text-xs text-red-600">
                      {CodeApplied?.data?.error}
                    </p>
                  )}
                </div> */}

                {/* <form
                  className="flex items-center px-4"
                  onSubmit={(e) => VoucherFunc(e)}
                >
                  <div className="relative  ">
                    <input
                      type="text"
                      value={code}
                      required={!code.includes("-")}
                      maxLength={14}
                      minLength={14}
                      onChange={(e) => setCode(e.target.value)}
                      placeholder=" Enter reedeem code"
                      id="Redeem"
                      className={`block border font-style px-2.5 pb-2.5 w-48 2xs:w-auto  pt-2  text-[15px] text-gray-900 bg-gray-100 rounded-lg peer focus:outline-none focus:ring-0 `}
                    />
                  </div>
                  <button
                    type="submit"
                    className="text-medium ml-3 mt-1 h-9 mb-0.5 bg-primary px-2 text-white rounded-lg"
                  >
                    Apply
                  </button>
                </form> */}

             

                <div className="divide-y divide-dashed p-2 divide-gray-300  font-style ">
                  <div className="flex justify-between p-2 items-center ">
                    <p className=" text-gray-600">Total M.R.P : </p>
                    <p>₹{cartTotalMrp}</p>
                  </div>
                  <div className="flex justify-between p-2">
                    <p className="text-gray-600">Savings on M.R.P :</p>
                    <p className="text-[#ef3237]">
                      - ₹{" "}
                      {(Number(saveMrp) + Number(getOfferPrice())).toFixed(2)}
                    </p>
                  </div>

                  
                  {/* <div className="flex justify-between p-2">
                    <p className="text-gray-600"> Products total price :</p>
                    <p>₹ {totalPrice?.toFixed(2)}</p>
                  </div> */}
                  {/* <div className="flex justify-between p-2">
                    <p className="text-gray-600"> Promotion applied :</p>
                    <p className=" text-[#ef3237] ">
                      - ₹ {getOfferPrice().toFixed(2)}
                    </p>
                  </div> */}

                  <div className="flex justify-between p-2">
                    <p className="text-gray-600"> Sub-total :</p>
                    <p className=" text-gray-900 ">₹ {checkoutTotal}</p>
                  </div>

                  {/* {!CodeApplied?.data?.error && CodeApplied && (
                <div className="m-1 pt-2 flex justify-between">
                  <div> Vocher applied </div>
                  <div className="text-green-700">
                    - ₹ {Number(CodeApplied?.data.CouponPrice).toFixed(2)}
                  </div>
                </div>
              )} */}

                  <div className="flex justify-between p-2">
                    <p className="text-gray-600"> Shipping :</p>
                    <p className=" text-gray-900 ">
                      {checkoutTotal >= Number(599) ? (
                        <p className="text-center mt-auto capitalize text-[15px] text-primary font-style">
                          Free
                        </p>
                      ) : (
                        <>
                          <p className="text-center mt-auto  text-[15px] capitalize text-sm text-primary font-style">
                            ₹ 49.00
                          </p>
                        </>
                      )}
                    </p>
                  </div>

                  {/* <div className="flex justify-between p-2">
                    <p className="text-gray-600"> Order total :</p>
                    <p className=" text-gray-900 ">
                      - ₹ {getOfferPrice().toFixed(2)}
                    </p>
                  </div> */}

                  <div className="flex justify-between p-2 items-center text-black">
                    <p className=" text-black text-lg">Net Payable :</p>
                    {checkoutTotal >= Number(599) ? (
                      <p className="text-center mt-auto capitalize text-[15px] text-primary font-style">
                        ₹ {checkoutTotal}
                      </p>
                    ) : (
                      <>
                        <p className="text-center mt-auto  text-[15px] capitalize text-sm text-primary font-style">
                          ₹ {(Number(checkoutTotal) + 49.0).toFixed(2)}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className=" mt-auto">
                {checkoutTotal >= Number(599) ? (
                  <p className="text-center mt-auto capitalize text-[15px] text-primary font-style">
                    your order is eligible for free delivery
                  </p>
                ) : (
                  <div className="m-2">
                    <p className="text-center mt-auto sm:text-[15px] text-[13px] capitalize text-sm text-primary font-bold ">
                      get free delivery!!
                      <span className="text-black ml-1 font-normal">
                        on orders above{" "}
                        <span className="text-green-800 font-bold ">
                          {" "}
                          ₹ 599{" "}
                        </span>
                      </span>
                    </p>
                    <p className="text-center mt-auto sm:text-[15px] text-[13px] text-black font-style ">
                      Add
                      <span className="mx-1 text-black font-bold ">
                        ₹ {(Number(599) - Number(checkoutTotal)).toFixed(2)}
                      </span>
                      Worth of Product to Get Free Delivery
                    </p>
                  </div>
                )}
              </div>
              <div className=" px-4 sm:px-5 pb-4  shadow-md rounded-lg   bg-gray-100 m-3">
                <div className="flex justify-between text-lg font-bold  text-gray-700  mt-4">
                  TOTAL AMOUNT{" "}
                  <p className="mr-4">
                    ₹{" "}
                    {checkoutTotal >= Number(599) ? (
                      <>{checkoutTotal}</>
                    ) : (
                      <>{(Number(checkoutTotal) + 49.0).toFixed(2)}</>
                    )}
                  </p>
                </div>

                <p className="mt-1 text-sm text-gray-500 mb-5">
                  Shipping and taxes calculated at checkout.
                </p>

                <Button
                  onClick={checkoutHandler}
                  className="text-center  bg-primary  h-12 flex justify-center items-center text-white text-[15px] font-style capitalize  rounded-lg    w-full "
                >
                  Continue to Checkout
                </Button>
              </div>
            </>
          )}
        </div>

        {!userInfo ? (
          <LoginSlide forProfileOpen={forProfileOpen} open={prof.profile} />
        ) : (
          ""
        )}
      </div>
    </Drawer>
  )
})

export default CartPage
