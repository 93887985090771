import React from "react";
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import loading from "../images/Myunde.png";
// import loading from "../images/MLogo.webp";
// import loading from "../images/loader.gif"

function Loader() {
  return (
    <div className=" opacity-[0.5] justify-center items-center flex w-full h-screen -mt-20 sm:-mt-32 md:-mt-32 lg:-mt-[150px]  xl:-mt-[155px] 2xl:-mt-[150px]   ">
      <img src={loading} alt="brand logo" className="w-32 md:w-52  " />
    </div>



    // <Spinner
    //     animation='border'
    //     role='status'
    //     style={{
    //         height: '100px',
    //         width: '100px',
    //         margin: 'auto',
    //         display: 'block'
    //     }}
    // >
    //     <span className='sr-only'>Loading...</span>
    // </Spinner>

    /* <SkeletonTheme/> */
    /* <div className="mr-5 ml-12">
            <Skeleton width={240} height={50} className="mr-5 mt-48 ml-5" />
            <Skeleton width={240} height={25} className="mr-5 ml-5" />
            <Skeleton width={240} height={30} className="mr-5 ml-5" />
            <Skeleton width={240} height={30} className="mr-5 ml-5" />
            <Skeleton width={240} height={30} className="mr-5 ml-5" />
            <div>
            <Skeleton width={240} height={50} className="mr-5 mt-5 ml-5" />
            <Skeleton width={240} height={25} className="mr-5 ml-5" />
            <Skeleton width={240} height={30} className="mr-5 ml-5" />
            <Skeleton width={240} height={30} className="mr-5 ml-5" />
            <Skeleton width={240} height={30} className="mr-5 ml-5" />

            </div>

            </div> */

    /*          
         <Skeleton width={300} height={500} className="mr-2 mx-6 mt-20" />
         <Skeleton width={300} height={500}  className="mr-2  mt-20"/>
         <Skeleton width={300} height={500}  className="mr-2  mt-20"/>
         <Skeleton width={300} height={500}  className="mr-2  mt-20"/> */
  );
}

export default Loader;
