import React, { useState } from "react";
import "../css/sizeselect.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { listCampaignImages } from "../actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import video from "../images/3 BRIEF FINAL.mp4";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Error404 from "../components/ServerErrorPage";
import Loader from "../components/Loader";
import ProgressiveImage from "react-progressive-graceful-image";
import load from "../images/myloGo.jpg";
import loader from "../images/Campaign alter_TRunk campaign copy.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Fab, Tooltip } from "@mui/material";
import { FaWhatsapp } from "react-icons/fa";
import loadImg from "../images/logo loading page.gif";
import FooterContent from "../components/FooterContent";
// import { getBlogCategory } from "../actions/blogActions";
// import { Helmet } from "react-helmet";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function HomePage() {
  useEffect(() => {
    AOS.init();
  }, []);

  const categoryList = useSelector((state) => state.categoryList);
  const { category } = categoryList;

  const dispatch = useDispatch();
  const campaignIamgesList = useSelector((state) => state.campaignimagesList);

  const { campaignimages, loading, error } = campaignIamgesList;

  useEffect(() => {
    if (!campaignimages?.length) {
      dispatch(listCampaignImages());
    }
  }, []);

  function groupBy(array) {
    const groupData = [];
    for (let index = 0; index < array?.length; index++) {
      const dataInstance = groupData.find(
        (value) => value.id === array[index].campaign
      );
      if (!dataInstance) {
        groupData.push({ id: array[index].campaign, value: [array[index]] });
      } else {
        dataInstance.value.push(array[index]);
      }
    }
    return groupData.map((v) => v.value);
  }

  const navigate = useNavigate();
  const slidesDesktop = campaignimages?.filter(
    (desktop) => desktop.img_position === "desktopSliderImages"
  );
  const slideMobile = campaignimages?.filter(
    (desktop) => desktop.img_position === "mobileSliderImages"
  );

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const BodyImages =
    dimensions.width >= 640
      ? Object.values(
          groupBy(
            campaignimages?.filter(
              (desktop) => desktop.img_position === "desktopBodyImages"
            )
          )
        )
      : Object.values(
          groupBy(
            campaignimages?.filter(
              (desktop) => desktop.img_position === "mobileBodyImages"
            )
          )
        );

  const CustomRight = ({ onClick }) => (
    <div className="  absolute left-[1%] " onClick={onClick}>
      <svg
        className="w-10 h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 2xl:w-16 2xl:h-16 text-white svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="arcs"
      >
        <path d="M15 18l-6-6 6-6" />
      </svg>
    </div>
  );

  const CustomLeft = ({ onClick }) => (
    <div className=" absolute right-[1%] " onClick={onClick}>
      <svg
        className="w-10 h-10 lg:w-12 lg:h-12 xl:w-14 xl:h-14 2xl:w-16 2xl:h-16 text-white svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="arcs"
      >
        <path d="M9 18l6-6-6-6" />
      </svg>
    </div>
  );

  const [hoverName, setHoverName] = useState([]);

  const effectName = (img) => {
    if (img?.brand_offer) {
      const filteredeffectName = category.filter(
        (cat) => cat.id === img?.brand_offer?.category
      );

      setHoverName(filteredeffectName);
    } else if (img?.product_offer) {
      // console.log(img?.product_offer);
    } else {
      // console.log(img);
    }
  };

  const NavigatePage = (brandOffer, productOffer, urlOffer) => {
    if (urlOffer?.url) {
      navigate(urlOffer?.url);
      return;
    }
    if (
      productOffer?.product == null &&
      (brandOffer?.category != null || brandOffer?.brand != null)
    ) {
      navigate(
        `products/?category=${brandOffer.category ?? ""}&brand=${
          brandOffer.brand ?? ""
        }&style=&fabric=&color=&size=`
      );
    } else if (productOffer != null) {
      navigate(`product/${productOffer?.product}?clr=0`);
    } else return;
  };

  return (
    <div className="select-none font-poppins">
      {loading || !BodyImages || !slidesDesktop ? (
        <Loader />
      ) : error ? (
        <Error404 />
      ) : (
        <div>
          <div>
            {/* -mt-2 sm:mt-8 */}
            <div className="bg-transparent  overflow-x-scroll lg:hidden w-full  no-scrollbar z-40">
              <div className="flex  w-full  py-2">
                {category?.map((category, i) => {
                  return (
                    <Link
                      to={`products/?category=${category.id}`}
                      className="w-full "
                      key={i}
                    >
                      <div className="w-16 h-16 sm:w-20 sm:h-20  overflow-hidden border-2 border-gray-400   rounded-full mx-2 bg-gradient">
                        <ProgressiveImage
                          src={category.image}
                          placeholder={load}
                        >
                          {(src) => (
                            <img
                              src={src}
                              alt={`Myunde ${
                                category.name ?? "campaign images"
                              }  Speciallty for men`}
                            />
                          )}
                        </ProgressiveImage>
                      </div>
                      <h1 className="text-[9px] sm:text-[10px] text-center font-bold text-primary">
                        {category.name}
                      </h1>
                    </Link>
                  );
                })}
              </div>
            </div>

            <div className="sm:hidden  px-2 ">
              <Carousel
                responsive={responsive}
                autoPlay={true}
                autoPlaySpeed={4000}
                infinite={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                className=""
              >
                {slideMobile?.map((slide, i) => {
                  return (
                    <div
                      className="flex justify-center"
                      key={i}
                      onClick={() =>
                        NavigatePage(
                          slide.brand_offer,
                          slide.product_offer,
                          slide.url_offer
                        )
                      }
                    >
                      <LazyLoadImage
                        src={slide.image}
                        alt={`Myunde - Speciallty for men`}
                        width={600}
                        height={400}
                      ></LazyLoadImage>
                      {/* <img
                        className="w-full"
                        src={slide.image}
                        alt={`Myunde - Speciallty for men`}
                      /> */}
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
          {!slidesDesktop == [] ? (
            <>
              {/* lg:-mt-4 */}
              <div className="relative  overflow-hidden hidden sm:grid grid-cols-5 lg:pt-3 xl:pt-1.5 2xl:pt-2.5">
                <div className="col-span-5 ">
                  <Carousel
                    responsive={responsive}
                    autoPlay={true}
                    autoPlaySpeed={4000}
                    infinite={true}
                    customLeftArrow={<CustomRight />}
                    customRightArrow={<CustomLeft />}
                    removeArrowOnDeviceType={["mobile"]}
                    className=""
                  >
                    {slidesDesktop?.map((slide, i) => {
                      return (
                        <div
                          onClick={() =>
                            NavigatePage(
                              slide.brand_offer,
                              slide.product_offer,
                              slide.url_offer
                            )
                          }
                          className="flex justify-center "
                          key={i}
                        >
                          <img
                            data-ciid={slide.campaign}
                            className="w-full "
                            src={slide.image}
                            alt={`Myunde - Speciallty for men`}
                          />
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
                {/* <div className="">
                  <video
                    src={video}
                    className="video"
                    loop
                    autoPlay
                    muted
                    poster={loadImg}
                  ></video>
                </div> */}
              </div>
            </>
          ) : (
            ""
          )}

          {BodyImages.map((image, i) => {
            if (image?.length === 1) {
              return (
                <div className="flex " key={i}>
                  {image.map((img, i) => {
                    return (
                      <div
                        key={i}
                        className="w-screen "
                        onClick={() =>
                          NavigatePage(
                            img.brand_offer,
                            img.product_offer,
                            img.url_offer
                          )
                        }
                      >
                        <ProgressiveImage src={img.image} placeholder={loader}>
                          {(src) => (
                            <img
                             data-ciid={img.campaign}
                              src={src}
                              alt={`Myunde - Speciallty for men `}
                              className="w-full"
                            />
                          )}
                        </ProgressiveImage>

                        {/* <LazyLoadImage
                        src={img.image}
                        alt={`Myunde - Speciallty for men`}
                      ></LazyLoadImage> */}
                      </div>
                    );
                  })}
                </div>
              );
            } else if (image?.length === 2) {
              return (
                <div className="flex justify-evenly" key={i}>
                  {image.map((img, i) => {
                    return (
                      <div
                        onClick={() =>
                          NavigatePage(
                            img.brand_offer,
                            img.product_offer,
                            img.url_offer
                          )
                        }
                        key={i}
                        className="w-1/2  flex"
                      >
                        <ProgressiveImage src={img.image} placeholder={load}>
                          {(src) => (
                            <img
                            data-ciid={img.campaign}
                              src={src}
                              alt={`Myunde - Speciallty for men `}
                              className="w-full"
                            />
                          )}
                        </ProgressiveImage>
                        {/* <LazyLoadImage
                        src={img.image}
                        alt={`Myunde - Speciallty for men`}
                      
                      ></LazyLoadImage> */}
                      </div>
                    );
                  })}
                </div>
              );
            } else if (image?.length === 3) {
              return (
                <div className="flex  justify-center  " key={i}>
                  {image.map((img, i) => {
                    return (
                      <div
                        onClick={() =>
                          NavigatePage(
                            img.brand_offer,
                            img.product_offer,
                            img.url_offer
                          )
                        }
                        key={i}
                        className={`sm:w-1/3 ${
                          img.effect === "zoom-in" && "border-hover"
                            ? "border-4  md:border-[15px] border-white overflow-hidden"
                            : ""
                        } `}
                      >
                        <ProgressiveImage src={img.image} placeholder={load}>
                          {(src) => (
                            <div
                              className={`${
                                img.effect === "zoom-in" && "hover-border"
                                  ? " scale-110 "
                                  : ""
                              }`}
                            >
                              {img.effect === "zoom-in" ? (
                                <img
                                data-ciid={img.campaign}
                                  src={src}
                                  alt={`Myunde - Speciallty for men`}
                                  className="w-full"
                                  data-aos-duration="1200"
                                  data-aos="zoom-out"
                                  data-sizes="auto"
                                  data-parent-fit="cover"
                                />
                              ) : img.effect === "hover-border" ? (
                                <div
                                  className={` ${
                                    img.effect === "hover-border"
                                      ? "   lg:border-[15px] border-white overflow-hidden group  relative  "
                                      : "group  relative  overflow-hidden"
                                  } `}
                                >
                                  <div
                                    className="z-10 absolute  text-4xl  hidden lg:group-hover:block  hover:bg-black/30 duration-500 w-full h-full   "
                                    onMouseOver={() => effectName(img)}
                                  >
                                    <h2 className=" text-white border-2 border-white  flex items-center justify-center h-[86%] m-8 xl:m-12">
                                      <p>
                                        {img?.brand_offer
                                          ? hoverName[0]?.name
                                          : ""}
                                      </p>
                                    </h2>
                                  </div>

                                  <img
                                    className="lg:scale-110"
                                    data-ciid={img.campaign}
                                    src={src}
                                    alt={`Myunde - Speciallty for men`}
                                  />
                                </div>
                              ) : (
                                <img
                                  src={src}
                                  data-ciid={img.campaign}
                                  alt={`Myunde - Speciallty for men`}
                                  className="w-full"
                                />
                              )}
                            </div>
                          )}
                        </ProgressiveImage>

                        {/* <LazyLoadImage
                        src={img.image}
                        alt={`Myunde - Speciallty for men`}
                        
                      ></LazyLoadImage> */}
                      </div>
                    );
                  })}
                </div>
              );
            } else if (image?.length === 4) {
              return (
                <div className="flex" key={i}>
                  {image.map((img, i) => {
                    return (
                      <div
                        onClick={() =>
                          NavigatePage(
                            img.brand_offer,
                            img.product_offer,
                            img.url_offer
                          )
                        }
                        key={i}
                        className=" flex-start lg:w-1/4  flex "
                      >
                        <ProgressiveImage src={img.image} placeholder={load}>
                          {(src) => (
                            <div className="">
                              {img.effect === "hover-border" ? (
                                <div
                                  className={` ${
                                    img.effect === "hover-border"
                                      ? " lg:border-[15px] border-white overflow-hidden group  relative  "
                                      : "group  relative  overflow-hidden"
                                  } `}
                                >
                                  <div
                                    onMouseOver={() => effectName(img)}
                                    className=" z-10 absolute  text-4xl  hidden lg:group-hover:block  hover:bg-black/30 duration-500 w-full h-full   "
                                  >
                                    <h2 className="duration-500 text-white border-2 border-white flex items-center justify-center h-[86%] m-8 ">
                                      <p></p>
                                    </h2>
                                  </div>

                                  <img
                                    className="lg:scale-125 "
                                    data-ciid={img.campaign}
                                    src={src}
                                    alt={`Myunde - Speciallty for men`}
                                  />
                                </div>
                              ) : (
                                <img
                                  src={src}
                                  alt={`Myunde - Speciallty for men`}
                                  className="w-full"
                                  data-ciid={img.campaign}
                                />
                              )}
                            </div>
                          )}
                        </ProgressiveImage>

                        {/* <LazyLoadImage
                        src={img.image}
                        alt={`Myunde - Speciallty for men`}
                       
                      ></LazyLoadImage> */}
                      </div>
                    );
                  })}
                </div>
              );
            }
          })}
        </div>
      )}
      <FooterContent />
    </div>
  );
}

export default HomePage;
